import React, { useState } from "react";
import { useSelector } from "react-redux";
import FileUploadModal from "../../../components/shared/FileUploadModal";
import LoadingModal from "../../../components/shared/LoadingModal";
import { createUserGallery } from "../../../services/contentService";

export default function CreateNewAlbum({ albums, setAlbums }) {
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const appSlice = useSelector((state) => state?.app);
  const user = appSlice?.user;

  const upload = async () => {
    if (!user) throw new Error("Unauthorized");
    setLoading(true);
    try {
      const album = await createUserGallery({ user, files, title });
      const albumsCopy = albums;
      albumsCopy.unshift(album);
      setAlbums([...albumsCopy]);
    } catch (error) {
      throw error;
    }finally{
      setLoading(false);
      setShowModal(false);
      setFiles([]);
    }
  };

  return (
    <>
      {showModal && (
        <FileUploadModal
          setShowModal={(show) => setShowModal(show)}
          files={files}
          setFiles={(files) => setFiles(files)}
          onSubmit={() => upload(files)}
          shouldClearFilesOnClose={true}
          shouldClearFilesOnSubmit={true}
          mediaType="image"
          setTitle={(title) => setTitle(title)}
        />
      )}
      {loading && <LoadingModal />}
      <button
        className="px-6 mx-auto btn w-52"
        onClick={() => setShowModal(true)}
      >
        YENİ ALBÜM OLUŞTUR
      </button>
    </>
  );
}
