import React from "react";
import classNames from "classnames";
import KalekimKvkk from "./KalekimKvkk";
import KaleseramikKvkk from "./KaleseramikKvkk";

export default function KvkkPolicyModal(props) {
  const { kvkkActiveTab, setKvkkActiveTab, kvkkModalOpen, setKvkkModalOpen } =
    props;

  return (
    kvkkModalOpen && (
      <div className="min-h-screen inline-block align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-full sm:w-full sm:pb-16 sm:pt-12 sm:px-12 outline-none">
        <div className="absolute pt-4 pr-4 sm:block top-5 right-5">
          <button
            type="button"
            onClick={() => setKvkkModalOpen(false)}
            className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            <span className="sr-only">Close</span>
            <span className="sr-only">Profile</span>
            <svg role="img" className="w-6 h-6 text-primary-black">
              <use xlinkHref="/assets/img/necromancers.svg#cross" />
            </svg>
          </button>
        </div>
        <div className="modal-body">
          <div className="flex flex-col items-center">
            <div className="space-y-12">
              <h1 className="text-xxl" id="modal-title">
                K.V.K.K.
              </h1>
              <div className="text-center">
                <div className="relative z-0 inline-flex p-0.5 bg-secondary-secondary-bg mx-auto">
                  <button
                    type="button"
                    onClick={() => setKvkkActiveTab(1)}
                    className={classNames(
                      kvkkActiveTab === 1
                        ? "inline-block !bg-primary-red !text-white active"
                        : "inline-block !text-primary-black !bg-transparent",
                      "tab-btn text-primary-black hover:text-white"
                    )}
                  >
                    {" "}
                    Kalekim{" "}
                  </button>
                  <button
                    type="button"
                    onClick={() => setKvkkActiveTab(2)}
                    className={classNames(
                      kvkkActiveTab === 2
                        ? "inline-block !bg-primary-red !text-white active"
                        : "inline-block !text-primary-black !bg-transparent",
                      "tab-btn text-primary-black hover:text-white"
                    )}
                  >
                    {" "}
                    Kaleseramik{" "}
                  </button>
                </div>
              </div>
              {kvkkActiveTab === 1 ? (
                <div className="prose-xxl">
                  <KalekimKvkk />
                </div>
              ) : (
                <div className="prose-xxl">
                  <KaleseramikKvkk />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
