import React from "react";
import Select from "react-select";

const CustomSelect = ({
  options,
  placeholder,
  defaultValue,
  value,
  onChange,
  name,
  error,
}) => {
  const customStyles = {
    control: (styles, { isFocused }) => {
      let border;
      let boxShadow;

      if (error) {
        border = "1px solid red";
      } else if (isFocused) {
        border = "2px solid #212121";
        boxShadow = "2px solid #212121";
      } else {
        border = "1px solid #cecdd9";
        boxShadow = "1px solid #cecdd9";
      }

      return {
        ...styles,
        borderRadius: 0,
        border: border,

        boxShadow: boxShadow,

        "&:hover": {
          border: error ? border : "1px solid #cecdd9",
        },
      };
    },
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "default",
        "&:hover": {
          cursor: !isDisabled ? "pointer" : "not-allowed",
        },
      };
    },
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        display: "none",
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        fontWeight: 400,
        color: "#68836e",
        fontSize: "0.8rem",
      };
    },

    input: (styles, { isFocused }) => {
      return {
        ...styles,
        paddingTop: "1rem",
        paddingBottom: "0.375rem",
        boxShadow: "none",
        "&:focus": {
          border: isFocused ? "none !important" : "none !important",
          outline: isFocused ? "none !important" : "none !important",
          boxShadow: isFocused ? "none !important" : "none !important",
          borderColor: "#fff",
        },
      };
    },

    menu: (styles) => {
      return {
        ...styles,
        borderRadius: 0,
      };
    },
  };
  return (
    <div className="container">
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        styles={customStyles}
        className="basic-single"
        classNamePrefix="select"
        name={name}
        options={options}
      />
    </div>
  );
};

export default CustomSelect;
