import React, { useEffect, useState } from "react";
import classNames from "classnames";

export default function Image({ gallery, image, index , selectedImages, setSelectedImages}) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
      const selectedImagesCopy = selectedImages;
      if(selected){
        setSelectedImages([...selectedImages, image])
      }else{
        if(selectedImagesCopy[index] === image){
            selectedImagesCopy?.splice(index,1);
            setSelectedImages([...selectedImagesCopy]);
          }
      }
      //eslint-disable-next-line
  },[selected])

  useEffect(() => {
    if(selectedImages?.length < 1){
      setSelected(false);
    }
  },[selectedImages])

  return (
    <div
      className="flex-col col-span-6 lg:col-span-4 lg:flex cursor-pointer"
      onClick={() => setSelected(!selected)}
    >
      <div
        className={classNames(selected && "selected-image", "flex flex-col")}
      >
        <img
          src={image?.url}
          className="w-full"
          draggable="false"
          alt={`${gallery?.user?.Name}-img-${index}`}
        />
        {selected && (
          <div className="select-icon">
            <svg role="img" className="w-8 text-white">
              <use xlinkHref="/assets/img/necromancers.svg#check" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
