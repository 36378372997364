import React, { useState } from "react";
import {
  isUserAttendActivity,
  leaveActivity,
} from "../../../services/contentService";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingModal from "../../../components/shared/LoadingModal";
import Modal from "../../../components/shared/Modal";

function SingleActivity({ activity }) {
  const [isUserAttended, setIsUserAttended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModalForLeave, setShowSuccessModalForLeave] =
    useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [apiError, setApiError] = useState("");
  const user = useSelector((state) => state.app.user);

  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  const date = new Date();
  const eventDate = new Date(activity.activityDate?.iso);

  const handleLeaveActivity = async (id) => {
    try {
      setIsLoading(true);
      await leaveActivity(id);
      setIsUserAttended(false);
      setShowSuccessModalForLeave(true);
    } catch (e) {
      setApiError(e.message);
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIsUserAttendActivity = async (activityId) => {
    try {
      setIsLoading(true);
      const response = await isUserAttendActivity(activityId, user?.objectId);
      if (response) {
        setIsUserAttended(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const isUserAttendActivityMarkup = () => {
    if (date > eventDate) {
      return (
        <button
          disabled
          className="w-full max-w-none lg:w-48 bg-primary-gray form-submit-btn hover:bg-primary-gray px-0"
        >
          SÜRESİ DOLMUŞTUR
        </button>
      );
    } else if (isUserAttended) {
      return (
        <button
          onClick={() => handleLeaveActivity(activity?.objectId)}
          className="w-full uppercase max-w-none lg:w-28 bg-primary-red form-submit-btn revert"
        >
          Vazgeç
        </button>
      );
    } else {
      return (
        <div className="activity-attend-button">
          {activity?.capacity > 0 ? (
            <Link
              to={`/activity/${activity.objectId}`}
              className="w-full uppercase max-w-none lg:w-48 bg-primary-red form-submit-btn"
            >
              ETKİNLİĞE KATIL
            </Link>
          ) : (
            <button
              disabled
              className="w-full uppercase max-w-none lg:w-48 bg-primary-gray form-submit-btn hover:bg-primary-gray"
            >
              Kontenjan Dolu
            </button>
          )}
        </div>
      );
    }
  };

  useEffect(() => {
    handleIsUserAttendActivity(activity?.objectId, user?.objectId);

    // eslint-disable-next-line
  }, [activity?.objectId, user?.objectId]);

  return (
    <div
      className="gap-6 lg:border lg:p-6 lg:flex lg:border-secondary-lines"
      key={activity.objectId}
    >
      <Modal
        shouldShow={showSuccessModalForLeave}
        updateModalState={(show) => setShowSuccessModalForLeave(show)}
        msg="Etkinliğe katılmaktan başarıyla vazgeçtiniz."
        type="success"
      />

      <Modal
        shouldShow={showErrorModal}
        updateModalState={(show) => setShowErrorModal(show)}
        msg={apiError}
        type="error"
      />

      {isLoading && <LoadingModal />}
      <div className="w-[108px] h-[108px] bg-secondary-secondary-bg flex flex-col items-center float-left">
        <p className="text-center text-md font-md">
          {new Date(activity.activityDate?.iso).getDate()}
        </p>
        <p className="text-xs text-center font-md">
          {monthNames[new Date(activity.activityDate?.iso).getMonth()]}
        </p>
        <p className="text-xs text-center font-md">
          {new Date(activity.activityDate?.iso).getFullYear()}
        </p>
      </div>
      <div className="flex-col lg:flex">
        <div className="float-left pl-4 lg:pl-0">
          <Link to={`/activity/${activity.objectId}`}>
            <h6>{activity?.title}</h6>
          </Link>
          <div className="flex flex-col gap-5 pt-2 lg:flex-row">
            <div className="relative">
              {activity?.city ? (
                <span className="flex items-center text-secondary-secondary text-body-s">
                  {activity?.city?.NAME}{" "}
                  {activity?.region?.NAME && `/ ${activity?.region?.name}`}
                </span>
              ) : (
                <span className="flex items-center text-secondary-secondary text-body-s">
                  Tüm iller ve İlçeler
                </span>
              )}
            </div>
            <div className="relative">
              <span className="flex items-center text-secondary-secondary text-body-s list-dot">
                Etkinlik Bitiş Tarihi;{" "}
                {new Date(activity.deadlineDate?.iso).toLocaleDateString()}
              </span>
            </div>
          </div>
        </div>
        <div className="float-left pt-4 lg:pt-0">
          <p className="max-w-3xl text-left text-secondary-secondary">
            {activity?.description}
          </p>
          <div className="flex gap-6 mt-4 lg:pt-4 lg:mt-2">
            <div className="relative">
              <span className="flex items-center text-secondary-secondary font-md text-body-s">
                <svg
                  className="w-5 h-5 mr-2 text-secondary-primary "
                  role="img"
                >
                  <use xlinkHref="/assets/img/necromancers.svg#user" />
                </svg>
                {activity?.capacity + activity?.attendies} Kişilik
              </span>
            </div>
            <div className="relative">
              <span className="flex items-center text-secondary-secondary text-body-s font-md span-dots list-dot black-dot">
                <svg
                  className="w-5 h-5 mr-2 text-secondary-primary "
                  role="img"
                >
                  <use xlinkHref="/assets/img/necromancers.svg#time" />
                </svg>
                {activity?.duration} saat
              </span>
            </div>
            <div className="relative">
              <span className="flex items-center text-secondary-secondary font-md text-body-s list-dot black-dot">
                <svg
                  className="w-5 h-5 mr-2 text-secondary-primary "
                  role="img"
                >
                  <use xlinkHref="/assets/img/necromancers.svg#friends" />
                </svg>
                {activity?.attendies} kişi katıldı
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="items-center justify-end float-left w-full pt-4 lg:w-auto lg:pt-0 lg:flex lg:ml-auto">
        {/* {handleIsUserAttendActivity(activity.objectId) ? (
          <button className="w-full uppercase max-w-none lg:w-28 bg-primary-red form-submit-btn revert">
            Vazgeç
          </button>
        ) : (
          <button
            onClick={() => handleAttendActivity(activity?.objectId)}
            className="w-full uppercase max-w-none lg:w-48 bg-primary-red form-submit-btn"
          >
            ETKİNLİĞE KATIL
          </button>
        )} */}
        {isUserAttendActivityMarkup()}
      </div>
    </div>
  );
}

export default SingleActivity;
