import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getGalleryById } from "../../services/contentService";
import Lightbox from "react-image-lightbox";
import { Helmet } from "react-helmet";

export default function GalleryDetails() {
  const [gallery, setGallery] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    handleGetGalleryById();
    //eslint-disable-next-line
  }, []);

  const handleGetGalleryById = async () => {
    let gallery = await getGalleryById(id);
    const { images } = gallery;
    const reversedImages = images?.reverse();
    gallery = {
      ...gallery,
      images: reversedImages,
    };
    setGallery(gallery);
  };

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <div id="content" className="p-4 lg:p-0">
        <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
          <main className="px-4 mx-auto max-w-7xl lg:px-1">
            <div className="grid flex-col justify-between grid-cols-12 lg:flex-row lg:items-center">
              <div className="col-span-7">
                <h1 className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  {gallery?.text}
                </h1>
                <Link
                  to="/kale-wall"
                  className="inline-flex items-center px-6 py-3 mt-10 btn btn-primary revert"
                >
                  <svg
                    role="img"
                    className="w-5 h-5 mr-3 text-secondary-titles"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#arrow-line-left" />
                  </svg>
                  GERİ
                </Link>
              </div>
              <h6 className="col-span-5 text-right text-md">
                {
                  new Date(gallery?.createdAt)
                    ?.toLocaleString("tr-TR")
                    ?.split(" ")[0]
                }
              </h6>
            </div>
          </main>
        </div>

        <div className="px-4 mx-auto my-12 max-w-7xl lg:my-16 lg:px-1">
          <div className="grid grid-cols-12 gap-6">
            {gallery?.images?.map((img, index) => (
              <div
                className="flex-col col-span-6 lg:col-span-4 lg:flex"
                key={index}
                onClick={() => {
                  setPhotoIndex(index);
                  setIsLightboxOpen(true);
                }}
              >
                <div className="flex flex-col">
                  <img
                    src={img?.url}
                    className="object-cover object-center cursor-pointer"
                    style={{ width: 408, height: 408 }}
                    alt={`img-${index}`}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {isLightboxOpen && (
          <Lightbox
            mainSrc={gallery?.images?.[photoIndex]?.url}
            nextSrc={
              gallery?.images?.[(photoIndex + 1) % gallery?.images?.length]?.url
            }
            prevSrc={
              gallery?.images?.[
                (photoIndex + gallery?.images?.length - 1) %
                  gallery?.images?.length
              ]?.url
            }
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + gallery?.images?.length - 1) %
                  gallery?.images?.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % gallery?.images?.length)
            }
          />
        )}
      </div>
    </>
  );
}
