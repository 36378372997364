import React, { useState } from "react";
import classNames from "classnames";
import AuthLayout from "../../layouts/AuthLayout";
import KalekimKvkk from "../../components/shared/KalekimKvkk";
import KaleseramikKvkk from "../../components/shared/KaleseramikKvkk";
import { Helmet } from "react-helmet";

export default function Kvkk() {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content">
          <div className="px-4 py-5 bg-background-purple md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  {" "}
                  K.V.K.K.{" "}
                </h1>
              </div>
            </main>
          </div>
          <div className="px-4 mx-auto my-8 max-w-7xl lg:px-1">
            <div className="px-4 xl:px-0">
              <div className="mb-8 text-center">
                <div className="relative z-0 inline-flex p-0.5 bg-secondary-secondary-bg mx-auto">
                  <button
                    type="button"
                    onClick={() => setActiveTab(1)}
                    className={classNames(
                      activeTab === 1
                        ? "inline-block !bg-primary-red !text-white active"
                        : "inline-block !text-primary-black !bg-transparent",
                      "px-4 tab-btn text-primary-black hover:text-white lg:w-auto"
                    )}
                  >
                    Kalekim
                  </button>

                  <button
                    type="button"
                    onClick={() => setActiveTab(2)}
                    className={classNames(
                      activeTab === 2
                        ? "inline-block !bg-primary-red !text-white active"
                        : "inline-block !text-primary-black !bg-transparent",
                      "px-4 tab-btn text-primary-black hover:text-white lg:w-auto"
                    )}
                  >
                    Kaleseramik
                  </button>
                </div>
              </div>

              <div className="prose-xxl">
                {activeTab === 1 ? <KalekimKvkk /> : <KaleseramikKvkk />}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
