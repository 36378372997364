import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  createPostComment,
  getReplies,
} from "../../../services/contentService";
import Reply from "./Reply";

function Comment({ comment, post, showMore, index }) {
  const [replyText, setReplyText] = useState("");
  const [isReply, setIsReply] = useState(false);
  const [replies, setReplies] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleReply = async (e) => {
    if (replyText.length === 0 || !replyText.trim()) return;


    setLoading(true);

    const result = await createPostComment({
      id: post?.id,
      comment: replyText,
      parentId: comment?.id,
    });

    setIsReply(false);
    setReplyText("");

    setReplies([
      ...replies,
      {
        id: result?.newComment?.id,
        comment: result?.newComment?.comment,
        parentId: result?.newComment?.parentId,
        user: result?.newComment?.user,
        createdAt: result?.newComment?.createdAt,
      },
    ]);

    setLoading(false);
  };

  useEffect(() => {
    const handleGetReplies = async () => {
      const replies = await getReplies(comment?.id);
      const jsonReplies = replies.map((reply) => {
        return reply.toJSON();
      });
      setReplies(jsonReplies);
    };
    handleGetReplies();
    //cleanup function
    return () => {
      setReplies([]);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={classNames(!showMore && index > 2 && "hidden", "flex mt-6")}
        key={comment?.id}
      >
        <div className="flex w-full">
          <Link to={`/user/${comment?.user?.id}`}>
            <div className="w-12 h-12 mr-3 overflow-hidden rounded-full relative flex items-center justify-center bg-secondary-titles">
              {comment?.user?.profile_image ? (
                <img
                  src={comment?.user?.profile_image}
                  alt={comment?.user?.name || comment?.user?.Name}
                  className="w-full h-full object-center object-cover"
                />
              ) : (
                <>
                  <span className="sr-only">User</span>
                  <svg role="img" className="w-8 h-8 text-white">
                    <use xlinkHref="/assets/img/necromancers.svg#user" />
                  </svg>
                </>
              )}
            </div>
          </Link>
          <div className="w-full">
            <div className="relative flex flex-col gap-3 p-3 bg-secondary-secondary-bg triangle">
              <Link to={`/user/${comment?.user?.id}`}>
                <p className="font-sm">{comment?.user?.name}</p>
              </Link>
              <p>{comment?.comment}</p>
              <p className="text-body-s text-secondary-secondary">
                {new Date(comment?.createdAt).toLocaleString()}
              </p>
            </div>

            {!post?.commentDenied && (
              <button
                className="mt-3 text-body text-secondary-secondary"
                onClick={() => {
                  setIsReply(true);
                }}
              >
                Yanıtla
              </button>
            )}
            {isReply && (
              <div className="relative flex flex-col gap-3 p-3 bg-secondary-secondary-bg triangle">
                {loading ? (
                  <svg
                    role="img"
                    className="animate-spin h-5 w-5 text-red-800 mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 0 1 8-8v2a6 6 0 1 0 6 6h2a8
                     8 0 0 1-16 0z"
                    ></path>
                  </svg>
                ) : (
                  <div className=" my-6 reply-input">
                    <div className="form-input-wrapper">
                      <input
                        autoFocus
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        type="text"
                        id="text"
                        autoComplete="text"
                        name="text"
                        placeholder=" "
                        className="bg-transparent form-input"
                      />
                      <label
                        htmlFor="text"
                        className="uppercase bg-transparent form-label"
                      >
                        {comment?.user?.name}’A YANIT VERİYORSUN
                      </label>
                      <div className="flex justify-end bg-secondary-secondary-bg mt-1">
                        <button
                          type="button"
                          className="mr-2"
                          onClick={(e) => handleReply(e)}
                        >
                          Cevapla
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setReplyText("");
                            setIsReply(false);
                          }}
                        >
                          Vazgeç
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* Reply comment */}
            {replies?.map((reply) => (
              <Reply key={reply.objectId} reply={reply} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Comment;
