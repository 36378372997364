import React, { useEffect, useState, useCallback } from "react";
import {
  completeExam,
  getQuestionChoices,
  saveExamAnswer,
} from "../../../services/contentService";

export default function Question(props) {
  const {
    questionCount,
    questionIndex,
    setQuestionIndex,
    question,
    questions,
    answers,
    isQuestionAnswered,
    exam,
    setAnswers,
    setShowExamModal,
    setLoading,
    setShowErrorModal,
    setErrorMessage,
    previousAnswers,
    setPreviousAnswers,
    timer,
    setExamAsCancelled,
    setShowInfoModal,
    setExamResult,
    isAnsweredPreviously,
  } = props;

  const [choices, setChoices] = useState([]);
  const { title, description } = question?.toJSON();

  const saveExamAnswers = async () => {
    setLoading(true);
    try {
      await saveAnswer(question?.id);
      const result = await completeExam(exam?.objectId);
      setExamResult(result);
    } catch (error) {
      setErrorMessage(error?.message);
      setShowErrorModal(true);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const saveAnswer = async (id) => {
    const answerExists = previousAnswers?.some(
      (i) => i.get("question")?.id === id
    );
    if (!answerExists) {
      const answer = answers?.find((i) => i.questionId === id);
      try {
        const newAnswer = await saveExamAnswer(answer);
        setPreviousAnswers([...previousAnswers, newAnswer]);
      } catch (error) {
        setErrorMessage(error?.message);
        setShowErrorModal(true);
      }
    }

    questionIndex !== questions.length - 1 &&
      setQuestionIndex(questionIndex + 1);
  };

  const handleAnswer = (choiceId) => {
    const examId = exam?.objectId;

    if (isQuestionAnswered(question?.id)) {
      const answersCopy = [...answers];
      const index = answers.findIndex((i) => i.questionId === question?.id);
      answersCopy[index].choiceId = choiceId;
      setAnswers(answersCopy);
    } else {
      setAnswers([...answers, { questionId: question?.id, examId, choiceId }]);
    }
  };

  const isChoiceAnswered = useCallback(
    (choiceId) => {
      const choiceExists = previousAnswers.filter(
        (i) =>
          i?.get("question")?.id === question?.id &&
          i?.get("choice")?.id === choiceId &&
          i?.get("exam")?.id === exam?.objectId
      );
      const choiceExistsAnswers = answers.filter(
        (i) =>
          i?.choiceId === choiceId &&
          i?.questionId === question?.id &&
          i?.examId === exam?.objectId
      );

      if (choiceExists.length > 0 || choiceExistsAnswers.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    [answers, previousAnswers, exam?.objectId, question?.id]
  );

  useEffect(() => {
    (async () => {
      if (
        timer?.days === 0 &&
        timer?.hours === 0 &&
        timer?.minutes === 0 &&
        timer?.seconds === 0
      ) {
        setLoading(true);
        try {
          // if (previousAnswers?.length === questions?.length - 1) {
          //   if (isQuestionAnswered(question?.objectId)) {
          //     const answer = answers?.find(
          //       (i) => i.questionId === question?.id
          //     );
          //     const newAnswer = await saveExamAnswer(answer);
          //     setPreviousAnswers([...previousAnswers, newAnswer]);
          //     await completeExam(exam?.objectId);
          //     setShowSuccessModal(true);
          //     setExamAsCompleted();
          //   }
          // } else {
          //   setExamAsCancelled();
          //   setShowInfoModal(true);
          // }

          setExamAsCancelled();
          setShowInfoModal(true);
          setShowExamModal(false);
        } catch (error) {
          setErrorMessage(error?.message);
          setShowErrorModal(true);
        } finally {
          setLoading(false);
        }
      }
    })();
    //eslint-disable-next-line
  }, [timer, answers, exam?.objectId]);

  useEffect(() => {
    (async () => {
      try {
        const choices = await getQuestionChoices(question?.id);
        setChoices(choices);
      } catch (error) {
        setErrorMessage(error?.message);
        setShowErrorModal(true);
      }
    })();
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="relative w-full max-w-full lg:max-w-2xl lg:border lg:p-6 lg:mt-16"
      style={{ minWidth: 576 }}
    >
      <div className="relative top-0 right-0 mb-6 lg:absolute lg:right-6 lg:top-6 lg:mb-0">
        <p className="font-bold text-center text-secondary-primary text-body-l">
          {questionIndex + 1} / {questionCount}
        </p>
      </div>
      <h4 className="max-w-lg text-md">{title}</h4>
      <p className="mt-4">{description}</p>
      <div className="flex flex-col gap-6 mt-8">
        {choices?.map((choice) => {
          const { text } = choice?.toJSON();
          return (
            <div className="form-input-wrapper" key={choice?.id}>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="w-4 h-4 text-black border border-primary-black"
                  name="radio"
                  disabled={isAnsweredPreviously(question?.id)}
                  onChange={() => handleAnswer(choice?.id)}
                  checked={isChoiceAnswered(choice?.id)}
                />
                <div className="ml-2 form-label text-body-s text-secondary-primary">
                  {text}
                </div>
              </label>
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-center gap-4 mt-8">
        {questionIndex !== 0 && (
          <button
            type="button"
            className="flex-1 px-6 form-submit-btn lg:flex-none lg:w-20 revert disabled:opacity-75"
            onClick={() => setQuestionIndex(questionIndex - 1)}
          >
            GERİ
          </button>
        )}

        {questionIndex === questions?.length - 1 ? (
          <button
            type="button"
            className="flex-1 px-6 uppercase form-submit-btn lg:flex-none lg:w-32 disabled:opacity-75"
            disabled={!isQuestionAnswered(question?.id)}
            onClick={() => saveExamAnswers()}
          >
            Onayla
          </button>
        ) : (
          <button
            type="button"
            className="flex-1 px-6 uppercase form-submit-btn lg:flex-none lg:w-32 disabled:opacity-75"
            disabled={questionIndex === questions?.length - 1}
            onClick={() => saveAnswer(question?.id)}
          >
            Devam Et
          </button>
        )}
      </div>
    </div>
  );
}
