import React, { useEffect, useState } from "react";
import { getQuestionChoices } from "../../../services/contentService";

export default function Answer(props) {
  const { answer, questionIndex, questionCount } = props;
  const { question, choice } = answer?.toJSON();
  const [allChoices, setAllChoices] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const choices = await getQuestionChoices(question?.objectId);
        setAllChoices(choices);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [question]);

  return (
    <div className="relative w-full max-w-full lg:max-w-2xl lg:border lg:p-6 lg:mt-16">
      <div className="relative top-0 right-0 mb-6 lg:absolute lg:right-6 lg:top-6 lg:mb-0">
        <p className="font-bold text-center text-secondary-primary text-body-l">
          {questionIndex + 1} / {questionCount}
        </p>
      </div>
      <h4 className="max-w-lg text-md">{question?.title}</h4>
      <p className="mt-4">{question?.description}</p>
      <div className="flex flex-col gap-6 mt-8">
        {allChoices?.map((item) => {
          const { text } = item?.toJSON();
          if (
            item?.id === choice?.objectId &&
            question?.answer?.objectId === item?.id
          ) {
            return (
              <div className="form-input-wrapper" key={item?.id}>
                <label className="flex items-center cursor-pointer">
                  <span className="sr-only">check</span>
                  <svg
                    role="img"
                    className="w-3 h-3 absolute left-0 text-white  ml-0.5"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#check" />
                  </svg>
                  <input
                    type="radio"
                    className="w-4 h-4 text-green-500 border-primary-black success"
                    name="radio"
                    disabled
                  />
                  <div className="ml-2 form-label text-body-s text-secondary-primary">
                    {text}
                  </div>
                </label>
              </div>
            );
          } else if (
            item?.id === choice?.objectId &&
            question?.answer?.objectId !== item?.id
          ) {
            return (
              <div className="form-input-wrapper">
                <label
                  className="relative flex items-center cursor-pointer"
                  key={item?.id}
                >
                  <span className="sr-only">cross</span>
                  <svg
                    role="img"
                    className="absolute left-0 w-2 h-2 ml-1 text-white"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#cross" />
                  </svg>
                  <input
                    type="radio"
                    className="w-4 h-4 border border-primary-black text-validation-error error"
                    name="radio"
                    disabled
                  />
                  <div className="ml-2 form-label text-body-s text-validation-error">
                    {text}
                  </div>
                </label>
              </div>
            );
          } else {
            return (
              <div className="form-input-wrapper">
                <label className="flex items-center cursor-pointer">
                  <span className="sr-only">check</span>
                  <svg
                    role="img"
                    className="w-3 h-3 absolute left-0 text-white  ml-0.5"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#check" />
                  </svg>
                  <input
                    type="radio"
                    className={
                      question?.answer?.objectId === item?.id
                        ? "w-4 h-4 text-green-500 border-primary-black success"
                        : ""
                    }
                    name="radio"
                    value="1"
                    disabled
                  />
                  <div className="ml-2 form-label text-body-s text-secondary-primary">
                    {text}
                  </div>
                </label>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
