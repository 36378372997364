import React, { useState } from "react";

export const ActivityModal = ({
  accept,
  dismiss,
  handleAfterClose,
  shouldShow,
  updateModalState,
  terms,
}) => {
  const [isAccepted, setIsAccepted] = useState(false);

  if (shouldShow)
    return (
      <div
        className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain"
        x-ref="dialog"
        aria-modal="true"
      >
        <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center lg:block lg:p-0">
          <div
            className="fixed inset-0 min-h-screen overflow-y-auto transition-opacity bg-white lg:bg-primary-black lg:bg-opacity-90"
            aria-hidden="true"
          ></div>
          <span
            className="hidden lg:inline-block lg:align-middle lg:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block h-auto w-full max-w-full align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-auto lg:overflow-hidden shadow-xl transform transition-all lg:my-8 lg:align-middle {{ modalSize }} lg:w-full lg:pb-16 lg:pt-12 lg:px-12 outline-none">
            <div className="absolute top-0 right-0 pt-4 pr-4 lg:block">
              <button
                onClick={() => {
                  updateModalState(false);
                  if (handleAfterClose) handleAfterClose();
                }}
                type="button"
                className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <svg className="w-6 h-6 text-primary-black" role="img">
                  <use xlinkHref="/assets/img/necromancers.svg#cross" />
                </svg>
              </button>
            </div>

            <div className="modal-body">
              <div className="flex flex-col items-center">
                <div className="mt-4 text-center sm:mt-5">
                  <h3 className="text-md" id="modal-title">
                    Katılım Şartları/Detayları
                  </h3>
                  <div className="mt-6">
                    {terms && (
                      <p className="text-left max-w-7xl">
                        {terms.split("\n").map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-center max-w-md px-4 py-3 pt-2 mx-auto mt-6">
                  <div className="form-input-wrapper">
                    <input
                      onChange={(e) =>
                        e.target.checked
                          ? setIsAccepted(true)
                          : setIsAccepted(false)
                      }
                      type="checkbox"
                      id="title"
                      autoComplete="cc-given-title"
                      name="title"
                      className="form-input"
                    />
                    <label
                      htmlFor="title"
                      className="ml-2 form-label text-body-s text-secondary-titles"
                    ></label>
                    <label>Katılma koşullarını kabul ediyorum</label>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-4 pt-6">
                <button
                  onClick={() => updateModalState(false)}
                  type="button"
                  className="uppercase form-submit-btn revert"
                >
                  VAZGEÇ
                </button>
                <button
                  onClick={() => accept()}
                  type="button"
                  className="uppercase form-submit-btn whitespace-nowrap disabled:opacity-80"
                  disabled={!isAccepted}
                >
                  KABUL ET
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};
