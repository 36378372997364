import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import TemporaryImages from "./TemporaryImages";

export default function FileUploadModal({
  files,
  setFiles,
  setShowModal,
  onSubmit,
  shouldClearFilesOnClose,
  mediaType,
  setTitle,
}) {
  const [backgroundColor, setBackgroundColor] = useState("#F7F7FC");
  const [temporaryImages, setTemporaryImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const temp = [];
      const temporaryFiles = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const index = i;
        const url = URL.createObjectURL(acceptedFiles[i]);
        const extension =
          acceptedFiles[i]?.name?.split(".")[
            acceptedFiles[i]?.name?.split(".").length - 1
          ];

        if (mediaType === "image") {
          if (
            extension === "jpg" ||
            extension === "png" ||
            extension === "jpeg"
          ) {
            if (acceptedFiles[i].size <= 3000000) {
              setErrorMessage(null);
              const name = acceptedFiles[i].name;
              temp.push(acceptedFiles[i]);
              temporaryFiles.push({
                index,
                name,
                url,
                extension,
              });
            } else {
              setErrorMessage(
                "Lütfen 4 MB'den daha küçük resimler yükleyiniz."
              );
            }
          } else {
            setErrorMessage(
              "Lütfen sadece .jpg, .jpeg ve .png uzantılı resimler yükleyin."
            );
          }
        } else {
          if (
            extension === "mp4" ||
            extension === "mkv" ||
            extension === "m4v" ||
            extension === "avi" ||
            extension === "mpg"
          ) {
            if (acceptedFiles[i].size <= 125000000) {
              setErrorMessage(null);
              const name = acceptedFiles[i].name;
              temp.push(acceptedFiles[i]);
              temporaryFiles.push({
                index,
                name,
                url,
                extension,
              });
            } else {
              setErrorMessage(
                "Lütfen 125 MB'den daha küçük videolar yükleyiniz."
              );
            }
          } else {
            setErrorMessage(
              "Lütfen sadece .mp4, .mkv, .m4v, .avi, .mpg uzantılı videolar yükleyin."
            );
          }
        }
      }
      setFiles([...files, temp]);
      setTemporaryImages([...temporaryImages, [...temporaryFiles]]);
      setBackgroundColor("#F7F7FC");
    },
    onDragEnter: () => setBackgroundColor("#eee"),
    onDragLeave: () => setBackgroundColor("#F7F7FC"),
  });

  const removeImage = (mainIndex, index, name) => {
    const temporaryImagesCopy = temporaryImages;
    const filesCopy = files;
    const tempImg = temporaryImagesCopy[mainIndex][index];
    const tempFile = filesCopy[mainIndex][index];

    if (tempImg.name === name) temporaryImagesCopy[mainIndex].splice(index, 1);
    if (temporaryImagesCopy[mainIndex]?.length === 0)
      temporaryImagesCopy.splice(mainIndex, 1);
    setTemporaryImages([...temporaryImagesCopy]);

    if (tempFile.name === name) filesCopy[mainIndex].splice(index, 1);
    if (filesCopy[mainIndex]?.length === 0) filesCopy.splice(mainIndex, 1);
    setFiles([...filesCopy]);
  };

  return (
    <div
      className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain"
      aria-labelledby="modal-title"
      aria-modal="true"
    >
      <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center lg:block lg:p-0">
        <div
          className="fixed inset-0 min-h-screen overflow-y-auto transition-opacity bg-white lg:bg-primary-black lg:bg-opacity-90"
          aria-hidden="true"
        ></div>
        <span
          className="hidden lg:inline-block lg:align-middle lg:h-screen"
          aria-hidden="true"
        >
          ​
        </span>
        <div className="inline-block h-auto w-full max-w-full align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-auto lg:overflow-hidden shadow-xl transform transition-all lg:my-8 lg:align-middle lg:max-w-md lg:w-full lg:pb-16 lg:pt-12 lg:px-12 outline-none">
          <div className="absolute top-0 right-0 pt-4 pr-4 lg:block">
            <button
              type="button"
              className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <span className="sr-only">Profile</span>
              <svg
                role="img"
                className="w-6 h-6 text-primary-black"
                aria-hidden="true"
                focusable="false"
                onClick={() => {
                  setTemporaryImages([]);
                  setShowModal(false);
                  if (shouldClearFilesOnClose) setFiles([]);
                }}
              >
                <use xlinkHref="/assets/img/necromancers.svg#cross" />
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="flex flex-col items-center ">
              {setTitle && (
                <div className="form-input-wrapper w-full">
                  <input
                    type="text"
                    id="title"
                    autoComplete="title"
                    name="name"
                    placeholder=" "
                    className="bg-transparent form-input w-full"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <label htmlFor="name" className="bg-transparent form-label">
                    Albüm Başlığı
                  </label>
                </div>
              )}

              <div
                className="mt-16"
                style={{ backgroundColor }}
                {...getRootProps()}
              >
                <div
                  htmlFor="upload-photo"
                  className="flex flex-col px-4 border border-dashed cursor-pointer py-14 border-secondary-lines"
                >
                  {errorMessage && (
                    <h6 className="text-center  text-primary-red">
                      {errorMessage}
                    </h6>
                  )}
                  <h6 className="text-xs text-center font-xs">
                    {mediaType === "image"
                      ? "Görselleri buraya sürükleyiniz"
                      : "Videoları buraya sürükleyiniz"}
                  </h6>
                  <span className="text-center text-body">
                    Max. {mediaType === "image" ? 4 : 125} mb olmalı
                  </span>
                </div>
                <input
                  {...getInputProps()}
                  type="file"
                  name="photo"
                  className="absolute opacity-0 -z-1"
                  id="upload-photo"
                  multiple
                />
              </div>
              <TemporaryImages
                temporaryImages={temporaryImages}
                removeImage={removeImage}
              />
              <div className="flex justify-center w-full max-w-xs">
                <button
                  className="w-52 mt-8 uppercase form-submit-btn lg:w-auto"
                  disabled={errorMessage || temporaryImages.length === 0}
                  onClick={async () => {
                    if (!errorMessage) {
                      await onSubmit();
                      setTemporaryImages([]);
                    }
                  }}
                >
                  Ekle
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
