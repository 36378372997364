import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { getPosts } from "../../../services/contentService";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function KaleWall() {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [posts, setPosts] = useState([]);

  console.log("posts", posts);

  useEffect(() => {
    (async () => {
      try {
        const result = await getPosts();
        if (result) {
          setPosts(result);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="px-4 py-12 lg:py-24 bg-background-red">
      <main className="flex flex-col gap-10 px-1 mx-auto lg:grid lg:grid-cols-2 lg:gap-6 max-w-7xl">
        <div className="flex items-center justify-center">
          <div className="lg:space-y-8">
            <div className="lg:space-y-1 ">
              <h2 className="text-lg text-center lg:text-left mr-5">
                Kale Duvarı
              </h2>
            </div>
            <Link to="/kale-wall" className="w-full mt-8 btn lg:w-auto lg:mt-0">
              TÜMÜNÜ GÖR
            </Link>
          </div>
        </div>
        <div className="flex justify-center">
          <Swiper
            className="relative flex flex-col overflow-hidden lg:flex-row"
            modules={[Navigation]}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={1}
          >
            <div className="w-full swiper-container swiper-initialized swiper-horizontal swiper-pointer-events">
              <div
                className="swiper-wrapper"
                id="swiper-wrapper-eaad646010969eb42"
                aria-live="polite"
              >
                {posts
                  .filter((item, index) => index < 5)
                  .map((post) => (
                    <SwiperSlide>
                      <div
                        className="space-y-12 swiper-slide swiper-slide-duplicate swiper-slide-prev swiper-slide-duplicate-next"
                        data-swiper-slide-index="1"
                        style={{ width: "624px" }}
                        role="group"
                        aria-label="2 / 2"
                      >
                        <div className="relative max-w-md px-8 py-20 mx-auto feedback bg-background-purple slider-bottom-arrow">
                          <span className="sr-only">Prev</span>
                          <svg
                            role="img"
                            className="absolute w-16 h-16 right-6 opacity-5 top-8"
                          >
                            <use xlinkHref="/assets/img/necromancers.svg#text" />
                          </svg>

                          <h5 className="text-sm">{post.text}</h5>
                        </div>
                        <div className="flex justify-center">
                          <div className="flex-shrink-0 w-12 h-12 mr-4">
                            <img
                              className="rounded-full object-cover w-full h-full"
                              src={post.user?.profile_image}
                              alt="profile"
                            />
                          </div>
                          <div>
                            <span>{post.user?.name}</span>
                            <p className="text-body-s text-secondary-secondary">
                              {post.user?.firmName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </div>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              ></span>
            </div>
            <div className="flex pt-10 mx-auto">
              <div className="left-0 z-10 flex items-center mx-3 lg:absolute lg:inset-y-0 lg:mx-0">
                <button
                  ref={prevRef}
                  //   @click="swiper.slidePrev()"
                  className="slider-arrow bg-background-purple"
                >
                  <span className="sr-only">Prev</span>
                  <svg role="img" className="w-6 h-6">
                    <use xlinkHref="/assets/img/necromancers.svg#arrow-left" />
                  </svg>
                </button>
              </div>
              <div className="inset-y-0 right-0 z-10 flex items-center mx-3 lg:absolute lg:mx-0">
                <button
                  //   @click="swiper.slideNext()"
                  ref={nextRef}
                  className="slider-arrow bg-background-purple"
                >
                  <span className="sr-only">Next</span>
                  <svg role="img" className="w-6 h-6">
                    <use xlinkHref="/assets/img/necromancers.svg#arrow-right" />
                  </svg>
                </button>
              </div>
            </div>
          </Swiper>
        </div>
      </main>
    </div>
  );
}
