import React, { useEffect, useState } from "react";
import LoadingModal from "../../components/shared/LoadingModal";
import Modal from "../../components/shared/Modal";
import AuthLayout from "../../layouts/AuthLayout";
import { getExams } from "../../services/contentService";
import Exam from "./components/Exam";
import { Helmet } from "react-helmet";

export default function InformationCube() {
  const [exams, setExams] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      const examsJson = [];
      try {
        setLoading(true);
        const exams = await getExams();
        exams?.map((exam) => {
          return examsJson.push(exam?.toJSON());
        });
        setExams([...examsJson]);
      } catch (error) {
        setErrorMessage(error?.message);
        setShowErrorModal(true);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {showSuccessModal && (
          <Modal
            shouldShow={showSuccessModal}
            type="success"
            msg={`Sınav başarı ile tamamlandı.`}
            updateModalState={(state) => setShowSuccessModal(state)}
          />
        )}
        {showErrorModal && (
          <Modal
            shouldShow={showErrorModal}
            type="error"
            msg={errorMessage}
            updateModalState={(state) => setShowErrorModal(state)}
          />
        )}
        {showInfoModal && (
          <Modal
            shouldShow={showInfoModal}
            type="info"
            msg="Sınav süreniz tamamlandığı için oturumunuz sonlandırılmıştır."
            updateModalState={(state) => setShowInfoModal(state)}
          />
        )}
        {loading && <LoadingModal />}
        <div id="content">
          <div className="px-4 py-5 bg-background-teal lg:py-16 lg:px-0">
            <main className="mx-auto max-w-7xl lg:px-1 md:px-4">
              <div className="flex flex-col justify-between lg:flex-row lg:items-center">
                <h1 className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  Yarışma
                </h1>
              </div>
            </main>
          </div>

          <div className="max-w-4xl px-4 mx-auto my-12 lg:my-16 lg:px-0">
            <h3 className="mb-8 text-sm text-center lg:text-lg lg:text-left">
              Yeni Yarışmalar
            </h3>
            {exams?.map((exam) => {
              return (
                !exam?.complete && (
                  <Exam
                    key={exam?.objectId}
                    exam={exam}
                    exams={exams}
                    setExams={(exams) => setExams(exams)}
                    setShowSuccessModal={(show) => setShowSuccessModal(show)}
                    setLoading={(loading) => setLoading(loading)}
                    setShowErrorModal={(show) => setShowErrorModal(show)}
                    setErrorMessage={(msg) => setErrorMessage(msg)}
                    setShowInfoModal={(state) => setShowInfoModal(state)}
                  />
                )
              );
            })}
          </div>
          <div className="max-w-4xl px-4 mx-auto my-12 lg:my-16 lg:px-0">
            <h3 className="mb-8 text-sm text-center lg:text-lg lg:text-left">
              Katıldıklarım
            </h3>
            {exams?.map((exam) => {
              return (
                exam?.complete && (
                  <Exam
                    key={exam?.objectId}
                    exam={exam}
                    setLoading={(loading) => setLoading(loading)}
                    setErrorMessage={(msg) => setErrorMessage(msg)}
                    setShowErrorModal={(show) => setShowErrorModal(show)}
                  />
                )
              );
            })}
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
