import React from "react";

const Notifications = ({ notificationModal }) => {
  return (
    <div
      ref={notificationModal}
      className="fixed top-0 left-0 z-10 w-full h-screen transform bg-white shadow-lg lg:left-auto lg:top-auto lg:absolute lg:h-auto lg:max-w-sm lg:right-0"
    >
      <div className="w-full">
        <div className="flex items-center justify-between w-full p-6 bg-background-red">
          <h3 className="text-sm">Bildirimlerim</h3>
        </div>
        <div className="flex flex-col overflow-y-auto overscroll-contain h-max lg:max-h-[23rem]">
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*news icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33333 4V18.3158C5.33333 18.5531 5.24554 18.7807 5.08926 18.9485C4.93298 19.1163 4.72101 19.2105 4.5 19.2105C4.27899 19.2105 4.06702 19.1163 3.91074 18.9485C3.75446 18.7807 3.66667 18.5531 3.66667 18.3158V5.78947H2V18.3158C2 19.0277 2.26339 19.7104 2.73223 20.2138C3.20107 20.7172 3.83696 21 4.5 21H19.5C20.163 21 20.7989 20.7172 21.2678 20.2138C21.7366 19.7104 22 19.0277 22 18.3158V4H5.33333ZM20.3333 18.3158C20.3333 18.5531 20.2455 18.7807 20.0893 18.9485C19.933 19.1163 19.721 19.2105 19.5 19.2105H6.85833C6.95252 18.9231 7.00042 18.6205 7 18.3158V5.78947H20.3333V18.3158ZM14.5 7.57895H8.66667V13.8421H14.5V7.57895ZM12.8333 12.0526H10.3333V9.36842H12.8333V12.0526ZM18.6667 15.6316H8.66667V17.4211H18.6667V15.6316ZM18.6667 12.0526H16.1667V13.8421H18.6667V12.0526Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">Haber</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*book icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.66667 17.3333V6.5H2V19H11.1667L9.5 17.3333H3.66667ZM19.5 16.5V4H13.3167L13.075 4.24166C12.7893 4.52562 12.4028 4.68501 12 4.68501C11.5972 4.68501 11.2107 4.52562 10.925 4.24166L10.675 4H4.5V16.5H10.0167C10.5809 16.9475 11.2799 17.191 12 17.191C12.7201 17.191 13.4191 16.9475 13.9833 16.5H19.5ZM13.075 15.075C12.9346 15.2176 12.7673 15.3309 12.5828 15.4082C12.3982 15.4855 12.2001 15.5253 12 15.5253C11.7999 15.5253 11.6018 15.4855 11.4172 15.4082C11.2327 15.3309 11.0654 15.2176 10.925 15.075L10.675 14.8333H6.16667V5.66667H10.0167C10.3572 5.93219 10.7486 6.12504 11.1667 6.23333V12.3333H12.8333V6.23333C13.2514 6.12504 13.6428 5.93219 13.9833 5.66667H17.8333V14.8333H13.325L13.075 15.075ZM20.3333 6.5V17.3333H14.5L12.8333 19H22V6.5H20.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">EĞİTİM</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*favorite icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4156 4.61586C22.5281 6.7703 22.5281 10.2633 20.4156 12.4178L12 21L3.58444 12.4178C1.47185 10.2633 1.47185 6.7703 3.58444 4.61586C5.55331 2.60783 8.66241 2.47136 10.7868 4.20631L12 5.39604L13.2132 4.20631C15.3376 2.47136 18.4467 2.60783 20.4156 4.61586ZM5.01222 6.01611C3.66259 7.39283 3.66259 9.6408 5.01222 11.0175L12.0001 18.1434L18.988 11.0175C20.3376 9.6408 20.3376 7.39283 18.988 6.01611C17.6591 4.66127 15.5218 4.66127 14.193 6.01611L12.0001 8.25262L9.80724 6.01611C8.47838 4.66127 6.34109 4.66127 5.01222 6.01611Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">HEDİYE</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*notification icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5715 22.0971C11.447 21.9729 11.3492 21.8262 11.2824 21.6662H13.7175C13.6507 21.8262 13.553 21.9729 13.4284 22.0971C13.1807 22.344 12.847 22.4819 12.5 22.4819C12.1529 22.4819 11.8192 22.344 11.5715 22.0971Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M19.6205 18.174V10.44C19.6205 6.68403 16.7524 3.60305 13.1015 3.2955V2H11.8987V3.2955C8.24774 3.60305 5.37955 6.68403 5.37955 10.44V18.174H4V19.3852H21.0002V18.174H19.6205ZM18.4177 18.174H6.58246V10.44C6.58246 7.15418 9.23752 4.48097 12.5 4.48097C15.7625 4.48097 18.4177 7.15455 18.4177 10.44V18.174Z"
                    fill="currentColor"
                    stroke="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">Duyuru</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*news icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33333 4V18.3158C5.33333 18.5531 5.24554 18.7807 5.08926 18.9485C4.93298 19.1163 4.72101 19.2105 4.5 19.2105C4.27899 19.2105 4.06702 19.1163 3.91074 18.9485C3.75446 18.7807 3.66667 18.5531 3.66667 18.3158V5.78947H2V18.3158C2 19.0277 2.26339 19.7104 2.73223 20.2138C3.20107 20.7172 3.83696 21 4.5 21H19.5C20.163 21 20.7989 20.7172 21.2678 20.2138C21.7366 19.7104 22 19.0277 22 18.3158V4H5.33333ZM20.3333 18.3158C20.3333 18.5531 20.2455 18.7807 20.0893 18.9485C19.933 19.1163 19.721 19.2105 19.5 19.2105H6.85833C6.95252 18.9231 7.00042 18.6205 7 18.3158V5.78947H20.3333V18.3158ZM14.5 7.57895H8.66667V13.8421H14.5V7.57895ZM12.8333 12.0526H10.3333V9.36842H12.8333V12.0526ZM18.6667 15.6316H8.66667V17.4211H18.6667V15.6316ZM18.6667 12.0526H16.1667V13.8421H18.6667V12.0526Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">Haber</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*  book icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.66667 17.3333V6.5H2V19H11.1667L9.5 17.3333H3.66667ZM19.5 16.5V4H13.3167L13.075 4.24166C12.7893 4.52562 12.4028 4.68501 12 4.68501C11.5972 4.68501 11.2107 4.52562 10.925 4.24166L10.675 4H4.5V16.5H10.0167C10.5809 16.9475 11.2799 17.191 12 17.191C12.7201 17.191 13.4191 16.9475 13.9833 16.5H19.5ZM13.075 15.075C12.9346 15.2176 12.7673 15.3309 12.5828 15.4082C12.3982 15.4855 12.2001 15.5253 12 15.5253C11.7999 15.5253 11.6018 15.4855 11.4172 15.4082C11.2327 15.3309 11.0654 15.2176 10.925 15.075L10.675 14.8333H6.16667V5.66667H10.0167C10.3572 5.93219 10.7486 6.12504 11.1667 6.23333V12.3333H12.8333V6.23333C13.2514 6.12504 13.6428 5.93219 13.9833 5.66667H17.8333V14.8333H13.325L13.075 15.075ZM20.3333 6.5V17.3333H14.5L12.8333 19H22V6.5H20.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">EĞİTİM</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*  favorite icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4156 4.61586C22.5281 6.7703 22.5281 10.2633 20.4156 12.4178L12 21L3.58444 12.4178C1.47185 10.2633 1.47185 6.7703 3.58444 4.61586C5.55331 2.60783 8.66241 2.47136 10.7868 4.20631L12 5.39604L13.2132 4.20631C15.3376 2.47136 18.4467 2.60783 20.4156 4.61586ZM5.01222 6.01611C3.66259 7.39283 3.66259 9.6408 5.01222 11.0175L12.0001 18.1434L18.988 11.0175C20.3376 9.6408 20.3376 7.39283 18.988 6.01611C17.6591 4.66127 15.5218 4.66127 14.193 6.01611L12.0001 8.25262L9.80724 6.01611C8.47838 4.66127 6.34109 4.66127 5.01222 6.01611Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">HEDİYE</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                {/*  notification icon*/}
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5715 22.0971C11.447 21.9729 11.3492 21.8262 11.2824 21.6662H13.7175C13.6507 21.8262 13.553 21.9729 13.4284 22.0971C13.1807 22.344 12.847 22.4819 12.5 22.4819C12.1529 22.4819 11.8192 22.344 11.5715 22.0971Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M19.6205 18.174V10.44C19.6205 6.68403 16.7524 3.60305 13.1015 3.2955V2H11.8987V3.2955C8.24774 3.60305 5.37955 6.68403 5.37955 10.44V18.174H4V19.3852H21.0002V18.174H19.6205ZM18.4177 18.174H6.58246V10.44C6.58246 7.15418 9.23752 4.48097 12.5 4.48097C15.7625 4.48097 18.4177 7.15455 18.4177 10.44V18.174Z"
                    fill="currentColor"
                    stroke="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">Duyuru</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5715 22.0971C11.447 21.9729 11.3492 21.8262 11.2824 21.6662H13.7175C13.6507 21.8262 13.553 21.9729 13.4284 22.0971C13.1807 22.344 12.847 22.4819 12.5 22.4819C12.1529 22.4819 11.8192 22.344 11.5715 22.0971Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M19.6205 18.174V10.44C19.6205 6.68403 16.7524 3.60305 13.1015 3.2955V2H11.8987V3.2955C8.24774 3.60305 5.37955 6.68403 5.37955 10.44V18.174H4V19.3852H21.0002V18.174H19.6205ZM18.4177 18.174H6.58246V10.44C6.58246 7.15418 9.23752 4.48097 12.5 4.48097C15.7625 4.48097 18.4177 7.15455 18.4177 10.44V18.174Z"
                    fill="currentColor"
                    stroke="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">Haber</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
          <a className="flex flex-col flex-1 w-full px-4" href="/">
            <div className="flex flex-row py-4 border-b border-secondary-lines">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mr-4 overflow-hidden rounded-full bg-secondary-secondary-bg">
                <svg
                  className="w-6 link-icon text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5715 22.0971C11.447 21.9729 11.3492 21.8262 11.2824 21.6662H13.7175C13.6507 21.8262 13.553 21.9729 13.4284 22.0971C13.1807 22.344 12.847 22.4819 12.5 22.4819C12.1529 22.4819 11.8192 22.344 11.5715 22.0971Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M19.6205 18.174V10.44C19.6205 6.68403 16.7524 3.60305 13.1015 3.2955V2H11.8987V3.2955C8.24774 3.60305 5.37955 6.68403 5.37955 10.44V18.174H4V19.3852H21.0002V18.174H19.6205ZM18.4177 18.174H6.58246V10.44C6.58246 7.15418 9.23752 4.48097 12.5 4.48097C15.7625 4.48097 18.4177 7.15455 18.4177 10.44V18.174Z"
                    fill="currentColor"
                    stroke="currentColor"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="uppercase">EĞİTİM</span>
                <p className="mt-1 text-secondary-secondary">
                  Pulvinar nam non scelerisque feugiat ut orci, turpis ac.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
