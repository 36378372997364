/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useSelector } from "react-redux";
import {
  getLiveStreams,
  updateliveStreamWatchTime,
} from "../../services/contentService";

import Parse from "parse";
import { getBayiBranches, getBayiFirms } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function LiveStream() {
  const [activeViewer, setActiveViewer] = useState(null);
  const [url, setUrl] = React.useState("");
  const [stream, setStream] = useState("");
  const [firms, setFirms] = useState([]);
  const [branches, setBranches] = useState([]);
  const [intervalId, setIntervalId] = useState(null);
  const [loading, setLoading] = useState(true);

  const ownUser = useSelector((state) => state?.app?.user);
  const navigate = useNavigate();

  console.log(stream);

  useEffect(() => {
    (async () => {
      let branches;
      const firms = await getBayiFirms();
      setFirms(firms);
      if (ownUser?.firm) {
        branches = await getBayiBranches(ownUser?.firm);
      }
      setBranches(branches);
      if (stream && ownUser) {
        isUserExist(ownUser?.objectId, stream.objectId);
      }

      setLoading(false);
    })();
    //eslint-disable-next-line
  }, [stream]);

  const getFirmName = (firmId) => {
    const currentFirm = firms?.find((firm) => firm.Id === firmId);
    return currentFirm?.Name;
  };

  const getBranchType = (branchId) => {
    if (ownUser?.branch) {
      const currentBranch = branches?.find((branch) => branch.Id === branchId);
      return currentBranch?.Type;
    }
  };

  useEffect(() => {
    const fetchUrl = async () => {
      const result = await getLiveStreams();
      console.log(result.toJSON());
      if (!result) return;
      const json = result.toJSON();
      setStream(json);

      // regex for iframe src
      const regex = /src="(.*?)"/g;
      const match = regex.exec(json.embedded_link);
      setUrl(match[1]);
    };
    fetchUrl();
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      } else {
        window.location.reload();
      }
    };
  }, []);

  useEffect(() => {
    (() => {
      streamInterval();
    })();
  }, [ownUser, stream]);

  async function streamInterval() {
    const intervalId = setInterval(async () => {
      if (!ownUser || !stream) return;
      try {
        await updateliveStreamWatchTime(ownUser?.objectId, stream.objectId);
      } catch (error) {
        console.log(error);
      }
    }, 60000);
    setIntervalId(intervalId);
  }

  async function setNewViewer(userId, streamId) {
    let viewer = Parse.Object.extend("BayiLiveStreamRecord");
    let newViewer = new viewer();
    newViewer.set("user", {
      __type: "Pointer",
      className: "_User",
      objectId: userId,
    });
    newViewer.set("stream", {
      __type: "Pointer",
      className: "BayiLiveStream",
      objectId: streamId,
    });
    console.log(firms);
    const firm = getFirmName(ownUser?.firm);
    const branch = getBranchType(ownUser?.branch);
    newViewer.set("branchName", branch);
    newViewer.set("firmName", firm);
    newViewer.set("enteredAt", new Date());

    return newViewer
      .save()
      .then((savedView) => {
        return savedView;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function isUserExist() {
    const liveStreamQuery = new Parse.Query("BayiLiveStreamRecord")
      .equalTo("stream", {
        __type: "Pointer",
        className: "BayiLiveStream",
        objectId: stream.objectId,
      })
      .equalTo("user", {
        __type: "Pointer",
        className: "_User",
        objectId: ownUser?.objectId,
      });
    const isStoredAlready = await liveStreamQuery.first();
    if (!isStoredAlready) {
      const result = await setNewViewer(ownUser?.objectId, stream.objectId);
      setActiveViewer(result);
      console.log(activeViewer);
    } else {
      // do nothing :)
    }
  }

  const liveStreamLiveQuery = async () => {
    if (!stream) return;
    const BayiLiveStream = Parse.Object.extend("BayiLiveStream");
    const query = new Parse.Query(BayiLiveStream);
    query.get(stream.objectId);

    const liveStreamSubscription = await query.subscribe();

    liveStreamSubscription.on("update", (object) => {
      if (object.get("is_active") === false) {
        navigate("/home");
      }
    });
  };

  useEffect(() => {
    liveStreamLiveQuery();
  }, [stream]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div className="px-4 py-5 bg-primary-red lg:py-16 lg:px-0">
          <main className="px-4 mx-auto max-w-7xl lg:px-1">
            <div className="flex flex-col justify-between lg:flex-row lg:items-center">
              <h1 className="mb-4 text-white text-md lg:text-xxl font-lg lg:mb-0">
                Canlı Yayın
              </h1>
              {!loading &&
                (stream ? (
                  <h6 className="text-white">
                    Bu canlı yayını sistem tarafından belirlenen sürenin
                    üzerinde izlerseniz{" "}
                    {stream.ks_point !== 0 && `KS ${stream.ks_point} Puan`}
                    {stream.kk_point !== 0 &&
                      `, KK ${stream.kk_point} Puan`}{" "}
                    kazanacaksınız.
                  </h6>
                ) : (
                  <h6>Şu anda canlı yayın bulunmamaktadır.</h6>
                ))}
            </div>
          </main>
        </div>
        {!loading && (
          <div className="px-4 flex justify-center mx-auto my-12 max-w-7xl lg:my-16 lg:px-1">
            {stream ? (
              <div className="video-wrapper">
                <iframe
                  className="responsive-iframe"
                  src={url || ""}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                {/* {parse(url || "")} */}
              </div>
            ) : (
              <lottie-player
                src="https://assets7.lottiefiles.com/packages/lf20_zT3Phk.json"
                background="transparent"
                speed="1"
                style={{ width: "500px", height: "500px" }}
                loop
                autoplay
              ></lottie-player>
            )}
          </div>
        )}
      </AuthLayout>
    </>
  );
}

export default LiveStream;
