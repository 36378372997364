import React from "react";

export default function Answer({ answer }) {
  return (
    <div>
      <h4 className="max-w-lg mt-10 text-md lg:mt-0">
        {answer?.question?.title}
      </h4>
      <p className="mt-4">{answer?.question?.description}</p>
      {answer?.question?.type === "ch" && (
        <div className="flex flex-col gap-6 mt-8">
          {answer?.question?.choices?.map((choiceItem) => (
            <div className="form-input-wrapper" key={choiceItem?.choiceId}>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="w-4 h-4 text-black border border-primary-black"
                  name="radio"
                  disabled
                  checked={
                    choiceItem?.choiceId === answer?.choices?.[0]?.choiceId
                  }
                />
                <div className="ml-2 form-label text-body-s text-secondary-primary">
                  {choiceItem?.choice}
                </div>
              </label>
            </div>
          ))}
        </div>
      )}
      {answer?.question?.type === "free" && (
        <div className="form-input-wrapper mt-4">
          <textarea
            type="text"
            id="other"
            name="other"
            placeholder=""
            disabled
            value={answer?.freetext_value}
            className="h-40 bg-transparent resize-none form-input disabled:opacity-70"
          ></textarea>
          <label htmlFor="other" className="bg-transparent form-label">
            Cevabınız
          </label>
        </div>
      )}
      {answer?.question?.type === "chmulti" && (
        <div className="flex flex-col gap-6 mt-8">
          {answer?.question?.choices?.map((choiceItem) => {
            const filteredAnswers = answer?.choices?.find(
              (i) => i.choiceId === choiceItem.choiceId
            );
            return (
              <div className="form-input-wrapper" key={choiceItem?.choiceId}>
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-black border border-primary-black rounded-full disabled:opacity-70"
                    name="check"
                    disabled
                    checked={choiceItem?.choiceId === filteredAnswers?.choiceId}
                  />
                  <div className="ml-2 form-label text-body-s text-secondary-primary">
                    {choiceItem?.choice}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
