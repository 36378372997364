import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  social: false,
  mobileSocial: false,
  about: false,
  mobileAbout: false,
  contact: false,
  mobileContact: false,
  search: false,
  notifications: false,
  messages: false,
  newMessages: false,
  messenger: false,
  profile: false,
  mobileMenu: false,
  overlay: false,
};

export const headerSlice = createSlice({
  name: "headerSlice",
  initialState,
  reducers: {
    SET_SOCIAL: (state, action) => {
      state.social = action.payload;
    },
    SET_MOBILE_SOCIAL: (state) => {
      state.mobileSocial = !state.mobileSocial;
    },
    SET_ABOUT: (state, action) => {
      state.about = action.payload;
    },
    SET_MOBILE_ABOUT: (state) => {
      state.mobileAbout = !state.mobileAbout;
    },
    SET_CONTACT: (state, action) => {
      state.contact = action.payload;
    },
    SET_MOBILE_CONTACT: (state) => {
      state.mobileContact = !state.mobileContact;
    },
    TOGGLE_CONTACT: (state, action) => {
      state.contact = !state.contact;
    },
    SET_MOBILE_MENU: (state, action) => {
      state.mobileMenu = action.payload;
    },
    TOGGLE_MOBILE_MENU: (state) => {
      state.mobileMenu = !state.mobileMenu;
    },
    SET_OVERLAY: (state, action) => {
      state.overlay = action.payload;
    },
    SET_SEARCH: (state, action) => {
      state.search = action.payload;
    },
    TOGGLE_SEARCH: (state) => {
      state.search = !state.search;
    },
    SET_NOTIFICATIONS: (state, action) => {
      state.notifications = action.payload;
    },
    TOGGLE_NOTIFICATIONS: (state) => {
      state.notifications = !state.notifications;
    },
    SET_MESSAGES: (state, action) => {
      state.messages = action.payload;
    },
    TOGGLE_MESSAGES: (state) => {
      state.messages = !state.messages;
    },
    SET_PROFILE: (state, action) => {
      state.profile = action.payload;
    },
    TOGGLE_PROFILE: (state) => {
      state.profile = !state.profile;
    },
  },
});

export const {
  SET_SOCIAL,
  SET_ABOUT,
  SET_CONTACT,
  SET_MOBILE_MENU,
  SET_OVERLAY,
  SET_MOBILE_ABOUT,
  SET_MOBILE_CONTACT,
  SET_MOBILE_SOCIAL,
  TOGGLE_CONTACT,
  TOGGLE_SEARCH,
  SET_NOTIFICATIONS,
  TOGGLE_NOTIFICATIONS,
  TOGGLE_MOBILE_MENU,
  SET_MESSAGES,
  TOGGLE_MESSAGES,
  SET_PROFILE,
  TOGGLE_PROFILE,
  SET_SEARCH,
} = headerSlice.actions;
export default headerSlice.reducer;
