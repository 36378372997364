import React from "react";
import { useSelector } from "react-redux";

export default function GiftCatalog(props) {
  const { title, text, image, buttonText } = props;
  const user = useSelector((state) => state.app.user);

  return (
    <div
      className="px-4 py-12 lg:py-24 bg-background-purple"
      data-testid="gift-catalog-el"
    >
      <main className="mx-auto">
        <div className="flex flex-col-reverse items-center px-1 mx-auto space-y-reverse lg:grid lg:grid-cols-2 space-y-9 lg:gap-6 max-w-7xl">
          <div className="flex justify-center">
            <img
              src={image?.url}
              // data-srcset="/assets/img/image-11.png 1x,
              // 	 2x
              // "
              alt=""
              className="block responsive loaded"
              // srcSet="
              // 	/assets/img/image-11.png 1x,
              // 	 2x
              // "
              data-loaded="true"
            />
          </div>
          <div className="text-center lg:space-y-8 lg:text-left">
            <div className="lg:space-y-1">
              <h2 className="text-xl text-center lg:text-left">{title}</h2>
              <p className="mt-2 text-center lg:mt-0 lg:max-w-xl text-secondary-primary lg:text-justify">
                {text}
              </p>
            </div>
            <a
              href={`https://bayi.kalepuan.com/MusteriHizmetleri/UyeGirisi.aspx?sessionid=${user?.SF_id}`}
              target="_blank"
              rel="noreferrer"
              className="w-full mt-8 btn lg:w-auto lg:mt-0"
              data-testid="link-btn"
            >
              {buttonText}
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}
