import React, { useState, useEffect, memo } from "react";
import Pagination from "./Pagination";
import DonutChart from "react-donut-chart";

function SpentRewards({ gifts }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = gifts?.giftList?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (!gifts) return;
    setPieData([
      {
        label: `Kaleseramik Puan`,
        value: Number(gifts.totalKS),
      },
      {
        label: `Kalekim Puan`,
        value: Number(gifts.totalKK),
      },
    ]);
  }, [gifts]);

  return (
    <div>
      <div className="flex flex-col px-4 lg:px-0">
        <div className="flex flex-col gap-12 lg:flex-row">
          <div className="flex flex-col w-full max-w-4xl mx-auto mb-16 space-y-4 md:flex-row cdc-container justify-evenly">
            <div className="flex flex-row space-x-24">
              <div className="flex flex-col relative">
                <div className="cdc-overlay z-50 w-[325px] h-[325px]">
                  <div className="cdc-text">
                    <div className="space-y-4">
                      <p className="text-body-s font-sm">Toplam</p>
                      <p className="text-lg font-lg">
                        {(gifts?.totalKK + gifts?.totalKS)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </p>
                      <p className="text-body-s text-secondary-secondary">
                        Kale Puan
                      </p>
                    </div>
                  </div>
                </div>
                <DonutChart
                  data={pieData}
                  interactive={false}
                  selectedOffset={false}
                  colors={["#E94034", "#6258C9"]}
                  legend={false}
                  width={325}
                  height={325}
                  strokeColor="transparent"
                  className="p-0"
                />
                {/* <p className="mt-8 text-body-s text-secondary-secondary">
                  Son Kullanım Tarihi; 01.12.2021
                </p> */}
              </div>

              <div className="flex flex-col justify-start ml-4 space-y-4 cdc-legend pt-6 w-96">
                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-red"></span>
                  <span>
                    Kaleseramik Puan{" "}
                    <b>
                      {gifts?.totalKS
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                  </span>
                </span>

                <span
                  title="28 (28.000000000000004%)"
                  className="cdc-legend-item"
                >
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-purple"></span>
                  <span className="">
                    Kalekim Puan{" "}
                    <b>
                      {gifts?.totalKK
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                  </span>
                </span>

                <p className="text-secondary-secondary">
                  KS; Kaleseramik | KK; Kalekim
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="inline-block min-w-full">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="bg-secondary-secondary-bg">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black"
                    >
                      TARİH
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left uppercase font-md text-body-l text-primary-black"
                    >
                      ÜRÜN ADI
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 uppercase font-md text-body-l text-primary-black"
                    >
                      PUAN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((gift) => {
                    const { createdAt, desc, amount } = gift.toJSON();
                    return (
                      <tr
                        className="bg-white border-b border-secondary-lines"
                        key={gift.id}
                      >
                        <td className="px-6 py-2 text-center text-body text-secondary-primary">
                          {new Date(createdAt).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-2 text-body text-secondary-primary">
                          {desc.map((desc, index) => (
                            <p key={index}>{desc}</p>
                          ))}
                        </td>
                        <td className="px-6 py-2 text-center text-body text-primary-red">
                          {amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        totalItems={gifts?.giftList?.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}
export default memo(SpentRewards);
