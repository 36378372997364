import React, { useEffect, useState } from "react";
import { SingleLibraryDoc } from "../../components/shared/SingleLibraryDoc";
import AuthLayout from "../../layouts/AuthLayout";
import { getOtherDocs } from "../../services/contentService";
import LoadingModal from "../../components/shared/LoadingModal";
import { Helmet } from "react-helmet";

const OtherDocs = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("descending");
  const [docs, setDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getOtherDocs(filterValue);
      setDocs(result);
      setFilteredDocs(result);
      setLoading(false);
    })();
  }, [filterValue]);
  useEffect(() => {
    if (searchValue) {
      const filteredDocs = docs.filter((doc) => {
        const docToRender = doc.toJSON();

        return docToRender.title
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      setFilteredDocs(filteredDocs);
    } else {
      setFilteredDocs(docs);
    }
    //eslint-disable-next-line
  }, [searchValue]);
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div id="content" className="">
          <div className="px-4 py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 md:grid lg:items-center">
                <div className="col-span-7">
                  <h1 className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                    Diğer Dokümanlar
                  </h1>
                </div>
              </div>
            </main>
          </div>
          <div className="max-w-4xl px-8 mx-auto">
            <div className="flex flex-col gap-6 pt-16 lg:flex-row">
              <div className="w-full">
                <div className="relative form-input-wrapper search-container">
                  <input
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    type="text"
                    id="searchInput"
                    autoComplete="search"
                    name="search"
                    placeholder=" "
                    className="bg-transparent form-input"
                  />
                  <label htmlFor="search" className="bg-transparent form-label">
                    Doküman Ara
                  </label>
                  <button
                    type="button"
                    className="absolute top-0 z-10 p-2 right-2 whitespace-nowrap search-icon"
                    id="menu-button"
                  >
                    <svg
                      role="img"
                      className="w-6 link-icon text-primary-black "
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#search" />
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="absolute top-0 z-10 hidden p-2 right-2 whitespace-nowrap delete-icon"
                    id="menu-button"
                  >
                    <svg
                      className="w-10 link-icon text-primary-black "
                      role="img"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#delete" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="w-full">
                <div className="form-input-wrapper">
                  <select
                    onChange={(e) => setFilterValue(e.target.value)}
                    value={filterValue}
                    id="order"
                    name="order"
                    autoComplete="order"
                    className="bg-transparent form-input"
                  >
                    <option name="1" value="ascending">
                      Eskiden Yeniye
                    </option>
                    <option name="1" value="descending">
                      Yeniden Eskiye
                    </option>
                  </select>
                  <label htmlFor="order" className="bg-transparent form-label">
                    Sırala
                  </label>
                </div>
              </div>
            </div>
            <div className="grid justify-center w-full grid-cols-2 mt-16 mb-20 lg:grid-cols-4 gap-x-6 gap-y-12 menu-item">
              {filteredDocs.map((doc) => {
                const docToRender = doc.toJSON();
                return (
                  <div
                    key={docToRender.objectId}
                    className="hover:text-red-400 "
                  >
                    <SingleLibraryDoc
                      docType={docToRender.documentType}
                      title={docToRender.title}
                      file={docToRender.file}
                    />
                  </div>
                );
              })}

              {/* <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-teal">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#keynote" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-purple">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#document" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-red">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#powerpoint" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-blue">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#pdf" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-teal">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#keynote" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-purple">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#document" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-red">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#powerpoint" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-blue">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#pdf" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-teal">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#keynote" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-purple">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#document" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-red">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#powerpoint" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-blue">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#pdf" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-teal">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#keynote" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center">
              <a href="#" className="space-y-6 focus:outline-none">
                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-purple">
                  <svg
                    className="w-10 link-icon text-primary-black "
                    role="img"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#document" />
                  </svg>
                </p>
                <p tabindex="0" className="w-48 text-center">
                  Nulla vitae eleifend duis semper facilisi habitasse.
                </p>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default OtherDocs;
