import React from "react";
import Post from "../../../KaleWall/components/Post";

function UserKaleWall({ posts, handleGetPosts, setPosts }) {
  return (
    <div className="w-3/5 mx-auto">
      {posts?.map((post) => (
        <div className="flex-col col-span-8 self-center mb-6" key={post?.id}>
          <Post
            posts={posts}
            post={post}
            key={post?.id}
            handleGetPosts={handleGetPosts}
            setPosts={(posts) => setPosts(posts)}
          />
        </div>
      ))}
    </div>
  );
}

export default UserKaleWall;
