import React, { useState, useEffect } from "react";
import GiftCatalog from "./components/GiftCatalog";
import KaleWall from "./components/KaleWall";
import MainSlider from "./components/MainSlider";
import EventsAndCalendar from "./components/EventsAndCalendar";
import MenuSection from "./components/MenuSection";
import AuthLayout from "../../layouts/AuthLayout";
import {
  getLoggedInContent,
  getLiveStreamBanner,
} from "../../services/contentService";
import { Helmet } from "react-helmet";

export default function HomePage() {
  const [content, setContent] = useState(null);
  const [liveStreamBannerUrl, setLiveStreamBannerUrl] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const result = await getLiveStreamBanner();
        if (result) {
          const json = await result.toJSON();
          if (json.banner) {
            setLiveStreamBannerUrl(json?.banner?.url);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const result = await getLoggedInContent();
        setContent(result);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <MainSlider
          sliderItems={content?.main_slider_items}
          liveStreamBannerUrl={liveStreamBannerUrl}
        />
        <MenuSection menuItems={content?.menu_items} />
        <GiftCatalog
          title={content?.info_section_title}
          text={content?.info_section_text}
          image={content?.info_section_image}
          buttonText={content?.info_section_button_text}
          buttonLink={content?.info_section_button_url}
        />
        <KaleWall />
        <EventsAndCalendar />
      </AuthLayout>
    </>
  );
}
