import React from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content">
          <div className="px-4 py-5 bg-background-purple md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto lg:px-1 max-w-7xl">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  {" "}
                  Gizlilik Politikası{" "}
                </h1>
              </div>
            </main>
          </div>
          <div className="mx-auto max-w-7xl">
            <div className="px-4 py-8 lg:py-16 xl:px-0">
              <p>
                Kaleseramik Çanakkale Kalebodur Seramik Sanayi A.Ş. (bundan
                böyle kısaca “KALESERAMİK”) olarak herhangi bir şekilde
                eriştiğimiz gerçek kişilere ait tüm kişisel verilerin
                korunmasına ve bu kapsamda Kanun ve ilgili mevzuat
                çerçevesindeki gereklilikleri tam olarak yerine getirmeyi ilke
                haline getirmiş bulunuyoruz. İşbu Aydınlatma metni,{" "}
                <Link to="/">www.kalesatiskulubu.com</Link> adresindeki
                sitemizin kullanıcılarının, internet sitemizde toplanan kişisel
                verilerin kaydedilmesi, işlenmesi, paylaşılması, üçüncü
                taraflara aktarılması, saklanması, silinmesi, imhası ve
                anonimleştirilmesi süreçleri ile bunlara ilişkin ilkelerimiz
                hakkında bilgilendirilmesi amacıyla hazırlanmıştır.
              </p>
              <p>
                <strong>KALESERAMİK, </strong>yürürlükteki ilgili mevzuat
                hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün
                korunması amacıyla gerekli organizasyonu kurmak ve teknik
                önlemleri almak ve uyarlamak konusunda veri sorumlusu sıfatıyla
                sorumluluğu üstlenmiştir.
              </p>
              <p>
                <strong>KALESERAMİK, </strong>kişisel verilere yetkisiz erişim
                veya bu bilgilerin kaybı, hatalı kullanımı, ifşa edilmesi,
                değiştirilmesi veya imha edilmesine karşı korumak için gerekli
                önlemleri almaktadır.
              </p>
              <p>
                <strong>KALESERAMİK, </strong>herhangi bir ihlali fark ettiği
                anda kullanıcıları bu konuda vakit kaybetmeksizin bilgilendirir,
                yasalara uygun bir şekilde takibinin yapılmasına olanak tanır ve
                bilgilerinin güvenliğini elinden gelen en iyi şekilde sağlar.
              </p>
              <h5 className="my-5">1. Kişisel Veri Tanımı</h5>
              <p>
                İşbu Kişisel Verilerin Korunması Politikası'nda kullanılan
                "Kişisel Bilgiler" terimi, isim, soy isim, doğum tarihi ve doğum
                yeri, telefon numarası, motorlu taşıt plakası, sosyal güvenlik
                numarası, pasaport numarası, özgeçmiş, resim, görüntü ve ses
                kayıtları, parmak izleri, e-posta adresi gibi bir gerçek kişi
                ile ilişkilendirilebilen bilgileri ifade etmektedir.
              </p>
              <h5 className="my-5">
                2. Kişisel Verilerin Toplanmasının Yasal Dayanağı
              </h5>
              <p>
                Kullanıcıların kişisel verilerinin kullanılması konusunda
                çeşitli kanunlarda düzenlemeler bulunmaktadır. En başta
                Anayasa’nın 20. maddesi, 5651 sayılı Internet Ortamında Yapılan
                Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla
                Mücadele Edilmesi Hakkında Kanun ve 6698 sayılı Kişisel
                Verilerin Korunması Kanunu ve bağlı mevzuat ile kişisel
                verilerin korunması esasları belirlenmiştir.
              </p>
              <h5 className="my-5">3. Kişisel Verilerin Toplanma Yöntemleri</h5>
              <p>
                <Link to="/">www.kalesatiskulubu.com</Link>’daki hesabını
                kullanırken doğrudan paylaşılan kullanıcı adı, şifre, e-mail,
                doğum tarihi, cinsiyet ve benzeri verilerle otomatik olarak elde
                edilen, görüntülenen sayfalar, ziyaret süreleri gibi siteye
                giriş bilgileri, çerezler vasıtasıyla elde edilen bilgiler,
                konum bilgileri, tarayıcı tipi ve dili, cihaz modeli gibi
                verileri ürün ve hizmetlerimizi sağlayabilmek için gereken süre
                boyunca saklıyoruz. Yer sağlayıcı olarak yasal yükümlülüğümüz
                gereğince kullanıcıların
                <Link to="/">www.kalesatiskulubu.com</Link>’daki hesabını
                kullanırken otomatik olarak elde edilen IP bilgilerini yasal
                süre boyunca saklıyoruz.
              </p>

              <p>
                Bununla birlikte <Link to="/">www.kalesatiskulubu.com</Link>’da
                bir hesabı olmaksızın veya{" "}
                <Link to="/">www.kalesatiskulubu.com</Link>’daki hesabına giriş
                yapılmaksızın sitenin ziyaret edilmesi ve kullanılması durumunda
                otomatik olarak görüntülenen sayfalar, ziyaret süreleri gibi
                siteye giriş bilgileri, IP bilgileri, çerezler vasıtasıyla elde
                edilen bilgiler, konum bilgileri, tarayıcı tipi ve dili, cihaz
                modeli gibi verileri anonim olarak ürün ve hizmetlerin
                sağlanabilmesi amacıyla gereken süre boyunca saklıyoruz.
              </p>

              <p>
                <Link to="/">www.kalesatiskulubu.com</Link>da bulunduğunuz süre
                boyunca tarayıcınıza izin verdiğiniz çerezlerin yerleştirilmesi
                söz konusu olabilir. Ayrıntılı bilgi için lütfen çerez
                politikamızı ziyaret ediniz.
              </p>

              <h5 className="my-5">
                4. Kişisel Verilerin Kullanıldığı Alanlar
              </h5>
              <p>
                Kişisel Bilgiler aşağıdaki amaçlar için kullanılacaktır, hiçbir
                durumda verinin alınması ile ilgili olan ve verinin alındığı
                mecrada aydınlatma metninde belirtilen amacın dışında
                kullanılmayacaktır.
              </p>

              <h5 className="my-5">5. Kişisel Bilgilerinin Paylaşımı</h5>
              <p>
                <Link to="/">www.kalesatiskulubu.com</Link> adlı internet
                sitesinden temin edilen Kişisel Bilgiler aşağıdaki amaçlar için
                kullanılacaktır, hiçbir durumda verinin alınması ile ilgili olan
                ve verinin alındığı mecrada aydınlatma metninde belirtilen
                amacın dışında kullanılmayacaktır.
              </p>

              <p>
                Ancak KALESERAMİK ilgili hizmetlerin sunulması ve ticari
                faaliyetin yürütülmesi açısından gerekli ve zorunlu hallerde
                gerekli tedbirleri alarak ve mevzuatın izin verdiği çerçevede
                bilgileri tedarikçileri, çalışanları, danışmanları, iş ortakları
                ve ilgili 3. Kişilerle paylaşabilecektir. Bu ilgili 3. Kişiler
                ilgili mevzuat çerçevesinde yurt dışında bulunan gerçek veya
                tüzel kişiler olabileceklerdir.
              </p>

              <p>
                Ayrıca, KALESERAMİK yürürlükteki mevzuat uyarınca yetkili, idari
                ve resmi makamlardan usulüne uygun olarak talep gelmesi halinde
                kullanıcının uhdesinde bulunan bilgilerini ilgili yetkili
                makamlarla paylaşabilir.
              </p>
              <h5 className="my-5">
                6. Kişisel Verilerin Korunması Kanunu uyarınca kullanıcıların
                hakları:
              </h5>

              <p>KVKK uyarınca kişisel verilerinizin;</p>
              <ul className="my-3">
                <li>• işlenip işlenmediğini öğrenme,</li>
                <li>• işlenmişse bilgi talep etme,</li>
                <li>
                  • işlenme amacını ve amacına uygun kullanılıp kullanılmadığını
                  öğrenme,
                </li>
                <li>
                  • yurt içinde/yurt dışında aktarıldığı 3. kişileri bilme,
                </li>
                <li>• eksik/yanlış işlenmişse düzeltilmesini isteme,</li>
                <li>
                  • KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde
                  silinmesini/yok edilmesini isteme,
                </li>
                <li>
                  • aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri
                  uyarınca yapılan işlemlerin bildirilmesini isteme,
                </li>
                <li>
                  • münhasıran otomatik sistemler ile analiz edilmesi nedeniyle
                  aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
                </li>
                <li>
                  • KVKK’ya aykırı olarak işlenmesi sebebiyle zarara uğramanız
                  hâlinde zararın giderilmesini talep etme haklarına sahip
                  olduğunuzu hatırlatmak isteriz.
                </li>
              </ul>

              <h5 className="my-5">
                7. Kişisel Verilerinizin Kaydedilmesine ilişkin Rızanız
              </h5>
              <p>
                <Link to="/">www.kalesatiskulubu.com</Link> adlı internet
                sitemizi kullandığınız andan itibaren sizden toplanan tüm
                bilgileri yukarıda belirtilen şekilde ve nedenlerle, bu
                politikada anlatıldığı gibi, kullanmamızı kabul etmiş olursunuz.
                Yukarıdaki amaç için elde edilen bilgiler, tamamen sizin özgür
                iradenizle tarafımıza sağlanmaktadır. Bu Kişisel Bilgileri bize
                verip vermemekte kullanıcılar serbesttir.
              </p>
              <h5 className="my-5">8. Politika’nın güncellenmesi</h5>
              <p>
                Politikamızı ve kişisel bilgileri işleme şeklimizi muhtelif
                zamanlarda gözden geçirebilir, tadil edebilir veya
                yenileyebiliriz. Güncellenmiş Politikayı{" "}
                <Link to="/">www.kalesatiskulubu.com</Link>
                adresinde bulunan internet sitemizden yayımlayacağız. Yenilenen
                şartlar, yayımlandıkları tarihten itibaren yürürlüğe
                gireceklerdir.
              </p>
              <p>
                Kişisel Veri Sahipleri, sorularını, görüşlerini veya taleplerini
                yazılı olarak, bizzat veya iadeli taahhütlü posta yoluyla,
                ks-kvkk@kale.com.tr adresine, şirketin kaleseramikas@hs01.kep.tr
                KEP adresine kayıtlı e-posta gönderilerek veya çağrı merkezine
                başvurarak iletmelidir. Bu halde 30 gün içerisinde yazılı
                şekilde cevap verilecektir.
              </p>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
