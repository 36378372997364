import React, { useState, useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import KalekimTab from "./components/KalekimTab";
import KaleseramikTab from "./components/KaleseramikTab";
import classNames from "classnames";
import { Link } from "react-router-dom";
import LoadingModal from "../../components/shared/LoadingModal";
import { getOtherDocs } from "../../services/contentService";
import {
  getEducationDocs,
  getKalekimCatalogs,
  getKaleseramikCatalogs,
} from "../../services/contentService";
import { SingleLibraryDoc } from "../../components/shared/SingleLibraryDoc";
import { Helmet } from "react-helmet";

const Library = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [eduDocs, setEduDocs] = useState([]);
  const [otherDocs, setOtherDocs] = useState([]);
  const [kalekimCatalogs, setKalekimCatalogs] = useState([]);
  const [kaleseramikCatalogs, setKaleseramikCatalogs] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getKalekimCatalogs();
      setKalekimCatalogs(result);
      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getKaleseramikCatalogs();
      setKaleseramikCatalogs(result);
      setLoading(false);
    })();
  }, []);
  const activeTabMarkup = () => {
    switch (activeTab) {
      case 1:
        return <KaleseramikTab catalogs={kaleseramikCatalogs} />;
      case 2:
        return <KalekimTab catalogs={kalekimCatalogs} />;
      default:
        return;
    }
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      // show only 4 documents
      const result = await getEducationDocs("descending", 4);
      setEduDocs(result);
      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getOtherDocs("descending", 4);
      setOtherDocs(result);
      setLoading(false);
    })();
  }, []);
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div id="content" className="">
          <div className="px-4 py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 md:grid lg:items-center">
                <div className="col-span-7">
                  <h1
                    data-testid="library-title"
                    className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0"
                  >
                    Kütüphane
                  </h1>
                </div>
              </div>
            </main>
          </div>
          <div className="bg-secondary-secondary-bg">
            <div className="px-1 mx-auto max-w-7xl">
              <div className="px-4 py-16 lg:px-0">
                <h2 className="mb-16 text-xl text-center">
                  Eğitim Dökümanları
                </h2>
                <div className="grid justify-center w-full grid-cols-2 gap-6 lg:flex menu-item">
                  {eduDocs?.map((doc) => {
                    const docToRender = doc.toJSON();
                    return (
                      <div
                        key={docToRender.objectId}
                        className="hover:text-red-400 "
                      >
                        <SingleLibraryDoc
                          docType={docToRender?.documentType}
                          title={docToRender?.title}
                          file={docToRender?.file}
                          url={docToRender?.url}
                        />
                      </div>
                    );
                  })}
                </div>
                <Link
                  data-testid="library-more-docs"
                  to="/education"
                  className="w-full mx-auto mt-12 form-submit-btn lg:w-auto max-w-none px-6 whitespace-nowrap lg:max-w-[140px]"
                >
                  Tümünü Gör
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="max-w-5xl mx-auto">
              <div className="py-16">
                <h2 className="mb-16 text-xl text-center">Ürün ve Katalog</h2>
                <div className="mb-12 text-center">
                  <div className="relative z-0 inline-flex bg-secondary-secondary-bg mx-auto p-0.5">
                    <button
                      data-testid="library-tab-2"
                      type="button"
                      onClick={() => setActiveTab(1)}
                      className={classNames(
                        activeTab === 1
                          ? "inline-block !bg-primary-red !text-white active"
                          : "inline-block !text-primary-black !bg-transparent",
                        "px-4 tab-btn text-primary-black hover:text-white lg:w-48"
                      )}
                    >
                      Kaleseramik
                    </button>
                    <button
                      data-testid="library-tab-1"
                      type="button"
                      onClick={() => setActiveTab(2)}
                      className={classNames(
                        activeTab === 2
                          ? "inline-block !bg-primary-red !text-white active"
                          : "inline-block !text-primary-black !bg-transparent",
                        "px-4 tab-btn text-primary-black hover:text-white lg:w-48"
                      )}
                    >
                      Kalekim
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-10 px-4 lg:grid-cols-3 lg:px-0"></div>
                {activeTabMarkup()}
              </div>
            </div>
          </div>
          <div className="bg-secondary-secondary-bg">
            <div className="px-1 mx-auto max-w-7xl">
              <div className="px-4 py-16 lg:px-0">
                <h2 className="mb-16 text-xl text-center">Dökümanlar</h2>
                <div className="grid justify-center w-full grid-cols-2 gap-6 lg:flex menu-item">
                  {otherDocs?.map((doc) => {
                    const docToRender = doc.toJSON();
                    return (
                      <div
                        key={docToRender.objectId}
                        className="hover:text-red-400 "
                      >
                        <SingleLibraryDoc
                          docType={docToRender?.documentType}
                          title={docToRender?.title}
                          file={docToRender?.file}
                          url={docToRender?.url}
                        />
                      </div>
                    );
                  })}
                </div>
                <Link
                  data-testid="library-other-docs"
                  to="/other-docs"
                  className="w-full mx-auto mt-12 form-submit-btn lg:w-auto max-w-none px-4 lg:max-w-[140px]"
                >
                  Tümünü Gör
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="max-w-5xl mx-auto">
              <div className="py-16">
                <h2 className="mb-16 text-xl text-center">Videolar</h2>
                <div className="mb-12 text-center">
                  <div className="relative z-0 inline-flex bg-secondary-secondary-bg mx-auto p-0.5">
                    <button
                      data-testid="library-tab-1"
                      type="button"
                      onClick={() => setActiveTab(1)}
                      className={classNames(
                        activeTab === 1
                          ? "inline-block !bg-primary-red !text-white active"
                          : "inline-block !text-primary-black !bg-transparent",
                        "px-4 tab-btn text-primary-black hover:text-white lg:w-48"
                      )}
                    >
                      Kalekim
                    </button>
                    <button
                      data-testid="library-tab-2"
                      type="button"
                      onClick={() => setActiveTab(2)}
                      className={classNames(
                        activeTab === 2
                          ? "inline-block !bg-primary-red !text-white active"
                          : "inline-block !text-primary-black !bg-transparent",
                        "px-4 tab-btn text-primary-black hover:text-white lg:w-48"
                      )}
                    >
                      Kaleseramik
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-10 px-4 lg:grid-cols-3 lg:px-0"></div>
                {/* {activeTabMarkup()} */}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default Library;
