import React, { useState, useEffect } from "react";

export const SingleLibraryDoc = ({ title, docType, file, url }) => {
  const [svgType, setSvgType] = useState("pdf");
  const [svgBgColor, setSvgBgColor] = useState("");
  useEffect(() => {
    switch (docType) {
      case "pdf":
        setSvgType("pdf");
        break;
      case "doc":
        setSvgType("document");
        break;
      case "docx":
        setSvgType("document");
        break;
      case "pptx":
        setSvgType("powerpoint");
        break;
      case "xls":
        setSvgType("keynote");
        break;
      default:
        setSvgType("pdf");
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    switch (docType) {
      case "pdf":
        setSvgBgColor("bg-background-blue");
        break;
      case "doc":
        setSvgBgColor("bg-background-purple");
        break;
      case "docx":
        setSvgBgColor("bg-background-purple");
        break;
      case "pptx":
        setSvgBgColor("bg-background-red");
        break;
      case "xls":
        setSvgBgColor("bg-background-teal");
        break;
      default:
        setSvgBgColor("bg-background-blue");
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div className="flex flex-col items-center ">
      {file && !url && (
        <a href={`${file?.url}`} className="space-y-6 focus:outline-none">
          <p className={`w-16 h-16 mx-auto link-icon-wrapper ${svgBgColor}`}>
            <svg role="img" className="w-10 link-icon text-primary-black">
              <use xlinkHref={`/assets/img/necromancers.svg#${svgType}`} />
            </svg>
          </p>
          <p tabIndex="0" className="w-48 text-center">
            {title}
          </p>
        </a>
      )}
      {file && url && (
        <a
          href={`${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="space-y-6 focus:outline-none"
        >
          <p className={`w-16 h-16 mx-auto link-icon-wrapper ${svgBgColor}`}>
            <svg role="img" className="w-10 link-icon text-primary-black">
              <use xlinkHref={`/assets/img/necromancers.svg#${svgType}`} />
            </svg>
          </p>
          <p tabIndex="0" className="w-48 text-center">
            {title}
          </p>
        </a>
      )}
      {!file && url && (
        <a
          href={`${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="space-y-6 focus:outline-none"
        >
          <p className={`w-16 h-16 mx-auto link-icon-wrapper ${svgBgColor}`}>
            <svg role="img" className="w-10 link-icon text-primary-black">
              <use xlinkHref={`/assets/img/necromancers.svg#${svgType}`} />
            </svg>
          </p>
          <p tabIndex="0" className="w-48 text-center">
            {title}
          </p>
        </a>
      )}
    </div>
  );
};
