import React from "react";
import FaqItem from "./FaqItem";

export default function Faq(props) {
  const {faq, faq_text} = props;

  return (
    <div className="mt-px">
      <div className="px-4 py-16 lg:py-24 bg-background-red lg:px-1" id="faq">
        <main className="px-1 mx-auto max-w-7xl">
          <div className="mx-auto space-y-12">
            <div className="space-y-4 text-center">
              <h2 className="text-xl">Sıkça Sorulan Sorular</h2>
              <p className="text-secondary-primary">
                {faq_text}
              </p>
            </div>
            <dl className="space-y-8">
              {faq?.map((faqItem, index) => (
                <FaqItem key={index} faqItem={faqItem} />
              ))}
            </dl>
          </div>
        </main>
      </div>
    </div>
  );
}
