import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { Helmet } from "react-helmet";

export default function TermsOfUse() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content">
          <div className="px-4 py-5 bg-background-purple md:px-4 lg:py-16 lg:px-1">
            <main className="px-1 mx-auto max-w-7xl">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  {" "}
                  Kullanım Koşulları{" "}
                </h1>
              </div>
            </main>
          </div>
          <div className="px-1 mx-auto max-w-7xl">
            <div className="px-4 py-8 lg:py-16 xl:px-0">
              <p>
                Lacus, sapien in aliquam nulla quam semper. Sit elementum ut
                etiam ultricies ipsum mauris. Nibh lectus sed cras pharetra,
                vel, molestie. Blandit dui tincidunt ut urna tortor, sapien,
                vitae ac at. Venenatis malesuada faucibus a feugiat vestibulum
                mi lacus. Dignissim tellus adipiscing parturient libero ante.
                Dignissim nisl, eget iaculis nulla lobortis curabitur hac mauris
                aliquam. Enim lectus ante ut vitae sapien. Amet lectus id arcu,
                faucibus sodales arcu gravida imperdiet. A et gravida
                condimentum placerat. Arcu tortor, ut duis gravida dui egestas
                sed. Mauris a quis fusce sem nec porttitor in nisl scelerisque.
                Pulvinar duis suspendisse maecenas posuere cursus. Etiam cras
                libero amet, sit suspendisse odio. Tincidunt diam a, sit nullam.
                Phasellus sed nisi, eu purus. Orci ultrices dui vivamus nisl
                sollicitudin. Velit, pretium ut pretium nec erat adipiscing
                sollicitudin nec tempor. Scelerisque bibendum lacinia mauris est
                metus eleifend adipiscing mauris. Tortor ut convallis eget
                tristique volutpat tristique id aliquet. Amet quisque risus,
                ipsum pulvinar a rhoncus, donec. Volutpat magna sem nunc aliquam
                venenatis vitae. Nam tellus nisi et vestibulum nunc amet,
                rhoncus. Ultrices risus massa neque mauris iaculis venenatis
                eros. Eget neque ullamcorper vitae congue mollis. Consectetur
                semper posuere turpis cras gravida sem non massa, bibendum.
                Ornare suspendisse nulla vulputate lorem varius euismod rhoncus.
                Semper pretium egestas nulla arcu viverra tristique. Adipiscing
                ultricies arcu varius pharetra enim, arcu porttitor pretium
                gravida. Non arcu nam in nec proin facilisis nibh viverra. Ac
                nisl congue odio consectetur libero eu risus sed. Morbi sit id
                eget leo et ultrices. In velit, commodo suspendisse urna quisque
                vestibulum. Est urna gravida ac enim. Suspendisse aliquam tortor
                eu, viverra cras tellus nulla. Ullamcorper adipiscing donec ut
                vitae accumsan diam mi. Sit euismod auctor id ultrices dolor
                penatibus odio donec. Ut ante tortor felis fermentum. Nulla
                volutpat gravida libero sit faucibus et integer faucibus massa.
                Commodo pulvinar diam, sed maecenas. Fermentum vulputate risus,
                accumsan adipiscing. Lectus in proin orci tincidunt faucibus.{" "}
                <br />
                <br /> Platea ornare vitae eros, neque. Congue mi vivamus quis
                lectus mattis eu non vitae. Hendrerit scelerisque iaculis justo
                sagittis eu. Nibh praesent pharetra ultrices bibendum.
                Scelerisque ac id commodo bibendum eget ut eget bibendum. Enim
                enim vestibulum, neque sed elit mattis et congue neque. Tellus
                volutpat purus morbi nunc. Nibh id cum libero enim lorem. Quam
                id arcu vestibulum, eu arcu sapien. Rhoncus ac pharetra, hac
                pharetra. Lorem aliquet augue quis nunc. Neque, tincidunt
                viverra donec platea lectus adipiscing at tincidunt enim.
                Pharetra ultrices blandit in nisi consequat. Sed congue
                venenatis massa netus et in tempus netus. Amet ullamcorper
                adipiscing massa at a elit, vestibulum at faucibus. <br />
                <br /> Gravida consectetur sit aliquet risus, fringilla egestas.
                Elementum ultricies quis vel fringilla ullamcorper enim. In sit
                tempor, nulla arcu justo. Justo, nulla felis integer mi quam
                quam. Sagittis, enim, tincidunt turpis sapien vehicula at.
                Commodo tempus pharetra, odio sit. Felis euismod quam non mi,
                sagittis semper in integer at. Nunc, nunc, lectus sagittis purus
                accumsan magna sed aliquet id. Ornare sed mauris feugiat aliquam
                suspendisse mi ac tempor varius. Mauris mattis massa sed
                vulputate sit dignissim.
              </p>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
