import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConversations } from "../../../../services/contentService";
import { SET_MESSAGE_USER } from "../../../../store/message/messageSlice";
import Conversations from "./components/Conversations";
import CreateMessage from "./components/CreateMessage";
import MessageDetails from "./components/MessageDetails";
import Parse from "parse";
import Modal from "../../../../components/shared/Modal";

const Messages = ({ messageModal }) => {
  const [activeContent, setActiveContent] = useState("conversations");
  const [conversations, setConversations] = useState([]);
  const [friendForMessageDetails, setFriendForMessageDetails] = useState(null);
  const [newConversation, setNewConversation] = useState(null);
  const [updatedConversation, setUpdatedConversation] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [apiError, setApiError] = useState("");
  const headerSlice = useSelector((state) => state?.header);
  const appSlice = useSelector((state) => state?.app);
  const messageSlice = useSelector((state) => state?.message);
  const userFromMessageSlice = messageSlice?.user;
  const isMessagesOpen = headerSlice?.messages;
  const { user } = appSlice;
  const dispatch = useDispatch();

  const startLiveConversation = async () => {
    const convoQuery = new Parse.Query("SocialConversation");
    convoQuery.subscribe().then((myConversationsSubscription) => {
      myConversationsSubscription.on("create", (object) => {
        const handledObject = {
          ...object.toJSON(),
          subscription: true,
        };
        setNewConversation(handledObject);
      });

      myConversationsSubscription.on("update", (object) => {
        const handledObject = {
          ...object.toJSON(),
          subscription: true,
        };
        setUpdatedConversation(handledObject);
      });
    });
  };

  useEffect(() => {
    if (userFromMessageSlice && isMessagesOpen) {
      setActiveContent("createMessage");
    } else if (!isMessagesOpen) {
      dispatch(SET_MESSAGE_USER(null));
    }
    //eslint-disable-next-line
  }, [userFromMessageSlice, isMessagesOpen]);

  useEffect(() => {
    (async () => {
      if (!user) throw new Error("Unauthorized");
      const conversations = await getConversations();
      setConversations(conversations);
    })();
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (newConversation) {
      conversations.unshift(newConversation);
    }
    //eslint-disable-next-line
  }, [newConversation]);

  useEffect(() => {
    if (updatedConversation) {
      const conversationsCopy = [...conversations];
      let index = -1;
      const conversationForUpdate = conversationsCopy?.find(
        (i) => i?.friend?.id === updatedConversation?.friend?.objectId
      );
      if (conversationForUpdate)
        index = conversationsCopy.indexOf(conversationForUpdate);
      if (index !== -1 && conversationsCopy?.[index]?.message) {
        conversationsCopy[index].message = {
          ...conversationsCopy?.[index]?.message,
          message: updatedConversation?.lastMessage?.message,
        };
        const exactConversation = conversationsCopy[index];
        conversationsCopy.splice(index, 1);
        conversationsCopy.unshift(exactConversation);
        setConversations([...conversationsCopy]);
      }
    }
    //eslint-disable-next-line
  }, [updatedConversation]);

  useEffect(() => {
    (async () => {
      startLiveConversation();
    })();
    //eslint-disable-next-line
  }, []);

  const activeContentMarkUp = () => {
    switch (activeContent) {
      case "createMessage":
        return (
          <CreateMessage
            activeContent={activeContent}
            setActiveContent={(content) => setActiveContent(content)}
            user={user}
            conversations={conversations}
            setConversations={(conversations) =>
              setConversations(conversations)
            }
            userFromMessageSlice={userFromMessageSlice}
            isMessagesOpen={isMessagesOpen}
          />
        );
      case "conversations":
        return (
          <Conversations
            setActiveContent={(content) => setActiveContent(content)}
            conversations={conversations}
            setConversations={(conversations) =>
              setConversations(conversations)
            }
            user={user}
            setFriendForMessageDetails={(friend) =>
              setFriendForMessageDetails(friend)
            }
          />
        );
      case "messageDetails":
        return (
          <MessageDetails
            activeContent={activeContent}
            setActiveContent={(content) => setActiveContent(content)}
            friendForMessageDetails={friendForMessageDetails}
            conversations={conversations}
            setConversations={(conversations) =>
              setConversations(conversations)
            }
            user={user}
            setShowErrorModal={(show) => setShowErrorModal(show)}
            setApiError={(error) => setApiError(error)}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 z-10 w-full h-screen max-w-full transform bg-white shadow-lg lg:left-auto lg:top-auto lg:h-auto lg:absolute lg:max-w-sm"
        ref={messageModal}
      >
        {activeContentMarkUp()}
      </div>
      <Modal
        type="error"
        msg={apiError}
        shouldShow={showErrorModal}
        updateModalState={(state) => setShowErrorModal(state)}
      />
    </>
  );
};

export default Messages;
