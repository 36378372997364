import React, {useLayoutEffect} from "react";
import { useLocation } from "react-router-dom";
import HeaderPublic from "../pages/NonLoginHomePage/components/HeaderPublic";
import FooterPublic from "../pages/NonLoginHomePage/components/FooterPublic";

const DefaultLayout = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      <HeaderPublic />
      <main>{children}</main>
      <FooterPublic />
    </>
  );
};

export default DefaultLayout;
