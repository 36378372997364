import React, { useCallback } from "react";

export default function Question(props) {
  const { question, answers, setAnswers, isQuestionAnswered, survey } = props;

  const handleAnswer = (choiceId) => {
    const surveyId = survey?.objectId;
    if (question?.type === "ch") {
      if (isQuestionAnswered(question)) {
        const answersCopy = [...answers];
        const index = answers.findIndex((i) => i.questionId === question?.objectId);
        answersCopy[index].choiceId = choiceId;
        setAnswers(answersCopy);
      } else {
        setAnswers([...answers, { questionId: question?.objectId, surveyId, choiceId }]);
      }
    } else if (question?.type === "free") {
      const text = choiceId;

      if (isQuestionAnswered(question)) {
        const answersCopy = [...answers];
        const index = answers.findIndex((i) => i.questionId === question?.objectId);
        answersCopy[index].text = text;
        setAnswers(answersCopy);
      } else {
        const answersCopy = [...answers];
        const index = answers.findIndex((i) => i.questionId === question?.objectId);
        if (index > -1) {
          answersCopy[index].text = text;
          setAnswers(answersCopy);
        } else {
          setAnswers([...answers, { questionId: question?.objectId, surveyId, text }]);
        }
      }
    } else {
      const answersCopy = [...answers];
      const questionIndex = answers.findIndex((i) => i.questionId === question?.objectId);
      if (questionIndex > -1) {
        const exactQuestion = answersCopy[questionIndex];
        const choiceIndex = exactQuestion.choiceIds.findIndex((i) => i === choiceId);
        if (choiceIndex > -1) {
          exactQuestion.choiceIds.splice(choiceIndex, 1);
        } else {
          exactQuestion.choiceIds.push(choiceId);
        }
        answersCopy[questionIndex] = exactQuestion;
        setAnswers(answersCopy);
      } else {
        answersCopy.push({
          questionId: question?.objectId,
          surveyId,
          choiceIds: [choiceId],
        });
        setAnswers(answersCopy);
      }
    }
  };

  const isChoiceAnswered = useCallback(
    (choiceId) => {
      if (question.type === "ch") {
        const surveyId = survey?.objectId;
        const choiceExists = answers.filter((i) => i.questionId === question?.objectId && i.choiceId === choiceId && i.surveyId === surveyId);
        if (choiceExists.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        const surveyId = survey?.objectId;
        const choiceExists = answers.filter((i) => i.questionId === question?.objectId && i.surveyId === surveyId && i.choiceIds.includes(choiceId));
        if (choiceExists.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    [answers, survey?.objectId, question?.objectId, question.type]
  );

  const getTextValue = useCallback(() => {
    const surveyId = survey?.objectId;
    const textValue = answers.filter((i) => i.questionId === question?.objectId && i.surveyId === surveyId);

    if (textValue?.length > 0) {
      return textValue[0]?.text;
    } else {
      return "";
    }
  }, [answers, question?.objectId, survey?.objectId]);

  return (
    <div>
      <h4 className="max-w-lg mt-10 text-md lg:mt-0">{question?.title}</h4>
      <p className="mt-4">{question?.description}</p>
      {question?.type === "ch" && (
        <div className="flex flex-col gap-6 mt-8">
          {question?.choices?.map((choiceItem) => (
            <div className="form-input-wrapper" key={choiceItem?.choiceId}>
              <label className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="w-4 h-4 text-black border border-primary-black"
                  name="radio"
                  onChange={() => handleAnswer(choiceItem?.choiceId)}
                  checked={isChoiceAnswered(choiceItem?.choiceId)}
                />
                <div className="ml-2 form-label text-body-s text-secondary-primary">{choiceItem?.choice}</div>
              </label>
            </div>
          ))}
        </div>
      )}
      {question?.type === "free" && (
        <div className="form-input-wrapper mt-4">
          <textarea
            type="text"
            id="other"
            name="other"
            placeholder=""
            onChange={(e) => handleAnswer(e.target.value)}
            value={getTextValue()}
            className="h-40 bg-transparent resize-none form-input"
          ></textarea>
          <label htmlFor="other" className="bg-transparent form-label">
            Cevabınız
          </label>
        </div>
      )}
      {question?.type === "chmulti" && (
        <div className="flex flex-col gap-6 mt-8">
          {question?.choices?.map((choiceItem) => (
            <div className="form-input-wrapper" key={choiceItem?.choiceId}>
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="w-4 h-4 text-black border border-primary-black rounded-full"
                  name="check"
                  onChange={() => handleAnswer(choiceItem?.choiceId)}
                  checked={isChoiceAnswered(choiceItem?.choiceId)}
                />
                <div className="ml-2 form-label text-body-s text-secondary-primary">{choiceItem?.choice}</div>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
