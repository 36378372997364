import React, { useState, useEffect } from "react";
import classNames from "classnames";
import AuthLayout from "../../layouts/AuthLayout";
import FriendRequests from "./components/FriendRequests";
import AddNewFriend from "./components/AddNewFriend";
import MyFriends from "./components/MyFriends";
import { getCities, getRegions } from "../../services/authService";
import { Helmet } from "react-helmet";

const Friends = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cityField, setCityField] = useState({ id: "0", name: "" });
  const [regionField, setRegionField] = useState({ id: "0", name: "" });

  const activeTabMarkup = () => {
    switch (activeTab) {
      case 1:
        return <MyFriends />;
      case 2:
        return <FriendRequests />;
      case 3:
        return (
          <AddNewFriend
            cities={cities}
            regions={regions}
            getCityName={getCityName}
            getRegionName={getRegionName}
            cityField={cityField}
            setCityField={(city) => setCityField(city)}
            regionField={regionField}
            setRegionField={(region) => setRegionField(region)}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    (async () => {
      const cities = await getCities();
      cities.sort((a, b) => sortByName(a, b));
      cities.unshift({ ID: "0", NAME: "" });
      setCities(cities);
    })();
  }, []);

  const getCityName = (id) => {
    const city = cities?.find((city) => city.ID === id);
    return city.NAME;
  };

  const getRegionName = (id) => {
    const region = regions?.find((region) => region.ID === id);
    return region.NAME;
  };

  const sortByName = (a, b) => {
    return a.NAME.localeCompare(b.NAME);
  };

  useEffect(() => {
    (async () => {
      if (cityField.id !== "0") {
        const regions = await getRegions(cityField.id);
        regions.sort((a, b) => sortByName(a, b));
        regions.unshift({ ID: "0", NAME: "" });
        setRegions(regions);
      } else {
        setRegions([{ ID: "0", NAME: "" }]);
      }
    })();
    //eslint-disable-next-line
  }, [cityField]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content" className="">
          <div className="px-4 py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 md:grid lg:items-center">
                <div className="col-span-7">
                  <h1 className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                    Arkadaşlar
                  </h1>
                </div>
              </div>
            </main>
          </div>

          <div className="px-1 mx-auto my-12 max-w-7xl lg:my-16">
            <div className="px-4 mx-auto my-12 max-w-7xl lg:my-16 lg:px-0">
              <div className="mb-12 text-center">
                <div className="relative z-0 inline-flex bg-secondary-secondary-bg mx-auto p-0.5">
                  <button
                    type="button"
                    onClick={() => setActiveTab(1)}
                    className={classNames(
                      activeTab === 1
                        ? "inline-block !bg-primary-red !text-white active"
                        : "inline-block !text-primary-black !bg-transparent",
                      "px-4 tab-btn text-primary-black hover:text-white lg:w-48"
                    )}
                  >
                    Arkadaşlarım
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      activeTab === 2
                        ? "inline-block !bg-primary-red !text-white active"
                        : "inline-block !text-primary-black !bg-transparent",
                      "px-4 tab-btn text-primary-black hover:text-white lg:w-48"
                    )}
                    onClick={() => setActiveTab(2)}
                  >
                    Arkadaşlık Taleplerim
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      activeTab === 3
                        ? "inline-block !bg-primary-red !text-white active"
                        : "inline-block !text-primary-black !bg-transparent",
                      "px-4 tab-btn text-primary-black hover:text-white lg:w-48"
                    )}
                    onClick={() => setActiveTab(3)}
                  >
                    Yeni Arkadaş Ekle
                  </button>
                </div>
              </div>
              <div className="relative flex flex-col">{activeTabMarkup()}</div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default Friends;
