import React, { useEffect, useState, memo } from "react";
import DonutChart from "react-donut-chart";

function ActivePoints({ pointData }) {
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (!pointData) return;

    setPieData([
      {
        label: `Kaleseramik Puanı`,
        value: Number(pointData?.remainingKSPoint),
      },
      {
        label: `Kalekim Puanı`,
        value: Number(pointData?.remainingKKPoint),
      },
    ]);
  }, [pointData]);

  return (
    <div>
      <div className="flex flex-col px-4 lg:px-0">
        <div className="flex flex-col gap-12 lg:flex-row">
          <div className="flex flex-col w-full max-w-4xl mx-auto mb-16 space-y-4 md:flex-row cdc-container justify-evenly">
            <div className="flex flex-row space-x-24">
              <div className="flex flex-col relative">
                <div className="cdc-overlay z-50 w-[325px] h-[325px]">
                  <div className="cdc-text">
                    <div className="space-y-4">
                      <p className="text-body-s font-sm">Toplam</p>
                      <p className="text-lg font-lg">
                        {pointData?.total_point
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </p>
                      <p className="text-body-s text-secondary-secondary">
                        Kale Puan
                      </p>
                    </div>
                  </div>
                </div>
                <DonutChart
                  data={pieData}
                  interactive={false}
                  selectedOffset={false}
                  colors={["#E94034", "#6258C9"]}
                  legend={false}
                  width={325}
                  height={325}
                  strokeColor="transparent"
                  className="p-0"
                />
                {/* <p className="mt-8 text-body-s text-secondary-secondary">
                  Son Kullanım Tarihi; 01.12.2021
                </p> */}
              </div>

              <div className="flex flex-col justify-start ml-4 space-y-4 cdc-legend pt-6 w-96">
                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-red"></span>
                  <span>
                    Kaleseramik Puan{" "}
                    <b>
                      {pointData?.remainingKSPoint
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                  </span>
                </span>

                <span
                  title="28 (28.000000000000004%)"
                  className="cdc-legend-item"
                >
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-purple"></span>
                  <span className="">
                    Kalekim Puan{" "}
                    <b>
                      {pointData?.remainingKKPoint
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                  </span>
                </span>

                <p className="text-secondary-secondary">
                  KS; Kaleseramik | KK; Kalekim
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default memo(ActivePoints);
