import React from "react";

const MyComponent = () => {
  return (
    <footer
      className="bg-secondary-secondary-bg"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-1 mx-auto max-w-7xl">
        <div className="py-6 border-t border-secondary-lines">
          <p className="text-center text-primary-black">
            © {new Date().getFullYear()} Kale Satış Kulübü
          </p>
        </div>
      </div>
    </footer>
  );
};

export default MyComponent;
