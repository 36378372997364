import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const NonRequireAuth = () => {
  const appSlice = useSelector((state) => state.app);
  let location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  if (appSlice.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate state={{ from: location }} to={from} />;
  }

  return <Outlet />;
};

export default NonRequireAuth;
