import React, { useRef , memo } from "react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

function Modal({
  shouldShow,
  updateModalState,
  type,
  msg,
  handleAfterClose,
  setIsConfirmed,
  secondMsg,
}) {
  const ref = useRef();
  useOnClickOutside(ref, () => updateModalState(false));
  if (shouldShow) {
    return (
      <div
        className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain"
        aria-labelledby="modal-title"
        aria-modal="true"
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity bg-primary-black bg-opacity-90"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            ref={type === "warning" ? null : ref}
            className="inline-block align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle {{ modalSize }} sm:w-full sm:pb-16 sm:pt-12 sm:px-12 outline-none"
          >
            {/* close button */}
            <div
              className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block"
              onClick={() => {
                updateModalState(false);
                if (handleAfterClose) handleAfterClose();
              }}
            >
              {type !== "warning" && (
                <button
                  type="button"
                  className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              )}
            </div>

            <div className="modal-body">
              {type === "error" ? (
                <>
                  <div className="flex items-center flex-col">
                    <p className="icon-wrapper bg-validation-error">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                          fill="currentColor"
                        />
                      </svg>
                    </p>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-md">Hata</h3>
                      <div className="mt-2">
                        <p>{msg}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-8">
                    <button
                      onClick={() => {
                        updateModalState(false);
                        if (handleAfterClose) handleAfterClose();
                      }}
                      type="button"
                      className="form-submit-btn uppercase w-24"
                    >
                      Tamam
                    </button>
                  </div>
                </>
              ) : null}
              {type === "success" ? (
                <>
                  <div className="flex flex-col items-center">
                    <p className="icon-wrapper bg-primary-green">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.1214 4L22 5.66401L8.89014 20L2 13.2188L3.7871 11.4599L8.79366 16.3872L20.1214 4Z"
                          fill="currentColor"
                        />
                      </svg>
                    </p>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-md">Başarılı</h3>
                      <div className="mt-2">
                        <p>{msg}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-8">
                    <button
                      type="button"
                      className="uppercase form-submit-btn"
                      onClick={() => {
                        updateModalState(false);
                        if (handleAfterClose) handleAfterClose();
                      }}
                    >
                      Tamam
                    </button>
                  </div>
                </>
              ) : null}
              {type === "warning" ? (
                <>
                  <div className="flex flex-col items-center">
                    <p className="icon-wrapper bg-validation-warning">
                      <svg role="img">
                        <use xlinkHref="/assets/img/necromancers.svg#warning" />
                      </svg>
                    </p>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-md">Uyarı</h3>
                      <div className="mt-2">
                        <p>{msg}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-8">
                    <button
                      type="button"
                      className="form-submit-btn uppercase w-24 revert"
                      onClick={() => {
                        if (setIsConfirmed) setIsConfirmed(false);
                        updateModalState(false);
                      }}
                    >
                      HAYIR
                    </button>

                    <button
                      type="button"
                      className="uppercase form-submit-btn mx-3"
                      onClick={() => {
                        if (setIsConfirmed) setIsConfirmed(true);
                        updateModalState(false);
                        if (handleAfterClose) handleAfterClose();
                      }}
                    >
                      Evet
                    </button>
                  </div>
                </>
              ) : null}

              {type === "info" ? (
                <>
                  <div className="flex flex-col items-center">
                    <p className="icon-wrapper bg-tertiary-blue">
                      <svg role="img">
                        <use xlinkHref="/assets/img/necromancers.svg#info" />
                      </svg>
                    </p>
                    <div className="mt-3 text-center sm:mt-5">
                      <h3 className="text-md">Bilgi</h3>
                      <div className="mt-2">
                        <p>{msg}</p>
                        <p>{secondMsg}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-8">
                    <button
                      type="button"
                      className="uppercase form-submit-btn mx-3"
                      onClick={() => {
                        if (setIsConfirmed) setIsConfirmed(true);
                        updateModalState(false);
                        if (handleAfterClose) handleAfterClose();
                      }}
                    >
                      Tamam
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default memo(Modal);
