import Parse from "parse";

const BayiNonLoggedInHomePage = Parse.Object.extend("BayiNonLoggedInHomePage");
const BayiLoggedInHomePage = Parse.Object.extend("BayiLoggedInHomePage");
const RegisterPageContent = Parse.Object.extend("RegisterPageContent");
const NewsBayi = Parse.Object.extend("NewsBayi");
const BayiCommunicationContent = Parse.Object.extend(
  "BayiCommunicationContent"
);
const BayiKaleWallContent = Parse.Object.extend("BayiKaleWallContent");
const ImageGallery = Parse.Object.extend("ImageGallery");

export const getNonLoggedInContent = async () => {
  try {
    const query = new Parse.Query(BayiNonLoggedInHomePage);
    const result = await query.first();
    return result.toJSON();
  } catch (error) {
    if (error.code === 209) {
      await Parse.User.logOut();
      window.location.href = "/";
    }
  }
};

export const getLoggedInContent = async () => {
  try {
    const query = new Parse.Query(BayiLoggedInHomePage);
    const result = await query.first();
    return result.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const getRegisterContent = async () => {
  try {
    const query = new Parse.Query(RegisterPageContent);
    const result = await query.first();
    return result.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const getNews = async () => {
  try {
    const query = new Parse.Query(NewsBayi);
    const result = await query.find();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getNewsById = async (id) => {
  try {
    const query = new Parse.Query(NewsBayi);
    return await query.get(id);
  } catch (error) {
    console.log(error);
  }
};

export const getContactContent = async () => {
  try {
    const query = new Parse.Query(BayiCommunicationContent);
    const result = await query.first();
    return result.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const getKaleWallContent = async () => {
  try {
    const query = new Parse.Query(BayiKaleWallContent);
    const result = await query.first();
    return result.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const createPost = async (postData) => {
  let files = [];
  let uploadedFiles = [];

  if (postData.files.length > 0) {
    for (let i = 0; i < postData.files.length; i++) {
      for (let y = 0; y < postData.files[i].length; y++) {
        files.push(postData.files[i][y]);
      }
    }

    if (files.length > 0) {
      await Promise.all(
        files.map(async (file) => {
          const newFile = new Parse.File(file.name, file, "image/png");
          await newFile.save();
          uploadedFiles.push(newFile);
        })
      );
    }
  }

  return await Parse.Cloud.run("CreatePost", {
    text: postData.text,
    attachments: uploadedFiles,
    commentDenied: postData.commentDenied,
  });
};

export const updatePost = async (postData) => {
  return await Parse.Cloud.run("EditPost", {
    post_id: postData.id,
    commentDenied: postData.commentDenied,
  });
};

export const deletePost = async (id) => {
  return await Parse.Cloud.run("DeletePost", { post_id: id });
};

export const createPostComment = async (commentData) => {
  let files = [];

  if (commentData.files && commentData.files.length > 0) {
    await Promise.all(
      commentData.files.map(async (file) => {
        const newFile = new Parse.File(file.name, file, "image/png");
        await newFile.save();
        files.push(newFile);
      })
    );
  }

  return await Parse.Cloud.run("CreatePostComment", {
    post_id: commentData.id,
    comment: commentData.comment,
    attachments: files,
    parentId: commentData.parentId,
  });
};

export const getPosts = async (userId) => {
  return await Parse.Cloud.run("GetFeed", { limit: 20, skip: 0, userId });
};

export const getPostComments = async (id) => {
  return await Parse.Cloud.run("GetPostComments", {
    post_id: id,
    limit: 20,
    skip: 0,
  });
};

export const likePost = async (id) => {
  return await Parse.Cloud.run("LikePost", { post_id: id });
};

export const unlikePost = async (id) => {
  console.log(id);
  return await Parse.Cloud.run("UnlikePost", { post_id: id });
};

export const getPostLikers = async (id) => {
  return await Parse.Cloud.run("GetPostLikers", { post_id: id });
};
export const getEducationDocs = async (sort, limit = 100) => {
  try {
    const query = new Parse.Query("BayiEducationDocs");
    if (sort === "ascending") {
      query.ascending("createdAt");
      query.limit(limit);
    } else {
      query.descending("createdAt");
      query.limit(limit);
    }

    const result = await query.find();
    return result;
  } catch (error) {
    console.log(error);
  }
};
export const getOtherDocs = async (sort, limit = 100) => {
  try {
    const query = new Parse.Query("BayiDocs");
    if (sort === "ascending") {
      query.ascending("createdAt");
      query.limit(limit);
    } else {
      query.descending("createdAt");
      query.limit(limit);
    }

    const result = await query.find();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getLatestOtherDoc = async () => {
  const query = new Parse.Query("BayiDocs");
  query.descending("createdAt");
  try {
    return await query.first();
  } catch (error) {
    console.log(error);
  }
};

export const getKalekimCatalogs = async () => {
  try {
    const query = new Parse.Query("KalekimCatalog");
    const result = await query.find();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getKaleseramikCatalogs = async () => {
  try {
    const query = new Parse.Query("KaleseramikCatalog");
    const result = await query.find();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getGalleryFromKale = async () => {
  try {
    const query = new Parse.Query("BayiGalleryFromKale");
    query.descending("createdAt");
    const result = await query.find();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getGalleryById = async (id) => {
  try {
    const query = new Parse.Query("BayiGalleryFromKale");
    const gallery = await query.get(id);
    return gallery.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const getLatestAlbumFromKaleGallery = async () => {
  try {
    const query = new Parse.Query("BayiGalleryFromKale");
    query.descending("createdAt");
    const result = await query.first();
    return result.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const createUserGallery = async (galleryData) => {
  let files = [];
  let uploadedFiles = [];

  if (!galleryData.user) throw new Error("Unauthorized");

  if (galleryData.files.length > 0) {
    for (let i = 0; i < galleryData.files.length; i++) {
      for (let y = 0; y < galleryData.files[i].length; y++) {
        files.push(galleryData.files[i][y]);
      }
    }
  }

  if (files.length > 0) {
    await Promise.all(
      files.map(async (file) => {
        const newFile = new Parse.File(file.name, file, "image/png");
        await newFile.save();
        uploadedFiles.push(newFile);
      })
    );
  }

  try {
    const imageGallery = new ImageGallery();
    return await imageGallery.save({
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: galleryData.user.objectId,
      },
      images: uploadedFiles,
      title: galleryData.title,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateUserGallery = async (galleryData) => {
  let files = [];
  let uploadedFiles = [];

  if (!galleryData.user) throw new Error("Unauthorized");

  if (galleryData.files.length > 0) {
    for (let i = 0; i < galleryData.files.length; i++) {
      for (let y = 0; y < galleryData.files[i].length; y++) {
        files.push(galleryData.files[i][y]);
      }
    }
  }

  if (files.length > 0) {
    await Promise.all(
      files.map(async (file) => {
        const newFile = new Parse.File(file.name, file, "image/png");
        await newFile.save();
        uploadedFiles.push(newFile);
      })
    );
  }

  try {
    const query = new Parse.Query(ImageGallery);
    const gallery = await query.get(galleryData.id);
    // add over existing images
    const images = gallery.get("images");
    images.unshift(...uploadedFiles);
    const updatedGallery = await gallery.save({
      user: {
        __type: "Pointer",
        className: "_User",
        objectId: galleryData.user.objectId,
      },
      images: images,
    });
    return updatedGallery.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const removeUserGalleryById = async (id) => {
  const query = new Parse.Query(ImageGallery);
  const gallery = await query.get(id);
  return await gallery.destroy();
};

export const getUserGalleries = async (userId) => {
  const query = new Parse.Query(ImageGallery);

  query.descending("createdAt");
  query.equalTo("user", {
    __type: "Pointer",
    className: "_User",
    objectId: userId,
  });

  return await query.find();
};

export const getUserGalleryById = async (id) => {
  try {
    const query = new Parse.Query(ImageGallery);
    const gallery = await query.get(id);
    return gallery.toJSON();
  } catch (error) {
    console.log(error);
  }
};

export const removeUserGalleryImages = async (id, images) => {
  const query = new Parse.Query(ImageGallery);
  const gallery = await query.get(id);
  return await gallery.save({
    images,
  });
};

export const updateUserGalleryTitle = async (id, title) => {
  const query = new Parse.Query(ImageGallery);
  const gallery = await query.get(id);
  return await gallery.save({
    title,
  });
};

export const getMyFriends = async () => {
  return Parse.Cloud.run("GetMyFriends")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const getMyPendingFriends = async () => {
  return Parse.Cloud.run("GetMyPendingFriendRequests")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const addFriend = async (friend_id) => {
  return Parse.Cloud.run("AddFriendRequest", { friend_id: friend_id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const responseToFriendRequest = async (friend_id, action) => {
  return Parse.Cloud.run("ResponseToFriendRequest", {
    friend_id: friend_id,
    action: action,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const removeFriend = async (friend_id) => {
  return Parse.Cloud.run("RemoveFriend", { friend_id: friend_id });
};

export const getProfileInfo = async (user_id) => {
  return Parse.Cloud.run("GetProfileInfo", { user_id: user_id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const searchUser = async (
  nameField,
  regionField,
  cityField,
  class_c,
  limit,
  skip
) => {
  return Parse.Cloud.run("UserProfileSearch", {
    nameq: nameField,
    uzmanlik: class_c,
    city: cityField,
    county: regionField,
    limit: limit,
    skip: skip,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getReplies = async (id) => {
  try {
    const query = new Parse.Query("SocialComment");
    query.equalTo("parentId", id);
    query.includeAll();
    return await query.find();
  } catch (error) {
    console.log(error);
  }
};

export const getSurveys = async () => {
  return await Parse.Cloud.run("GetSurveys");
};

export const getSurveyQuestions = async (id) => {
  return await Parse.Cloud.run("GetSurveyQuestions", { survey_id: id });
};

export const saveSurveyAnswer = async (answerData) => {
  return await Parse.Cloud.run("SaveSurveyAnswer", {
    survey_id: answerData.surveyId,
    question_id: answerData.questionId,
    freetext_value: answerData.text,
    choice_id: answerData.choiceId,
    choice_ids: answerData.choiceIds,
  });
};

export const getMySurveyAnswers = async (surveyId) => {
  return await Parse.Cloud.run("GetMySurveyAnswers", { survey_id: surveyId });
};

export const getSurveyAnswersByUser = async (surveyId) => {
  return await Parse.Cloud.run("GetSurveyAnswersByUser", {
    survey_id: surveyId,
  });
};

export const completeSurvey = async (surveyId) => {
  return await Parse.Cloud.run("CompleteSurvey", { survey_id: surveyId });
};

export const getAllAnswersByUser = async (surveyId, userId) => {
  return await Parse.Cloud.run("GetAllAnswersByUser", { surveyId, userId });
};

export const forgotPassword = async (phoneNumber) => {
  return await Parse.Cloud.run("ForgotMyPassword", { username: phoneNumber });
};

export const getConversations = async () => {
  return await Parse.Cloud.run("GetConversations");
};

export const getTotalUnreadConversations = async () => {
  return await Parse.Cloud.run("GetTotalUnreadConversations");
};

export const searchFriends = async (name) => {
  return await Parse.Cloud.run("SearchFriends", { nameq: name });
};

export const sendMessage = async (friendId, message) => {
  return await Parse.Cloud.run("SendMessage", { message, friend_id: friendId });
};

export const getMessages = async (friendId) => {
  return await Parse.Cloud.run("GetMessages", {
    friend_id: friendId,
    limit: 1000,
  });
};
export async function getLiveStreams() {
  let LiveStream = Parse.Object.extend("BayiLiveStream");
  let liveStreamQuery = new Parse.Query(LiveStream);
  liveStreamQuery.equalTo("is_active", true);
  return await liveStreamQuery.first();
}

export const getFriendRequest = async (id) => {
  let FriendRequest = Parse.Object.extend("SocialFriend");
  let friendRequestQuery = new Parse.Query(FriendRequest);
  friendRequestQuery.equalTo("objectId", id);
  const result = await friendRequestQuery.first();
  return result.toJSON();
};

export const getFriendReqFriendId = async (id) => {
  let FriendRequest = Parse.Object.extend("SocialFriend");
  let friendRequestQuery = new Parse.Query(FriendRequest);
  friendRequestQuery.equalTo("objectId", id);
  friendRequestQuery.includeAll();
  const result = await friendRequestQuery.first();
  // convert to json
  return result.toJSON();
};

export const liveStreamLeftRecord = async (userId, streamId) => {
  const Stream = Parse.Object.extend("BayiLiveStreamRecord");
  let query = new Parse.Query(Stream);
  query.equalTo("user", {
    __type: "Pointer",
    className: "_User",
    objectId: userId,
  });
  query.equalTo("stream", {
    __type: "Pointer",
    className: "BayiLiveStream",
    objectId: streamId,
  });
  // query.set("exitedAt", new Date());
  const stream = await query.first();
  if (stream) {
    stream.set("exitedAt", new Date());
    return await stream.save();
  }
};

export const updateliveStreamWatchTime = async (userId, streamId) => {
  //this side calling from live stream page with set interval
  const query = new Parse.Query("BayiLiveStreamRecord");
  query.equalTo("user", {
    __type: "Pointer",
    className: "_User",
    objectId: userId,
  });
  query.equalTo("stream", {
    __type: "Pointer",
    className: "BayiLiveStream",
    objectId: streamId,
  });
  const stream = await query.first();
  if (!stream) return;
  stream.increment("watchTime");
  await stream.save({});
};

export const getExams = async () => {
  return await Parse.Cloud.run("GetExams");
};

export const getExamQuestionCount = (id) => {
  return Parse.Cloud.run("GetExamQuestionCount", { exam_id: id });
};

export const getExamQuestions = (id) => {
  return Parse.Cloud.run("GetExamQuestions", { exam_id: id });
};

export const getQuestionChoices = (id) => {
  return Parse.Cloud.run("GetQuestionChoices", { question_id: id });
};

export const saveExamAnswer = (answer) => {
  return Parse.Cloud.run("SaveExamAnswer", {
    question_id: answer.questionId,
    choice_id: answer.choiceId,
  });
};

export const startExam = (userId, examId) => {
  return Parse.Cloud.run("StartExam", { user_id: userId, exam_id: examId });
};

export const completeExam = (examId) => {
  return Parse.Cloud.run("CompleteExam", { exam_id: examId });
};

export const getExamAnswersByUser = (examId) => {
  return Parse.Cloud.run("GetMyExamResults", { exam_id: examId });
};

export const getMyExamAnswers = (examId) => {
  return Parse.Cloud.run("GetMyExamAnswers", { exam_id: examId });
};

export const getMyExamHistory = (examId) => {
  return Parse.Cloud.run("GetMyExamHistory", { exam_id: examId });
};

export const getActivityCategories = async () => {
  let categories = [];
  const Categories = Parse.Object.extend("ActivityCategory");
  let query = new Parse.Query(Categories);
  const result = await query.find();
  categories = result.map((category) => category.toJSON());
  return categories;
};

export const getActivities = async (user) => {
  const Activity = Parse.Object.extend("Activity");
  const becomedUser = await (
    await Parse.User.become(user.sessionToken)
  ).toJSON();
  const queryAndParts = [];
  let activities = [];

  if (becomedUser?.Gender__c) {
    queryAndParts.push(
      Parse.Query.or(
        new Parse.Query(Activity).equalTo("gender", undefined),
        new Parse.Query(Activity).equalTo("gender", null),
        new Parse.Query(Activity).equalTo("gender", becomedUser.Gender__c)
      )
    );
  }

  if (becomedUser?.TypeKS__c || becomedUser?.TypeKK__c) {
    if (
      becomedUser?.TypeKS__c === "true" &&
      becomedUser?.TypeKK__c === "false"
    ) {
      queryAndParts.push(new Parse.Query(Activity).equalTo("eventFirm", "ks"));
    } else if (
      becomedUser?.TypeKS__c === "false" &&
      becomedUser?.TypeKK__c === "true"
    ) {
      queryAndParts.push(new Parse.Query(Activity).equalTo("eventFirm", "kk"));
    } else if (
      becomedUser?.TypeKS__c === "true" &&
      becomedUser?.TypeKK__c === "true"
    ) {
      queryAndParts.push(
        Parse.Query.or(
          new Parse.Query(Activity).equalTo("eventFirm", "ks"),
          new Parse.Query(Activity).equalTo("eventFirm", "kk")
        )
      );
    } else {
      queryAndParts.push(
        Parse.Query.or(
          new Parse.Query(Activity).equalTo("eventFirm", null),
          new Parse.Query(Activity).equalTo("eventFirm", null)
        )
      );
    }
  }

  if (becomedUser?.Billing_City__c) {
    const cityQuery = new Parse.Query("City");
    cityQuery.equalTo("ID", becomedUser.Billing_City__c);
    const city = await cityQuery.first();

    queryAndParts.push(
      Parse.Query.or(
        new Parse.Query(Activity).equalTo("city", undefined),
        new Parse.Query(Activity).equalTo("city", null),
        new Parse.Query(Activity).equalTo("city", {
          __type: "Pointer",
          className: "City",
          objectId: city.id,
        })
      )
    );
  }

  if (becomedUser?.Billing_Region__c) {
    const regionQuery = new Parse.Query("Region");
    regionQuery.equalTo("ID", becomedUser.Billing_Region__c);
    const region = await regionQuery.first();

    queryAndParts.push(
      Parse.Query.or(
        new Parse.Query(Activity).equalTo("region", undefined),
        new Parse.Query(Activity).equalTo("region", null),
        new Parse.Query(Activity).equalTo("region", {
          __type: "Pointer",
          className: "Region",
          objectId: region.id,
        })
      )
    );
  }

  if (queryAndParts.length === 0) {
    return activities;
  } else {
    const compoundActivityQuery = Parse.Query.and(...queryAndParts);
    compoundActivityQuery.include("city");
    compoundActivityQuery.include("region");
    // compoundActivityQuery.ascending('activityDate')

    activities = compoundActivityQuery.map((activity) => activity.toJSON());
  }

  return activities;
};

export const getSingleActivity = async (id) => {
  let activity = {};
  const Activities = Parse.Object.extend("Activity");
  let query = new Parse.Query(Activities);
  query.equalTo("objectId", id);
  query.includeAll();
  const result = await query.first();
  activity = result.toJSON();
  return activity;
};

export const getExam = (examId) => {
  return Parse.Cloud.run("GetExam", { exam_id: examId });
};

export const getLiveStreamBanner = async () => {
  const Stream = Parse.Object.extend("BayiLiveStream");
  let query = new Parse.Query(Stream);
  query.equalTo("is_active", true);
  return await query.first();
};

export const attendActivity = async (activityId) => {
  return Parse.Cloud.run("JoinActivity", { activityId });
};

export const isUserAttendActivity = async (activityId, userId) => {
  const ActivityRecord = Parse.Object.extend("ActivityRecord");
  let query = new Parse.Query(ActivityRecord);
  query.equalTo("activity", {
    __type: "Pointer",
    className: "Activity",
    objectId: activityId,
  });
  query.equalTo("user", {
    __type: "Pointer",
    className: "_User",
    objectId: userId,
  });
  const result = await query.first();
  if (result) {
    return true;
  } else {
    return false;
  }
};

export const leaveActivity = async (activityId) => {
  return Parse.Cloud.run("LeaveActivity", { activityId });
};

export const getPointInfo = async () => {
  return Parse.Cloud.run("GetPointInfo");
};

export const getUserPointsRanking = async () => {
  return Parse.Cloud.run("GetUserPointsRanking");
};

export const getSingleCity = async (id) => {
  const City = Parse.Object.extend("City");
  let query = new Parse.Query(City);
  query.equalTo("objectId", id);
  query.includeAll();
  const result = await query.first();
  return result ? result.toJSON() : {};
};

export const getSingleRegion = async (id) => {
  const Region = Parse.Object.extend("Region");
  let query = new Parse.Query(Region);
  query.equalTo("objectId", id);
  query.includeAll();
  const result = await query.first();
  return result ? result.toJSON() : {};
};

export const getGifts = async () => {
  return await Parse.Cloud.run("GetGifts");
};

export const getNegativePoints = async () => {
  return await Parse.Cloud.run("GetNegativePointRecords");
};

export const getExpiredPoints = async () => {
  return await Parse.Cloud.run("GetExpiredPoints");
};

export const getUserPointRecord = async () => {
  return await Parse.Cloud.run("GetUserPointRecords");
};

export const getLmsLoginAccessToken = async () => {
  return await Parse.Cloud.run("lmsLogin");
};
