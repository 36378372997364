import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { Helmet } from "react-helmet";

function ConsumptionCalc() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content" className="lg:p-0">
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1
                  data-testid="page-title"
                  className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0"
                >
                  Tüketim Hesaplama
                </h1>
              </div>
            </main>
          </div>
          <div className="px-4 mx-auto my-12 max-w-7xl lg:my-16 lg:px-1">
            <iframe
              data-testid="iframe"
              title="ConsumptionCalc"
              className="min-h-full"
              src="https://kalekim.com/iframe/tuketim-hesapla"
              style={{ overflow: "hidden", height: "100%", minHeight: "60vh" }}
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default ConsumptionCalc;
