export const isEmpty = (data) => {
  let result = false;
  !data || data.trim() === "" ? (result = true) : (result = false);
  return result;
};

export const isEmail = (email) => {
  const emailRegExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (!emailRegExp.test(String(email))) {
    return false;
  } else {
    return true;
  }
};

const removeExceptNumbersRegex = /[\s()_]/g;

export const isLengthEqual = (data, length) => {
  const replacedData = data.replace(removeExceptNumbersRegex,"");
  const usernameWithoutZero = replacedData.substring(1);
  return usernameWithoutZero.length === length
};
