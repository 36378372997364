import { memo } from "react";

function Pagination({ itemsPerPage, totalItems, setCurrentPage, currentPage }) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center gap-3 mt-12">
      {pageNumbers.length > 0 &&
        pageNumbers.map((number, index) => (
          <button
            type="button"
            key={index}
            onClick={() => setCurrentPage(number)}
            className={
              currentPage && currentPage === number
                ? "flex items-center justify-center px-4 py-2 text-white border-2 text-body-l bg-primary-black border-secondary-titles"
                : "flex items-center justify-center px-4 py-2 border-2 text-body-l text-primary-black border-secondary-titles"
            }
          >
            {number}
          </button>
        ))}
    </div>
  );
}
export default memo(Pagination);
