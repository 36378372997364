import React from "react";
import { useNavigate } from "react-router-dom";

const KaleseramikTab = ({ catalogs }) => {
  console.log(catalogs);
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-1 gap-10 px-4 lg:grid-cols-3 lg:px-0">
      {catalogs?.map((doc) => {
        const docToRender = doc.toJSON();
        return (
          <div key={docToRender?.objectId} className="hover:text-red-400 ">
            {docToRender?.file && !docToRender?.url && (
              <a
                href={`${docToRender?.file?.url}`}
                className="flex items-center"
              >
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${docToRender?.thumbnail?.url}`}
                  alt={docToRender?.thumbnail?.name}
                />
                <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
              </a>
            )}
            {docToRender?.file && docToRender?.url && (
              <a
                href={`${docToRender?.url}`}
                className="flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${docToRender?.thumbnail?.url}`}
                  alt={docToRender?.thumbnail?.name}
                />
                <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
              </a>
            )}
            {!docToRender?.file &&
              docToRender?.url &&
              (docToRender?.url.includes("iframe") ? (
                <button
                  className="flex items-center"
                  onClick={() =>
                    navigate(`/offsite-source`, {
                      state: { url: docToRender?.url },
                    })
                  }
                >
                  <img
                    style={{ width: "80px", height: "80px" }}
                    src={`${docToRender?.thumbnail?.url}`}
                    alt={docToRender?.thumbnail?.name}
                  />
                  <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
                </button>
              ) : (
                <a
                  href={`${docToRender?.url}`}
                  className="flex items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "80px", height: "80px" }}
                    src={`${docToRender?.thumbnail?.url}`}
                    alt={docToRender?.thumbnail?.name}
                  />
                  <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
                </a>
              ))}
          </div>
        );
      })}
      {/* <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/dummy-product-2.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Seramik Karolar</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-2.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Havuz Seramikleri</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-3.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Banyo Mobilyaları</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-4.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Banyo Aksesuarları</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-5.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Armatürler</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-6.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Rezervuar</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-7.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Duş Sistemleri</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-8.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Duş ve Küvet Tekneleri</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-9.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Duş ve Küvet Kabinleri</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-10.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Klozet</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-11.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Lavabo</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/seramik-12.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Mutfak</p>
      </Link> */}
    </div>
  );
};

export default KaleseramikTab;
