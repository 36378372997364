import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

export default function MainSlider({ sliderItems, liveStreamBannerUrl }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="py-11 lg:py-16 pt- sm:pb-24" id="content">
      <main className="px-1 mx-auto max-w-7xl">
        {liveStreamBannerUrl && (
          <Link to="/live-stream" className="btn bg-primary-red white max-w-[12rem] mb-3">
            Canlı Yayını İzle
          </Link>
        )}
        <Swiper
          className="relative flex flex-row overflow-hidden"
          modules={[Navigation]}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          slidesPerView={1}
        >
          {!liveStreamBannerUrl && (
            <div className="absolute inset-y-0 left-0 z-10 items-center hidden lg:flex top-56">
              <button
                // onClick={() => swiper.slideNext()}
                // onClick={() => setSlideIndex(--slideIndex)}
                //   @click="homeSlider.slidePrev()"
                className="slider-arrow"
                ref={prevRef}
              >
                <span className="sr-only">Prev</span>
                <svg role="img" className="w-6 h-6 text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#arrow-left" />
                </svg>
              </button>
            </div>
          )}

          <div className="w-full swiper-container swiper-initialized swiper-horizontal swiper-pointer-events">
            <div
              className="swiper-wrapper"
              id="swiper-wrapper-f3105e17a7b54fbbb"
              aria-live="polite"
            >
              {sliderItems?.map((item, index) => {
                if (index === 0 && liveStreamBannerUrl) {
                  return (
                    <SwiperSlide
                      style={{ width: "1272px" }}
                      role="group"
                      aria-label="1/2"
                      key={index}
                    >
                      <div className="flex flex-col">
                        <div className="flex-shrink-0">
                          {/* <div className="grid items-center max-w-3xl grid-cols-1 gap-6 px-1 mx-auto lg:max-w-7xl lg:grid-cols-4 pb-11 lg:pb-20">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                              <h2 className="text-xl text-center lg:text-xxl font-body lg:text-left">
                                {item.main_slider_title}
                              </h2>
                            </div>
                            <div className="flex flex-col items-end lg:col-span-2">
                              <div className="flex flex-col items-center lg:items-start">
                                <p className="mb-8 text-base text-center lg:text-left lg:max-w-md text-secondary-titles">
                                  {item.main_slider_text}
                                </p>
                                <a
                                  href={item.main_slider_button_url}
                                  className="w-full btn mr-auto lg:mr-0 bg-primary-red white max-w-[12rem]"
                                >
                                  {item.main_slider_button_text}
                                </a>
                              </div>
                            </div>
                          </div> */}
                          <Link to="/live-stream">
                            <img src={liveStreamBannerUrl} alt="" />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                }
                return (
                  <SwiperSlide
                    style={{ width: "1272px" }}
                    role="group"
                    aria-label="1/2"
                    key={index}
                  >
                    <div className="flex flex-col">
                      <div className="flex-shrink-0">
                        <div className="grid items-center max-w-3xl grid-cols-1 gap-6 px-1 mx-auto lg:max-w-7xl lg:grid-cols-4 pb-11 lg:pb-20">
                          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            <h2 className="text-xl text-center lg:text-xxl font-body lg:text-left">
                              {item.main_slider_title}
                            </h2>
                          </div>
                          <div className="flex flex-col items-end lg:col-span-2">
                            <div className="flex flex-col items-center lg:items-start">
                              <p className="mb-8 text-base text-center lg:text-left lg:max-w-md text-secondary-titles">
                                {item.main_slider_text}
                              </p>
                              <a
                                href={item.main_slider_button_url}
                                className="w-full btn mr-auto lg:mr-0 bg-primary-red white max-w-[12rem]"
                              >
                                {item.main_slider_button_text}
                              </a>
                            </div>
                          </div>
                        </div>
                        <img src={item.main_slider_image.url} alt="" />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
            <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            ></span>
          </div>
          <div className="swiper-pagination lg:hidden swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            <span
              className="swiper-pagination-bullet swiper-pagination-bullet-active"
              tabIndex="0"
              role="button"
              aria-label="Go to slide 1"
              aria-current="true"
            ></span>
            <span
              className="swiper-pagination-bullet"
              tabIndex="0"
              role="button"
              aria-label="Go to slide 2"
            ></span>
          </div>
          {!liveStreamBannerUrl && (
            <div className="absolute inset-y-0 right-0 z-10 items-center hidden top-56 lg:flex">
              <button
                //   @click="homeSlider.slideNext()"
                //   onClick={() => swiper.slideNext()}
                className="slider-arrow"
                ref={nextRef}
              >
                <span className="sr-only">Next</span>
                <svg role="img" className="w-6 h-6 text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#arrow-right" />
                </svg>
              </button>
            </div>
          )}
        </Swiper>
      </main>
    </div>
  );
}
