import React, { useState } from "react";
import InputMask from "react-input-mask";
import { login, confirmContract } from "../../../services/authService";
import Modal from "../../../components/shared/Modal";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingModal from "../../../components/shared/LoadingModal";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../../store/app/appSlice";
import Swal from "sweetalert2";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

export default function Login(props) {
  const [user, setUser] = useState({
    username: "",
    password: "",
    rememberMe: false,
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const key = "6LeA3nYlAAAAAG-Ld-qF6SpeiMb8gg-DEhyOs3DF";
  let navigate = useNavigate();
  let location = useLocation();
  // let state = location.state;
  // let from = state ? state.from.pathname : "/home";
  const dispatch = useDispatch();
  const {
    bannerImage,
    bannerBackground,
    bannerLink,
    setShowForgotPasswordModal,
  } = props;

  const handleForgotPasswordModal = () => {
    setShowForgotPasswordModal((prev) => !prev);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (verified) {
      try {
        setErrorMsg(null);
        setLoading(true);
        const res = await login({
          username: user.username.replace(/\D/g, "").substring(1),
          password: user.password,
          rememberMe: user.rememberMe,
        });

        if (res.contractPermission === false) {
          setLoading(false);
          if (!loading) {
            Swal.fire({
              title:
                "Kullanıcılarımızın platformu kullanabilmesi için yenilenen kullanım sözleşmesini kabul etmesi gerekmektedir.",
              icon: "warning",
              html:
                "<div id='pdfViewer' style='display:flex; flex-direction:column; align-items:center'></div>" +
                "<p><input checked type='checkbox' style='margin-right:5px' id='contractPermission'/>" +
                "Kullanım sözleşmesi'ni okudum, anladım ve kabul ediyorum.</p>",
              confirmButtonText: "Onayla",
              showCloseButton: true,
              width: "50%",

              preConfirm: () => {
                var contractPermission = Swal.getPopup().querySelector(
                  "#contractPermission"
                ).checked;

                return {
                  contractPermission: contractPermission,
                };
              },
            }).then((result) => {
              if (result.value.contractPermission) {
                confirmContract(res.lastContractId, res.sessionToken).then(
                  (response) => {
                    if (response.message === "user contract sign success") {
                      Swal.fire({
                        title: "Başarılı!",
                        text: "Sözleşme başarıyla kaydedildi. Lütfen tekrar giriş yapınız.",
                        confirmButtonText: `Tamam`,
                        icon: "success",
                        allowOutsideClick: false,
                      });
                    }
                  }
                );
              } else {
                Swal.fire({
                  title: "Dikkat!",
                  text: "Kullanım sözleşmesini kabul etmeden giriş yapamayacaksınız.",
                  confirmButtonText: `Tamam`,
                  icon: "error",
                  allowOutsideClick: false,
                });
              }
            });

            window.pdfjsLib.GlobalWorkerOptions.workerSrc =
              "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

            var pdfUrl = res.contractFile;
            var pdfContainer = document.getElementById("pdfViewer");

            window.pdfjsLib
              .getDocument(pdfUrl)
              .promise.then(function (pdfDoc) {
                for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
                  (function (pageNum) {
                    pdfDoc.getPage(pageNum).then(function (page) {
                      var canvas = document.createElement("canvas");
                      var pageContainer = document.createElement("div");

                      pageContainer.appendChild(canvas);
                      pdfContainer.appendChild(pageContainer);
                      var viewport = page.getViewport({ scale: 1.3 });
                      canvas.height = viewport.height;
                      canvas.width = viewport.width;

                      var renderContext = {
                        canvasContext: canvas.getContext("2d"),
                        viewport: viewport,
                      };
                      page.render(renderContext);
                    });
                  })(pageNum);
                }
              })
              .catch(function (error) {
                console.error("PDF yüklenirken bir hata oluştu:", error);
              });
          }
        }

        dispatch(SET_USER(res.toJSON()));

        if (!res.error) {
          const origin = location.state?.from?.pathname || "/home";
          navigate(origin);
          // navigate(from, { replace: true });
        }
      } catch (e) {
        setErrorMsg(e.message);
        setShowModal(true);
        setLoading(false);
      }
    } else {
      setErrorMsg("Lütfen robot olmadığınızı doğrulayın.");
      setShowModal(true);
    }
  };

  return (
    <>
      {loading && <LoadingModal />}
      <div
        className="container flex flex-col items-center justify-center max-w-full mx-auto"
        style={{ backgroundColor: bannerBackground }}
      >
        {/* <div className="hidden md:block">
          <a href={bannerLink}>
            <img
              src={bannerImage?.url}
              // data-srcset="
              // 	/assets/img/welcome.jpg 1x,
              // 	 2x
              // "
              alt="productname"
              className="block"
            />
          </a>
        </div> */}
        <div className="block w-full ">
          <a href={bannerLink}>
            <img
              src={bannerImage?.url}
              // data-srcset="
              // 	/assets/img/welcome.jpg 1x,
              // 	 2x
              // "
              alt="productname"
              className="block w-full"
            />
          </a>
        </div>
        <div className="flex flex-col items-start justify-center flex-1 w-full px-4 py-8 mx-auto lg:px-1 lg:py-20 max-w-7xl lg:flex-row">
          <div className="flex w-full lg:space-y-4 lg:flex-1">
            <h2 className="w-full text-lg text-center lg:w-auto lg:text-xxl lg:text-left">
              Giriş Yap
            </h2>
          </div>
          <div className="flex flex-1 w-full px-4 pt-8 lg:w-auto lg:py-8 lg:p-0">
            <form
              data-testid="form"
              onSubmit={handleSubmit}
              className="flex flex-col flex-1 space-y-8"
            >
              <div className="flex flex-col items-center flex-1 gap-4 lg:space-x-6 lg:flex-row">
                <div className="flex flex-1 w-full form-input-wrapper">
                  <InputMask
                    id="kale-phone"
                    className="p-4 bg-transparent form-input focus:outline-none phone"
                    placeholder=" "
                    mask="0 (999) 999 99 99"
                    value={user.username}
                    onChange={(e) =>
                      setUser({ ...user, username: e.target.value })
                    }
                    maskplaceholder="_"
                  />
                  <label
                    htmlFor="kale-phone"
                    className="bg-transparent form-label"
                  >
                    Cep Telefon Numaranız
                  </label>
                </div>
                <div className="flex flex-1 w-full form-input-wrapper">
                  <input
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    type={passwordVisible ? "text" : "password"}
                    name="kale-password"
                    id="kale-password"
                    placeholder=" "
                    autoComplete="chrome-off"
                    className="p-4 bg-transparent form-input focus:outline-none"
                  />
                  <label
                    htmlFor="kale-password"
                    className="bg-transparent form-label"
                  >
                    Şifreniz
                  </label>
                  <button
                    type="button"
                    className="absolute right-4 top-1/2 transform -translate-y-1/2"
                    onClick={togglePasswordVisibility}
                  >
                    {passwordVisible ? (
                      <EyeOffIcon className="w-6 h-6 text-gray-500" />
                    ) : (
                      <EyeIcon className="w-6 h-6 text-gray-500" />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between flex-1 lg:flex-row">
                <div className="flex flex-col flex-1 space-y-4 text-base">
                  <div className="inline-flex items-center">
                    <input
                      value={user.rememberMe}
                      onChange={() =>
                        setUser((prevState) => ({
                          ...user,
                          rememberMe: !prevState.rememberMe,
                        }))
                      }
                      type="checkbox"
                      className="mr-2 form-input"
                      id="remember"
                      name="remember"
                    />
                    <label htmlFor="remember" className="cursor-pointer">
                      Beni Hatırla
                    </label>
                  </div>
                  <button
                    type="button"
                    className="inline-flex items-center"
                    onClick={handleForgotPasswordModal}
                  >
                    <span className="sr-only">Profile</span>
                    <svg
                      role="img"
                      className="w-4 h-4 mr-2"
                      width={24}
                      height={24}
                      fill="none"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#lock" />
                    </svg>{" "}
                    Şifremi Unuttum{" "}
                  </button>
                </div>
                <div></div>
                <div className="flex justify-end flex-1 mt-6 lg:mt-0">
                  <button
                    type="submit"
                    className="w-48 uppercase bg-primary-red form-submit-btn"
                  >
                    GİRİŞ YAP
                  </button>
                </div>
              </div>
              <div className="flex justify-center lg:justify-end">
                <ReCAPTCHA
                  sitekey={key}
                  hl="tr"
                  onChange={(token) => {
                    setVerified(true);
                  }}
                  onExpired={() => {
                    setVerified(false);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        shouldShow={showModal}
        updateModalState={(state) => setShowModal(state)}
        type="error"
        msg={`${errorMsg}`}
      />
    </>
  );
}
