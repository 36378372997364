import React, { useEffect, useState } from "react";
import LoadingModal from "../../components/shared/LoadingModal";
import AuthLayout from "../../layouts/AuthLayout";
import { getNonLoggedInContent } from "../../services/contentService";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await getNonLoggedInContent();
        setContent(result);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div id="content">
          <div className="px-4 py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1
                  className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0"
                  data-testid="about-us-header"
                >
                  Kale Hakkında
                </h1>
                <h6 className="col-span-5">{content?.about_kale_subtitle}</h6>
              </div>
            </main>
          </div>
          <div className="px-4 mx-auto my-12 max-w-7xl lg:my-16 xl:px-1">
            <div className="flex flex-col justify-between xl:flex-row">
              <div className="flex flex-1 lg:px-16">
                <img
                  src={content?.about_kale_image?.url}
                  alt=""
                  className="w-full"
                  data-testid="about-us-image"
                />
              </div>
              <div className="flex flex-col flex-1 pt-5">
                <h2 className="text-xl">{content?.about_kale_title}</h2>
                <p
                  className="mt-8 text-body-s text-justify text-secondary-primary"
                  data-testid="about-us-text"
                >
                  {content?.about_kale_text}
                </p>
              </div>
            </div>
          </div>
          <div className="py-16 mb-2 bg-secondary-secondary-bg">
            <div className="px-1 mx-auto max-w-7xl">
              <div className="flex flex-col px-4 lg:px-0">
                <h2 className="text-lg text-center lg:text-xl">Değerlerimiz</h2>
                <div className="flex flex-col justify-between gap-16 mt-20 lg:flex-row lg:gap-0">
                  <div className="flex flex-col items-center gap-6">
                    <span className="flex items-center justify-center w-32 h-32 rounded-full">
                      <img
                        src="/assets/img/about-us/trust.jpg"
                        alt="we create trust"
                      />
                    </span>
                    <h6 className="text-xs font-sm">Güven Yaratırız</h6>
                  </div>
                  <div className="flex flex-col items-center gap-6">
                    <span className="flex items-center justify-center w-32 h-32 rounded-full">
                      <img
                        alt="we are committed"
                        src="/assets/img/about-us/committed.jpg"
                      />
                    </span>
                    <h6 className="text-xs font-sm">
                      Kararlı ve Azimli Davranırız
                    </h6>
                  </div>
                  <div className="flex flex-col items-center gap-6">
                    <span className="flex items-center justify-center w-32 h-32 rounded-full">
                      <img
                        src="/assets/img/about-us/responsibility.jpg"
                        alt="we take responsibility"
                      />
                    </span>
                    <h6 className="text-xs font-sm">Sorumluluk Alırız</h6>
                  </div>
                  <div className="flex flex-col items-center gap-6">
                    <span className="flex items-center justify-center w-32 h-32 rounded-full">
                      <img
                        src="/assets/img/about-us/partnership.jpg"
                        alt="we act in partnership"
                      />
                    </span>
                    <h6 className="text-xs font-sm">
                      İş Birliği İçinde <br /> Hareket Ederiz
                    </h6>
                  </div>
                  <div className="flex flex-col items-center gap-6">
                    <span className="flex items-center justify-center w-32 h-32 rounded-full">
                      <img src="/assets/img/about-us/lead.jpg" alt="we lead" />
                    </span>
                    <h6 className="text-xs font-sm">Öncülük Ederiz</h6>
                  </div>
                  <div className="flex flex-col items-center gap-6">
                    <span className="flex items-center justify-center w-32 h-32 rounded-full bg-primary-yellow">
                      <img
                        src="/assets/img/about-us/entrepreneurship.jpg"
                        alt="we support entrepreneurship"
                      />
                    </span>
                    <h6 className="text-xs font-sm">
                      Girişimciliği Destekleriz
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default AboutUsPage;
