import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useParams } from "react-router-dom";
import { confirmEmailVerification } from "../../services/authService";
import { Helmet } from "react-helmet";

export default function EmailVerification() {
  const { token } = useParams();
  useEffect(() => {
    (async () => {
      try {
        await confirmEmailVerification(token);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [token]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <DefaultLayout>
        <div className="text-center my-5" style={{ minHeight: "79vh" }}>
          <div className="py-5">
            <svg role="img" className="mx-auto" width={75} height={75}>
              <use xlinkHref="/assets/img/necromancers.svg#check" />
            </svg>
            <div className="my-10">
              Email adresiniz başarıyla onaylanmıştır.
            </div>
            <Link
              to="/register"
              className="btn with-icon"
              data-testid="link-btn"
            >
              <span>Ana Sayfa</span>
            </Link>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
}
