import React, { useState, useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { createBayiTicket } from "../../services/authService";
import Modal from "../../components/shared/Modal";
import { getContactContent } from "../../services/contentService";
import LoadingModal from "../../components/shared/LoadingModal";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  const [content, setContent] = useState(null);
  const [companySelect, setCompanySelect] = useState("KK");
  const [issueSelect, setIssueSelect] = useState("complaint");
  const [message, setMessage] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const issueList = [
    {
      label: "Şikayet",
      value: "complaint",
    },
    {
      label: "Öneri",
      value: "suggestion",
    },
    {
      label: "Bilgi alma",
      value: "information",
    },
    {
      label: "Talep",
      value: "request",
    },
    {
      label: "Teşekkür",
      value: "thanks",
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const content = await getContactContent();
        setContent(content);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrorModal(false);
      setSuccessModal(false);
      setLoading(true);
      const msg = await createBayiTicket({
        firm: companySelect,
        subject: issueSelect,
        message,
      });
      setLoading(false);
      setSuccessModal(true);
      setSuccessMsg(msg.message);
      setMessage("");
    } catch (e) {
      setLoading(false);
      setErrorModal(true);
      setErrorMsg(e.message);
      console.log(e);
    }
  };

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        {errorModal && (
          <Modal
            type="error"
            msg={errorMsg}
            updateModalState={(state) => setErrorModal(state)}
            shouldShow={errorModal}
          />
        )}
        {successModal && (
          <Modal
            type="success"
            msg={successMsg}
            updateModalState={(state) => setSuccessModal(state)}
            shouldShow={successModal}
          />
        )}
        <div id="content" className="lg:p-0">
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  {content?.title}
                </h1>
                <h6 className="col-span-5 text-xs font-lg">{content?.text}</h6>
              </div>
            </main>
          </div>
          <div className="px-4 mx-auto my-12 max-w-7xl lg:my-16 lg:px-1">
            <div className="flex flex-col justify-between grid-cols-12 p-4 lg:p-0 lg:grid lg:flex-row ">
              <h1 className="col-span-7 mb-8 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                <img
                  data-testid="contact-image"
                  src={content?.image?.url}
                  alt="contact"
                />
              </h1>
              <div className="col-span-5">
                <div>
                  <h3 className="text-sm text-center lg:text-md lg:text-left">
                    İletişim Formu
                  </h3>
                  <p className="mt-2 text-center text-body font-body lg:text-left">
                    Bizimle iletişime geçmek için aşağıdaki iletişim formunu
                    doldurabilirsin.
                  </p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col lg:flex-row lg:space-x-14">
                    <div className="flex-col justify-center flex-1 lg:px-1">
                      <div className="flex flex-col gap-12 mt-10">
                        <div className="form-input-wrapper">
                          <select
                            value={companySelect}
                            onChange={(e) => setCompanySelect(e.target.value)}
                            id="company"
                            name="company"
                            autoComplete="company"
                            className="bg-transparent form-input"
                          >
                            <option name="KS" value="KS">
                              Kaleseramik
                            </option>
                            <option name="KK" value="KK">
                              Kalekim
                            </option>
                          </select>
                          <label
                            htmlFor="company"
                            className="bg-transparent form-label"
                          >
                            Firma Seçiniz
                          </label>
                        </div>
                        <div className="form-input-wrapper">
                          <select
                            // value={issueSelect}
                            onChange={(e) => setIssueSelect(e.target.value)}
                            id="subject"
                            name="subject"
                            autoComplete="subject"
                            className="bg-transparent form-input"
                          >
                            {issueList.map((issue) => (
                              <option
                                key={issue.label}
                                value={issue.label}
                                name={issue.label}
                              >
                                {issue.label}
                              </option>
                            ))}
                          </select>
                          <label
                            htmlFor="subject"
                            className="bg-transparent form-label"
                          >
                            Konu Seçiniz
                          </label>
                        </div>
                        <div className="form-input-wrapper">
                          <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            type="text"
                            id="other"
                            name="other"
                            placeholder=""
                            className="h-40 bg-transparent resize-none form-input"
                          ></textarea>
                          <label
                            htmlFor="other"
                            className="bg-transparent form-label"
                          >
                            Mesajınız
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end w-full mt-8 mr-0">
                    <button
                      type="submit"
                      className="w-full max-w-full uppercase lg:w-28 form-submit-btn lg:max-w-12"
                    >
                      GÖNDER
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="py-10 lg:py-16 bg-primary-black">
            <h3 className="text-center text-white text-md lg:text-lg font-md">
              Müşteri İletişim Merkezi
            </h3>
            <p className="mt-4 text-center text-white text-body-l">
              Bize bu numaralardan ulaşabilirsiniz.
            </p>
            <div className="flex flex-col items-center justify-center gap-6 mt-10 text-center lg:text-left lg:flex-row">
              <div>
                <p className="uppercase text-primary-red text-btn">
                  KALESERAMİK ÇAĞRI MERKEZİ
                </p>
                <a className="text-white text-body" href="tel:0850 800 52 53">
                  0850 800 52 53
                </a>
              </div>
              <div>
                <p className="uppercase text-primary-red text-btn">
                  KALESERAMİK WHATSAPP HATTI
                </p>
                <a className="text-white text-body" href="tel:0532 305 52 53">
                  0532 305 52 53
                </a>
              </div>
              <div>
                <p className="uppercase text-primary-red text-btn">
                  KALEKİM ÇAĞRI MERKEZİ
                </p>
                <a className="text-white text-body" href="tel:0850 222 87 82">
                  0850 222 87 82
                </a>
              </div>
              <div>
                <p className="uppercase text-primary-red text-btn">
                  KALEKİM WHATSAPP HATTI
                </p>
                <a className="text-white text-body" href="tel:0532 552 87 82">
                  0532 552 87 82
                </a>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default ContactPage;
