import React from "react";
import {
  SET_OVERLAY,
  TOGGLE_SEARCH,
  SET_SEARCH,
} from "../../../store/header/headerSlice";
import { useDispatch } from "react-redux";

const Search = () => {
  const dispatch = useDispatch();
  return (
    <div
      role="menu"
      className="fixed top-0 left-0 z-10 w-full h-screen bg-white lg:h-auto"
    >
      <div className="flex flex-col px-0 mx-auto max-w-7xl lg:justify-between lg:px-1">
        <div className="flex items-center justify-between p-6 lg:hidden bg-background-purple">
          <h3 className="text-sm">Arama</h3>
        </div>

        <div className="flex flex-col items-center justify-between lg:flex-row">
          <button
            onClick={() => dispatch(SET_SEARCH(false))}
            type="button"
            className="lg:hidden absolute right-4 top-4 rounded-none z-20 p-1.5 whitespace-nowrap bg-secondary-secondary"
          >
            <svg
              className="w-5 h-5 text-white link-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <div className="hidden px-2 lg:px-0 lg:flex">
            <div className="flex items-center flex-shrink-0">
              <a href="/">
                <span className="sr-only">Kale Bayi Kulübü</span>
                <img
                  src="/assets/img/logo.png"
                  alt="kale logo"
                  className="responsive w-[4.5rem]"
                />
              </a>
            </div>
          </div>

          <div className="relative flex flex-col justify-center w-full h-full max-w-4xl px-4 py-4 pt-4 md:pt-20 lg:pt-4 lg:justify-center">
            <button
              type="button"
              className="absolute z-10 left-8 whitespace-nowrap"
              id="menu-button"
            >
              <svg
                className="w-6 link-icon text-primary-black"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.82989 2C14.1542 2 17.6598 5.50903 17.6598 9.83768C17.6598 11.6269 17.0609 13.276 16.0528 14.5952L16.0158 14.6429L22 20.6121L20.6186 22L14.6317 16.0283L14.5827 16.0668C13.2648 17.0759 11.6173 17.6754 9.82989 17.6754C5.50504 17.6754 2 14.1663 2 9.83768C2 5.50903 5.50504 2 9.82989 2ZM9.83021 3.95942C6.58671 3.95942 3.95752 6.59123 3.95752 9.83768C3.95752 13.0841 6.58671 15.7159 9.83021 15.7159C13.0732 15.7159 15.7023 13.0841 15.7023 9.83768C15.7023 6.59123 13.0732 3.95942 9.83021 3.95942Z"
                  fill="currentColor"
                />
              </svg>
            </button>
            <div className="relative flex justify-center search">
              <input
                id="autoComplete"
                type="text"
                className="relative w-full py-3 pl-12 pr-4 transition duration-150 ease-in-out bg-white border focus:ring-transparent lg:pr-12 border-secondary-lines leading-[1.375rem]"
                placeholder="Ne Arıyorsunuz?"
              />
              <button
                type="reset"
                className="absolute right-0 z-10 flex items-center h-8 pr-3 lg:border-r lg:right-14 top-2"
              >
                {/*delete icon  */}
                <svg
                  className="w-6 link-icon text-primary-red"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 6V8H18.2223V22H5.77773V8H4V6H20ZM16.4443 8H7.55538V20H16.4443V8ZM11.1114 10V18H9.33353V10H11.1114ZM14.6667 10V18H12.8889V10H14.6667ZM14.6669 2V4H9.33353V2H14.6669Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <button
              onClick={() => {
                dispatch(TOGGLE_SEARCH());
                dispatch(SET_OVERLAY(false));
              }}
              type="button"
              className="hidden lg:block absolute right-8 rounded-none z-20 p-1.5 whitespace-nowrap bg-secondary-secondary"
            >
              <svg
                className="w-3 h-3 text-white link-icon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                  fill="currentColor"
                />
              </svg>
              {/*  cross icon  */}
            </button>
          </div>
          <div className="w-full max-w-4xl px-4 lg:w-auto lg:px-0">
            <button className="w-full mx-auto mt-6 btn bg-primary-red white lg:max-w-[12rem] lg:hidden">
              Arama Yap
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
