import React, { useEffect, useState, useRef } from "react";
import { addFriend, searchUser } from "../../../services/contentService";
import LoadingModal from "../../../components/shared/LoadingModal";
import { Link } from "react-router-dom";
import Parse from "parse";

const AddNewFriend = ({
  cities,
  regions,
  getCityName,
  cityField,
  setCityField,
  getRegionName,
  regionField,
  setRegionField,
}) => {
  const [nameField, setNameField] = useState("");
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const startOfList = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    searchUser(nameField, regionField?.name, cityField?.name)
      .then((response) => {
        response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        setSearchedUsers(response);
        window.innerWidth > 1024
          ? window.scrollTo(0, startOfList.current.offsetTop + 350)
          : window.scrollTo(0, startOfList.current.offsetTop + 250);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleAddFriend = async (user_id) => {
    setIsLoading(true);
    addFriend(user_id)
      .then((response) => {
        searchUser(nameField, regionField?.name, cityField?.name)
          .then((response) => {
            setSearchedUsers(response);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {}, [searchedUsers]);

  return (
    <>
      {isLoading && <LoadingModal />}
      <div className="flex">
        <div className="flex flex-col w-full p-4 lg:p-0">
          <div className="w-full max-w-md mx-auto">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-8 mb-12 lg:px-5 lg:mb-16"
            >
              <div className="form-input-wrapper">
                <input
                  value={nameField}
                  onChange={(e) => setNameField(e.target.value)}
                  type="text"
                  id="name"
                  autoComplete="cc-given-name"
                  name="name"
                  placeholder=" "
                  className="bg-transparent form-input"
                />
                <label htmlFor="name" className="bg-transparent form-label">
                  Ad Soyad
                </label>
              </div>
              <div className="form-input-wrapper">
                <select
                  id="graduation"
                  name="graduation"
                  autoComplete="graduation"
                  className="bg-transparent form-input"
                  onChange={(e) =>
                    setCityField({
                      id: e.target.value,
                      name:
                        e.target.value === "0"
                          ? ""
                          : getCityName(e.target.value),
                    })
                  }
                >
                  {cities?.map((city) => (
                    <option key={city.ID} value={city.ID}>
                      {city.NAME}
                    </option>
                  ))}
                </select>
                <label
                  htmlFor="graduation"
                  className="bg-transparent form-label"
                >
                  Şehir
                </label>
              </div>
              <div className="form-input-wrapper">
                {/* <input
                  value={regionField}
                  onChange={(e) => setRegionField(e.target.value)}
                  type="text"
                  id="district"
                  autoComplete="cc-given-district"
                  name="district"
                  placeholder=" "
                  className="bg-transparent form-input"
                /> */}

                <select
                  id="graduation"
                  name="graduation"
                  autoComplete="graduation"
                  className="bg-transparent form-input"
                  onChange={(e) =>
                    setRegionField({
                      id: e.target.value,
                      name:
                        e.target.value === "0"
                          ? ""
                          : getRegionName(e.target.value),
                    })
                  }
                >
                  {regions?.map((region) => (
                    <option key={region.ID} value={region.ID}>
                      {region.NAME}
                    </option>
                  ))}
                </select>

                <label htmlFor="district" className="bg-transparent form-label">
                  İlçe
                </label>
              </div>
              <button
                type="submit"
                className="inline-flex items-center w-full max-w-full px-6 mx-auto lg:max-w-[12rem] lg:mt-10 form-submit-btn"
              >
                ARA
              </button>
            </form>
          </div>
          <h2 className="mb-10 text-lg font-lg" ref={startOfList}>
            Kişi Sonuç Listesi
          </h2>
          {!searchedUsers.length && (
            <div className="text-center">Sonuç Yok</div>
          )}
          {searchedUsers.map((user) => {
            if (user.id === Parse.User.current().id) {
              return null;
            }

            return (
              <div
                key={user.id}
                className="flex w-full pb-4 border-b border-secondary-lines"
              >
                <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row mt-3">
                  <div className="flex">
                    <Link to={`/user/${user?.id}`}>
                      <div className="flex items-center justify-center w-[88px] h-[88px] overflow-hidden rounded-full mr-6 bg-secondary-titles">
                        {user.profile_image && (
                          <img
                            src={`${user.profile_image}`}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        )}
                        {!user.profile_image && (
                          <svg
                            role="img"
                            className="w-[66px] h-[66px] overflow-hidden rounded-full text-white"
                          >
                            <use xlinkHref="/assets/img/necromancers.svg#user" />
                          </svg>
                        )}
                      </div>
                    </Link>
                    <div className="flex flex-col justify-center">
                      <Link to={`/user/${user?.id}`}>
                        <p className="mb-1 text-body-l font-md">{user.name}</p>
                      </Link>
                      {/* <p>ABC Kimya</p> */}
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-4 mt-6 lg:flex-row md:w-auto lg:mt-0">
                    {!user.is_request_sent && !user?.is_friend && (
                      <button
                        onClick={() => handleAddFriend(user.id)}
                        className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                      >
                        ARKADAŞ EKLE
                      </button>
                    )}
                    {user.is_request_sent && !user?.is_friend && (
                      <button className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto">
                        İSTEK GÖNDERİLDİ
                      </button>
                    )}
                    {user?.is_friend && (
                      <button className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto">
                        Arkadaşsınız
                      </button>
                    )}
                    {/* {user.is_request_sent && (
                      <button className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto">
                        İstek Gönderildi
                      </button>
                    )}
                    {user.is_friend && (
                      <button className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto">
                        Arkadaşsınız
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            );
          })}
          {/* <div class="flex w-full pb-4 border-b border-secondary-lines">
            <div class="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
              <div class="flex">
                <div class="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                  <img src="/assets/img/dummy/profile-pic.png" alt="" />
                </div>
                <div class="flex flex-col justify-center">
                  <p class="mb-1 text-body-l font-md">Ahmet Aslan</p>
                  <p>ABC Kimya</p>
                </div>
              </div>
              <div class="flex flex-col w-full gap-4 mt-6 lg:flex-row md:w-auto lg:mt-0">
                <a
                  href="#"
                  class="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                >
                  ARKADAŞ EKLE
                </a>
              </div>
            </div>
          </div>
          <div class="flex w-full pb-4 mt-4 border-b border-secondary-lines">
            <div class="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
              <div class="flex">
                <div class="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                  <img src="/assets/img/dummy/profile-pic.png" alt="" />
                </div>
                <div class="flex flex-col justify-center">
                  <p class="mb-1 text-body-l font-md">Ahmet Aslan</p>
                  <p>ABC Kimya</p>
                </div>
              </div>
              <div class="flex flex-col w-full gap-4 mt-6 lg:flex-row md:w-auto lg:mt-0">
                <a
                  href="#"
                  class="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                >
                  ARKADAŞ EKLE
                </a>
              </div>
            </div>
          </div> */}
          {/* ads */}
        </div>
      </div>
    </>
  );
};

export default AddNewFriend;
