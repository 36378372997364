import React from "react";

export default function KaleseramikKvkk() {
  return (
    <>
      <p>
        {" "}
        İşbu yazı yoluyla bayi çalışan portalımızı ziyaret eden siz bayi
        çalışanlarımızı, 6698 sayılı Kişisel Verilerin Korunması Kanunu (kısaca
        “Kanun”) gereğince bilgilendirmekteyiz. İşbu Aydınlatma Metni, bayi
        çalışan portalı ziyaretçilerimizden toplanan kişisel verilerin
        kaydedilmesi, işlenmesi, paylaşılması, üçüncü taraflara aktarılması,
        saklanması, silinmesi ve imhası süreçleri ile bunlara ilişkin
        ilkelerimiz hakkında ziyaretçilerimizin bilgilendirilmesi amacıyla
        hazırlanmıştır.
      </p>
      <h5 className="my-5">I) Veri sorumlusu</h5>
      <p>
        KALESERAMİK Çanakkale Kalebodur Seramik Sanayi A.Ş. (kısaca
        “KALESERAMİK”) Adres: Büyükdere Cad. Kaleseramik Binası 34330 Levent
        İstanbul/Türkiye, Mersis No:229000429600015, Kanun kapsamında “Veri
        Sorumlusudur”.
      </p>

      <h5 className="my-5">II) Kişisel verileriniz hangi amaçla işlenecek</h5>
      <p>
        Kişisel verileriniz, aşağıdaki durum ve koşullarda KALESERAMİK
        tarafından Kanun kapsamında işlenmektedir:
      </p>

      <ol className="my-3">
        <li>
          1.Duyuru, kampanya, satış ve pazarlama faaliyetlerin için sizinle
          iletişime geçebilmek için,
        </li>
        <li>
          2.Kazandığınız puan kapsamında, sadakat programını yönetebilmek için,
        </li>
        <li>
          3.Düzenlediğimiz organizasyonlar ve sunduğumuz hizmetlere ile ilgili
          bilgi iletişimi yapılması için,
        </li>
        <li>
          4.Sosyal medyada yapılan etkinlik haberlerinde kullanabilmek için,
        </li>
        <li>
          5.Portal kullanım alışkanlıklarının belirlenmesi ve veri analizi
          yapabilmek için,{" "}
        </li>
        <li>
          6.Portalda sunulan hizmetlerimizin kalitesini artırabilmek için,
        </li>
        <li>
          7.Portal üzerinden hizmet alınması durumunda bu hizmetin
          gerekliliklerinin yerine getirilebilmesi için.
        </li>
      </ol>
      <h5 className="my-5">III) Hangi kişisel verileriniz işlenecek</h5>
      <p>
        Aşağıdakilerle sınırlı olmamak ve bunlar örnekleyici olmak kaydı ile
        sizinle kurulacak hukuki ilişkiye veya yapılacak işleme göre aşağıdaki
        verileriniz işlenecektir,
      </p>
      <p>Portala girmiş olduğunuz;</p>
      <ol className="my-3">
        <li>1.Ad ve Soyadı bilgileriniz,</li>
        <li>2.Cinsiyet bilgileriniz,</li>
        <li>3.T.C. Kimlik No bilgileriniz,</li>
        <li>4.Doğum Tarihi bilgileriniz,</li>
        <li>5.Cep iletişim bilgileriniz,</li>
        <li>6.Email iletişim bilgileriniz,</li>
        <li>7.Eğitim Durumu bilgileriniz,</li>
        <li>8.Çalıştığınız Firma bilgileriniz,</li>
        <li>9.Unvan bilgileriniz,</li>
        <li>10.Adres bilgileriniz,</li>
        <li>
          11.Portala yüklediğiniz veya gönderdiğiniz her türlü veri, fotoğraf,
          paylaşım vb.
        </li>
        <li>
          12.Portala ulaşmak için kullandığınız tarayıcı, üzerinden geldiğiniz
          internet sitesi veya uygulama gibi bilgiler
        </li>
        <li>
          13.5651 sayılı yasa gereği tutulması zorunlu olan IP, kullanılan
          protokol ve iletişim kayıtları,
        </li>
        <li>
          14.Ziyaret ettiğiniz sayfalar, buralarda harcana süre vb. kullanım
          istatistikleri
        </li>
      </ol>

      <h5 className="my-5">
        IV) Kişisel Verileriniz Kimlere ve Hangi Amaçla Aktarılabilecek
      </h5>
      <p>
        İşlenen kişisel verileriniz yukarıdaki amaçlar ve Kanun’un belirlediği
        şartlara göre;
      </p>
      <ol className="my-3">
        <li>1.İştiraklerimize, grup şirketlerimize, hissedarlarımıza,</li>
        <li>
          2.Ürün ve hizmetlerimizin sunulabilmesi amacıyla sınırlı olmak üzere
          iş ortaklarımıza ve tedarikçilerimize,
        </li>
        <li>
          3.Ticari, mali ve hukuki danışmanlıklar ve denetimler amacıyla
          danışmanlara ve denetim firmalarına,
        </li>
        <li>
          4.Kampanya, tanıtım ve duyurularımızın yapılabilmesi için bu
          süreçlerin içinde olan taşeron ve hizmet alınan üçüncü taraflara,
        </li>
        <li>
          5.Müşteri şikayetlerinin çözümlenmesi amacıyla alınan Çağrı Merkezi
          ve/veya Şikayet takibi hizmeti şirketlerine,
        </li>
        <li>
          6.Yalnızca Kanunlarda şart koşulduğu ve kapsamıyla sınırlı olmak üzere
          yetkili kamu kurum ve kuruluşlarıyla yargı mercilerine,
        </li>
        <li>
          7.Portal üzerinden aldığınız hizmete ilişkin yükümlülüklerimizin
          yerine getirilebilmesi amacıyla hizmeti sağlamak için işbirliği
          yaptığımız tedarikçi ve danışmanlarımızla
        </li>
        <li>
          8.Sitemizle bağlantılı sosyal medya hesapları ile yaptığınız iletişim
          için yer sağlayıcı veya sistem sağlayıcı olan sosyal medya platformu
          sahibi şirketlerle
        </li>
        <li>
          9.Veri aktarımı için rızanızı aldığımız diğer alıcılara, açık rızanız
          ya da Kanun ile belirlenmiş koşullar çerçevesinde aktarılabilecektir.
        </li>
      </ol>

      <h5 className="my-5">
        V) Kişisel veri işlemenin yöntemi ve hukuki sebebi
      </h5>
      <p>
        Kişisel verileriniz, KALESERAMİK veya KALESERAMİK adına veri işleyen
        gerçek ya da tüzel kişiler tarafından; internet sitesi, sosyal medya
        kanallarımız, mobil uygulamalarımız ve bu sayılanlarla sınırlı olmamak
        üzere sözlü, yazılı, görsel veya elektronik kanallar aracılığıyla ve
        açık rızanız alınmak suretiyle toplanmaktadır. İnternet sitemiz ve
        bağlantılı sosyal medya hesapları ile ilgili otomatik veri toplama
        yöntemlerimiz için
        https://www.kale.com.tr/tr/kurumsal/kisisel-verilerin-korunmasi
        adresinden çerez politikamıza ulaşabilirsiniz.
      </p>
      <p>
        Kişisel verileriniz, KALESERAMİK tarafından, ticari faaliyetlerimizin
        sunulabilmesi ve faaliyetlerimizde sürekliliğin sağlanabilmesi,
        Kanunlarca öngörülen sorumluluklarımızın tam ve doğru bir şekilde yerine
        getirebilmesi amacıyla işlenmektedir.
      </p>

      <h5 className="my-5">6) Kanun gereğince yasal haklarınız</h5>
      <p>
        KVKK’ nın 11. maddesinde haklarınız yazılı olup, bunlar aşağıdaki
        gibidir:
      </p>
      <p>KALESERAMİK’e yazılı olarak başvurarak, kişisel verilerinizin;</p>
      <ol className="my-3">
        <li>a)işlenip işlenmediğini öğrenme,</li>
        <li>b)işlenmişse buna ilişkin bilgi talep etme,</li>
        <li>
          c)işlenme amacını ve amacına uygun kullanılıp kullanılmadığını
          öğrenme,
        </li>
        <li>ç)yurt içinde veya yurt dışında aktarıldığı 3. kişileri bilme,</li>
        <li>
          d)eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini
          isteme,
        </li>
        <li>
          e)KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini
          veya yok edilmesini veya anonim hale getirilmesini isteme,
        </li>
        <li>
          f)aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri
          uyarınca yapılan işlemlerin bildirilmesini isteme,
        </li>
        <li>
          g)münhasıran otomatik sistemler ile analiz edilmesi nedeniyle
          aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
        </li>
        <li>
          ğ)kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde
          zararın giderilmesini talep etme haklarınızı kullanabilirsiniz.
        </li>
      </ol>

      <p>
        Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici
        gerekli bilgiler ile kullanmayı talep ettiğiniz hakkınıza yönelik
        açıklamalarınızı içeren talebinizi; www.kale.com.tr adresindeki formu
        doldurarak, şirketimizin; Büyükdere Cad. Kaleseramik Binası, 34330
        Levent-İSTANBUL adresine bizzat getirebilir veya noter kanalı veya
        iadeli taahhütlü posta yoluyla gönderebilir veya formun imzalı bir
        suretini ks-kvkk@kale.com.tr ya da kaleseramikas@hs01.kep.tr mail
        adresine iletebilirsiniz.
      </p>

      <p>
        Talepleriniz, talebinizin niteliğine göre mümkün olan en kısa sürede ve
        en geç 30 gün içerisinde ücretsiz olarak sonuçlandırılır. Ancak işlemin
        ayrıca bir maliyeti gerektirmesi halinde, Kişisel Verileri Koruma Kurulu
        tarafından belirlenen tarifedeki ücret alınabilir. Talebiniz başkası
        adına yapılacaksa bu konuda almış olduğunuz bir vekaletnamenin ve
        kimliğinizi doğrular diğer belgelerin başvurunuza eklenmiş olması
        gerekmektedir.
      </p>

      <p>
        Yukarıdaki aydınlatma metni ve ilgili mevzuat çerçevesinde kişisel
        verilerimin satış, pazarlama tanıtım faaliyetleri çerçevesinde
        işlenmesine ve bu faaliyetlerin yürütülmesi konusunda hizmet alınan
        tedarikçi ve iş ortakları ile paylaşılmasına rıza gösteriyorum.
      </p>
    </>
  );
}
