import Parse from "parse";

async function userContractCheck(token) {
  return Parse.Cloud.run("userContractCheck", {}, { sessionToken: token })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export async function confirmContract(contractId, token) {
  return Parse.Cloud.run(
    "userContractSign",
    {
      contractId: contractId,
    },
    { sessionToken: token }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export const login = async (user) => {
  return Parse.Cloud.run("LoginBayi", {
    username: user.username,
    password: user.password,
    rememberMe: user.rememberMe,
  })
    .then(async (result) => {
      const user = result.toJSON();

      const contract = await userContractCheck(user.sessionToken);

      if (contract.status === false && contract.lastContractId) {
        return {
          contractPermission: false,
          lastContractId: contract.lastContractId,
          sessionToken: user.sessionToken,
          contractFile: contract.contract._url,
        };
      }

      await Parse.User.become(user.sessionToken);
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const logout = async () => {
  return await Parse.User.logOut();
};

export const getCurrentUser = async () => {
  return await Parse.User.current()?.fetch();
};

export const registerBayi = async (user) => {
  return Parse.Cloud.run("RegisterBayi", {
    FirstName: user.firstName,
    LastName: user.lastName,
    Email: user.email,
    Phone: user.phoneNum,
    Title_of_Person__c: user.title,
    Class__c: user.title,
    Education_Status__c: user.graduation,
    The_Kale_Company_Work_With__c: user.company,
    kkEmailPermission: user.kkEmailPermission,
    kkKvkkPermission: user.kkKvkkPermission,
    ksEmailPermission: user.ksEmailPermission,
    ksKvkkPermission: user.ksKvkkPermission,
    placier: user.placier,
    branch: user.branch,
    firm: user.firm,
    Gender__c: user.gender,
    ID_Number__c: user.tcId,
    Birthday: user.birthday,
    Billing_City__c: user.city,
    City__r: user?.City__r,
    Billing_Region__c: user.region,
    Billing_City__r: user?.Billing_City__r,
    Region__r: user?.Region__r,
    BillingRegion__r: user?.BillingRegion__r,
    Billing_Region__r: user?.BillingRegion__r,
  })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
};

export const getCities = async () => {
  let cityQuery = new Parse.Query("City");
  cityQuery.ascending("NAME");

  try {
    let cities = await cityQuery.find();
    let cityArr = [];
    cities.forEach((city) => {
      cityArr.push(city.toJSON());
    });
    return cityArr;
  } catch (error) {
    throw error;
  }
};

export const getRegions = async (cityId) => {
  let regionQuery = new Parse.Query("Region");
  regionQuery.equalTo("City__C", cityId);
  regionQuery.ascending("NAME");

  try {
    let regions = await regionQuery.find();
    let regionArr = [];
    regions.forEach((region) => {
      regionArr.push(region.toJSON());
    });
    return regionArr;
  } catch (error) {
    throw error;
  }
};

export const getBayiTitles = async () => {
  let titleQuery = new Parse.Query("BayiTitle");
  titleQuery.ascending("tr");

  try {
    let titles = await titleQuery.find();
    let titleArr = [];
    titles.forEach((title) => {
      titleArr.push(title.toJSON());
    });
    return titleArr;
  } catch (error) {
    throw error;
  }
};

export const getBayiFirms = async () => {
  const firms = [];
  return Parse.Cloud.run("GetBayiFirms")
    .then((result) => {
      result.data.forEach((firm) => {
        firms.push(firm);
      });
      return firms;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getBayiBranchesRegister = async (id) => {
  try {
    const branches = [];
    return Parse.Cloud.run("GetBayiBranches", {
      parentId: id,
    }).then((result) => {
      result.data.forEach((branch) => {
        branches.push(branch);
      });
      return branches;
    });
  } catch (error) {
    console.log(error);
  }
};
export const updateBayiProfile = async (profileInfo) => {
  let newImage = null;

  try {
    if (profileInfo?.profile_image?.lastModified) {
      newImage = new Parse.File(
        profileInfo?.profile_image?.name,
        profileInfo?.profile_image,
        "image/png"
      );
      await newImage.save();
    } else {
      newImage = profileInfo?.profile_image;
    }

    profileInfo.profile_image = newImage;

    if (profileInfo.birthdate) {
      profileInfo.Birthday = profileInfo.birthdate;
      delete profileInfo.birthdate;
    }

    return await Parse.Cloud.run("UpdateBayiProfile", profileInfo);
  } catch (error) {
    throw error;
  }
};

export const createBayiTicket = async (data) => {
  return Parse.Cloud.run("CreateBayiTicket", {
    firm: data.firm,
    subject: data.subject,
    message: data.message,
  })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const changeBayiPassword = async (data) => {
  return Parse.Cloud.run("ChangeBayiPassword", {
    oldPassword: data.oldPassword,
    newPassword: data.newPassword,
  });
};

export const getBayiBranches = async (id) => {
  return await Parse.Cloud.run("GetBayiBranches", { parentId: id })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const StartOTPBayi = (phone) => {
  return Parse.Cloud.run("StartOTPBayi", { phone_number: phone });
};

export const ConfirmOTPBayi = (jwt, code) => {
  return Parse.Cloud.run("ConfirmOTPBayi", { jwt, value: code });
};

export const ResendOTPSMSBayi = (jwt) => {
  return Parse.Cloud.run("ResendOTPSMSBayi", { old_jwt: jwt });
};
export const sendEmailVerification = async (email) => {
  return await Parse.Cloud.run("SendVerificationEmailBayi", { email });
};

export const confirmEmailVerification = async (token) => {
  return await Parse.Cloud.run("ConfirmVerificationEmailBayi", { jwt: token });
};
export const getSingleUser = async (id) => {
  const query = new Parse.Query("_User");
  query.equalTo("objectId", id);
  query.includeAll();
  const result = await query.find();
  return result[0].toJSON();
};
