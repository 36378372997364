import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  pointData: null,
  negativePoints: {
    manuel: {
      ks: 0,
      kk: 0,
      total: 0,
    },
    expired: 0,
  },
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    SET_USER: (state, action) => {
      state.user = action.payload;
    },
    SET_POINT_DATA: (state, action) => {
      state.pointData = action.payload;
    },
    SET_POINT_GROUPS: (state, action) => {
      const point = action.payload;
      if (point.type === "positive") {
        state.pointData = {
          ...state.pointData,
          remainingKKPoint: state.pointData.remainingKKPoint + point.kk_amount,
          remainingKSPoint: state.pointData.remainingKSPoint + point.ks_amount,
          total_point: state.pointData.total_point + point.amount,
          groups: {
            ...state.pointData.groups,
            kkPoint: state.pointData.groups.kkPoint + point.kk_amount,
            ksPoint: state.pointData.groups.ksPoint + point.ks_amount,
          },
          records: [point, ...state.pointData.records],
        };

        switch (point.resource) {
          case "Manuel":
            state.pointData = {
              ...state.pointData,
              groups: {
                ...state.pointData.groups,
                manuel: {
                  kk: state.pointData.groups.manuel.kk + point.kk_amount,
                  ks: state.pointData.groups.manuel.ks + point.ks_amount,
                },
              },
            };
            break;
          case "Siteye Giriş":
            state.pointData = {
              ...state.pointData,
              groups: {
                ...state.pointData.groups,
                beingOnline: {
                  kk: state.pointData.groups.beingOnline.kk + point.kk_amount,
                  ks: state.pointData.groups.beingOnline.ks + point.ks_amount,
                },
              },
            };
            break;
          case "Bilgi Küpü":
            state.pointData = {
              ...state.pointData,
              groups: {
                ...state.pointData.groups,
                exam: {
                  kk: state.pointData.groups.exam.kk + point.kk_amount,
                  ks: state.pointData.groups.exam.ks + point.ks_amount,
                },
              },
            };
            break;
          case "Anket":
            state.pointData = {
              ...state.pointData,
              groups: {
                ...state.pointData.groups,
                survey: {
                  kk: state.pointData.groups.survey.kk + point.kk_amount,
                  ks: state.pointData.groups.survey.ks + point.ks_amount,
                },
              },
            };
            break;
          default:
            break;
        }
      } else {
        state.pointData = {
          ...state.pointData,
          remainingKKPoint: state.pointData.remainingKKPoint - point.kk_used,
          remainingKSPoint: state.pointData.remainingKSPoint - point.ks_used,
          total_point:
            state.pointData.total_point - point.kk_used - point.ks_used,
          groups: {
            ...state.pointData.groups,
            manuel: {
              kk: state.pointData.groups.manuel.kk - point.kk_used,
              ks: state.pointData.groups.manuel.ks - point.ks_used,
            },
          },
          records: [point, ...state.pointData.records],
        };

        state.negativePoints = {
          ...state.negativePoints,
          manuel: {
            ks: state.negativePoints.manuel.ks - point.ks_used,
            kk: state.negativePoints.manuel.kk - point.kk_used,
            total:
              state.negativePoints.manuel.total - point.ks_used - point.kk_used,
          },
        };
      }
    },
    SET_NEGATIVE_MANUEL_POINTS: (state, action) => {
      state.negativePoints = {
        ...state.negativePoints,
        manuel: action.payload,
      };
    },
    SET_EXPIRED_POINTS: (state, action) => {
      state.negativePoints = {
        ...state.negativePoints,
        expired: action.payload,
      };
    },
  },
});

export const {
  SET_USER,
  SET_USER_CREDENTIALS,
  SET_POINT_DATA,
  SET_POINT_GROUPS,
  SET_NEGATIVE_MANUEL_POINTS,
  SET_EXPIRED_POINTS,
} = appSlice.actions;
export default appSlice.reducer;
