import React, { useState, useEffect, useRef } from "react";
import {
  getMessages,
  getTotalUnreadConversations,
  sendMessage,
} from "../../../../../services/contentService";
import Parse from "parse";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_MESSAGES } from "../../../../../store/header/headerSlice";
import { SET_UNREAD_CONVERSATIONS_COUNT } from "../../../../../store/message/messageSlice";

export default function MessageDetails({
  friendForMessageDetails,
  setActiveContent,
  user,
  activeContent,
  setShowErrorModal,
  setApiError,
}) {
  //eslint-disable-next-line
  const [messages, setMessages] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const scrollbar = useRef(null);
  const endOfMessages = useRef(null);
  const messageInput = useRef(null);

  let messageSubscription;

  const startLiveChat = async () => {
    const SocialMessage = Parse.Object.extend("SocialMessage");
    const query = new Parse.Query(SocialMessage);
    query.equalTo("from", friendForMessageDetails?.id);
    query.equalTo("to", user?.objectId);

    messageSubscription = await query.subscribe();

    messageSubscription.on("create", (object) => {
      let messagesCopy = messages;
      if (messages !== null) {
        messagesCopy = messages;
      }

      messagesCopy.push(object);
      setMessages([...messagesCopy]);
    });
  };

  useEffect(() => {
    (async () => {
      if (messages !== null) await startLiveChat();
    })();
    //eslint-disable-next-line
  }, [user, friendForMessageDetails?.id, activeContent, messages]);

  useEffect(() => {
    (async () => {
      try {
        const messages = await getMessages(friendForMessageDetails?.id);
        const reversedMessages = messages?.reverse();
        const conversationsCount = await getTotalUnreadConversations();
        dispatch(SET_UNREAD_CONVERSATIONS_COUNT(conversationsCount));
        setMessages(reversedMessages);
      } catch (error) {
        console.log(error);
      }
    })();
    //eslint-disable-next-line
  }, [friendForMessageDetails?.id]);

  useEffect(() => {
    scrollbar.current.scrollTo(0, endOfMessages.current.offsetTop);
  }, [messages?.length]);

  const sendMessageToFriend = async () => {
    if (!user) throw new Error("Unauthorized");
    if (messageContent?.trim() !== "") {
      setLoading(true);
      try {
        const message = await sendMessage(
          friendForMessageDetails?.id,
          messageContent
        );
        setMessages([...messages, message]);
        setMessageContent("");
        messageInput.current.value = "";
        // updateConversations(friendForMessageDetails?.id, message);
      } catch (error) {
        if (error.code === 141) {
          setApiError(
            "Arkadaşınız olmayan bir kullanıcıya mesaj gönderemezsiniz."
          );
        } else {
          setApiError(error.message);
        }
        setShowErrorModal(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const calculateTimePassed = (createdAt) => {
    const now = new Date();
    const messageDate = new Date(createdAt);
    const timeLeft = now - messageDate;

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    let finalContent = null;
    if (days > 0) {
      finalContent = `${days}d ${hours}`;
    } else if (days <= 0 && hours > 0) {
      finalContent = `${hours}h ${minutes}m`;
    } else if (hours <= 0 && minutes > 0) {
      finalContent = `${minutes}m`;
    } else {
      finalContent = `1s`;
    }
    return (
      <p className="mt-1 text-body-s text-secondary-secondary">
        {finalContent}
      </p>
    );
  };

  return (
    <div className="w-full" role="none">
      <div className="flex items-center justify-start p-4 bg-secondary-secondary-bg">
        <button
          className="relative p-1 bg-primary-black"
          onClick={() => setActiveContent("conversations")}
        >
          <span className="sr-only">Arrow</span>
          <svg role="img" className="w-6 h-6 text-white link-icon">
            <use xlinkHref="/assets/img/necromancers.svg#arrow-left" />
          </svg>
        </button>
        <div className="w-12 h-12 ml-6 overflow-hidden rounded-full">
          {friendForMessageDetails?.profile_image ? (
            <img
              src={friendForMessageDetails?.profile_image}
              alt={friendForMessageDetails?.name}
              className="w-full h-full object-cover object-center"
            />
          ) : (
            <svg
              role="img"
              className="w-full h-full object-cover object-center"
            >
              <use xlinkHref="/assets/img/necromancers.svg#user" />
            </svg>
          )}
        </div>
        <Link
          to={`/user/${friendForMessageDetails?.id}`}
          onClick={() => dispatch(SET_MESSAGES(false))}
        >
          <p className="ml-4 text-body font-body-s">
            {friendForMessageDetails?.name}
          </p>
        </Link>
      </div>
      <div
        className="flex flex-col overflow-y-auto h-max lg:max-h-[36rem]"
        ref={scrollbar}
      >
        <div className="flex flex-col p-4">
          <div className="flex flex-col">
            {messages?.map((msg, index) => {
              const { createdAt, from, message } = msg?.toJSON();
              if (from === friendForMessageDetails?.id) {
                if (
                  messages?.[index]?.get("from") !==
                  messages?.[index + 1]?.get("from")
                ) {
                  return (
                    <div className="flex w-full" key={msg?.id}>
                      <div className="flex flex-col-reverse w-12 h-12 mr-3 overflow-hidden rounded-full bg-secondary-titles items-center justify-center">
                        {friendForMessageDetails?.profile_image ? (
                          <img
                            src={friendForMessageDetails?.profile_image}
                            alt={friendForMessageDetails?.name}
                            className="object-center object-cover w-full h-full"
                          />
                        ) : (
                          <svg role="img" className="w-8 h-8 text-white">
                            <use xlinkHref="/assets/img/necromancers.svg#user" />
                          </svg>
                        )}
                      </div>
                      <div className="w-full max-w-[231px]">
                        <div className="relative flex flex-col gap-3 p-3 bg-secondary-secondary-bg triangle-reverse">
                          <p>{message}</p>
                        </div>
                        {calculateTimePassed(createdAt)}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="flex w-full max-w-[231px] ml-[60px]"
                      key={msg?.id}
                    >
                      <div className="w-full mb-2">
                        <div className="relative flex flex-col gap-3 p-3 bg-secondary-secondary-bg">
                          <p>{message}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              } else {
                if (
                  messages?.[index - 1] &&
                  messages?.[index]?.get("to")?.id !==
                    messages?.[index + 1]?.get("to")?.id
                ) {
                  return (
                    <div className="flex justify-end w-full mt-2" key={msg?.id}>
                      <div className="w-full max-w-[247px]">
                        <div className="relative flex flex-col gap-3 p-3 bg-primary-black triangle-right">
                          <p className="text-white">{message}</p>
                        </div>
                        {calculateTimePassed(createdAt)}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex justify-end w-full mt-2" key={msg?.id}>
                      <div className="w-full max-w-[247px]">
                        <div className="relative flex flex-col gap-3 p-3 bg-primary-black">
                          <p className="text-white">{message}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            })}
          </div>
        </div>
        <div className="end w-full" style={{ height: 1 }} ref={endOfMessages} />
      </div>
      <div className="px-4 pb-8 flex">
        <div className="form-input-wrapper w-5/6">
          <input
            type="text"
            id="other"
            autoComplete="cc-given-other"
            name="other"
            placeholder=" "
            className="bg-transparent form-input"
            onChange={(e) => setMessageContent(e.target.value)}
            onKeyPress={(e) =>
              e.key === "Enter" &&
              sendMessageToFriend(friendForMessageDetails?.id, messageContent)
            }
            ref={messageInput}
          />
          <label htmlFor="other" className="bg-transparent form-label">
            Cevap Yaz
          </label>
        </div>
        <button
          type="button"
          id="commentButton"
          className="uppercase form-submit-btn w-1/6 h-14 flex align-center cursor-pointer disabled:opacity-75 disabled:cursor-wait"
          onClick={() =>
            sendMessageToFriend(friendForMessageDetails?.id, messageContent)
          }
          disabled={loading}
        >
          Gönder
        </button>
      </div>
    </div>
  );
}
