import React, { useState } from "react";
import { useSelector } from "react-redux";
import LoadingModal from "../../../components/shared/LoadingModal";
import { updateUserGallery } from "../../../services/contentService";
import FileAddModal from "./FileAddModal";

export default function AddNewImage({ gallery, setGallery }) {
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const appSlice = useSelector((state) => state?.app);
  const user = appSlice?.user;

  const upload = async () => {
    if (!user) throw new Error("Unauthorized");
    setLoading(true);
    try {
      const updatedGallery = await updateUserGallery({
        user,
        files,
        id: gallery?.objectId,
      });
      setGallery(updatedGallery);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
      setShowModal(false);
      setFiles([]);
    }
  };

  return (
    <>
      {showModal && (
        <FileAddModal
          setShowModal={(show) => setShowModal(show)}
          files={files}
          setFiles={(files) => setFiles(files)}
          onSubmit={() => upload(files)}
          shouldClearFilesOnClose={true}
          shouldClearFilesOnSubmit={true}
          mediaType="image"
        />
      )}
      {loading && <LoadingModal />}
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="inline-flex items-center px-6 py-3 mt-4 mr-6 text-white lg:mt-10 btn btn-primary bg-primary-red border-primary-red"
      >
        Fotoğraf Ekle
        <svg role="img" className="w-5 h-5 ml-3 text-white">
          <use xlinkHref="/assets/img/necromancers.svg#plus" />
        </svg>
      </button>
    </>
  );
}
