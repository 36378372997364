import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingModal from "../../components/shared/LoadingModal";
import AuthLayout from "../../layouts/AuthLayout";
import {
  getActivities,
  getActivityCategories,
} from "../../services/contentService";
import SingleActivity from "./components/SingleActivity";
import { Helmet } from "react-helmet";

const Activity = () => {
  const [activityCategories, setActivityCategories] = useState([]);
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.app.user);

  const handleGetActivityCategories = async () => {
    try {
      const response = await getActivityCategories();
      setActivityCategories(response);
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetActivities = async () => {
    setLoading(true);
    if (!user) return;
    try {
      const finalActivities = [];
      const response = await getActivities(user);
      const completedActivities = response.filter(
        (i) => new Date(i.activityDate.iso) < new Date()
      );

      completedActivities?.sort((a, b) => {
        if (a.activityDate.iso !== b.activityDate.iso) {
          return a.activityDate.iso > b.activityDate.iso ? -1 : 1;
        }
        return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1;
      });

      const uncompletedActivities = response.filter(
        (i) => new Date(i.activityDate.iso) > new Date()
      );
      uncompletedActivities?.sort((a, b) => {
        if (a.activityDate.iso !== b.activityDate.iso) {
          return a.activityDate.iso < b.activityDate.iso ? -1 : 1;
        }
        return new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1;
      });

      uncompletedActivities?.map((item) => {
        return finalActivities.push(item);
      });
      completedActivities?.map((item) => {
        return finalActivities.push(item);
      });
      setActivities([...finalActivities]);
      setFilteredActivities(finalActivities);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };
  // usememo

  const filterActivitiesByCategory = () => {
    if (!selectedCategory) {
      setFilteredActivities([...activities]);
    } else {
      const filteredActivities = activities.filter((activity) => {
        return activity?.category?.title === selectedCategory;
      });
      setFilteredActivities(filteredActivities);
    }
  };

  useEffect(() => {
    filterActivitiesByCategory();
    // eslint-disable-next-line
  }, [selectedCategory]);

  useEffect(() => {
    handleGetActivityCategories();
    handleGetActivities();
    //eslint-disable-next-line
  }, [user]);

  // useEffect(() => {
  //   if (filteredActivities === activities) {
  //     setSelectedCategory(null);
  //   }
  // }, [filteredActivities, activities]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div id="content" className="lg:p-0">
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  Etkinlikler
                </h1>
              </div>
            </main>
          </div>

          <div className="max-w-6xl px-4 mx-auto lg:px-0">
            <div className="flex flex-row gap-3 pt-12 mx-auto -mr-4 overflow-x-auto lg:pt-16 flex-nowrap whitespace-nowrap lg:-mr-0">
              <button
                className={`${
                  selectedCategory === null
                    ? "bg-primary-red text-primary-white"
                    : ""
                } px-6 py-2 border-primary-red btn text-primary-red hover:bg-primary-red hover:text-primary-white`}
                onClick={() => {
                  setFilteredActivities([...activities]);
                  setSelectedCategory(null);
                }}
              >
                {String("Tüm Etkinlikler").toLocaleUpperCase("tr-TR")}
              </button>
              {activityCategories?.map((category) => (
                <button
                  key={category.title}
                  onClick={() => handleSelectCategory(category?.title)}
                  // className="px-6 py-2 border-primary-red btn text-primary-red hover:bg-primary-red hover:text-primary-white"
                  className={`${
                    selectedCategory === category?.title
                      ? "bg-primary-red text-primary-white"
                      : ""
                  } px-6 py-2 border-primary-red btn text-primary-red hover:bg-primary-red hover:text-primary-white`}
                >
                  {String(category?.title)?.toLocaleUpperCase("tr-TR")}
                </button>
              ))}
            </div>
          </div>
          <div className="px-4 mx-auto my-12 max-w-7xl lg:px-1">
            <div className="flex flex-col gap-6 px-4">
              {selectedCategory === null ? (
                activities.length > 0 ? (
                  activities?.map((activity) => (
                    <SingleActivity
                      activity={activity}
                      key={activity.objectId}
                    />
                  ))
                ) : (
                  <div className="flex flex-col gap-6 px-4">
                    <h1 className="text-center text-md font-md">
                      Etkinlik bulunamadı
                    </h1>
                    <p className="text-center text-secondary-secondary font-md">
                      Bir etkinlik bulunamadı.
                    </p>
                  </div>
                )
              ) : filteredActivities.length > 0 ? (
                filteredActivities?.map((activity) => (
                  <SingleActivity activity={activity} key={activity.objectId} />
                ))
              ) : (
                filteredActivities.length === 0 && (
                  <div className="flex flex-col gap-6 px-4">
                    <h1 className="text-center text-md font-md">
                      Etkinlik bulunamadı
                    </h1>
                    <p className="text-center text-secondary-secondary font-md">
                      Bir etkinlik bulunamadı.
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default Activity;
