import React, { useState } from "react";
import classNames from "classnames";
import Answer from "./Answer";

export default function CompletedExamModal(props) {
  const { examResults, setShowCompletedExamModal, questionCount, choices } =
    props;
  const [questionIndex, setQuestionIndex] = useState(0);

  return (
    <div
      className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain lg:align-middle"
      aria-labelledby="modal-title"
      aria-modal="true"
    >
      <div className="flex items-start justify-center min-h-full pb-20 text-center sm:p-0">
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          ​
        </span>
        <div className="min-h-screen inline-block align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-full sm:w-full sm:pb-16 sm:pt-12 sm:px-12 outline-none">
          <div className="absolute pt-4 pr-4 sm:block top-5 right-5">
            <button
              type="button"
              className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              onClick={() => setShowCompletedExamModal(false)}
            >
              <span className="sr-only">Close</span>
              <span className="sr-only">Profile</span>
              <svg role="img" className="w-6 h-6 text-primary-black">
                <use xlinkHref="/assets/img/necromancers.svg#cross" />
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="flex flex-col items-center w-full">
              <div className="w-full max-w-full lg:max-w-2xl ">
                {examResults?.map((answer, index) => {
                  if (questionIndex === index) {
                    return (
                      <Answer
                        answer={answer}
                        setQuestionIndex={(index) => setQuestionIndex(index)}
                        key={answer?.id}
                        questionCount={questionCount}
                        questionIndex={questionIndex}
                        choices={choices}
                      />
                    );
                  } else {
                    return <span key={index} className="hidden"></span>;
                  }
                })}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center gap-4 mt-8">
            {questionIndex !== 0 && (
              <button
                type="button"
                className={classNames(
                  questionIndex === 0 && "opacity-50",
                  "flex-1 px-6 form-submit-btn lg:flex-none lg:w-20 revert"
                )}
                onClick={() => setQuestionIndex(questionIndex - 1)}
              >
                GERİ
              </button>
            )}
            <button
              type="button"
              className={classNames(
                questionIndex === examResults?.length - 1 && "opacity-50",
                "flex-1 px-6 uppercase form-submit-btn lg:flex-none lg:w-32"
              )}
              disabled={questionIndex === examResults?.length - 1}
              onClick={() =>
                questionIndex !== examResults.length - 1 &&
                setQuestionIndex(questionIndex + 1)
              }
            >
              Devam Et
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
