import React, { useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useForm } from "react-hook-form";
import { changeBayiPassword, logout } from "../../services/authService.js";
import LoadingModal from "../../components/shared/LoadingModal";
import Modal from "../../components/shared/Modal";
import { Helmet } from "react-helmet";
import classNames from "classnames";
function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onBlur",
  });
  const onSubmit = (data) => {
    setLoading(true);
    changeBayiPassword(data)
      .then(() => {
        setLoading(false);
        setSuccessModal(true);
        logout();
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setErrMsg(e.message);
        setErrorModal(true);
      });
  };
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content ">
          {loading && <LoadingModal closeButton={false} />}
          {successModal && (
            <Modal
              type="success"
              msg="Şifre Güncelleme İşlemi Başarılı"
              updateModalState={(state) => setSuccessModal(state)}
              shouldShow={successModal}
            />
          )}
          {errorModal && (
            <Modal
              type="error"
              msg={errMsg}
              updateModalState={(state) => setErrorModal(state)}
              shouldShow={errorModal}
            />
          )}
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto lg:px-1 max-w-7xl">
              <div className="flex flex-col justify-between lg:flex-row lg:items-center">
                <h1
                  data-testid="change-password-title"
                  className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0"
                >
                  Şifre Değiştir
                </h1>
                <h6>Lütfen en az 8 karakter olarak giriniz.</h6>
              </div>
            </main>
          </div>

          <div className="max-w-sm px-4 mx-auto my-12 lg:my-16 lg:px-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-8 form-input-wrapper">
                <input
                  {...register("oldPassword", {
                    required: {
                      value: true,
                      message: "Lütfen şifrenizi giriniz.",
                    },
                    // minLength: {
                    //   value: 8,
                    //   message: "Şifreniz en az 8 karakter olmalıdır.",
                    // },
                  })}
                  id="oldPassword"
                  type={hideCurrentPassword ? "password" : "text"}
                  name="oldPassword"
                  placeholder=" "
                  className="bg-transparent form-input"
                  // data-testid="old-password-input"
                />
                <label
                  htmlFor="oldPassword"
                  className="bg-transparent form-label"
                >
                  Mevcut Şifre
                </label>
                {hideCurrentPassword ? (
                  <button
                    type="button"
                    className={classNames(
                      errors?.oldPassword
                        ? "absolute top-1/4"
                        : "absolute top-1/3",
                      "right-3"
                    )}
                    onClick={() => setHideCurrentPassword(false)}
                  >
                    <svg
                      role="img"
                      className="w-5 h-5 text-secondary-secondary cursor-pointer"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#hide-password" />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    className={classNames(
                      errors?.oldPassword
                        ? "absolute top-1/4"
                        : "absolute top-1/3",
                      "right-3"
                    )}
                    onClick={() => setHideCurrentPassword(true)}
                  >
                    <svg
                      role="img"
                      className="w-5 h-5 text-secondary-secondary"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#unhide-password" />
                    </svg>
                  </button>
                )}

                {errors?.oldPassword && (
                  <p
                    role="alert"
                    className="mt-2 text-validation-error"
                    id="email-error"
                  >
                    {errors?.oldPassword.message}
                  </p>
                )}
              </div>
              <div className="mb-8 form-input-wrapper">
                <input
                  {...register("newPassword", {
                    required: {
                      value: true,
                      message: "Lütfen şifrenizi giriniz.",
                    },
                    minLength: {
                      value: 8,
                      message: "Şifreniz en az 8 karakter olmalıdır.",
                    },
                  })}
                  id="newPassword"
                  type={hideNewPassword ? "password" : "text"}
                  name="newPassword"
                  placeholder=" "
                  className="bg-transparent form-input"
                />
                <label
                  htmlFor="newPassword"
                  className="bg-transparent form-label"
                >
                  Yeni Şifre
                </label>
                {hideNewPassword ? (
                  <button
                    type="button"
                    className={classNames(
                      errors?.newPassword
                        ? "absolute top-1/4"
                        : "absolute top-1/3",
                      "right-3"
                    )}
                    onClick={() => setHideNewPassword(false)}
                  >
                    <svg
                      role="img"
                      className="w-5 h-5 text-secondary-secondary"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#hide-password" />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setHideNewPassword(true)}
                    className={classNames(
                      errors?.newPassword
                        ? "absolute top-1/4"
                        : "absolute top-1/3",
                      "right-3"
                    )}
                  >
                    <svg
                      role="img"
                      className="w-5 h-5 text-secondary-secondary"
                      onClick={() => setHideNewPassword(true)}
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#unhide-password" />
                    </svg>
                  </button>
                )}
                {errors?.newPassword && (
                  <div
                    role="alert"
                    className="mt-2 text-validation-error"
                    id="email-error"
                  >
                    {errors?.newPassword.message}
                  </div>
                )}
              </div>
              <div className="mb-8 form-input-wrapper">
                <input
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message: "Lütfen şifrenizi giriniz.",
                    },
                    minLength: {
                      value: 8,
                      message: "Şifreniz en az 8 karakter olmalıdır.",
                    },
                    validate: (value) => {
                      const { newPassword } = getValues();
                      if (newPassword !== value) {
                        return newPassword === value || "Şifreler uyuşmuyor";
                      }
                    },
                  })}
                  id="confirmPassword"
                  type={hideConfirmPassword ? "password" : "text"}
                  name="confirmPassword"
                  placeholder=" "
                  className="bg-transparent form-input"
                />
                <label
                  htmlFor="confirmPassword"
                  className="bg-transparent form-label"
                >
                  Yeni Şifre Tekrar
                </label>
                {hideConfirmPassword ? (
                  <button
                    type="button"
                    onClick={() => setHideConfirmPassword(false)}
                    className={classNames(
                      errors?.confirmPassword
                        ? "absolute top-1/4"
                        : "absolute top-1/3",
                      "right-3"
                    )}
                  >
                    <svg
                      role="img"
                      className="w-5 h-5 text-secondary-secondary"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#hide-password" />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setHideConfirmPassword(true)}
                    className={classNames(
                      errors?.confirmPassword
                        ? "absolute top-1/4"
                        : "absolute top-1/3",
                      "right-3"
                    )}
                  >
                    <svg
                      role="img"
                      className="w-5 h-5 text-secondary-secondary"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#unhide-password" />
                    </svg>
                  </button>
                )}
                {errors?.confirmPassword && (
                  <p
                    role="alert"
                    className="mt-2 text-validation-error"
                    id="email-error"
                  >
                    {errors?.confirmPassword.message}
                  </p>
                )}
              </div>
              <div className="lg:px-0 w-full lg:max-w-[12rem] items-center flex mx-auto mt-16">
                <button
                  type="submit"
                  className="w-full max-w-full uppercase form-submit-btn"
                >
                  KAYDET
                </button>
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default ChangePassword;
