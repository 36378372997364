import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingModal from "../../components/shared/LoadingModal";
import Modal from "../../components/shared/Modal";
import DefaultLayout from "../../layouts/DefaultLayout";
import {
  responseToFriendRequest,
  getFriendReqFriendId,
} from "../../services/contentService";
import { Helmet } from "react-helmet";

const VerifyFriendReq = () => {
  const [loading, setLoading] = useState(true);
  const [showErrModal, setShowErrModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { id } = useParams();

  const getReq = async () => {
    try {
      setLoading(true);
      // promises.push(getFriendReqFriendId(id));
      // const result = await getFriendRequest(id);
      const result = await getFriendReqFriendId(id);
      const friendId = result.user.objectId;
      await responseToFriendRequest(friendId, "accept");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrMsg(error.message);
      console.log(error);
    }
  };
  useEffect(() => {
    try {
      getReq();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <DefaultLayout>
        {loading && <LoadingModal />}
        {showErrModal && (
          <Modal
            shouldShow={showErrModal}
            msg={errMsg || "Bir hata oluştu. Lütfen tekrar deneyiniz."}
            onClose={() => setShowErrModal(false)}
            updateModalState={(state) => setShowErrModal(state)}
          />
        )}
        <div className="text-center my-5" style={{ minHeight: "79vh" }}>
          <div className="py-5">
            <svg role="img" className="mx-auto" width={75} height={75}>
              <use xlinkHref="/assets/img/necromancers.svg#check" />
            </svg>
            <div className="my-10">Arkadaş Ekleme Başarılı.</div>
            <Link to={`/user/${id}`} className="btn with-icon">
              <span>Ana Sayfa</span>
            </Link>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default VerifyFriendReq;
