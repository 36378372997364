import React, { useEffect, useState } from "react";
import About from "./components/About";
import Communication from "./components/Communication";
import Faq from "./components/Faq";
import Login from "./components/Login";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getNonLoggedInContent } from "../../services/contentService";
import ForgotPassword from "./components/ForgotPassword";
import { Helmet } from "react-helmet";

export default function NonLoginHomePage() {
  const [content, setContent] = useState(null);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const result = await getNonLoggedInContent();
        setContent(result);
      } catch (err) {
        console.log(err);
      }
    })();

    //cleanup func
    return () => {
      setContent(null);
    };
  }, []);

  useEffect(() => {
    setShowForgotPasswordModal(false);
    // cleanup func
    return () => {
      setShowForgotPasswordModal(false);
    };
  }, [isConfirmed]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <DefaultLayout>
        <Login
          showForgotPasswordModal={showForgotPasswordModal}
          setShowForgotPasswordModal={setShowForgotPasswordModal}
          bannerImage={content?.banner}
          bannerBackground={content?.banner_background}
          bannerLink={content?.banner_link}
        />
        <About
          image={content?.about_kale_image}
          subtitle={content?.about_kale_subtitle}
          title={content?.about_kale_title}
          text={content?.about_kale_text}
        />
        <Faq faq={content?.faq} faqText={content?.faq_text} />
        <Communication />
        <ForgotPassword
          showForgotPasswordModal={showForgotPasswordModal}
          setShowForgotPasswordModal={setShowForgotPasswordModal}
          setIsConfirmed={(isConfirmed) => setIsConfirmed(isConfirmed)}
        />
      </DefaultLayout>
    </>
  );
}
