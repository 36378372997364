import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_SOCIAL,
  SET_ABOUT,
  SET_CONTACT,
  SET_MOBILE_MENU,
  // SET_OVERLAY,
  TOGGLE_SEARCH,
  // TOGGLE_NOTIFICATIONS,
  TOGGLE_MOBILE_MENU,
  // TOGGLE_MESSAGES,
  TOGGLE_PROFILE,
  SET_MESSAGES,
  SET_PROFILE,
  SET_NOTIFICATIONS,
} from "../../../store/header/headerSlice";
import classNames from "classnames";
import Notifications from "./Notifications";
import Messages from "./Messages/Messages";
import Search from "./Search";
import Profile from "./Profile";
import { Link } from "react-router-dom";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { SET_MESSAGE_USER } from "../../../store/message/messageSlice";

const HeaderPrivate = () => {
  const headerSlice = useSelector((state) => state.header);

  // const unreadConversationsCount = useSelector(
  //   (state) => state.message.unreadConversationsCount
  // );

  const dispatch = useDispatch();

  const profileModal = useRef();
  const messageModal = useRef();
  const notificationModal = useRef();

  useOnClickOutside(profileModal, (e) => {
    switch (e.target.tagName) {
      case "svg":
        return;
      case "path":
        return;
      case "button":
        return;
      default:
        dispatch(SET_PROFILE(false));
    }
  });

  useOnClickOutside(messageModal, (e) => {
    switch (e.target.tagName) {
      case "svg":
        return;
      case "path":
        return;
      case "button":
        return;
      default:
        dispatch(SET_MESSAGES(false));
        dispatch(SET_MESSAGE_USER(null));
    }
  });

  useOnClickOutside(notificationModal, (e) => {
    switch (e.target.tagName) {
      case "svg":
        return;
      case "path":
        return;
      case "button":
        return;
      default:
        dispatch(SET_NOTIFICATIONS(false));
    }
  });

  return (
    <div className="">
      <header
        data-testid="header-component"
        className={classNames("relative z-50 h-20 bg-white hidden lg:block ")}
      >
        <nav
          className="bg-white"
          onMouseLeave={() => {
            dispatch(SET_MOBILE_MENU(false));
          }}
        >
          <div className="relative px-1 mx-auto max-w-7xl">
            <div className="flex justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex items-center flex-shrink-0 py-4">
                  <Link to="/">
                    <span className="sr-only">Kale Bayi Kulubü</span>
                    <img
                      alt="kale logo"
                      className="responsive w-[4.5rem]"
                      src="/assets/img/logo.png"
                    />
                  </Link>
                </div>
              </div>
              <div className="hidden lg:ml-6 lg:flex lg:space-x-16 lg:justify-center">
                <a
                  onMouseOver={() => {
                    dispatch(SET_SOCIAL(true));
                    dispatch(SET_ABOUT(false));
                    dispatch(SET_CONTACT(false));
                    // dispatch(SET_OVERLAY(true));
                    dispatch(SET_MOBILE_MENU(true));
                  }}
                  href="/"
                  className={classNames(
                    "inline-flex items-center px-1 py-4 border-b-2 border-transparent",
                    {
                      "border-primary-red": headerSlice.social,
                      "border-transparent": !headerSlice.social,
                    }
                  )}
                >
                  Sosyal Kale
                  <span className="ml-2 pointer-events-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 16L5 9.15113L6.17654 8L12 13.6977L17.8235 8L19 9.15113L12 16Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </a>
                <Link
                  to="/activity"
                  className="inline-flex items-center px-1 py-4 border-b-2 border-transparent"
                >
                  Etkinlikler
                </Link>
                <Link
                  to="/about"
                  className={classNames(
                    "inline-flex items-center px-1 py-4 border-b-2 border-transparent",
                    {
                      "border-primary-red": headerSlice.about,
                      "border-transparent": !headerSlice.about,
                    }
                  )}
                  onMouseOver={() => {
                    dispatch(SET_ABOUT(true));
                    dispatch(SET_SOCIAL(false));
                    dispatch(SET_CONTACT(false));
                    // dispatch(SET_OVERLAY(true));
                    dispatch(SET_MOBILE_MENU(true));
                  }}
                >
                  Hakkımızda
                  <span className="ml-2 pointer-events-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 16L5 9.15113L6.17654 8L12 13.6977L17.8235 8L19 9.15113L12 16Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </Link>
                <Link
                  onMouseOver={() => {
                    dispatch(SET_CONTACT(true));
                    dispatch(SET_SOCIAL(false));
                    dispatch(SET_ABOUT(false));
                    // dispatch(SET_OVERLAY(true));
                    dispatch(SET_MOBILE_MENU(true));
                  }}
                  to="/contact"
                  className={classNames(
                    "inline-flex items-center px-1 py-4 border-b-2 border-transparent",
                    {
                      "border-primary-red": headerSlice.contact,
                      "border-transparent": !headerSlice.contact,
                    }
                  )}
                >
                  İletişim
                  <span className="ml-2 pointer-events-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 16L5 9.15113L6.17654 8L12 13.6977L17.8235 8L19 9.15113L12 16Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </Link>
              </div>
              <div className="flex items-center lg:hidden">
                {/*Mobile menu button */}
                <button
                  onClick={() => dispatch(TOGGLE_MOBILE_MENU())}
                  type="button"
                  className="inline-flex items-center justify-center p-2 focus:outline-none"
                  aria-controls="mobile-menu"
                >
                  <span className="sr-only">Open main menu</span>
                  <span
                    className={classNames("block p-2", {
                      hidden: headerSlice.mobileMenu,
                      block: !headerSlice.mobileMenu,
                    })}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22 17V19H2V17H22ZM22 11V13H2V11H22ZM22 5V7H2V5H22Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span
                    className={classNames("block p-2 bg-primary-black", {
                      block: headerSlice.mobileMenu,
                      hidden: !headerSlice.mobileMenu,
                    })}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="hidden space-x-6 lg:ml-4 lg:flex lg:items-center">
                <button
                  onClick={() => {
                    dispatch(TOGGLE_SEARCH());
                    dispatch(SET_MOBILE_MENU(false));
                  }}
                  type="button"
                  className="flex-shrink-0 h-20 p-1 py-4 bg-white border-b-2 border-transparent"
                >
                  <span className="sr-only">View Search</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.82989 2C14.1542 2 17.6598 5.50903 17.6598 9.83768C17.6598 11.6269 17.0609 13.276 16.0528 14.5952L16.0158 14.6429L22 20.6121L20.6186 22L14.6317 16.0283L14.5827 16.0668C13.2648 17.0759 11.6173 17.6754 9.82989 17.6754C5.50504 17.6754 2 14.1663 2 9.83768C2 5.50903 5.50504 2 9.82989 2ZM9.83021 3.95942C6.58671 3.95942 3.95752 6.59123 3.95752 9.83768C3.95752 13.0841 6.58671 15.7159 9.83021 15.7159C13.0732 15.7159 15.7023 13.0841 15.7023 9.83768C15.7023 6.59123 13.0732 3.95942 9.83021 3.95942Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                {/* <button
                  onClick={() => {
                    dispatch(TOGGLE_NOTIFICATIONS());
                    dispatch(SET_MESSAGES(false));
                    dispatch(SET_PROFILE(false));
                    dispatch(SET_MOBILE_MENU(false));
                  }}
                  type="button"
                  className={classNames(
                    "flex-shrink-0 h-20 p-1 py-4 bg-white border-b-2 border-transparent",
                    {
                      "border-primary-red": headerSlice.notifications,
                      "border-transparent": !headerSlice.notifications,
                    }
                  )}
                >
                  <span className="sr-only">View Notifications</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5715 22.0971C11.447 21.9729 11.3492 21.8262 11.2824 21.6662H13.7175C13.6507 21.8262 13.553 21.9729 13.4284 22.0971C13.1807 22.344 12.847 22.4819 12.5 22.4819C12.1529 22.4819 11.8192 22.344 11.5715 22.0971Z"
                      stroke="currentColor"
                    />
                    <path
                      d="M19.6205 18.174V10.44C19.6205 6.68403 16.7524 3.60305 13.1015 3.2955V2H11.8987V3.2955C8.24774 3.60305 5.37955 6.68403 5.37955 10.44V18.174H4V19.3852H21.0002V18.174H19.6205ZM18.4177 18.174H6.58246V10.44C6.58246 7.15418 9.23752 4.48097 12.5 4.48097C15.7625 4.48097 18.4177 7.15455 18.4177 10.44V18.174Z"
                      fill="currentColor"
                      stroke="currentColor"
                    />
                  </svg>
                </button> */}
                {/* <button
                  onClick={() => {
                    dispatch(TOGGLE_MESSAGES());
                    dispatch(SET_MOBILE_MENU(false));
                    dispatch(SET_NOTIFICATIONS(false));
                    dispatch(SET_PROFILE(false));
                  }}
                  type="button"
                  className={classNames(
                    "flex-shrink-0 h-20 p-1 py-4 bg-white border-b-2 border-transparent relative",
                    {
                      "border-primary-red":
                        headerSlice.messages ||
                        headerSlice.newMessages ||
                        headerSlice.messenger,
                      "border-transparent":
                        !headerSlice.messages &&
                        !headerSlice.newMessages &&
                        !headerSlice.messenger,
                    }
                  )}
                >
                  <span className="sr-only">View Messages</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22 4V20H2V4H22ZM4 8.17235V18H20.0002V8.18655L12.1831 13.1793L4 8.17235ZM19.7082 6H4.28174L12.16 10.8207L19.7082 6Z"
                      fill="currentColor"
                    />
                  </svg>
                  {unreadConversationsCount > 0 && (
                    <span
                      className="rounded-full w-5 h-5 bg-red-600 text-white absolute bottom-5 right-0 flex flex-col justify-center font-semibold"
                      style={{ fontSize: 14 }}
                    >
                      {unreadConversationsCount}
                    </span>
                  )}
                </button> */}
                <button
                  onClick={() => {
                    dispatch(TOGGLE_PROFILE());
                    dispatch(SET_MOBILE_MENU(false));
                    dispatch(SET_MESSAGES(false));
                    dispatch(SET_NOTIFICATIONS(false));
                  }}
                  type="button"
                  className={classNames(
                    "flex-shrink-0 h-20 p-1 py-4 bg-white border-b-2 border-transparent",
                    {
                      "border-primary-red": headerSlice.profile,
                      "border-transparent": !headerSlice.profile,
                    }
                  )}
                >
                  <span className="sr-only">Profile</span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 14C16.937 14 21.0548 17.4355 22 22H19.9062C18.9999 18.5496 15.8038 16 12 16C8.19615 16 5.00011 18.5496 4.09384 22H2C2.94523 17.4355 7.06302 14 12 14ZM11.9998 2C14.8177 2 17.1018 4.2386 17.1018 7.00003C17.1018 9.7614 14.8177 12 11.9998 12C9.18186 12 6.89783 9.7614 6.89783 7.00003C6.89783 4.2386 9.18186 2 11.9998 2ZM12.0001 4C10.3096 4 8.93868 5.34316 8.93868 7.00003C8.93868 8.65684 10.3096 10 12.0001 10C13.6906 10 15.0615 8.65684 15.0615 7.00003C15.0615 5.34316 13.6906 4 12.0001 4Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/*Notification modal*/}
            {headerSlice.notifications && (
              <Notifications notificationModal={notificationModal} />
            )}
            {/*messages modal*/}
            {headerSlice.messages && <Messages messageModal={messageModal} />}
            {/*  Search */}
            {headerSlice.search && <Search />}
            {/*Profile Modal*/}
            {headerSlice.profile && <Profile profileModal={profileModal} />}
            {/*modals will come here*/}
          </div>

          {/*MENU*/}
          {headerSlice.mobileMenu && (
            <div
              className="absolute inset-0 z-30 h-screen p-6 bg-white lg:h-full top-20 lg:top-0 lg:relative"
              aria-modal="true"
              // style={{ display: "none" }}
            >
              <div className="flex flex-col w-full max-h-full mx-auto max-w-7xl">
                <div className="space-y-1">
                  <dl>
                    {headerSlice.social && (
                      <div data-testid="social-menu">
                        <dt
                          className={classNames(
                            "text-lg border-b-2 border-transparent lg:hidden",
                            {
                              "border-primary-red": headerSlice.social,
                              "border-transparent": !headerSlice.social,
                            }
                          )}
                        >
                          <button
                            type="button"
                            className="flex items-start justify-between w-full py-8 text-left"
                            aria-controls="menu-0"
                          >
                            <span className="text-body-l">Sosyal Kale</span>
                            <span className="flex ml-6">
                              <span
                                className={classNames(
                                  "w-6 h-6 transform rotate-180",
                                  {
                                    "rotate-180": headerSlice.social,
                                    "rotate-0": !headerSlice.social,
                                  }
                                )}
                              >
                                <svg
                                  role="img"
                                  className="link-icon text-primary-black"
                                >
                                  <use xlinkHref="/assets/img/necromancers.svg#arrow-bottom" />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </dt>
                        <dd className="-ml-8 -mr-8 overflow-y-auto lg:overflow-hidden">
                          <div
                            className="flex justify-start w-full lg:justify-evenly menu-item"
                            onMouseLeave={() => dispatch(SET_SOCIAL(false))}
                          >
                            <div className="flex flex-col items-center flex-shrink-0">
                              <Link
                                to="/kale-wall"
                                className="py-4 space-y-6 px-9"
                              >
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-red">
                                  <svg
                                    role="img"
                                    className="link-icon text-primary-black w-10 h-10"
                                  >
                                    <use xlinkHref="/assets/img/necromancers.svg#social" />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Kale Duvarı
                                </p>
                              </Link>
                            </div>
                            <div className="flex flex-col items-center flex-shrink-0">
                              <Link
                                to="/gallery"
                                className="py-4 space-y-6 px-9"
                              >
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-blue">
                                  <svg
                                    role="img"
                                    className="text-primary-black link icon w-10 h-10"
                                  >
                                    <use xlinkHref="/assets/img/necromancers.svg#gallery" />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Galeri
                                </p>
                              </Link>
                            </div>
                            <div className="flex flex-col items-center flex-shrink-0">
                              <Link
                                to="/library"
                                className="py-4 space-y-6 px-9"
                              >
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-purple">
                                  <svg
                                    role="img"
                                    className="text-primary-black link icon w-10 h-10"
                                  >
                                    <use xlinkHref="/assets/img/necromancers.svg#book-alternative" />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Kütüphane
                                </p>
                              </Link>
                            </div>
                            <div className="flex flex-col items-center flex-shrink-0">
                              <Link
                                to="/information-cube"
                                className="py-4 space-y-6 px-9"
                              >
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-teal">
                                  <svg
                                    role="img"
                                    className="text-primary-black link icon w-10 h-10"
                                  >
                                    <use xlinkHref="/assets/img/necromancers.svg#competition" />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Yarışma
                                </p>
                              </Link>
                            </div>
                            <div className="flex flex-col items-center flex-shrink-0">
                              <Link
                                to="/points"
                                className="py-4 space-y-6 px-9"
                              >
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-red">
                                  <svg
                                    role="img"
                                    className="text-primary-black link icon w-9 h-9"
                                  >
                                    <use xlinkHref="/assets/img/necromancers.svg#pie" />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Kale Puan
                                </p>
                              </Link>
                            </div>
                            {/* <div className="flex flex-col items-center flex-shrink-0">
                              <Link
                                to="/friends"
                                className="py-4 space-y-6 px-9"
                              >
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-blue">
                                  <svg
                                    role="img"
                                    className="text-primary-black link icon w-12 h-12"
                                  >
                                    <use xlinkHref="/assets/img/necromancers.svg#friends" />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Arkadaşlar
                                </p>
                              </Link>
                            </div> */}
                          </div>
                        </dd>
                      </div>
                    )}
                    {headerSlice.about && (
                      <div data-testid="about-menu">
                        <dt
                          className={classNames(
                            "text-lg border-b-2 border-transparent lg:hidden",
                            {
                              "border-primary-red": headerSlice.about,
                              "border-transparent": !headerSlice.about,
                            }
                          )}
                        >
                          <button
                            type="button"
                            className="flex items-start justify-between w-full py-8 text-left"
                            aria-controls="menu-1"
                          >
                            <span className="text-body-l">Hakkımızda</span>
                            <span className="flex ml-6">
                              <span
                                className={classNames(
                                  "w-6 h-6 transform rotate-180",
                                  {
                                    "rotate-180": headerSlice.about,
                                    "rotate-0": !headerSlice.about,
                                  }
                                )}
                              >
                                {/*        arrow-bottom icon */}
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 16L5 9.15113L6.17654 8L12 13.6977L17.8235 8L19 9.15113L12 16Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </dt>
                        <dd className="mt-2">
                          <div
                            className="flex justify-center w-full menu-item lg:gap-20"
                            onMouseLeave={() => dispatch(SET_CONTACT())}
                          >
                            <div className="flex flex-col items-center">
                              <Link to="/about" className="px-6 py-4 space-y-6">
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-red">
                                  {/*kale-line  */}
                                  <svg
                                    className="link-icon text-primary-black "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18.0257 5.37831H18.7757V4.62831V2.75H21.25V21.25H2.75045L2.76147 2.75H5.26478V4.62831V5.37831H6.01478H10.02H10.77V4.62831V2.75H13.2729V4.62831V5.37831H14.0229H18.0257Z"
                                      stroke="currentColor"
                                      strokeWidth="1.5"
                                    />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Kale Hakkında
                                </p>
                              </Link>
                            </div>
                            <div className="flex flex-col items-center">
                              <Link to="/news" className="px-6 py-4 space-y-6">
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-blue">
                                  {/*news */}
                                  <svg
                                    className="link-icon text-primary-black "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.33333 4V18.3158C5.33333 18.5531 5.24554 18.7807 5.08926 18.9485C4.93298 19.1163 4.72101 19.2105 4.5 19.2105C4.27899 19.2105 4.06702 19.1163 3.91074 18.9485C3.75446 18.7807 3.66667 18.5531 3.66667 18.3158V5.78947H2V18.3158C2 19.0277 2.26339 19.7104 2.73223 20.2138C3.20107 20.7172 3.83696 21 4.5 21H19.5C20.163 21 20.7989 20.7172 21.2678 20.2138C21.7366 19.7104 22 19.0277 22 18.3158V4H5.33333ZM20.3333 18.3158C20.3333 18.5531 20.2455 18.7807 20.0893 18.9485C19.933 19.1163 19.721 19.2105 19.5 19.2105H6.85833C6.95252 18.9231 7.00042 18.6205 7 18.3158V5.78947H20.3333V18.3158ZM14.5 7.57895H8.66667V13.8421H14.5V7.57895ZM12.8333 12.0526H10.3333V9.36842H12.8333V12.0526ZM18.6667 15.6316H8.66667V17.4211H18.6667V15.6316ZM18.6667 12.0526H16.1667V13.8421H18.6667V12.0526Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Haberler
                                </p>
                              </Link>
                            </div>
                          </div>
                        </dd>
                      </div>
                    )}
                    {headerSlice.contact && (
                      <div data-testid="contact-menu">
                        <dt
                          className={classNames(
                            "text-lg border-b-2 border-transparent lg:hidden",
                            {
                              "border-primary-red": headerSlice.contact,
                              "border-transparent": !headerSlice.contact,
                            }
                          )}
                        >
                          <button
                            type="button"
                            className="flex items-start justify-between w-full py-8 text-left"
                            aria-controls="meun-2"
                          >
                            <span className="text-body-l">İletişim</span>
                            <span className="flex ml-6">
                              <span
                                className={classNames(
                                  "w-6 h-6 transform rotate-180",
                                  {
                                    "rotate-180": headerSlice.contact,
                                    "rotate-0": !headerSlice.contact,
                                  }
                                )}
                              >
                                {/*arrow-bottom*/}
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12 16L5 9.15113L6.17654 8L12 13.6977L17.8235 8L19 9.15113L12 16Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </dt>
                        <dd className="mt-2">
                          <div
                            className="flex justify-center w-full menu-item lg:gap-20"
                            onMouseLeave={() => dispatch(SET_ABOUT())}
                          >
                            <div className="flex flex-col items-center">
                              <Link to="/contact" className="py-4 space-y-6">
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-purple">
                                  {/*mail-opened */}
                                  <svg
                                    className="link-icon text-primary-black "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.9997 2L21.9994 8V22H2V8L11.9997 2ZM3.99973 10.1724V20H19.9997V10.1866L12.1827 15.1793L3.99973 10.1724ZM11.9997 4.33241L5.07648 8.4864L12.1597 12.8207L18.9347 8.49353L11.9997 4.33241Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Bize Ulaşın
                                </p>
                              </Link>
                            </div>
                            <div className="flex flex-col items-center">
                              <Link to="/faq" className="py-4 space-y-6">
                                <p className="w-16 h-16 mx-auto link-icon-wrapper bg-background-teal">
                                  {/*faq */}
                                  <svg
                                    className="link-icon text-primary-black "
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.24051 4.62807C3.4302 6.28479 2.2944 8.54853 2.04974 10.9875C1.80508 13.4264 2.4687 15.8699 3.91401 17.8519C5.35931 19.8338 7.48516 21.2154 9.88604 21.7333C12.2869 22.2511 14.7948 21.8689 16.9313 20.6595L17.7195 20.9073L21.1979 22L20.409 18.4491L20.2302 17.6438C21.6266 15.625 22.2288 13.1637 21.9216 10.7299C21.6145 8.2961 20.4195 6.06056 18.5651 4.45034C16.7107 2.84012 14.3268 1.96811 11.8688 2.00089C9.41084 2.03368 7.05114 2.96896 5.24051 4.62807ZM3.44427 12.4872C3.44427 12.4685 3.44427 12.449 3.44079 12.4303C3.43731 12.4116 3.44079 12.3782 3.44079 12.3519C3.44079 12.3067 3.44079 12.2609 3.43592 12.213C3.43592 12.1866 3.43592 12.1603 3.43592 12.1339C3.43592 12.0894 3.43592 12.0443 3.43592 11.9999C3.43592 11.9936 3.43592 11.9874 3.43592 11.9818C3.43592 11.9555 3.43592 11.9298 3.43592 11.9041C3.43592 11.8666 3.43592 11.8284 3.43592 11.7909C3.43592 11.7534 3.43593 11.7097 3.43941 11.6694C3.43941 11.6424 3.43941 11.6146 3.43941 11.5868C3.43941 11.5375 3.44427 11.4876 3.44775 11.4383C3.44775 11.4216 3.44775 11.4043 3.44775 11.3876C3.45193 11.3284 3.45679 11.2694 3.46236 11.2106V11.1932C3.58445 9.87481 4.01112 8.60258 4.70888 7.47643C5.40664 6.35027 6.35649 5.40084 7.48386 4.70267C8.61123 4.00451 9.88542 3.57661 11.2064 3.45258C12.5274 3.32855 13.8592 3.51177 15.0973 3.98784C16.3354 4.46391 17.446 5.21988 18.342 6.19639C19.2381 7.17291 19.895 8.34339 20.2614 9.61594C20.6278 10.8885 20.6936 12.2285 20.4537 13.5307C20.2137 14.8329 19.6746 16.0618 18.8785 17.1211L18.8862 17.1579L19.0949 18.0902L19.1645 18.3915L19.57 20.2298L17.7717 19.6654L17.1713 19.4772L16.5216 19.2738C15.2574 20.0522 13.8124 20.49 12.3279 20.5443C10.8433 20.5986 9.37012 20.2676 8.05218 19.5836C6.73424 18.8997 5.61669 17.8862 4.80877 16.6422C4.00086 15.3982 3.53023 13.9664 3.44288 12.4865L3.44427 12.4872Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.5"
                                      strokeMiterlimit="2.61"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.9917 16.8173C12.2022 16.818 12.4089 16.7615 12.5898 16.654C12.7681 16.5496 12.9166 16.4013 13.0212 16.2233C13.1279 16.0419 13.1841 15.8352 13.1841 15.6248C13.1841 15.4143 13.1279 15.2077 13.0212 15.0263C12.9164 14.8482 12.7679 14.6997 12.5898 14.5949C12.4084 14.4882 12.2018 14.432 11.9913 14.432C11.7809 14.432 11.5743 14.4882 11.3928 14.5949C11.2151 14.6999 11.0669 14.8483 10.9621 15.0263C10.8555 15.2077 10.7992 15.4143 10.7992 15.6248C10.7992 15.8352 10.8555 16.0419 10.9621 16.2233C11.0667 16.4011 11.215 16.5494 11.3928 16.654C11.5741 16.7614 11.781 16.8178 11.9917 16.8173ZM12.999 13.0297C13.0041 12.8787 13.056 12.733 13.1477 12.6129C13.2573 12.4631 13.3821 12.3251 13.52 12.2009C13.6708 12.062 13.8341 11.9098 14.0063 11.7486C14.1824 11.5833 14.345 11.4042 14.4926 11.213C14.6486 11.0112 14.7742 10.7877 14.8657 10.5496C14.9653 10.283 15.0157 10.0005 15.0144 9.71593C15.0168 9.41272 14.9621 9.11177 14.8532 8.82878C14.753 8.56999 14.6053 8.33224 14.4176 8.12782C14.2332 7.92461 14.0168 7.75284 13.7771 7.61929C13.533 7.48108 13.2713 7.37684 12.999 7.30945C12.7218 7.24021 12.4372 7.20522 12.1515 7.20525C11.7018 7.20235 11.2556 7.28242 10.835 7.44145C10.4259 7.59405 10.0515 7.82694 9.73387 8.12643C9.41001 8.43747 9.15284 8.81116 8.97803 9.22477L10.7148 10.2168C10.794 10.0255 10.8979 9.84544 11.024 9.68119C11.1437 9.52574 11.295 9.39742 11.4679 9.30466C11.6539 9.20851 11.8608 9.1596 12.0702 9.16224C12.3884 9.16224 12.626 9.23171 12.776 9.36093C12.8509 9.42674 12.9099 9.50871 12.9486 9.60065C12.9872 9.69259 13.0045 9.79211 12.999 9.89169C12.9967 10.0735 12.9454 10.2514 12.8503 10.4065C12.7459 10.5762 12.6208 10.7323 12.478 10.8712C12.3272 11.0199 12.164 11.1727 11.9917 11.3256C11.8208 11.4774 11.6585 11.6386 11.5054 11.8084C11.3558 11.9753 11.2304 12.1623 11.133 12.3642C11.0343 12.5721 10.9835 12.7995 10.9844 13.0297V13.8633H12.999V13.0297Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </p>
                                <p className="text-center focus:outline-none whitespace-nowrap">
                                  Sıkça Sorulan Sorular
                                </p>
                              </Link>
                            </div>
                          </div>
                        </dd>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
            </div>
          )}
        </nav>
      </header>

      {/*Overlay*/}
      {/* {headerSlice.overlay && (
        <div
          // style={{ display: "none" }}
          className="fixed inset-0  z-40 transition-opacity bg-primary-black bg-opacity-90"
          // onClick={() => dispatch(SET_OVERLAY(false))}
          // onMouseOver={() => dispatch(SET_OVERLAY(false))}
        ></div>
      )} */}
    </div>
  );
};

export default HeaderPrivate;
