import React from 'react'

export default function KalekimKvkk() {
  return (
    <>
    <p>
        {" "}
        İşbu yazı yoluyla internet sitemizi ziyaret eden sizleri, 6698 sayılı
        Kişisel Verilerin Korunması Kanunu (kısaca “Kanun”) gereğince
        bilgilendirilmekteyiz. İşbu Aydınlatma Metni, internet sitemizi kullanan
        ziyaretçilerimizden toplanan kişisel verilerin kaydedilmesi, işlenmesi,
        paylaşılması, üçüncü taraflara aktarılması, saklanması, silinmesi ve
        imhası süreçleri ile bunlara ilişkin ilkelerimiz hakkında
        ziyaretçilerimizin bilgilendirilmesi amacıyla hazırlanmıştır.
      </p>
      <h5 className="my-5">1) Veri sorumlusu</h5>
      <p>
        KALEKİM Kimyevi Maddeler Sanayi ve Tic. A.Ş. (kısaca “KALEKİM”) Adres:
        Firuzköy Mah. Firuzköy Bulvarı No: 188 Firuzköy / Avcılar / İstanbul /
        Türkiye, Mersis No: 0491002495800013, Kanun kapsamında “Veri
        Sorumlusudur”.
      </p>

      <h5 className="my-5">2) Kişisel verileriniz hangi amaçla işlenecek</h5>
      <p>
        Kişisel verileriniz, aşağıdaki durum ve koşullarda KALEKİM tarafından
        Kanun kapsamında işlenmektedir:
      </p>
      <p>Kişisel verileriniz;</p>
      <ol className="my-3">
        <li>1.Kampanya, satış ve pazarlama faaliyetlerinde kullanmak için,</li>
        <li>
          2.Düzenlediğimiz organizasyonlar ve sunduğumuz hizmetlere ile ilgili
          bilgi iletişimi yapılması için,
        </li>
        <li>
          3.Sosyal medyada yapılan etkinlik haberlerinde kullanılmak amacıyla,
        </li>
        <li>
          4.İnternet sitesi kullanıcıları ve ziyaretçilerinin kullanım
          alışkanlıklarının belirlenmesi, veri analizi yapabilmek için,
        </li>
        <li>5.Sitede sunulan hizmetlerimizin kalitesinin artırılması için,</li>
        <li>
          6.Site üzerinden hizmet alınması durumunda bu hizmetin
          gerekliliklerinin yerine getirilebilmesi için.
        </li>
      </ol>
      <h5 className="my-5">3) Hangi kişisel verileriniz işlenecek</h5>
      <p>
        Aşağıdakilerle sınırlı olmamak ve bunlar örnekleyici olmak kaydı ile
        sizinle kurulacak hukuki ilişkiye veya yapılacak işleme göre aşağıdaki
        verileriniz işlenecektir,{" "}
      </p>
      <p>Portala girmiş olduğunuz;</p>
      <ol className="my-3">
        <li>1.Ad ve Soyadı bilgileriniz,</li>
        <li>2.Cinsiyet bilgileriniz,</li>
        <li>3.T.C. Kimlik No bilgileriniz,</li>
        <li>4.Doğum Tarihi bilgileriniz,</li>
        <li>5.Cep iletişim bilgileriniz,</li>
        <li>6.Email iletişim bilgileriniz,</li>
        <li>7.Eğitim Durumu bilgileriniz,</li>
        <li>8.Çalıştığınız Firma bilgileriniz,</li>
        <li>9.Unvan bilgileriniz,</li>
        <li>10.Adres bilgileriniz,</li>
        <li>
          11.Portala yüklediğiniz veya gönderdiğiniz her türlü veri, fotoğraf,
          paylaşım vb.
        </li>
        <li>
          12.Portala ulaşmak için kullandığınız tarayıcı, üzerinden geldiğiniz
          internet sitesi veya uygulama gibi bilgiler
        </li>
        <li>
          13.5651 sayılı yasa gereği tutulması zorunlu olan IP, kullanılan
          protokol ve iletişim kayıtları,
        </li>
        <li>
          14.Ziyaret ettiğiniz sayfalar, buralarda harcana süre vb. kullanım
          istatistikleri
        </li>
      </ol>

      <h5 className="my-5">
        4) Kişisel Verileriniz Kimlere ve Hangi Amaçla Aktarılabilecek
      </h5>
      <p>
        İşlenen kişisel verileriniz yukarıdaki amaçlar ve Kanun’un belirlediği
        şartlara göre;
      </p>
      <ol className="my-3">
        <li>1.İştiraklerimize, grup şirketlerimize, hissedarlarımıza,</li>
        <li>
          2.Ürün ve hizmetlerimizin sunulabilmesi amacıyla sınırlı olmak üzere
          iş ortaklarımıza ve tedarikçilerimize,
        </li>
        <li>
          3.Ticari, mali ve hukuki danışmanlıklar ve denetimler amacıyla
          danışmanlara ve denetim firmalarına,
        </li>
        <li>
          4.Kampanya, tanıtım ve duyurularımızın yapılabilmesi için bu
          süreçlerin içinde olan taşeron ve hizmet alınan 3.taraflara,
        </li>
        <li>
          5.Müşteri şikayetlerinin çözümlenmesi amacıyla alınan Çağrı Merkezi
          ve/veya Şikayet takibi hizmeti şirketlerine,
        </li>
        <li>
          6.Yalnızca Kanunlarda şart koşulduğu ve kapsamıyla sınırlı olmak üzere
          yetkili kamu kurum ve kuruluşlarıyla yargı mercilerine,
        </li>
        <li>
          7.İnternet sitemiz veya burada bağlantı verilen sosyal medya
          hesaplarımız üzerinden bir hizmet almakta iseniz bu hizmete ilişkin
          yükümlülüklerimizin yerine getirilebilmesi amacıyla hizmeti sağlamak
          için işbirliği yaptığımız tedarikçi ve danışmanlarımızla,
        </li>
        <li>
          8.Sitemizle bağlantılı sosyal medya hesapları ile yaptığınız iletişim
          için yer sağlayıcı veya sistem sağlayıcı olan sosyal medya platformu
          sahibi şirketlerle,
        </li>
        <li>
          9.Veri aktarımı için rızanızı aldığımız diğer alıcılara, açık rızanız
          ya da Kanun ile belirlenmiş koşullar çerçevesinde aktarılabilecektir.
        </li>
      </ol>

      <h5 className="my-5">
        5) Kişisel veri işlemenin yöntemi ve hukuki sebebi
      </h5>
      <p>
        Kişisel verileriniz, KALEKİM veya KALEKİM adına veri işleyen gerçek ya
        da tüzel kişiler tarafından; internet sitesi, sosyal medya kanallarımız,
        mobil uygulamalarımız ve bu sayılanlarla sınırlı olmamak üzere sözlü,
        yazılı, görsel veya elektronik kanallar aracılığıyla ve açık rızanız
        alınmak suretiyle toplanmaktadır. İnternet sitemiz ve bağlantılı sosyal
        medya hesapları ile ilgili otomatik veri toplama yöntemlerimiz için
        www.kalekim.com/tr/kisisel-verilerin-korunmasi adresinden çerez
        politikamıza ulaşabilirsiniz.
      </p>
      <p>
        Kişisel verileriniz, KALEKİM tarafından, ticari faaliyetlerimizin
        sunulabilmesi ve faaliyetlerimizde sürekliliğin sağlanabilmesi,
        Kanunlarca öngörülen sorumluluklarımızın tam ve doğru bir şekilde yerine
        getirebilmesi amacıyla işlenmektedir.
      </p>

      <h5 className="my-5">6) Kanun gereğince yasal haklarınız</h5>
      <p>
        KVKK’ nın 11. maddesinde haklarınız yazılı olup, bunlar aşağıdaki
        gibidir:
      </p>
      <p>KALEKİM ’e yazılı olarak başvurarak, kişisel verilerinizin;</p>
      <ol className="my-3">
        <li>1. İşlenip işlenmediğini öğrenme,</li>
        <li>2. İşlenmişse buna ilişkin bilgi talep etme,</li>
        <li>
          3. İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını
          öğrenme,
        </li>
        <li>4. Yurt içinde veya yurt dışında aktarıldığı 3. kişileri bilme,</li>
        <li>
          5. Eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini
          isteme,
        </li>
        <li>
          6. KVKK’ nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini
          veya yok
        </li>
        <li>
          7. Aktarıldığı 3. kişilere yukarıda sayılan (4) ve (5) bentleri
          uyarınca yapılan işlemlerin bildirilmesini isteme,
        </li>
        <li>
          8. Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle
          aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
        </li>
        <li>
          9. Kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde
          zararın giderilmesini talep etme haklarınızı kullanabilirsiniz.
        </li>
      </ol>

      <p>
        Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici
        gerekli bilgiler ile kullanmayı talep ettiğiniz hakkınıza yönelik
        açıklamalarınızı içeren talebinizi
        www.kalekim.com/tr/kisisel-verilerin-korunmasi adresindeki formu
        doldurarak, şirketin “Firuzköy Mah. Firuzköy Bulvarı No: 188 Firuzköy /
        Avcılar / İstanbul / Türkiye” adresine bizzat getirebilir veya noter
        kanalı veya iadeli taahhütlü posta yoluyla gönderebilir veya formun
        imzalı bir suretini kkkvkk@kale.com.tr mail adresine, şirketin
        Kalekim@hs01.kep.tr KEP adresine kayıtlı e-posta gönderilerek veya çağrı
        merkezine başvurarak iletebilirsiniz.
      </p>

      <p>
        Talepleriniz, talebinizin niteliğine göre mümkün olan en kısa sürede ve
        en geç 30 gün içerisinde ücretsiz olarak sonuçlandırılır. Ancak işlemin
        ayrıca bir maliyeti gerektirmesi halinde, Kişisel Verileri Koruma Kurulu
        tarafından belirlenen tarifedeki ücret alınabilir. Talebiniz başkası
        adına yapılacaksa bu konuda almış olduğunuz bir vekaletnamenin ve
        kimliğinizi doğrular diğer belgelerin başvurunuza eklenmiş olması
        gerekmektedir. Yukarıdaki aydınlatma metni ve ilgili mevzuat
        çerçevesinde kişisel verilerimin satış, pazarlama tanıtım faaliyetleri
        çerçevesinde ve yukarıda belirtilen amaçlara uygun şekilde işlenmesine
        ve bu faaliyetlerin yürütülmesi konusunda grup şirketlerine ve hizmet
        alınan tedarikçi ve iş ortakları ile paylaşılmasına rıza gösteriyorum.
      </p>
    </>
  )
}
