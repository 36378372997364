import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireAuth = () => {
  const appSlice = useSelector((state) => state.app);
  const userFromLocalStorage = localStorage.getItem(
    `Parse/${process.env.REACT_APP_PARSE_APPLICATION_ID}/currentUser`
  );
  const location = useLocation();

  if (!appSlice?.user && !userFromLocalStorage) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // localStorage.removeItem(`Parse/${process.env.REACT_APP_PARSE_APPLICATION_ID}/currentUser`);
    return <Navigate replace state={{ from: location }} to="/" />;
  }

  return <Outlet />;
};

export default RequireAuth;
