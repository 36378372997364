import { memo } from "react";

function LoadingModal({ closeButton }) {
  return (
    <div
      className="fixed inset-0 align-middle z-[200] overflow-y-auto overscroll-contain "
      aria-labelledby="modal-title"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center lg:block lg:p-0">
        <div
          className="fixed inset-0 min-h-screen overflow-y-auto transition-opacity bg-primary-black bg-opacity-90"
          aria-hidden="true"
        ></div>
        <span
          className="hidden lg:inline-block lg:align-middle lg:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block h-auto w-full max-w-full align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-auto lg:overflow-hidden shadow-xl transform transition-all lg:my-8 lg:align-middle  lg:w-full lg:pb-16 lg:pt-12 lg:px-12 outline-none">
          {closeButton && (
            <div className="absolute top-0 right-0 pt-4 pr-4 lg:block">
              <button
                type="button"
                className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-6 h-6 text-primary-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          )}

          <div className="modal-body">
            {/* <div className="ml-auto mr-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                style={{
                  margin: "auto",
                  background: "none",
                  display: "block",
                  shapeRendering: "auto",
                }}
                width="40"
                height="40"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="#c01b37"
                  stroke-width="15"
                  r="37"
                  stroke-dasharray="174.35839227423352 60.119464091411174"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  ></animateTransform>
                </circle>
              </svg>
            </div> */}
            <div className="flex flex-col items-center py-20">
              <p className="icon-wrapper bg-secondary-primary">
                <svg
                  className="w-6 h-6 text-white animate-spin"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_108_28930)">
                    <path
                      d="M12.7403 3H11.2705V7.49755H12.7403V3Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.9"
                      d="M8.12151 3.7818L6.84863 4.51669L9.09741 8.41168L10.3703 7.67679L8.12151 3.7818Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.8"
                      d="M4.55814 6.84628L3.82324 8.11916L7.71823 10.3679L8.45313 9.09506L4.55814 6.84628Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.7"
                      d="M7.49755 11.27H3V12.7398H7.49755V11.27Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.6"
                      d="M7.74067 13.68L3.8457 15.9287L4.5806 17.2016L8.47556 14.9529L7.74067 13.68Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.5"
                      d="M9.07203 15.484L6.82324 19.379L8.09612 20.1139L10.3449 16.2189L9.07203 15.484Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.4"
                      d="M12.7403 16.5122H11.2705V21.0098H12.7403V16.5122Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.35"
                      d="M14.8803 15.4768L13.6074 16.2117L15.8562 20.1066L17.1291 19.3718L14.8803 15.4768Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.27"
                      d="M16.2554 13.6021L15.5205 14.8749L19.4155 17.1237L20.1504 15.8508L16.2554 13.6021Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.18"
                      d="M21.0093 11.27H16.5117V12.7398H21.0093V11.27Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.15"
                      d="M19.4409 6.9233L15.5459 9.17206L16.2808 10.4449L20.1758 8.19617L19.4409 6.9233Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.1"
                      d="M15.8279 3.78749L13.5791 7.68251L14.852 8.4174L17.1008 4.52239L15.8279 3.78749Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_108_28930">
                      <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="translate(3 3)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </p>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-xs">Yükleniyor...</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(LoadingModal);
