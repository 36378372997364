import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import LoadingModal from "../../components/shared/LoadingModal";
import AuthLayout from "../../layouts/AuthLayout";
import {
  getUserGalleryById,
  removeUserGalleryImages,
  updateUserGalleryTitle,
} from "../../services/contentService";
import AddNewImage from "./components/AddNewImage";
import Image from "./components/Image";
import { Helmet } from "react-helmet";

export default function GalleryEdit() {
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const { id } = useParams();
  const appSlice = useSelector((state) => state?.app);
  const user = appSlice?.user;

  useEffect(() => {
    handleGetGalleryById();
    //eslint-disable-next-line
  }, []);

  const handleGetGalleryById = async () => {
    let gallery = await getUserGalleryById(id);
    const reversedImages = gallery?.images?.reverse();
    gallery = {
      ...gallery,
      images: reversedImages,
    };
    setGallery(gallery);
  };

  const removeSelectedImages = async () => {
    if (!user) throw new Error("Unauthorized");
    setLoading(true);
    try {
      const updatedGallery = gallery?.images?.filter((img) => {
        return !selectedImages.includes(img);
      });
      await removeUserGalleryImages(gallery?.objectId, updatedGallery);
      setGallery({
        ...gallery,
        images: updatedGallery,
      });
      setSelectedImages([]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const changeTitle = async () => {
    if (!user) throw new Error("Unauthorized");
    setLoading(true);
    if (newTitle !== gallery?.title && newTitle.trim() !== "") {
      try {
        await updateUserGalleryTitle(id, newTitle);
        setGallery({
          ...gallery,
          title: newTitle,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {}, [selectedImages]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content">
          {loading && <LoadingModal />}
          <div className="px-5 py-4 bg-background-teal md:px-4 lg:py-16 xl:px-0">
            <main className="mx-auto max-w-7xl lg:px-1">
              <div className="">
                <div className="grid grid-cols-12">
                  <h1 className="col-span-12 mb-4 lg:col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                    <div className="form-input-wrapper w-full">
                      <input
                        type="text"
                        id="title"
                        autoComplete="title"
                        name="name"
                        placeholder=" "
                        className="bg-transparent form-input w-full"
                        defaultValue={gallery?.title ? gallery?.title : ""}
                        onChange={(e) => setNewTitle(e.target.value)}
                      />
                      <label
                        htmlFor="name"
                        className="bg-transparent form-label"
                      >
                        Albüm Başlığı
                      </label>
                    </div>
                    {/* {gallery?.title ? gallery?.title : `${gallery?.user?.Name} Albümü` }  */}
                  </h1>
                  <h6 className="grid items-end col-span-12 text-xs text-left lg:text-right lg:col-span-5 lg:text-md">
                    {
                      new Date(gallery?.createdAt)
                        ?.toLocaleString("tr-TR")
                        ?.split(" ")[0]
                    }
                  </h6>
                </div>
                <div className="flex flex-col lg:flex-row">
                  <Link
                    to="/gallery"
                    className="inline-flex items-center px-6 py-3 mt-10 mr-6 btn btn-primary revert"
                  >
                    <svg
                      role="img"
                      className="w-5 h-5 mr-3 text-secondary-titles"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#arrow-line-left" />
                    </svg>
                    GERİ
                  </Link>
                  <div className="flex">
                    <button
                      type="button"
                      onClick={() => removeSelectedImages()}
                      disabled={selectedImages.length < 1}
                      className={
                        selectedImages.length < 1
                          ? "inline-flex items-center px-6 py-3 mt-4 mr-6 text-white lg:mt-10 btn btn-primary bg-primary-red border-primary-red opacity-70"
                          : "inline-flex items-center px-6 py-3 mt-4 mr-6 text-white lg:mt-10 btn btn-primary bg-primary-red border-primary-red"
                      }
                    >
                      SEÇİLİ OLANLARI SİL
                      <svg role="img" className="w-5 h-5 ml-3 text-white">
                        <use xlinkHref="/assets/img/necromancers.svg#delete" />
                      </svg>
                    </button>

                    <button
                      type="button"
                      onClick={() => setSelectedImages([])}
                      disabled={selectedImages.length < 1}
                      className={
                        selectedImages.length < 1
                          ? "inline-flex items-center px-6 py-3 mt-4 mr-6 text-white lg:mt-10 btn btn-primary bg-primary-black opacity-70"
                          : "inline-flex items-center px-6 py-3 mt-4 mr-6 text-white lg:mt-10 btn btn-primary bg-primary-black"
                      }
                    >
                      Vazgeç
                    </button>

                    <AddNewImage gallery={gallery} setGallery={setGallery} />

                    <button
                      type="button"
                      onClick={() => changeTitle()}
                      disabled={
                        newTitle === gallery?.title || newTitle.trim() === ""
                      }
                      className={
                        newTitle === gallery?.title || newTitle.trim() === ""
                          ? "inline-flex items-center px-6 py-3 mt-4 text-white lg:mt-10 btn btn-primary bg-primary-red border-primary-red opacity-70"
                          : "inline-flex items-center px-6 py-3 mt-4 mr-6 text-white lg:mt-10 btn btn-primary bg-primary-red border-primary-red"
                      }
                    >
                      Başlığı Güncelle
                      <svg role="img" className="w-5 h-5 ml-3 text-white">
                        <use xlinkHref="/assets/img/necromancers.svg#edit" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>

          <div className="px-1 mx-auto my-12 max-w-7xl lg:my-16">
            <div className="grid grid-cols-12 gap-6 px-4">
              {gallery?.images?.map((image, index) => (
                <Image
                  gallery={gallery}
                  index={index}
                  image={image}
                  key={index}
                  selectedImages={selectedImages}
                  setSelectedImages={(images) => setSelectedImages(images)}
                />
              ))}
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
