import React from "react";

export default function Communication() {
  return (
    <div className="pt-6 pb-9 lg:pb-16 bg-secondary-secondary-bg">
      <main className="px-1 mx-auto mt-16 space-y-12 max-w-7xl">
        <div className="space-y-4">
          <h2 className="text-xl text-center">İletişim</h2>
        </div>
        <div className="flex flex-col items-center justify-center px-4 lg:space-x-6 lg:flex-row lg:px-0">
          <div className="w-full p-2 bg-white">
            <div className="w-full aspect-w-2 aspect-h-2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4764.767480824197!2d29.008687109301942!3d41.08187559068638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab6682aa2db0b%3A0x7051316229ddac73!2sKaleseramik%20%C3%87anakkale%20Seramik%20Kalebodur%20Seramik!5e0!3m2!1str!2str!4v1640728064777!5m2!1str!2str"
                width="600"
                height="450"
                className="border-0"
                title="Kaleseramik"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div className="flex flex-col px-6 pt-6 space-y-4 pb-7 lg:pt-10 lg:pb-9">
              <p className="text-btn text-primary-red">KALESERAMİK</p>
              <span>
                Kaleseramik Çanakkale Kalebodur Seramik San. A.Ş.
                <br />
                Adres: Prof. Ahmet Kemal Aru Sk. Kaleseramik Binası
                Levent-İstanbul
                <br />
                Ticaret Sicil No: 71314
                <br />
                Telefon: 02123715253
                <br />
                İrtibat kişisi: mehmetkucukefe@kale.com.tr
                <br />
              </span>
            </div>
          </div>
          <div className="w-full p-2 mt-10 bg-white lg:mt-0">
            <div className="w-full aspect-w-2 aspect-h-2">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.7220154885235!2d28.70057901606027!3d41.009457227299926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa0eeaba0733d%3A0x6866079d3db876b5!2zRmlydXprw7Z5LCBGaXJ1emvDtnkgQmx2LiBObzoxODgsIDM0MzI1IEF2Y8SxbGFyL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1640728001474!5m2!1str!2str"
                width="600"
                height="450"
                className="border-0"
                title="kalekim"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div className="flex flex-col px-6 pt-6 space-y-4 pb-7 lg:pt-10 lg:pb-9">
              <p className="text-btn text-primary-red">KALEKİM</p>
              <span>
                <br />
                <br />
                <br />
                <br />
                <br />
              </span>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
