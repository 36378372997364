import React, { useEffect, useState, useRef } from "react";
import Comment from "./Comment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import {
  deletePost,
  likePost,
  unlikePost,
  updatePost,
} from "../../../services/contentService";
import {
  createPostComment,
  getPostComments,
} from "../../../services/contentService";
import ReactPlayer from "react-player";
import Lightbox from "react-image-lightbox";
import { Link } from "react-router-dom";
import Parse from "parse";

export default function Post({ post, posts, setPosts }) {
  const [text, setText] = useState(null);
  const [comments, setComments] = useState(null);
  const [play, setPlay] = useState(false);
  const [files] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [isSettingsBoxOpen, setIsSettingsBoxOpen] = useState(false);
  const [isLikesBoxOpen, setIsLikesBoxOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const lastComment = useRef(null);
  const commentInput = useRef(null);

  useEffect(() => {
    (async () => {
      const comments = await getPostComments(post?.id);
      const commentsWithoutReplies = comments.filter((i) => !i.parentId);
      const reversedComments = commentsWithoutReplies.reverse();
      setComments(reversedComments);
    })();
    //cleanup function
    return () => {
      setComments(null);
    };
    //eslint-disable-next-line
  }, [post]);

  useEffect(() => {}, [play]);

  useEffect(() => {
    const items = [];
    post?.attachments?.forEach((attachment) => {
      const extension =
        attachment?.split(".")[attachment?.split(".").length - 1];
      let isReturn = true;
      if (
        extension === "mkv" ||
        extension === "mp4" ||
        extension === "m4v" ||
        extension === "avi" ||
        extension === "mpg"
      )
        isReturn = false;
      if (isReturn) items.push(attachment);
    });
    setLightboxImages(items);
    // cleanup function
    return () => {
      setLightboxImages([]);
    };
  }, [post?.attachments]);

  const handleLikePost = async () => {
    setIsLikesBoxOpen(false);
    let postsCopy = posts;
    const postsOld = posts;
    const index = postsCopy?.findIndex((i) => i?.id === post?.id);
    let postForLike = postsCopy[index];
    postForLike = {
      ...postForLike,
      likedByUser: true,
      likes: postForLike?.likes + 1,
    };

    postForLike?.likeUsers?.push({
      userName: Parse.User.current().get("Name"),
      profileImage: Parse.User.current().get("profileImage"),
    });

    postsCopy[index] = postForLike;
    setPosts([...postsCopy]);

    try {
      await likePost(post?.id);
    } catch (error) {
      setPosts([...postsOld]);
    }
  };

  const handleUnlikePost = async () => {
    let postsCopy = posts;
    const postsOld = posts;
    const index = postsCopy?.findIndex((i) => i?.id === post?.id);
    let postForUnlike = postsCopy[index];
    postForUnlike = {
      ...postForUnlike,
      likedByUser: false,
      likes: postForUnlike?.likes - 1,
    };

    postForUnlike.likeUsers = postForUnlike?.likeUsers?.filter(
      (user) => user.userName !== Parse.User.current().get("Name")
    );

    postsCopy[index] = postForUnlike;
    setPosts([...postsCopy]);
    try {
      await unlikePost(post?.id);
    } catch (error) {
      console.log(error);
      setPosts([...postsOld]);
    }
  };

  const handleUpdatePost = async () => {
    try {
      let newPost = await updatePost({
        id: post?.id,
        commentDenied: !post?.commentDenied,
      });

      setPosts(
        posts?.map((i) => {
          if (i?.id === newPost.id) return newPost;
          return i;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePost = async () => {
    try {
      deletePost(post?.id);
      setPosts(posts?.filter((i) => i?.id !== post?.id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSettingsBox = () => {
    setIsSettingsBoxOpen(!isSettingsBoxOpen);
    setIsLikesBoxOpen(false);
  };

  const handleLikesBox = () => {
    setIsLikesBoxOpen(!isLikesBoxOpen);
    setIsSettingsBoxOpen(false);
  };

  const createComment = async (e) => {
    setLoading(true);
    const allComments = [];
    try {
      const comment = await createPostComment({
        id: post?.id,
        comment: text,
        files,
      });
      comments?.forEach((comment) => {
        allComments.push(comment);
      });
      allComments.push(comment?.newComment);
      setComments([...allComments]);
      setText(null);
      commentInput.current.value = "";
      if (!showMore) {
        setShowMore(true);
        window.scrollTo({
          top: lastComment.current.offsetTop - 300,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex p-6 border border-secondary-lines">
      {isLightboxOpen && (
        <Lightbox
          mainSrc={lightboxImages?.[photoIndex]}
          nextSrc={lightboxImages?.[(photoIndex + 1) % lightboxImages?.length]}
          prevSrc={
            lightboxImages?.[
              (photoIndex + lightboxImages.length - 1) % lightboxImages?.length
            ]
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + lightboxImages.length - 1) % lightboxImages?.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightboxImages?.length)
          }
        />
      )}

      <div className="flex flex-col w-full">
        <span
          className={`${
            !post?.approved && "bg-red-500"
          } text-white px-1 py-1 rounded-md inline text-center`}
        >
          Post Onay Beklemektedir
        </span>
        {post?.user?.id === Parse.User.current().id && (
          <div className="mb-3 relative select-none">
            <svg
              role="img"
              className="w-8 h-8 cursor-pointer ml-auto"
              onClick={handleSettingsBox}
            >
              <use xlinkHref="/assets/img/necromancers.svg#settings" />
            </svg>
            {isSettingsBoxOpen && (
              <div className="w-52 absolute bg-gray-100 z-40 right-3 rounded-lg">
                <ul className="h-full text-center">
                  <li
                    className="m-3 p-3 border border-gray-500 cursor-pointer rounded-md hover:bg-gray-200"
                    onClick={handleUpdatePost}
                  >
                    {post?.commentDenied ? "Yoruma Aç" : "Yoruma Kapa"}
                  </li>
                  <li
                    className="m-3 p-3 border border-gray-500 cursor-pointer rounded-md hover:bg-gray-200"
                    onClick={handleDeletePost}
                  >
                    Gönderiyi Sil
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}

        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="relative flex flex-col overflow-hidden lg:flex-row d-none d-lg-block w-full mySwiper"
          slidesPerView={1}
        >
          {post?.attachments?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="text-center w-full h-full">
                {item?.split(".")[item?.split(".")?.length - 1] === "mkv" ||
                item?.split(".")[item?.split(".")?.length - 1] === "mp4" ||
                item?.split(".")[item?.split(".")?.length - 1] === "m4v" ||
                item?.split(".")[item?.split(".")?.length - 1] === "avi" ||
                item?.split(".")[item?.split(".")?.length - 1] === "mpg" ? (
                  <div key={index} className="w-full h-full">
                    <ReactPlayer
                      url={item}
                      width="100%"
                      height="100%"
                      controls={play}
                      onEnded={() => setPlay(false)}
                      playing={play}
                    />
                    {!play && (
                      <button
                        className="absolute top-0 bottom-0 left-0 right-0 inline-flex items-center justify-center w-20 h-20 mx-auto my-auto play-icon"
                        onClick={() => setPlay(true)}
                      >
                        <svg role="img" className="w-10 text-primary-red">
                          <use xlinkHref="/assets/img/necromancers.svg#play" />
                        </svg>
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="w-full h-full">
                    <img
                      src={item}
                      className="w-full cursor-pointer"
                      alt={`item-${index}`}
                      onClick={() => {
                        setIsLightboxOpen(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <p className="mt-6 mb-4 break-all">{post?.text}</p>
        <div className="flex justify-between">
          <div className="flex">
            <Link to={`/user/${post?.user?.id}`}>
              <div className="w-12 h-12 mr-3 overflow-hidden rounded-full bg-secondary-titles relative flex items-center justify-center">
                {post?.user?.profile_image ? (
                  <img
                    src={post?.user?.profile_image}
                    alt={post?.user?.name}
                    className="w-full h-full object-center object-cover"
                  />
                ) : (
                  <>
                    <span className="sr-only">User</span>
                    <svg role="img" className="w-8 h-8 text-white">
                      <use xlinkHref="/assets/img/necromancers.svg#user" />
                    </svg>
                  </>
                )}
              </div>
            </Link>
            <div>
              <Link to={`/user/${post?.user?.id}`}>
                <p className="font-sm">{post?.user?.name}</p>
              </Link>
              <p className="font-body text-secondary-secondary text-body-s">
                {new Date(post?.createdAt).toLocaleString()}
              </p>
            </div>
          </div>
          <div className="flex relative flex-auto items-center justify-end">
            <span
              className="mr-2 cursor-pointer hover:underline select-none"
              onClick={handleLikesBox}
            >
              {post?.likes} beğeni
            </span>
            {isLikesBoxOpen && post?.likeUsers?.length >= 1 && (
              <div className="w-52 absolute top-12 bg-gray-100 z-40 right-3 rounded-lg">
                <ul className="h-full text-center">
                  {post?.likeUsers?.map((item, index) => (
                    <li className="m-3 p-2" key={index}>
                      {item?.userName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div>
            {post?.likedByUser ? (
              <button
                className="flex items-center justify-center p-3 border border-primary-red"
                onClick={() => handleUnlikePost()}
              >
                <svg role="img" className="w-5 h-5 text-primary-red">
                  <use xlinkHref="/assets/img/necromancers.svg#favourite-fill" />
                </svg>
              </button>
            ) : (
              <button
                className="flex items-center justify-center p-3 border border-primary-red"
                onClick={() => handleLikePost()}
              >
                <svg role="img" className="w-5 h-5 text-primary-red">
                  <use xlinkHref="/assets/img/necromancers.svg#favourite" />
                </svg>
              </button>
            )}
          </div>
        </div>
        {/* {post?.topComments?.map((topComment) => (
          <div className="flex mt-6" key={topComment?.id}>
          <div className="flex w-full">
            <div className="w-12 h-12 mr-3 overflow-hidden rounded-full">
              <img src={topComment?.user?.profile_image} alt="" />
            </div>
            <div className="w-full">
              <div className="relative flex flex-col gap-3 p-3 bg-secondary-secondary-bg triangle">
                <p className="font-sm">{topComment?.user?.name}</p>
                <p>
                  {topComment?.comment}
                </p>
                <p className="text-body-s text-secondary-secondary">
                {new Date(topComment?.createdAt).toLocaleString()}
                </p>
              </div>
              <button className="mt-3 text-body text-secondary-secondary">
                Yanıtla
              </button>
            </div>
          </div>
        </div>
        ))} */}

        {comments?.map((comment, index) => {
          if (!comment.parentId) {
            return (
              <Comment
                comment={comment}
                post={post}
                showMore={showMore}
                index={index}
                key={comment?.id}
              />
            );
          } else {
            return <span className="sr-only" key={comment.id}></span>;
          }
        })}
        <div ref={lastComment} />

        <div className="my-6 flex">
          <div className="form-input-wrapper w-5/6">
            <input
              type="text"
              id="text"
              autoComplete="text"
              name="text"
              placeholder=" "
              className="bg-transparent form-input"
              disabled={post?.commentDenied}
              onChange={(e) => setText(e.target.value)}
              ref={commentInput}
            />
            <label htmlFor="text" className="bg-transparent form-label">
              {post?.commentDenied ? "Yoruma Kapalı" : "Yorum yap"}
            </label>
          </div>
          <button
            type="button"
            id="commentButton"
            disabled={post?.commentDenied || loading}
            className="uppercase form-submit-btn w-1/6 h-14 flex align-center cursor-pointer"
            onClick={() => createComment()}
          >
            {loading ? (
              <svg
                role="img"
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 0 1 8-8v2a6 6 0 1 0 6 6h2a8
                  8 0 0 1-16 0z"
                ></path>
              </svg>
            ) : (
              "Kaydet"
            )}
          </button>
        </div>

        {!post?.commentDenied && comments?.length > 2 && (
          <div className="flex justify-end underline text-body-l text-secondary-secondary">
            {!showMore && (
              <button type="button" onClick={() => setShowMore(true)}>
                Daha Fazla Göster
              </button>
            )}
            {showMore && (
              <button type="button" onClick={() => setShowMore(false)}>
                Daha Az Göster
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
