import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  const navigate = useNavigate();
  //
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div className="flex flex-col min-h-full pt-16 pb-12 bg-white">
          <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex flex-col items-center">
              <p className="w-24 h-24 icon-wrapper bg-validation-warning">
                {/*info*/}
                <svg
                  className="w-8 h-8 text-white"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.9997 8H11V22H14L13.9997 8Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.5 2C13.6046 2 14.5 2.89543 14.5 4C14.5 5.10457 13.6046 6 12.5 6C11.3954 6 10.5 5.10457 10.5 4C10.5 2.89543 11.3954 2 12.5 2Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
              <div className="mt-3 text-center sm:mt-16">
                <h3 className="text-md">Aradığınız Sayfa Bulunamadı</h3>
                {/* <div className="max-w-md mt-4">
                <p className="text-secondary-primary">
                  Gravida suspendisse velit et tortor tortor aenean eu et.
                  Sollicitudin integer felis amet faucibus erat mattis blandit
                  nulla.
                </p>
              </div> */}
              </div>
            </div>
            <div className="flex justify-center gap-4 mt-8">
              <button
                onClick={() => navigate("/")}
                type="button"
                className="w-full uppercase form-submit-btn lg:w-auto max-w-none"
              >
                ANA SAYFA
              </button>
            </div>
          </main>
        </div>
      </AuthLayout>
    </>
  );
};

export default NotFoundPage;
