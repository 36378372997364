import React from "react";
import { Link } from "react-router-dom";

const HeaderPublic = () => {
  return (
    <header className="relative bg-white border-b-2 border-gray-100">
      <div className="px-1 mx-auto max-w-7xl">
        <div className="flex items-center justify-between py-4">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link className="w-[4.5rem]" to="/">
              <img src="/assets/img/logo.png" alt="productname" data-testid="header-public-logo"/>
            </Link>
          </div>
          <div className="flex items-center justify-end">
            <Link to="/register" className="ml-8 btn with-icon">
              <div className="w-6 h-6 mr-2 text-white">
                <svg role="img" width={24} height={24} fill="none">
                  <use xlinkHref="/assets/img/necromancers.svg#user" />
                </svg>
              </div>

              <span>Üye Ol</span>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderPublic;
