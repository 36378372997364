import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderPrivate from "../pages/HomePage/components/HeaderPrivate";
import MobileHeaderPrivate from "../pages/HomePage/components/MobileHeaderPrivate";
import FooterPrivate from "../pages/HomePage/components/FooterPrivate";

const AuthLayout = ({ children }) => {
  const location = useLocation();
  
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <HeaderPrivate />
      <MobileHeaderPrivate />
      <main>{children}</main>
      <FooterPrivate />
    </>
  );
};

export default AuthLayout;
