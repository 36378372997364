import React from "react";

export default function About(props) {
  const { image, text, title, subtitle } = props;
  return (
    <div className="pt-16 lg:p-24" id="about">
      <main className="mx-auto">
        <div className="flex flex-col items-center gap-6 px-1 mx-auto lg:grid lg:grid-cols-2 max-w-7xl">
          <div className="flex flex-col px-4 lg:px-0" data-testid="about-text">
            <h2 className="mb-6 text-xl text-center lg:mb-4 lg:text-left">
              {title}
            </h2>
            <span className="mx-auto mb-2 uppercase lg:ml-0 text-primary-red text-btn">
              {subtitle}
            </span>
            <p className="max-w-xl text-justify text-secondary-primary">
              {text}
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src={image?.url}
              src-load="/assets/img/about.jpg"
              //   srcSet="true"
              lazyload="true"
              alt="productname"
              className="block"
            />
          </div>
        </div>
      </main>
    </div>
  );
}
