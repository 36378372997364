import React, { useEffect, useState } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import Survey from "./components/Survey";
import { getSurveys } from "../../services/contentService";
import Modal from "../../components/shared/Modal";
import { useSelector } from "react-redux";
import LoadingModal from "../../components/shared/LoadingModal";
import { Helmet } from "react-helmet";

export default function Surveys() {
  const [surveys, setSurveys] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state?.app?.user);

  useEffect(() => {
    if (user?.objectId) {
      (async () => {
        try {
          setLoading(true);
          const surveys = await getSurveys();
          setSurveys(surveys);
        } catch (error) {
          setApiError(error?.message);
          setShowErrorModal(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [user?.objectId]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {showSuccessModal && (
          <Modal
            shouldShow={showSuccessModal}
            type="success"
            msg={`Anket tamamlandı.`}
            updateModalState={(state) => setShowSuccessModal(state)}
          />
        )}
        {showErrorModal && (
          <Modal
            shouldShow={showErrorModal}
            type="error"
            msg={apiError}
            updateModalState={(state) => setShowErrorModal(state)}
          />
        )}
        {loading && <LoadingModal />}
        <div id="content">
          <div className="px-4 py-5 bg-background-teal lg:py-16 lg:px-0">
            <main className="mx-auto max-w-7xl md:px-4 lg:px-1">
              <div className="flex flex-col justify-between lg:flex-row lg:items-center">
                <h1 className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  Anketlerim
                </h1>
              </div>
            </main>
          </div>

          <div className="max-w-4xl px-4 mx-auto my-12 lg:my-16 lg:px-0">
            <h3 className="mb-8 text-sm text-center lg:text-lg lg:text-left">
              Yeni Anketler
            </h3>
            {surveys?.map((surveyItem) => {
              const survey = surveyItem?.toJSON();
              return (
                !survey?.complete && (
                  <Survey
                    key={survey?.objectId}
                    survey={survey}
                    surveys={surveys}
                    setSurveys={(surveys) => setSurveys(surveys)}
                    setShowSuccessModal={(show) => setShowSuccessModal(show)}
                    setLoading={(loading) => setLoading(loading)}
                    setApiError={(error) => setApiError(error)}
                    setShowErrorModal={(show) => setShowErrorModal(show)}
                    user={user}
                  />
                )
              );
            })}
          </div>

          <div className="max-w-4xl px-4 mx-auto my-12 lg:my-16 lg:px-0">
            <h3 className="mb-8 text-sm text-center lg:text-lg lg:text-left">
              Cevapladıklarım
            </h3>
            {surveys?.map((surveyItem) => {
              const survey = surveyItem?.toJSON();
              return (
                survey?.complete && (
                  <Survey
                    key={survey?.objectId}
                    survey={survey}
                    user={user}
                    setLoading={(loading) => setLoading(loading)}
                  />
                )
              );
            })}
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
