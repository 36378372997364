import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import LoadingModal from "../../../components/shared/LoadingModal";
import AuthLayout from "../../../layouts/AuthLayout";
import { getNewsById } from "../../../services/contentService";
import { Helmet } from "react-helmet";

export default function NewsDetails() {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const details = (await getNewsById(id)).toJSON();
        setDetails(details);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    })();
  }, [id]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div className="container flex flex-col items-center justify-center max-w-full px-4 pt-4 mx-auto bg-background-blue lg:px-0 lg:pt-0">
          <div className="px-1 max-w-7xl">
            <img
              data-lazyload
              src={details?.banner?.url}
              // data-srcset="
              // 		/assets/img/dummy/news.jpg 1x,
              // 		 2x"
              alt="Registration"
              className="block "
            />
          </div>
          <div className="items-start w-full px-1 py-8 mx-auto sm:px-4 xl:px-0 lg:pt-20 pb-28 max-w-7xl">
            <div className="flex flex-col justify-between flex-1 pb-2 lg:flex-row">
              <div className="flex flex-col">
                <h2 className="max-w-xl mb-8 text-md font-xxl lg:mb-0 lg:text-lg">
                  {details?.title}
                </h2>
              </div>
              <div className="flex ">
                <p className="max-w-md text-xs font-xs"> {details?.text} </p>
              </div>
            </div>
            <Link
              to="/news"
              className="inline-flex items-center px-6 py-3 mt-8 btn btn-primary revert"
            >
              <svg
                role="img"
                className="mr-1"
                fill="black"
                width={24}
                height={24}
              >
                <use xlinkHref="/assets/img/necromancers.svg#arrow-line-left" />
              </svg>
              GERİ
            </Link>
          </div>
        </div>
        <div
          className="relative flex flex-col items-center w-full px-4 pt-12 pb-20 mx-auto max-w-7xl xl:px-0"
          id="content"
        >
          <div
            className="text-left max-w-7xl"
            dangerouslySetInnerHTML={{ __html: details?.body }}
          ></div>
        </div>
      </AuthLayout>
    </>
  );
}
