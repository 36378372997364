import React from "react";
import classNames from "classnames";

export default function Conversations({
  conversations,
  setActiveContent,
  user,
  setFriendForMessageDetails,
}) {
  return (
    <div className="w-full" role="none">
      <div className="flex items-center justify-between p-6 bg-background-blue">
        <h3 className="text-sm font-sm">Mesajlarım</h3>
        <button
          className="flex items-center justify-center px-4 py-3 uppercase border text-btn font-sm border-primary-black"
          onClick={() => setActiveContent("createMessage")}
        >
          Mesaj Yaz
        </button>
      </div>
      <div className="flex flex-col overflow-y-auto overscroll-contain h-max lg:max-h-[23rem]">
        {conversations?.map((conversation, index) => (
          <div
            className="flex flex-col"
            key={index}
            onClick={() => {
              setFriendForMessageDetails(
                conversation?.subscription
                  ? conversation?.friendObj
                  : conversation?.friend
              );
              setActiveContent("messageDetails");
            }}
          >
            <div className="flex p-4 border-b cursor-pointer border-secondary-lines">
              <div className="flex-shrink-0 flex w-12 h-12 mr-4 overflow-hidden border-2 rounded-full bg-secondary-titles items-center justify-center">
                {conversation?.subscription === true ? (
                  <>
                    {conversation?.friendObj?.profile_image ? (
                      <img
                        src={conversation?.friendObj?.profile_image}
                        alt={conversation?.friendObj?.name}
                        className="object-center object-cover w-full h-full p-10 z-50"
                      />
                    ) : (
                      <svg
                        role="img"
                        className="w-8 h-8 text-white"
                      >
                        <use xlinkHref="/assets/img/necromancers.svg#user" />
                      </svg>
                    )}
                  </>
                ) : (
                  <>
                    {conversation?.friend?.profile_image ? (
                      <img
                        src={conversation?.friend?.profile_image}
                        alt={conversation?.friend?.name}
                        className="object-center object-cover w-full h-full z-50"
                      />
                    ) : (
                      <svg
                        role="img"
                        className="w-8 h-8 text-white"
                      >
                        <use xlinkHref="/assets/img/necromancers.svg#user" />
                      </svg>
                    )}
                  </>
                )}
              </div>
              <div>
                {conversation?.subscription ? (
                  <h4
                    className={classNames(
                      !conversation?.lastMessage?.seen &&
                        conversation?.lastMessage?.from !== user?.objectId &&
                        "font-bold",
                      "text-body"
                    )}
                  >
                    {conversation?.friendObj?.name}
                  </h4>
                ) : (
                  <h4
                    className={classNames(
                      !conversation?.message?.seen &&
                        conversation?.message?.from !== user?.objectId &&
                        "font-bold",
                      "text-body"
                    )}
                  >
                    {conversation?.friend?.name}
                  </h4>
                )}

                {conversation?.subscription ? (
                  <p
                    className={classNames(
                      !conversation?.lastMessage?.seen &&
                        conversation?.lastMessage?.from !== user?.objectId &&
                        "font-bold",
                      "text-body"
                    )}
                  >
                    {conversation?.lastMessage?.message?.slice(0,35)} {conversation?.lastMessage?.message?.length > 35 && ".."}
                  </p>
                ) : (
                  <p
                    className={classNames(
                      !conversation?.message?.seen &&
                        conversation?.message?.from !== user?.objectId &&
                        "font-bold",
                      "text-body"
                    )}
                  >
                    {conversation?.message?.message?.slice(0,35)} {conversation?.message?.message?.length > 35 && ".."}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
