import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LoadingModal from "../../../components/shared/LoadingModal";
import { removeUserGalleryById } from "../../../services/contentService";

export default function Album({
  album,
  setPhotoIndex,
  setIsLightboxOpen,
  setAlbumIndex,
  albumIndex,
  from,
  albums,
  setAlbums,
}) {
  const [loading, setLoading] = useState(false);
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [isDeleteHovered, setIsDeleteHovered] = useState(false);
  const { images, createdAt, user, text, title } = album?.toJSON();
  const appSlice = useSelector((state) => state?.app);
  const ownUser = appSlice?.user;

  const removeGallery = async () => {
    if (!ownUser) throw new Error("Unauthorized");
    setLoading(true);
    try {
      await removeUserGalleryById(album?.id);
      const albumsCopy = albums;
      const updatedAlbums = albumsCopy.filter(
        (gallery) => gallery.id !== album.id
      );
      setAlbums(updatedAlbums);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="flex-col col-span-4 px-4 py-6 border border-secondary-lines lg:flex"
      key={album?.id}
    >
      {loading && <LoadingModal />}
      <p className="mb-2 text-primary-red text-btn">
        {new Date(createdAt)?.toLocaleString("tr-TR")?.split(" ")[0]}
      </p>
      {from === "kale" ? (
        <p className="mb-6 text-xs font-xs">{text}</p>
      ) : (
        user && (
          <p className="mb-6 text-xs font-xs">
            {title ? title : `${user?.Name} Albümü`}
          </p>
        )
      )}

      <div className="flex flex-col gap-4">
        {images?.map((img, index) => {
          if (index === 0) {
            return (
              <img
                src={img?.url}
                style={{
                  width: 376,
                  height: 376,
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                key={index}
                draggable="false"
                onClick={() => {
                  setPhotoIndex(index);
                  setIsLightboxOpen(true);
                  setAlbumIndex(albumIndex);
                }}
                className="w-full cursor-pointer"
                alt="img"
              />
            );
          } else {
            return <span key={index} className="sr-only"></span>;
          }
        })}

        <div className="flex gap-4">
          {images?.length === 1 && <div style={{ width: 374, height: 114 }} />}
          {images?.map((img, index) => {
            if (index !== 0 && index < 3) {
              return (
                <img
                  src={img?.url}
                  key={index}
                  draggable="false"
                  style={{
                    width: 114,
                    height: 114,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  className="w-full cursor-pointer"
                  alt="img"
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsLightboxOpen(true);
                    setAlbumIndex(albumIndex);
                  }}
                />
              );
            } else {
              return <span key={index} className="sr-only"></span>;
            }
          })}
          {images?.length > 3 && (
            <Link
              to={
                from === "kale"
                  ? `/gallery/${album?.id}`
                  : `/user-gallery/${album?.id}`
              }
            >
              <div
                className="relative cursor-pointer"
                onClick={() => {
                  setPhotoIndex(3);
                  setIsLightboxOpen(true);
                  setAlbumIndex(albumIndex);
                }}
              >
                <img
                  src={images[3]?.url}
                  className="w-full opacity-20"
                  alt="img"
                  style={{
                    width: 114,
                    height: 114,
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <p className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-xs text-secondary-primary">
                  {images?.length - 3}+
                </p>
              </div>
            </Link>
          )}
        </div>
        {ownUser && from === "user" && (user && user?.objectId === ownUser?.objectId) && (
          <div className="flex justify-end gap-4">
            <Link
              to={`/user-gallery/edit/${album?.id}`}
              className="py-4 btn px-7 relative"
              onMouseEnter={() => setIsEditHovered(true)}
              onMouseLeave={() => setIsEditHovered(false)}
            >
              {isEditHovered  && <div className="absolute -top-9 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-2 leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md text-center" style={{minWidth:80, textTransform:'capitalize'}}>
                  Düzenle
                </span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
              </div>}

              <svg role="img" className="w-5 h-5 text-primary-black">
                <use xlinkHref="/assets/img/necromancers.svg#edit" />
              </svg>
            </Link>
            <button
              className="py-4 btn border-primary-red px-7 relative"
              onMouseEnter={() => setIsDeleteHovered(true)}
              onMouseLeave={() => setIsDeleteHovered(false)}
              onClick={() => removeGallery()}
            >
              {isDeleteHovered  && <div className="absolute -top-9 flex flex-col items-center mb-6 group-hover:flex">
                <span className="relative z-10 p-2 leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md text-center" style={{minWidth:80, textTransform:'capitalize'}}>
                  Sil
                </span>
                <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
              </div>}
              <svg role="img" className="w-5 h-5 text-primary-black">
                <use xlinkHref="/assets/img/necromancers.svg#delete" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
