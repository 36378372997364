import React, { useEffect, useState } from "react";
import AuthLayout from "../../../layouts/AuthLayout";
import {
  getSingleActivity,
  isUserAttendActivity,
  attendActivity,
  leaveActivity,
} from "../../../services/contentService";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingModal from "../../../components/shared/LoadingModal";
import { ActivityModal } from "../../../components/shared/ActivityModal";
import Modal from "../../../components/shared/Modal";

const ActivityDetail = () => {
  const [isUserAttended, setIsUserAttended] = useState(false);
  const [activity, setActivity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showSuccessModalForLeave, setShowSuccessModalForLeave] =
    useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [apiError, setApiError] = useState("");
  const user = useSelector((state) => state.app.user);

  const { id } = useParams();
  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getSingleActivity(id);
        setActivity(response);
        setLoading(false);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsUserAttendActivity = async (activityId) => {
    try {
      setLoading(true);
      const response = await isUserAttendActivity(activityId, user?.objectId);
      if (response) {
        setIsUserAttended(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      handleIsUserAttendActivity(activity?.objectId, user?.objectId);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [activity?.objectId, user?.objectId]);

  const isUserAttendActivityMarkup = () => {
    if (isUserAttended) {
      return (
        <button
          onClick={() => handleLeaveActivity(activity?.objectId)}
          className="w-full uppercase max-w-none lg:w-28 bg-primary-red form-submit-btn revert"
        >
          Vazgeç
        </button>
      );
    } else {
      if (activity?.capacity < 1) {
        return (
          <div className="activity-attend-button">
            <button
              onClick={() => setShow(true)}
              className="w-full uppercase max-w-none lg:w-48 bg-primary-gray form-submit-btn"
            >
              KONTENJAN DOLU
            </button>
          </div>
        );
      } else {
        return (
          <div className="activity-attend-button">
            <button
              onClick={() => setShow(true)}
              className="w-full uppercase max-w-none lg:w-48 bg-primary-red form-submit-btn"
            >
              ETKİNLİĞE KATIL
            </button>
          </div>
        );
      }
    }
  };

  const handleAttendActivity = async () => {
    try {
      setShow(false);
      setLoading(true);
      await attendActivity(activity.objectId);
      setIsUserAttended(true);
      setActivity({
        ...activity,
        attendies: activity.attendies + 1,
        capacity: activity.capacity - 1,
      });
      setShowSuccessModal(true);
    } catch (e) {
      setShowErrorModal(true);
      setApiError(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleLeaveActivity = async (id) => {
    try {
      setLoading(true);
      await leaveActivity(id);
      setIsUserAttended(false);
      setActivity({
        ...activity,
        attendies: activity.attendies - 1,
        capacity: activity.capacity + 1,
      });
      setShowSuccessModalForLeave(true);
    } catch (e) {
      setApiError(e.message);
      setShowErrorModal(e.message);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      {loading && <LoadingModal />}
      {show && (
        <ActivityModal
          shouldShow={show}
          updateModalState={(show) => setShow(show)}
          accept={handleAttendActivity}
          terms={activity && activity?.terms}
        />
      )}
      <Modal
        shouldShow={showSuccessModal}
        updateModalState={(show) => setShowSuccessModal(show)}
        msg="Etkinliğe başarıyla katıldınız."
        type="success"
      />
      <Modal
        shouldShow={showErrorModal}
        updateModalState={(show) => setShowErrorModal(show)}
        msg={apiError}
        type="error"
      />
      <Modal
        shouldShow={showSuccessModalForLeave}
        updateModalState={(show) => setShowSuccessModalForLeave(show)}
        msg="Etkinliğe katılmaktan başarıyla vazgeçtiniz."
        type="success"
      />
      <div className="container flex flex-col items-center justify-center max-w-full mx-auto bg-background-teal lg:px-0 lg:pt-0">
        <div className="p-0 px-1 max-w-7xl">
          <img src={activity?.image?.url} alt="activity-img" />
        </div>

        <div className="items-start w-full px-1 py-12 mx-auto lg:pt-16 lg:pb-28 max-w-7xl">
          <div className="flex flex-col justify-between flex-1 px-4 pb-2 lg:px-0 lg:flex-row">
            <div className="flex flex-col">
              <h2 className="max-w-xxl text-md font-xxl lg:text-xxl">
                {activity?.title}
              </h2>
              <h4 className="mt-4 text-md font-md">
                {new Date(activity?.activityDate?.iso).getDate()}{" "}
                {monthNames[new Date(activity?.activityDate?.iso).getMonth()]}{" "}
                {new Date(activity?.activityDate?.iso).getFullYear()}
              </h4>
              <div className="flex gap-5 pt-2">
                <div className="relative">
                  {activity?.city && (
                    <span className="flex items-center text-secondary-secondary text-body-s">
                      {activity?.city?.NAME} / {activity?.region?.NAME}
                    </span>
                  )}
                </div>
                <div className="relative">
                  <span className="flex items-center text-secondary-secondary text-body-s list-dot">
                    Etkinlik Bitiş Tarihi;{" "}
                    {new Date(activity?.deadlineDate?.iso).getDate()}{" "}
                    {
                      monthNames[
                        new Date(activity?.deadlineDate?.iso).getMonth()
                      ]
                    }{" "}
                    {new Date(activity?.deadlineDate?.iso).getFullYear()}
                  </span>
                </div>
              </div>
              <div className="flex gap-6 mt-4 lg:mt-2">
                <div className="relative">
                  <span className="flex items-center text-secondary-primary font-md text-body-s">
                    <svg
                      className="w-5 h-5 mr-2 text-secondary-primary "
                      role="img"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#user" />
                    </svg>
                    {activity?.capacity + activity?.attendies} Kişilik
                  </span>
                </div>
                <div className="relative">
                  <span className="flex items-center text-secondary-primary text-body-s font-md span-dots list-dot black-dot">
                    <svg
                      className="w-5 h-5 mr-2 text-secondary-primary "
                      role="img"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#time" />
                    </svg>
                    {activity?.duration} saat
                  </span>
                </div>
                <div className="relative">
                  <span className="flex items-center text-secondary-primary font-md text-body-s list-dot black-dot">
                    <svg
                      className="w-5 h-5 mr-2 text-secondary-primary "
                      role="img"
                    >
                      <use xlinkHref="/assets/img/necromancers.svg#friends" />
                    </svg>
                    {activity?.attendies} Kişi katıldı
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col pt-6 lg:pt-0">
              <p className="max-w-md text-xs font-xs">
                {activity?.description}
              </p>
              {isUserAttendActivityMarkup()}
            </div>
          </div>
        </div>
      </div>

      <div
        className="relative flex flex-col w-full px-4 pt-16 pb-20 mx-auto max-w-7xl lg:px-1"
        id="content"
      >
        <h5 className="mb-6 text-sm text-black">Katılım Şartları/Detayları</h5>
        {activity?.terms && (
          <p className="text-left max-w-7xl">
            {activity?.terms.split("\n").map((line, index) => (
              <span key={index} className="block">
                {line}
              </span>
            ))}
          </p>
        )}
      </div>
    </AuthLayout>
  );
};

export default ActivityDetail;
