import React from "react";
import { Link } from "react-router-dom";

function Reply({ reply }) {
  return (
    <div className="flex pl-16 mt-6">
      <div className="flex w-full">
        <Link to={`/profile/${reply?.user?.objectId}`}>
          <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 w-12 h-12 mr-3 overflow-hidden rounded-full bg-secondary-titles">
            {reply?.user?.profile_image ? (
              <img
                src={
                  reply?.user?.profile_image?.url || reply?.user?.profile_image
                }
                alt={reply?.user?.name || reply?.user?.Name}
                className="w-full h-full object-center object-cover"
              />
            ) : (
              <>
                <span className="sr-only">User</span>
                <svg role="img" className="w-8 h-8 text-white">
                  <use xlinkHref="/assets/img/necromancers.svg#user" />
                </svg>
              </>
            )}
          </div>
        </Link>
        <div className="w-full">
          <div className="relative flex flex-col gap-3 p-3 bg-secondary-secondary-bg triangle">
            <Link to={`/user/${reply?.user?.objectId}`}>
              <p className="font-sm">
                {reply?.user?.name || reply?.user?.Name}
              </p>
            </Link>
            <p>{reply?.comment}</p>
            <p className="text-body-s text-secondary-secondary">
              {new Date(reply?.createdAt).toLocaleString()}
            </p>
          </div>
          {/* <button className="mt-3 text-body text-secondary-secondary">
            Yanıtla
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Reply;
