import React, { useEffect } from "react";
import Parse from "parse";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser, logout } from "./services/authService";
import { SET_USER } from "./store/app/appSlice";
import {
  SET_UNREAD_CONVERSATIONS_COUNT,
  INCREASE_UNREAD_CONVERSATIONS_COUNT,
} from "./store/message/messageSlice";
import { useLocation } from "react-router-dom";
import { getTotalUnreadConversations } from "./services/contentService";

export default function AuthProvider(props) {
  const appSlice = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (!appSlice.user) {
        try {
          const user = await getCurrentUser();
          if (!user) {
            await logout();
          } else {
            dispatch(SET_USER(user?.toJSON()));
          }
        } catch (error) {
          if (error.code === 209) {
            dispatch(SET_USER(null));
            await logout();
            window.location.href = "/";
          }
        }
      } else {
        try {
          //eslint-disable-next-line
          const user = await getCurrentUser();
        } catch (error) {
          if (error.code === 209) {
            dispatch(SET_USER(null));
            await logout();
            window.location.href = "/";
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!appSlice.user) return;

    const getUnreadConversations = async () => {
      try {
        const conversationsCount = await getTotalUnreadConversations();
        console.log(conversationsCount);
        dispatch(SET_UNREAD_CONVERSATIONS_COUNT(conversationsCount));
      } catch (error) {
        console.log(error);
      }
    };

    getUnreadConversations();

    let conversationSubscription;

    const conversationsLQ = async () => {
      let query = new Parse.Query("SocialMessage");
      query.equalTo("to", {
        objectId: appSlice.user.objectId,
      });

      conversationSubscription = await query.subscribe();

      // conversationSubscription.on("open", () => {
      //   console.log("conversation subscription opened");
      // });

      conversationSubscription.on("create", (conversation) => {
        dispatch(INCREASE_UNREAD_CONVERSATIONS_COUNT());
      });

      // conversationSubscription.on("close", () => {
      //   console.log("conversation subscription closed");
      // });
    };

    conversationsLQ();

    return () => {
      if (conversationSubscription) {
        conversationSubscription.unsubscribe();
      }
    };
  }, [appSlice.user, dispatch]);

  return <>{props.children}</>;
}
