import React from "react";
import { Link } from "react-router-dom";

export default function EventsAndCalendar() {
  return (
    <div className="p-4 lg:py-24" data-testid="events-and-calender-component">
      <main className="flex flex-col items-center gap-4 px-1 mx-auto lg:grid lg:grid-cols-2 lg:gap-6 max-w-7xl">
        <div className="widget-events">
          <div className="inline-flex flex-col justify-between p-8 space-y-4 lg:flex-1 lg:items-start lg:justify-end">
            <div className="space-y-1">
              <h2 className="text-center text-white lg:text-left text-md lg:text-lg">
                Etkinlik Takvimi
              </h2>
            </div>
            <Link to="/activity" className="btn white">
              TÜMÜNE BAK
            </Link>
          </div>
        </div>
        <div className="widget-calculation">
          <div className="inline-flex flex-col justify-between p-8 space-y-4 lg:flex-1 lg:items-start lg:justify-end">
            <div className="space-y-1">
              <h2 className="text-center text-white lg:text-left text-md lg:text-lg">
                Tüketim Hesaplama
              </h2>
            </div>
            <Link to="/consumption-calc" className="btn white">
              ŞİMDİ HESAPLA
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}
