import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import Modal from "../../../../components/shared/Modal";
import {
  isEmpty,
  isEmail,
  isLengthEqual,
} from "../../../../util/FormValidationRules";
import classNames from "classnames";
import SmsVerification from "./SmsVerification";
import {
  StartOTPBayi,
  ConfirmOTPBayi,
  sendEmailVerification,
} from "../../../../services/authService";
import { useDispatch } from "react-redux";
import { SET_USER } from "../../../../store/app/appSlice";

export default function CommunicationInformation({
  userData,
  setUserData,
  errors,
  setErrors,
  user,
}) {
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [duration, setDuration] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [code, setCode] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [usernameBorderColor, setUsernameBorderColor] = useState(
    "rgb(206 205 217/var(--tw-border-opacity))"
  );
  const [emailBorderColor, setEmailBorderColor] = useState(
    "rgb(206 205 217/var(--tw-border-opacity))"
  );
  const dispatch = useDispatch();
  const handleEmailVerification = async () => {
    try {
      await sendEmailVerification(user?.email ? user.email : user?.Email);
      setOpenSuccessModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && !user?.emailVerified && !errors.emailEmpty && errors.isEmail) {
      setEmailBorderColor("#39adf7");
    } else if (
      user &&
      user?.emailVerified &&
      !errors.emailEmpty &&
      errors.isEmail
    ) {
      setEmailBorderColor("#1dc066");
    } else if (errors?.emailEmpty || !errors.isEmail) {
      setEmailBorderColor("rgb(255 117 117 / var(--tw-border-opacity))");
    } else {
      setEmailBorderColor("rgb(206 205 217/var(--tw-border-opacity))");
    }

    if (
      user &&
      !user?.mobile_verified &&
      !errors.usernameEmpty &&
      errors.usernameLengthCorrect
    ) {
      setUsernameBorderColor("#39adf7");
    } else if (
      user &&
      user?.mobile_verified &&
      !errors.usernameEmpty &&
      errors.usernameLengthCorrect
    ) {
      setUsernameBorderColor("#1dc066");
    } else if (!errors.usernameEmpty || !errors.usernameLengthCorrect) {
      setUsernameBorderColor("rgb(255 117 117 / var(--tw-border-opacity))");
    } else {
      setUsernameBorderColor("rgb(206 205 217/var(--tw-border-opacity))");
    }
    //eslint-disable-next-line
  }, [user, errors]);

  const handleSmsClick = () => {
    StartOTPBayi(userData.username)
      .then((result) => {
        setJwt(result.jwt);
        setDuration(
          Math.round((Date.parse(`${result.expiresAt}`) - Date.now()) / 1000)
        );
        setShowSmsModal(true);
      })
      .catch((error) => {
        console.log(error);
        setErrMsg(error.message);
        setErrorModal(true);
        setJwt(null);
        setShowSmsModal(false);
      });
  };

  const handleAfterSubmit = () => {
    setIsLoading(true);
    ConfirmOTPBayi(jwt, code)
      .then((result) => {
        if (!result.error) {
          dispatch(SET_USER({ ...user, mobile_verified: true }));
          setShowSmsModal(false);
          setSuccessMsg(result);
          setShowSuccessModal(true);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setErrMsg(e.message);
        setIsLoading(false);
      });
  };
  return (
    <div className="prose-xxl">
      <Modal
        shouldShow={openSuccessModal}
        type="success"
        msg={`${user?.email ? user.email : user?.Email} mail adresine doğrulama e-postası gönderildi. Lütfen maildeki linke tıklayıp mail adresinizi doğrulayınız.`}
        updateModalState={(state) => setOpenSuccessModal(state)}
      />

      <SmsVerification
        isLoading={isLoading}
        code={code}
        setCode={(state) => setCode(state)}
        handleAfterSubmit={handleAfterSubmit}
        jwt={jwt}
        setJwt={(state) => setJwt(state)}
        setDuration={(state) => setDuration(state)}
        duration={duration}
        shouldShow={showSmsModal}
        updateModalState={(state) => setShowSmsModal(state)}
        successMsg={successMsg}
        setSuccessMsg={(state) => setSuccessMsg(state)}
        errMsg={errMsg}
        setErrMsg={(state) => setErrMsg(state)}
      />
      {showSuccessModal && (
        <Modal
          // handleAfterClose={handleSuccess}
          type="success"
          msg={successMsg}
          updateModalState={(state) => setShowSuccessModal(state)}
          shouldShow={showSuccessModal}
        />
      )}
      {errorModal && (
        <Modal
          // handleAfterClose={handleSuccess}
          type="error"
          msg={errMsg || "Bir hata oluştu"}
          updateModalState={(state) => setErrorModal(state)}
          shouldShow={errorModal}
        />
      )}
      <div className="max-w-sm mx-auto">
        <div className="mb-8">
          <div className="form-input-wrapper">
            <input
              type="email"
              id="email-address"
              autoComplete="cc-given-email-address"
              name="email-address"
              placeholder=" "
              onBlur={(e) =>
                setErrors({
                  ...errors,
                  emailEmpty: isEmpty(e.target.value),
                  isEmail: isEmail(e.target.value),
                })
              }
              value={userData?.email ? userData.email : userData?.Email}
              onChange={(e) => {
              const newEmail = e.target.value;
              if ('email' in userData && userData.email !== undefined) {
              setUserData({ ...userData, email: newEmail });
            } else {
              setUserData({ ...userData, Email: newEmail });
            }
            }}
              style={{ borderColor: emailBorderColor }}
              className="bg-transparent form-input"
            />
            <label
              htmlFor="email-address"
              className="bg-transparent form-label"
            >
              Mail Adresiniz*
            </label>
            {(errors?.emailEmpty || !errors?.isEmail) && (
              <svg
                role="img"
                className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
              >
                <use xlinkHref="/assets/img/necromancers.svg#error" />
              </svg>
            )}
            {errors?.emailEmpty && (
              <p
                role="alert"
                className="mt-2 text-validation-error"
                id="email-error"
              >
                Zorunlu alan.
              </p>
            )}
            {!errors?.emailEmpty && !errors?.isEmail && (
              <p
                role="alert"
                className="mt-2 text-validation-error"
                id="email-error"
              >
                Email formatında olmalı.
              </p>
            )}
            {user &&
              !user?.emailVerified &&
              !errors.emailEmpty &&
              errors.isEmail && (
                <svg
                  className="absolute w-6 h-6 p-1 text-white rounded-full right-4 top-4 bg-tertiary-blue text-body"
                  aria-hidden="true"
                  focusable="false"
                >
                  <use xlinkHref="/assets/img/necromancers.svg#info" />
                </svg>
              )}
            {user &&
              !user?.emailVerified &&
              !errors.emailEmpty &&
              errors.isEmail && (
                <div className="flex justify-between mt-1">
                  <span
                    className="font-bold underline text-tertiary-blue text-body-s hover:cursor-pointer"
                    onClick={() => handleEmailVerification()}
                  >
                    Şimdi Doğrula
                  </span>
                  <p className="text-tertiary-blue text-body-s">
                    Doğrulanmamış Mail Adresi
                  </p>
                </div>
              )}

            {user &&
              user?.emailVerified &&
              !errors.emailEmpty &&
              errors.isEmail && (
                <svg
                  className="absolute w-6 h-6 p-1 text-white rounded-full right-4 top-4 bg-validation-confirmation text-body"
                  aria-hidden="true"
                  focusable="false"
                >
                  <use xlinkHref="/assets/img/necromancers.svg#check" />
                </svg>
              )}
            {user &&
              user?.emailVerified &&
              !errors.emailEmpty &&
              errors.isEmail && (
                <div className="flex justify-end mt-1">
                  <p className="text-validation-confirmation text-body-s">
                    Doğrulanmış Mail Adresi
                  </p>
                </div>
              )}
          </div>
        </div>
        <div className="form-input-wrapper">
          <InputMask
            name="phone"
            type="tel"
            id="phone-mask"
            className={classNames("bg-transparent form-input phone", {
              "border-blue-400":
                user && !user?.mobile_verified && !errors.usernameEmpty,
              "border-green-400":
                user && user?.mobile_verified && !errors.emailEmpty,
            })}
            placeholder=" "
            onBlur={(e) =>
              setErrors({
                ...errors,
                usernameEmpty: isEmpty(
                  e.target.value.replace(/([^0-9])+/g, "")
                ),
                usernameLengthCorrect: isLengthEqual(
                  e.target.value.replace(/([^0-9])+/g, ""),
                  10
                ),
              })
            }
            mask="0 (999) 999 99 99"
            value={userData?.username}
            onChange={(e) =>
              setUserData({
                ...userData,
                username: e.target.value.replace(/([^0-9])+/g, "").substring(1),
              })
            }
            maskplaceholder="_"
            data-testid="phone-input"
            style={{ borderColor: usernameBorderColor }}
          />
          <label htmlFor="phone" className="bg-transparent form-label">
            Cep Telefon Numaranız*
          </label>
          {(errors?.usernameEmpty || !errors?.usernameLengthCorrect) && (
            <svg
              role="img"
              className="w-5 h-5 text-validation-error absolute top-1/4 right-3"
            >
              <use xlinkHref="/assets/img/necromancers.svg#error" />
            </svg>
          )}
          {errors?.usernameEmpty && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              Zorunlu alan.
            </p>
          )}
          {!errors?.usernameEmpty && !errors?.usernameLengthCorrect && (
            <p
              role="alert"
              className="mt-2 text-validation-error"
              id="email-error"
            >
              11 hane olmalı.
            </p>
          )}

          {user &&
            !user?.mobile_verified &&
            !errors.usernameEmpty &&
            errors.usernameLengthCorrect && (
              <svg
                className="absolute w-6 h-6 p-1 text-white rounded-full right-4 top-4 bg-tertiary-blue text-body"
                aria-hidden="true"
                focusable="false"
              >
                <use xlinkHref="/assets/img/necromancers.svg#info" />
              </svg>
            )}

          {user &&
            !user?.mobile_verified &&
            !errors.usernameEmpty &&
            errors.usernameLengthCorrect && (
              <div className="flex justify-between mt-1">
                <span
                  onClick={handleSmsClick}
                  className="font-bold underline text-tertiary-blue text-body-s hover:cursor-pointer"
                >
                  Şimdi Doğrula
                </span>
                <p className="text-tertiary-blue text-body-s">
                  Doğrulanmamış Telefon Numarası
                </p>
              </div>
            )}

          {user && user?.mobile_verified && !errors.usernameEmpty && (
            <svg
              className="absolute w-6 h-6 p-1 text-white rounded-full right-4 top-4 bg-validation-confirmation text-body"
              aria-hidden="true"
              focusable="false"
            >
              <use xlinkHref="/assets/img/necromancers.svg#check" />
            </svg>
          )}

          {user && user?.mobile_verified && !errors.usernameEmpty && (
            <div className="flex justify-end mt-1">
              <p className="text-validation-confirmation text-body-s">
                Doğrulanmış Telefon Numarası
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
