import classNames from "classnames";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import FileUploadModal from "../../../components/shared/FileUploadModal";
import LoadingModal from "../../../components/shared/LoadingModal";
import Modal from "../../../components/shared/Modal";
import TemporaryImages from "../../../components/shared/TemporaryImages";
import { createPost, getPosts } from "../../../services/contentService";

export default function CreatePostMobile({ setPosts }) {
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);
  const [commentDenied, setCommentDenied] = useState(true);
  const [mainComponentTemporaryImages, setMainComponentTemporaryImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [textLengthError, setTextLengthError] = useState(false);
  const [mediaType, setMediaType] = useState(null);

  const appSlice = useSelector((state) => state.app);
  const user = appSlice?.user;
  const textInput = useRef();

  const handleCreatePost = async () => {
    if (!user) throw new Error("Unauthorized");
    if(textLengthError) throw new Error('Validation error. Text length is too long.');
    setLoading(true);
    
    try {
      await createPost({ text, userId: user?.objectId, commentDenied, files });
      setShowSuccessMessage(true);
      const postsAfterCreate = await getPosts();
      setPosts(postsAfterCreate);
      setFiles([]);
      setMainComponentTemporaryImages([]);
      setText("");
      textInput.current.value = "";
    } catch (error) {
      setApiError(error.message);
      setShowErrorMessage(true);
    } finally {
      setLoading(false);
    }
  };

  const removeImage = (mainIndex, index, name) => {
    const temporaryImagesCopy = mainComponentTemporaryImages;
    const filesCopy = files;
    const tempImg = temporaryImagesCopy[mainIndex][index];
    const tempFile = filesCopy[mainIndex][index];

    if (tempImg.name === name) temporaryImagesCopy[mainIndex].splice(index, 1);
    if (temporaryImagesCopy[mainIndex]?.length === 0)
      temporaryImagesCopy.splice(mainIndex, 1);
    setMainComponentTemporaryImages([...temporaryImagesCopy]);

    if (tempFile.name === name) filesCopy[mainIndex].splice(index, 1);
    if (filesCopy[mainIndex]?.length === 0) filesCopy.splice(mainIndex, 1);
    setFiles([...filesCopy]);
  };

  const modalOnSubmit = () => {
    const temp = [];
    const temporaryFiles = [];
    for (let i = 0; i < files.length; i++) {
      for (let y = 0; y < files[i].length; y++) {
        const mainIndex = i;
        const index = y;
        const url = URL.createObjectURL(files[i][y]);
        const extension =
          files[i][y]?.name?.split(".")[
            files[i][y]?.name?.split(".").length - 1
          ];
        const name = files[i][y].name;
        temp.push(files[i][y]);
        temporaryFiles.push({
          mainIndex,
          index,
          name,
          url,
          extension,
        });
      }
    }
    setMainComponentTemporaryImages([[...temporaryFiles]]);
    setShowModal(false);
  };

  useEffect(() => {
    if (text?.length >= 0 && text.length <= 100) {
      setTextLengthError(false);
    }else{
      setTextLengthError(true);
    }
  },[text?.length])


  return (
    <div className="flex p-6 mt-12 border lg:hidden border-secondary-lines">
      <Modal
        shouldShow={showSuccessMessage}
        type="success"
        msg="Gönderi başarıyla paylaşıldı."
        updateModalState={(state) => setShowSuccessMessage(state)}
      />
      <Modal
        shouldShow={showErrorMessage}
        type="error"
        msg={apiError ? apiError : "Profil güncellenirken hata oluştu."}
        updateModalState={(state) => setShowErrorMessage(state)}
      />
      {loading && <LoadingModal />}
      {showModal && (
        <FileUploadModal
          setShowModal={(show) => setShowModal(show)}
          files={files}
          setFiles={(files) => setFiles(files)}
          onSubmit={() => modalOnSubmit()}
          mainComponentTemporaryImages={mainComponentTemporaryImages}
          setMainComponentTemporaryImages={(img) =>
            setMainComponentTemporaryImages(img)
          }
          shouldClearFilesOnClose={false}
          mediaType={mediaType}
        />
      )}
      <div className="flex flex-col w-full">
        <p className="text-md text-secondary-titles font-md">
          Merhaba {user?.Name?.split(" ")[0]},
        </p>
        <div className="relative my-6">
          <div className={classNames(textLengthError ? "border-validation-error" : "border-secondary-lines","form-input-wrapper border")}>
            <textarea
              type="text"
              id="text"
              ref={textInput}
              autoComplete="text"
              name="text"
              placeholder=" "
              className="h-full bg-transparent resize-none form-input max-h-32"
              value={text}
              onChange={(e) => setText(e.target.value)}
            ></textarea>
            <label htmlFor="text" className="bg-transparent form-label">
              Bir Şeyler Paylaşın!
            </label>
            <div className="absolute bottom-4 left-4">
              <div className="form-input-wrapper">
                <input
                  type="checkbox"
                  id="kk-permission"
                  name="kk-permission"
                  className="form-input"
                  checked={commentDenied ? true : false}
                  onChange={(e) =>
                    setCommentDenied(e.target.checked ? true : false)
                  }
                />
                <label
                  htmlFor="kk-permission"
                  className="ml-2 form-label text-body-s text-secondary-titles"
                >
                  Yoruma Kapalı
                </label>
              </div>
            </div>
            <span className="absolute right-4 bottom-4 text-secondary-secondary text-body-s">
              {text?.length || 0}/100
            </span>
          </div>
        </div>
        <TemporaryImages
          temporaryImages={mainComponentTemporaryImages}
          removeImage={removeImage}
        />
        <div className="flex flex-col items-center justify-between">
          <div className="flex justify-between w-full gap-8">
            <button
              type="button"
              className="flex items-center text-body-s text-secondary-secondary font-sm"
              onClick={() => {
                setMediaType("image");
                setShowModal(true)
              }}
            >
              <svg role="img" className="w-5 h-5 mr-2 text-primary-red">
                <use xlinkHref="/assets/img/necromancers.svg#add-picture" />
              </svg>
              Resim Ekle
            </button>
            <button
              type="button"
              className="flex items-center text-body-s text-secondary-secondary font-sm"
              onClick={() => {
                setMediaType("video");
                setShowModal(true);
              }}
            >
              <svg role="img" className="w-5 h-5 mr-2 text-primary-red">
                <use xlinkHref="/assets/img/necromancers.svg#add-video" />
              </svg>
              Video Ekle
            </button>
          </div>
          <div className="w-full">
            <button
              type="button"
              className="w-full max-w-full mt-6 uppercase form-submit-btn"
              onClick={() => handleCreatePost()}
            >
              YAYINLA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
