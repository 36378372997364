import React from "react";
import { useNavigate } from "react-router-dom";

const KalekimTab = ({ catalogs }) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 gap-10 px-4 lg:grid-cols-3 lg:px-0">
      {catalogs?.map((doc) => {
        const docToRender = doc.toJSON();
        return (
          <div key={docToRender.objectId} className="hover:text-red-400 ">
            {docToRender?.file && !docToRender?.url && (
              <a
                href={`${docToRender?.file?.url}`}
                className="flex items-center"
              >
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${docToRender?.thumbnail?.url}`}
                  alt={docToRender?.thumbnail?.name}
                />
                <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
              </a>
            )}
            {docToRender?.file && docToRender?.url && (
              <a
                href={`${docToRender?.url}`}
                className="flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${docToRender?.thumbnail?.url}`}
                  alt={docToRender?.thumbnail?.name}
                />
                <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
              </a>
            )}
            {!docToRender?.file &&
            docToRender?.url &&
            docToRender?.url.includes("iframe") ? (
              <button
                className="flex items-center"
                onClick={() =>
                  navigate(`/offsite-source`, {
                    state: { url: docToRender?.url },
                  })
                }
              >
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${docToRender?.thumbnail?.url}`}
                  alt={docToRender?.thumbnail?.name}
                />
                <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
              </button>
            ) : (
              <a
                href={`${docToRender?.url}`}
                className="flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={`${docToRender?.thumbnail?.url}`}
                  alt={docToRender?.thumbnail?.name}
                />
                <p className="ml-4 text-xs font-xs">{docToRender?.title}</p>
              </a>
            )}
          </div>
        );
      })}
      {/* <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/dummy-product-1.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Su Yatılım Uygulamaları</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/dummy-product-3.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Seramik Uygulamaları</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/dummy-product-5.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Teknik Uygulamalar</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/dummy-product-2.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Zemin Uygulamaları</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/dummy-product-4.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Boya ve Dekoratif Uygulamalar</p>
      </Link>
      <Link to="#" class="flex items-center">
        <img src="/assets/img/dummy/dummy-product-6.jpg" alt="" />
        <p class="ml-4 text-xs font-xs">Isı Yalıtım Uygulamaları</p>
      </Link> */}
    </div>
  );
};
export default KalekimTab;
