import React, { useState } from "react";
import CreateNewAlbum from "./CreateNewAlbum";
import Lightbox from "react-image-lightbox";
import Album from "./Album";

export default function GalleryFromUser({
  userAlbums: albums,
  setUserAlbums: setAlbums,
}) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [albumIndex, setAlbumIndex] = useState(0);

  return (
    <div className="flex flex-col">
      <CreateNewAlbum
        albums={albums}
        setAlbums={(albums) => setAlbums(albums)}
      />
      <div className="grid-cols-12 gap-6 mt-12 lg:grid">
        {isLightboxOpen && (
          <Lightbox
            mainSrc={albums?.[albumIndex]?.get("images")?.[photoIndex]?._url}
            nextSrc={
              albums?.[albumIndex]?.get("images")?.[
                (photoIndex + 1) % albums?.[albumIndex]?.get("images")?.length
              ]?._url
            }
            prevSrc={
              albums?.[albumIndex]?.get("images")?.[
                (photoIndex + albums?.[albumIndex]?.get("images")?.length - 1) %
                  albums?.[albumIndex]?.get("images")?.length
              ]?._url
            }
            onCloseRequest={() => setIsLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + albums?.[albumIndex]?.get("images")?.length - 1) %
                  albums?.[albumIndex]?.get("images")?.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex(
                (photoIndex + 1) % albums?.[albumIndex]?.get("images")?.length
              )
            }
          />
        )}
        {albums?.map((album, index) => (
          <Album
            album={album}
            key={album?.id}
            setIsLightboxOpen={(open) => setIsLightboxOpen(open)}
            isLightboxOpen={isLightboxOpen}
            setAlbumIndex={(state) => setAlbumIndex(state)}
            setPhotoIndex={(index) => setPhotoIndex(index)}
            albumIndex={index}
            from="user"
            albums={albums}
            setAlbums={(albums) => setAlbums(albums)}
          />
        ))}
      </div>
    </div>
  );
}
