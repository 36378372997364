import React, { useState, useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { getNonLoggedInContent } from "../../services/contentService";
import FaqItem from "./components/FaqItem";
import { Helmet } from "react-helmet";

export default function Faq() {
  const [content, setContent] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await getNonLoggedInContent();
        setContent(result);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content" className="lg:p-0">
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  {" "}
                  Sıkça Sorulan Sorular{" "}
                </h1>
                <h6 className="col-span-5">{content?.faq_text}</h6>
              </div>
            </main>
          </div>
          <div className="px-4 mx-auto my-12 max-w-7xl lg:my-16 lg:px-1">
            <div className="flex flex-col gap-6 px-4 lg:grid lg:p-0 ">
              <dl className="space-y-8">
                {content?.faq?.map((faqItem, index) => (
                  <FaqItem key={index} faqItem={faqItem} />
                ))}
              </dl>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
