import classNames from "classnames";
import React, { useState, useEffect } from "react";
import KvkkPolicyModal from "../../../components/shared/KvkkPolicyModal";
import AuthLayout from "../../../layouts/AuthLayout";
import PersonalInformation from "./components/PersonalInformation";
import CommunicationInformation from "./components/CommunicationInformation";
import WorkAndStudyInformation from "./components/WorkAndStudyInformation";
import {
  getBayiFirms,
  getBayiBranches,
  updateBayiProfile,
  logout,
  ConfirmOTPBayi,
} from "../../../services/authService";
import Modal from "../../../components/shared/Modal";
import LoadingModal from "../../../components/shared/LoadingModal";
import { useSelector, useDispatch } from "react-redux";
import { SET_USER } from "../../../store/app/appSlice";
import { Helmet } from "react-helmet";
import SmsVerification from "./components/SmsVerification";

export default function UpdateProfile() {
  const [userData, setUserData] = useState({
    hide_profile: true,
    // isFriendsCanSeeAlbums: false,
    kkEmailPermission: false,
    username: "",
    Email: "",
    // kkKvkkConfirm: false,
    ksEmailPermission: false,
    // ksKvkkConfirm: false,
  });
  const [activeTab, setActiveTab] = useState(1);
  const [kvkkModalOpen, setKvkkModalOpen] = useState(false);
  const [kvkkActiveTab, setKvkkActiveTab] = useState(1);
  const [errors, setErrors] = useState({
    emailEmpty: false,
    isEmail: true,
    usernameEmpty: false,
    usernameLengthCorrect: true,
    placierEmpty: false,
    titleEmpty: false,
    educationEmpty: false,
    firmEmpty: false,
    branchEmpty: false,
    cityEmpty: false,
    regionEmpty: false,
    zipEmpty: false,
    addressEmpty: false,
  });
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firms, setFirms] = useState(null);
  const [branches, setBranches] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [hiddenApproveProfileImage, setHiddenApproveProfileImage] = useState(false);
  const [temporaryProfileImage, setTemporaryProfileImage] = useState(null);
  const user = useSelector((state) => state.app.user);
  const pointData = useSelector((state) => state.app.pointData);
  const dispatch = useDispatch();

  //For the sms verification
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [duration, setDuration] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [code, setCode] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const firms = await getBayiFirms();
      setFirms(firms);
      let branches;
      if (userData.firm) {
        branches = await getBayiBranches(userData?.firm);
      }
      setBranches(branches);
    })();
    //eslint-disable-next-line
  }, [userData?.firm]);

  useEffect(() => {
    (async () => {
      if (isConfirmed) {
        await update();
        await logout();
        window.location.href = "/";
      }
    })();
    //eslint-disable-next-line
  }, [isConfirmed]);

  useEffect(() => {
    (async () => {
      if (user) {
        const {
          Email,
          email,
          username,
          Billing_City__c,
          Billing_Region__c,
          Billing_City__r,
          Billing_Region__r,
          BillingStreet,
          City__r,
          zipCode,
          address,
          placier,
          Title_of_Person__c,
          Class__c,
          Education_Status__c,
          firm,
          branch,
          // isFriendsCanSeeAlbums,
          hide_profile,
          kkEmailPermission,
          ksEmailPermission,
          The_Kale_Company_Work_With__c,
          profile_image,
          Name,
          birthdate,
        } = user;

        setUserData({
          Email,
          email,
          username,
          Billing_City__c,
          Billing_Region__c,
          Billing_City__r,
          Billing_Region__r,
          BillingStreet,
          City__r,
          zipCode,
          address,
          placier,
          Title_of_Person__c,
          Class__c,
          Education_Status__c,
          firm,
          branch,
          // isFriendsCanSeeAlbums,
          // OnlyFriendsCanSee,
          hide_profile,
          kkEmailPermission,
          ksEmailPermission,
          The_Kale_Company_Work_With__c,
          profile_image,
          Name,
          birthdate,
        });
      }
    })();
  }, [user]);

  const updateProfile = async (e) => {
    e.preventDefault();
    if (
      errors.emailEmpty ||
      !errors.isEmail ||
      errors.usernameEmpty ||
      !errors.usernameLengthCorrect ||
      errors.placierEmpty ||
      errors.titleEmpty ||
      errors.educationEmpty ||
      errors.firmEmpty ||
      // errors.branchEmpty ||
      errors.cityEmpty ||
      errors.regionEmpty ||
      errors.zipEmpty ||
      errors.addressEmpty
    ) {
      return;
    } else {
      if (user?.branch !== userData?.branch || user?.firm !== userData?.firm) {
        setOpenWarningModal(true);
      } else {
        await update();
        setHiddenApproveProfileImage(true);
      }
    }
  };

  const update = async () => {
    try {
      setLoading(true);

      const updatedData = {};
      for (const key in userData) {
        if (userData[key] !== user[key]) {
          updatedData[key] = userData[key];
        }
      }

      let updateResponse = await updateBayiProfile(updatedData);
      if (updateResponse.OTPResponse) {
        setLoading(false);
        setJwt(updateResponse.OTPResponse.jwt);
        setDuration(Math.round((Date.parse(`${updateResponse.OTPResponse.expiresAt}`) - Date.now()) / 1000));
        setShowSmsModal(true);
        return;
      } else {
        setLoading(false);
        setOpenSuccessModal(true);
        const {
          email,
          username,
          Billing_City__c,
          Billing_Region__c,
          Billing_City__r,
          Billing_Region__r,
          BillingStreet,
          City__r,
          zipCode,
          address,
          placier,
          Title_of_Person__c,
          Class__c,
          Education_Status__c,
          firm,
          branch,
          // isFriendsCanSeeAlbums,
          // OnlyFriendsCanSee,
          hide_profile,
          kkEmailPermission,
          ksEmailPermission,
          The_Kale_Company_Work_With__c,
          profile_image,
          Name,
        } = userData;
        dispatch(
          SET_USER({
            ...user,
            email,
            username,
            Billing_City__c,
            Billing_Region__c,
            Billing_City__r,
            Billing_Region__r,
            BillingStreet,
            City__r,
            zipCode,
            address,
            placier,
            Title_of_Person__c,
            Class__c,
            Education_Status__c,
            firm,
            branch,
            // isFriendsCanSeeAlbums,
            // OnlyFriendsCanSee,
            hide_profile,
            kkEmailPermission,
            ksEmailPermission,
            The_Kale_Company_Work_With__c,
            profile_image: profile_image,
            Name,
          })
        );
      }
    } catch (error) {
      setLoading(false);
      setApiError(error.message);
      setOpenErrorModal(true);
    }
  };

  const getFirmName = (firmId) => {
    const currentFirm = firms?.find((firm) => firm.Id === firmId);
    return currentFirm?.Name;
  };

  const getBranchType = (branchId) => {
    if (userData?.branch) {
      const currentBranch = branches?.find((branch) => branch.Id === branchId);
      return currentBranch?.Type;
    }
  };

  const activeTabMarkup = () => {
    switch (activeTab) {
      case 1:
        return <CommunicationInformation userData={userData} setUserData={(info) => setUserData(info)} errors={errors} setErrors={(error) => setErrors(error)} user={user} />;
      case 2:
        return (
          <WorkAndStudyInformation
            userData={userData}
            setUserData={(info) => setUserData(info)}
            errors={errors}
            setErrors={(error) => setErrors(error)}
            firms={firms}
            getFirmName={getFirmName}
            branches={branches}
            getBranchType={getBranchType}
          />
        );
      case 3:
        return <PersonalInformation userData={userData} setUserData={(info) => setUserData(info)} errors={errors} setErrors={(error) => setErrors(error)} user={user} />;
      default:
        return;
    }
  };

  const handleAfterSubmit = () => {
    setIsLoading(true);
    ConfirmOTPBayi(jwt, code)
      .then((result) => {
        if (!result.error) {
          const {
            email,
            username,
            Billing_City__c,
            Billing_Region__c,
            Billing_City__r,
            Billing_Region__r,
            BillingStreet,
            City__r,
            zipCode,
            address,
            placier,
            Title_of_Person__c,
            Class__c,
            Education_Status__c,
            firm,
            branch,
            // isFriendsCanSeeAlbums,
            // OnlyFriendsCanSee,
            hide_profile,
            kkEmailPermission,
            ksEmailPermission,
            The_Kale_Company_Work_With__c,
            profile_image,
            Name,
          } = userData;
          dispatch(
            SET_USER({
              ...user,
              email,
              username,
              Billing_City__c,
              Billing_Region__c,
              Billing_City__r,
              Billing_Region__r,
              BillingStreet,
              City__r,
              zipCode,
              address,
              placier,
              Title_of_Person__c,
              Class__c,
              Education_Status__c,
              firm,
              branch,
              // isFriendsCanSeeAlbums,
              // OnlyFriendsCanSee,
              hide_profile,
              kkEmailPermission,
              ksEmailPermission,
              The_Kale_Company_Work_With__c,
              profile_image: profile_image,
              Name,
              mobile_verified: true,
            })
          );
          setShowSmsModal(false);
          setSuccessMsg(result);
          setShowSuccessModal(true);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setErrMsg(e.message);
        setIsLoading(false);
      });
  };

  return kvkkModalOpen ? (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-QFLY5D5WWK");
        `}
        </script>
      </Helmet>
      <KvkkPolicyModal kvkkActiveTab={kvkkActiveTab} kvkkModalOpen={kvkkModalOpen} setKvkkModalOpen={setKvkkModalOpen} setKvkkActiveTab={(tab) => setKvkkActiveTab(tab)} />
    </>
  ) : (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"></script>
        <script>
          {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-QFLY5D5WWK");
      `}
        </script>
      </Helmet>

      <AuthLayout>
        <Modal shouldShow={openSuccessModal} type="success" msg="Profil başarıyla güncellendi." updateModalState={(state) => setOpenSuccessModal(state)} />
        <Modal
          shouldShow={openErrorModal}
          type="error"
          msg={apiError ? apiError : "Profil güncellenirken hata oluştu."}
          updateModalState={(state) => setOpenErrorModal(state)}
        />
        <Modal
          shouldShow={openWarningModal}
          type="warning"
          msg="Bu bilgileri değiştirirseniz oturumunuz sonlandırılacak ve hesabınız onay bekliyor durumuna getirilecektir."
          updateModalState={(state) => setOpenWarningModal(state)}
          setIsConfirmed={(state) => setIsConfirmed(state)}
        />
        <SmsVerification
          isLoading={isLoading}
          code={code}
          setCode={(state) => setCode(state)}
          handleAfterSubmit={handleAfterSubmit}
          jwt={jwt}
          setJwt={(state) => setJwt(state)}
          setDuration={(state) => setDuration(state)}
          duration={duration}
          shouldShow={showSmsModal}
          updateModalState={(state) => setShowSmsModal(state)}
          successMsg={successMsg}
          setSuccessMsg={(state) => setSuccessMsg(state)}
          errMsg={errMsg}
          setErrMsg={(state) => setErrMsg(state)}
        />
        {showSuccessModal && (
          <Modal
            // handleAfterClose={handleSuccess}
            type="success"
            msg={successMsg}
            updateModalState={(state) => setShowSuccessModal(state)}
            shouldShow={showSuccessModal}
          />
        )}
        {errorModal && (
          <Modal
            // handleAfterClose={handleSuccess}
            type="error"
            msg={errMsg || "Bir hata oluştu"}
            updateModalState={(state) => setErrorModal(state)}
            shouldShow={errorModal}
          />
        )}
        {loading && <LoadingModal />}
        <div id="content">
          <div className="px-4 py-5 bg-background-teal lg:py-16 lg:px-0">
            <main className="mx-auto max-w-7xl md:px-4 lg:px-1">
              <div className="flex flex-col justify-between lg:flex-row lg:items-center">
                <h1 className="mb-4 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">Bilgileri Güncelleme</h1>
              </div>
            </main>
          </div>
          <div className="max-w-4xl px-4 mx-auto my-12 lg:my-16 lg:px-0">
            <form onSubmit={(e) => updateProfile(e)}>
              <div className="max-w-md mx-auto mb-16">
                <div className="flex justify-center">
                  <div className="relative flex items-center justify-center w-36 h-36 rounded-full bg-secondary-titles">
                    {user?.profile_image?.url ? (
                      <img
                        src={user?.profile_photo_approved && !hiddenApproveProfileImage ? temporaryProfileImage || user?.profile_image?.url : "/images/image-approve.png"}
                        alt="profile-img"
                        className="w-full h-full object-center object-cover rounded-full"
                      />
                    ) : (
                      <>
                        {temporaryProfileImage ? (
                          <img src={temporaryProfileImage || user?.profile_image?.url} alt="profile-img" className="w-full h-full object-center object-cover rounded-full" />
                        ) : (
                          <>
                            <span className="sr-only">User</span>
                            <svg role="img" className="w-20 h-20 text-white">
                              <use xlinkHref="/assets/img/necromancers.svg#user" />
                            </svg>
                          </>
                        )}
                      </>
                    )}

                    {user?.profile_photo_approved && !hiddenApproveProfileImage && (
                      <button className="absolute flex items-center justify-center w-12 h-12 overflow-hidden border-2 rounded-full bg-secondary-primary border-primary-white -bottom-6">
                        <input
                          type="file"
                          className="absolute h-full opacity-0 cursor-pointer"
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              profile_image: e.target.files[0],
                            });
                            setTemporaryProfileImage(URL.createObjectURL(e.target.files[0]));
                          }}
                        />
                        <span className="sr-only">Photo Add</span>
                        <svg role="img" className="w-6 h-6 text-white" fill="none">
                          <use xlinkHref="/assets/img/necromancers.svg#photo-add" />
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="ml-6">
                    {!user ? (
                      <div style={{ width: 244, height: 144 }}></div>
                    ) : (
                      <>
                        <p className="mb-2 text-sm font-bold">{user?.Name}</p>
                        <p className="mb-2 text-base text-secondary-primary">{getFirmName(user?.firm)}</p>
                        <p>
                          {user?.TypeKS__c === "true" && user?.TypeKK__c === "false" && "KS"}
                          {user?.TypeKK__c === "true" && user?.TypeKS__c === "false" && "KK"}
                          {user?.TypeKS__c === "true" && user?.TypeKK__c === "true" && "KS - KK"}
                        </p>
                        <p className="mb-2 text-sm font-bold">{pointData?.total_point?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</p>
                        <p className="text-base text-secondary-primary">Kale Puan</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="space-y-12">
                <div className="text-center">
                  <div className="relative z-0 inline-flex bg-secondary-secondary-bg mx-auto p-0.5">
                    <button
                      type="button"
                      onClick={() => setActiveTab(1)}
                      className={classNames(
                        activeTab === 1 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                        "px-4 tab-btn text-primary-black hover:text-white lg:w-auto"
                      )}
                    >
                      İletişim Bilgileri
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab(2)}
                      className={classNames(
                        activeTab === 2 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                        "px-4 break-normal tab-btn text-primary-black hover:text-white lg:w-auto"
                      )}
                    >
                      {" "}
                      İş-Eğitim Bilgileri{" "}
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab(3)}
                      className={classNames(
                        activeTab === 3 ? "inline-block !bg-primary-red !text-white active" : "inline-block !text-primary-black !bg-transparent",
                        "px-4 tab-btn text-primary-black hover:text-white lg:w-auto"
                      )}
                    >
                      Kişisel Bilgiler
                    </button>
                  </div>
                </div>
                <div>{activeTabMarkup()}</div>

                <div className="max-w-sm mx-auto">
                  <div className="flex flex-col items-center py-3 mx-auto lg:pt-8 lg:max-w-md lg:space-y-12">
                    <div className="space-y-6">
                      <h4 className="mb-10 text-md">İzinler</h4>
                      <div className="form-input-wrapper">
                        <label htmlFor="toggle1" className="flex items-center cursor-pointer toggle">
                          <div className="relative">
                            <input
                              type="checkbox"
                              id="toggle1"
                              className="sr-only"
                              checked={userData?.hide_profile ? true : false}
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  hide_profile: e.target.checked ? true : false,
                                });
                              }}
                            />
                            <div className="block h-4 mr-2 rounded-full bg-secondary-titles w-7"></div>
                            <div className="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition"></div>
                          </div>
                          <div className="form-label text-body-s text-secondary-titles">Bilgilerim sadece arkadaşlarıma görünür olsun.</div>
                        </label>
                      </div>
                      <div className="form-input-wrapper">
                        {/* <label
                        htmlFor="toggle2"
                        className="flex items-center cursor-pointer toggle"
                      >
                        <div className="relative">
                          <input
                            type="checkbox"
                            id="toggle2"
                            className="sr-only"
                            checked={
                              userData?.isFriendsCanSeeAlbums ? true : false
                            }
                            onChange={(e) =>
                              setUserData({
                                ...userData,
                                isFriendsCanSeeAlbums: e.target.checked
                                  ? true
                                  : false,
                              })
                            }
                          />
                          <div className="block h-4 mr-2 rounded-full bg-secondary-titles w-7"></div>
                          <div className="dot absolute left-0.5 top-0.5 bg-white w-3 h-3 rounded-full transition"></div>
                        </div>
                        <div className="form-label text-body-s text-secondary-titles">
                          {" "}
                          Albümlerim’deki resimlerimi arkadaşlarım görmesin.{" "}
                        </div>
                      </label> */}
                      </div>
                      <div className="form-input-wrapper">
                        <input
                          type="checkbox"
                          id="kk-permission"
                          name="kk-permission"
                          className="mr-1 form-input"
                          checked={userData?.kkEmailPermission ? true : false}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              kkEmailPermission: e.target.checked ? true : false,
                            })
                          }
                        />
                        <label htmlFor="kk-permission" className="form-label text-body-s text-secondary-titles">
                          <b
                            className="cursor-pointer"
                            onClick={() => {
                              setKvkkActiveTab(1);
                              setKvkkModalOpen(true);
                              document.documentElement.scrollTo(0, 0);
                            }}
                          >
                            Kalekim
                          </b>{" "}
                          Ticari elektronik ileti almak için onay veriyorum.
                        </label>
                      </div>
                      <div className="form-input-wrapper">
                        <input
                          type="checkbox"
                          id="kk-kvkk"
                          name="kk-kvkk"
                          className="mr-1 form-input"
                          // defaultChecked={userData?.kkKvkkConfirm}
                          checked
                          readOnly
                          // onChange={(e) =>
                          //   setUserData({
                          //     ...userData,
                          //     kkKvkkConfirm: e.target.checked ? true : false,
                          //   })
                          // }
                        />
                        <label htmlFor="kk-kvkk" className="form-label text-body-s text-secondary-titles">
                          <b
                            className="cursor-pointer"
                            onClick={() => {
                              setKvkkActiveTab(1);
                              setKvkkModalOpen(true);
                              document.documentElement.scrollTo(0, 0);
                            }}
                          >
                            Kalekim
                          </b>{" "}
                          Kişisel Verilere İlişkin Aydınlatma Metni'ni okudum, aydınlatma metni ve yürürlükteki mevzuat çerçevesinde kişisel verilerimin işlenmesini kabul
                          ediyorum.
                        </label>
                      </div>
                    </div>
                    <div className="space-y-6">
                      <div className="form-input-wrapper">
                        <input
                          type="checkbox"
                          id="ks-permission"
                          name="ks-permission"
                          className="mr-1 form-input"
                          checked={userData?.ksEmailPermission ? true : false}
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              ksEmailPermission: e.target.checked ? true : false,
                            })
                          }
                        />
                        <label htmlFor="ks-permission" className="form-label text-body-s text-secondary-titles">
                          <b
                            className="cursor-pointer"
                            onClick={() => {
                              setKvkkActiveTab(2);
                              setKvkkModalOpen(true);
                              document.documentElement.scrollTo(0, 0);
                            }}
                          >
                            Kaleseramik
                          </b>{" "}
                          Ticari elektronik ileti almak için onay veriyorum.
                        </label>
                      </div>
                      <div className="form-input-wrapper">
                        <input
                          type="checkbox"
                          id="ks-kvkk"
                          name="ks-kvkk"
                          className="mr-1 form-input"
                          checked
                          readOnly
                          // onChange={(e) =>
                          //   setUserData({
                          //     ...userData,
                          //     ksKvkkConfirm: e.target.checked ? true : false,
                          //   })
                          // }
                        />
                        <label htmlFor="ks-kvkk" className="form-label text-body-s text-secondary-titles">
                          <b
                            className="cursor-pointer"
                            onClick={() => {
                              setKvkkActiveTab(2);
                              setKvkkModalOpen(true);
                              document.documentElement.scrollTo(0, 0);
                            }}
                          >
                            Kaleseramik
                          </b>{" "}
                          Kişisel Verilere İlişkin Aydınlatma Metni'ni okudum, aydınlatma metni ve yürürlükteki mevzuat çerçevesinde kişisel verilerimin işlenmesini kabul
                          ediyorum.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 lg:px-0 w-full lg:max-w-[12rem] items-center flex mx-auto mt-16">
                    <button type="submit" className="w-full max-w-full uppercase form-submit-btn">
                      KAYDET
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
