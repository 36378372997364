import React, { useState, useEffect } from "react";
import AuthLayout from "../../layouts/AuthLayout";
import GalleryFromKale from "./components/GalleryFromKale";
import GalleryFromUser from "./components/GalleryFromUser";
import classNames from "classnames";
import LoadingModal from "../../components/shared/LoadingModal";
import {
  getGalleryFromKale,
  getUserGalleries,
} from "../../services/contentService";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

export default function Gallery() {
  const [activeTab, setActiveTab] = useState(1);
  const [kaleAlbums, setKaleAlbums] = useState(null);
  const [userAlbums, setUserAlbums] = useState(null);
  const [loading, setLoading] = useState(true);
  const appSlice = useSelector((state) => state.app);

  const user = appSlice?.user;
  useEffect(() => {
    (async () => {
      setLoading(true);
      const albums = await getGalleryFromKale();
      setKaleAlbums(albums);
      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const galleries = await getUserGalleries(user?.objectId);
      setUserAlbums(galleries);
      setLoading(false);
    })();
    //eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal />}
        <div id="content" className="p-4 lg:p-0">
          <div className="py-5 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-1 mx-auto max-w-7xl">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  Galeri
                </h1>
              </div>
            </main>
          </div>
          <div className="px-4 mx-auto my-12 lg:px-1 max-w-7xl lg:my-16">
            <div className="mb-12 text-center">
              <div className="relative z-0 inline-flex bg-secondary-secondary-bg mx-auto p-0.5">
                <button
                  type="button"
                  onClick={() => setActiveTab(1)}
                  className={classNames(
                    activeTab === 1
                      ? "inline-block !bg-primary-red !text-white active"
                      : "inline-block !text-primary-black !bg-transparent",
                    "p-3 tab-btn hover:text-white lg:w-48"
                  )}
                >
                  Kale’den
                </button>
                <button
                  type="button"
                  onClick={() => setActiveTab(2)}
                  className={classNames(
                    activeTab === 2
                      ? "inline-block !bg-primary-red !text-white active"
                      : "inline-block !text-primary-black !bg-transparent",
                    "p-3 break-normal tab-btn hover:text-white lg:w-48"
                  )}
                >
                  Albümlerim
                </button>
              </div>
            </div>
            {activeTab === 1 ? (
              <GalleryFromKale
                kaleAlbums={kaleAlbums}
                setAlbums={(albums) => setKaleAlbums(albums)}
              />
            ) : (
              <GalleryFromUser
                userAlbums={userAlbums}
                setUserAlbums={(albums) => setUserAlbums(albums)}
              />
            )}
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
