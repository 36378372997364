import React from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import { Helmet } from "react-helmet";

export default function CookiePolicy() {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div id="content">
          <div className="px-4 py-5 bg-background-purple md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col justify-between grid-cols-12 p-4 space-y-4 lg:p-0 lg:grid lg:flex-row lg:items-center sm:space-y-0">
                <h1 className="col-span-7 text-md lg:text-xxl text-primary-black font-lg lg:mb-0">
                  {" "}
                  Çerez Politikası{" "}
                </h1>
              </div>
            </main>
          </div>
          <div className="px-1 mx-auto max-w-7xl">
            <div className="px-4 py-8 lg:py-16 xl:px-0">
              <p className="font-bold">
                <Link to="/">www.kalesatiskulubu.com</Link> sitesinde çerez(ler)
                kullanılmaktadır. Çerezler, web sitesi ziyaretçilerinin web
                sitesi kullanımını analiz etmek amacıyla bilgisayara, tablet ve
                cep telefonu gibi mobil cihazlara bırakılan bir tür tanımlama
                dosyalarıdır. Tarayıcınızın çerez ayarlarına göre çerezleri
                kabul edebilir veya kabul etmeyebilirsiniz. Herhangi bir
                internet sitesi tekrar ziyaret edildiğinde çerezler,
                bilgisayarların tanınmasını sağlar. Eğer çerezleri devre dışı
                bırakmak ya da konu ile ilgili daha fazla bilgi almak
                isterseniz, lütfen Çerez Politikası’nı okuyunuz. Tarayıcınızdan
                Çerez ayarlarınızı değiştirmediğiniz sürece, bu sitedeki çerez
                kullanımını kabul etmiş olacağınızı özellikle belirtmek isteriz.
              </p>
              <br />
              <h5 className="text-center my-3">Çerez Politikası</h5>
              <p className="font-body">
                KALESERAMİK olarak, satış kulübü web sitemizi ziyaret eden
                kullanıcıların kişisel verilerinin gizliliğinin sağlanması ve
                korunması amacıyla gerekli teknik ve idari önlemleri alıyoruz.
                Kişisel verilerinizi 6698 sayılı Kişisel Verilerin Korunması
                Kanunu ve ilgili mevzuata uygun olarak işliyor, bu kişisel
                verileri gerekli olduğu süre boyunca muhafaza ediyor, bu sürenin
                sonunda siliyor, anonimleştiriyor veya imha ediyoruz.
              </p>
              <br />
              <p className="font-body">
                <Link to="/">www.kalesatiskulubu.com</Link> adlı web sitemizden
                gerektiği gibi yararlanılabilmek ve sitemizi geliştirilebilmek
                için çerezler (cookie) kullanılmaktadır. Bununla birlikte siz
                çerez kullanılmasını istemiyorsanız, tarayıcınızın ayarlarından
                çerezleri silebilir ve/veya engelleyebilirsiniz. Ancak bu, web
                sitemizin gerektiği gibi kullanabilmesini olumsuz
                etkileyebilecektir. Tarayıcınızdan çerez ayarlarınızı
                değiştirmediğiniz sürece, web sitemizdeki çerez kullanımını
                kabul etmiş olduğunuzu varsayacağız.
              </p>
              <br />
              <p className="font-body">
                Toplanan kişisel verilerle ilgili Politikamızı ve ilkelerimizi
                <Link to="/">www.kalesatiskulubu.com</Link> adresinde yer alan
                Kişisel Verilerin Korunması Politikası’nda okuyabilirsiniz.
              </p>
              <br />
              <h5 className="my-3">Çerez nedir?</h5>

              <p className="font-body">
                Çerezler, ziyaret ettiğiniz internet siteleri tarafından
                tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna depolanan
                bilgilerin, şifrelerin kaydedildiği, o internet sitesine tekrar
                girildiğinde bilgilerinizin hatırlanarak giriş yapıldığı metin
                dosyası, kod veya yazılım öğesidir.
              </p>
              <br />
              <h5 className="my-3">Çerezler nasıl kullanılır?</h5>
              <p className="font-body">
                İnternete erişim sağlayan araçlara (bilgisayar, tablet, telefon
                vd.) yüklenen çerezler veya elde edilen bilgiler aşağıda yazılı
                amaçlarla toplanmaktadır:
              </p>

              <ul className="my-3">
                <li>• istatistiksel analizleri yapılabilmek,</li>
                <li>• web sitesi güvenlik önlemlerini güncelleyebilmek,</li>
                <li>
                  • web sitesinin kullanılabilirliği ve performansını
                  artırabilmek,
                </li>
                <li>
                  • web sitesini geliştirebilmek ve web sitesinde sunulan
                  özellikleri sizlerin tercihlerine göre kişiselleştirebilmek;
                </li>
                <li>
                  • web sitemizin, kullanıcıların ve Şirketimizin hukuki ve
                  ticari menfaatlerini koruyabilmek,
                </li>
                <li>
                  • • Şirketimizin 5651 sayılı Internet Ortamında Yapılan
                  Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen
                  Suçlarla Mücadele Edilmesi Hakkında Kanun ve Internet
                  Ortamında Yapılan Yayınların Düzenlenmesine Dair Usul ve
                  Esaslar Hakkında Yönetmelik gereğince yasal ve ticari
                  sorumluluklarını yerine getirebilmek için{" "}
                </li>
              </ul>
              <h5 className="my-3">Web Sitemizde Kullanılan Çerez Türleri:</h5>
              <ul>
                <li>- Geçici çerezler: </li>
                <li>- Kalıcı çerezler: </li>
                <li>- Zorunlu Çerezler: </li>
                <li>- Performans ve analiz çerezleri: </li>
                <li>- İşlevsellik çerezleri: </li>
                <li>- İzleme çerezleri: </li>
              </ul>
              <h5 className="my-3">Web Sitemizde Kullanılan Çerez Türleri:</h5>
              <p>
                Web sitemizi kullandığınız taktirde çerezleri otomatik olarak
                kabul ettiğiniz varsayılacaktır. Bununla birlikte siz çerezleri
                kontrol edebilir veya silebilirsiniz. Çerezleri silmenin veya
                engellemenin çeşitli yolları bulunmaktadır. İnternete erişim
                sağladığınız cihazdaki çerezleri silebilir veya 3. taraf
                çerezlerini engelleyebilirsiniz. Ayrıca belli bazı sitelerden
                çerezleri engelleyebilir veya ayrım yapmaksızın tüm çerezleri
                engelleyebilir veya internet erişimine son verdiğinizde hali
                hazırdaki tüm çerezleri silebilirsiniz. Böylece çerezlerin
                cihazınızda kaydedilmesine engel olabilirsiniz.
              </p>
              <br />
              <p>
                KALESERAMİK’e ait web sitelerine farklı cihazlar üzerinden
                erişiyorsanız, her bir cihaz için (bilgisayar, tablet, telefon
                gibi) internet tarayıcınızın çerez ayarlarının tercihlerinize
                uygun olarak düzenlenip düzenlenmediğini kontrol edebilirsiniz.
                Bir veya daha fazla çerez kategorisini engellerseniz, mevcut
                çerezler ile bilgi toplamaya devam edebiliriz, ancak engellenen
                çerezlerin kullanımına derhal son verilecektir.
              </p>
              <br />
              <p>
                Çerezleri silmeyi tercih ederseniz ilgili web sitesindeki
                tercihleriniz de silinecektir. Ayrıca, çerezleri tamamen
                engellemeyi tercih ederseniz bu eyleminiz
                www.kalesatiskulubu.com sitemizi kullanımınızı etkileyebilir,
                web sitemizin belirli bölümlerini görüntüleyemeyebilir veya
                sizin için özelleştirilmiş olan bilgiler varsa artık
                erişemeyebilirsiniz.{" "}
              </p>
              <h5 className="my-3">Kullanıcı Anlaşması</h5>
              <p>
                Çerezlerden bazılarını veya hepsini silmeksizin veya
                engellemeksizin web sitemizi kullanmaya devam ettiğiniz
                taktirde, silmediğiniz veya engellemediğiniz çerezlerin
                cihazınıza yerleştirebileceğini kabul etmiş oluyorsunuz.
              </p>
              <h5 className="my-3">Bize Ulaşın</h5>
              <p>
                KALESERAMİK olarak işbu Çerez Politikasında, ihtiyaçlar veya
                yasaların öngördüğü değişiklikler ve güncellemeleri yapabiliriz.
                Bunları web sitemizi ziyaret ederek öğrenebilirsiniz.
              </p>
              <p>
                Çerez Politikasına ilişkin herhangi bir sorunuz olması halinde,
                ks-kvkk@kale.com.tr e-posta adresinden bizimle iletişime
                geçebilirsiniz.
              </p>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
