import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  unreadConversationsCount: 0,
};

export const messageSlice = createSlice({
  name: "messageSlice",
  initialState,
  reducers: {
    SET_MESSAGE_USER: (state, action) => {
      state.user = action.payload;
    },
    SET_UNREAD_CONVERSATIONS_COUNT: (state, action) => {
      state.unreadConversationsCount = action.payload;
    },
    INCREASE_UNREAD_CONVERSATIONS_COUNT: (state) => {
      state.unreadConversationsCount = state.unreadConversationsCount + 1;
    },
  },
});

export const {
  SET_MESSAGE_USER,
  SET_UNREAD_CONVERSATIONS_COUNT,
  INCREASE_UNREAD_CONVERSATIONS_COUNT,
} = messageSlice.actions;
export default messageSlice.reducer;
