import { useState, useEffect } from "react";
import DonutChart from "react-donut-chart";
import { useSelector } from "react-redux";
import Pagination from "./Pagination";

export default function NegativePoints({ pointData }) {
  const [pieData, setPieData] = useState([]);
  const [negativeRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = negativeRecords?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const negativePoints = useSelector((state) => state.app.negativePoints);

  useEffect(() => {
    if (!negativePoints) return;
    if (negativePoints.expired === 0 && negativePoints.manuel.total === 0) {
      setPieData([
        {
          label: `Expired Puan`,
          value: 1,
        },
        {
          label: `Negatif Puan`,
          value: 1,
        },
      ]);
    } else {
      setPieData([
        {
          label: `Expired Puan`,
          value: Number(negativePoints.expire),
        },
        {
          label: `Negatif Puan`,
          value: Number(negativePoints.manuel.total),
        },
      ]);
    }
  }, [negativePoints]);

  useEffect(() => {
    if (!pointData) return;

    pointData.records.forEach((record) => {
      if (record.type === "negative" && record.resource === "Manuel")
        negativeRecords.push(record);
    });
    //eslint-disable-next-line
  }, [pointData]);

  return (
    <div>
      <div className="flex flex-col px-4 lg:px-0">
        <div className="flex flex-col gap-12 lg:flex-row">
          <div className="flex flex-col w-full max-w-4xl mx-auto mb-16 space-y-4 md:flex-row cdc-container justify-evenly">
            <div className="flex flex-row space-x-24">
              <div className="flex flex-col relative">
                <div className="cdc-overlay z-50 w-[325px] h-[325px]">
                  <div className="cdc-text">
                    <div className="space-y-4">
                      <p className="text-body-s font-sm">Toplam</p>
                      <p className="text-lg font-lg">
                        {(
                          negativePoints?.expired +
                          negativePoints?.manuel?.total
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </p>
                      <p className="text-body-s text-secondary-secondary">
                        Kale Puan
                      </p>
                    </div>
                  </div>
                </div>
                <DonutChart
                  data={pieData}
                  interactive={false}
                  selectedOffset={false}
                  colors={["#E94034", "#6258C9"]}
                  legend={false}
                  width={325}
                  height={325}
                  strokeColor="transparent"
                  className="p-0"
                />
                {/* <p className="mt-8 text-body-s text-secondary-secondary">
                  Son Kullanım Tarihi; 01.12.2021
                </p> */}
              </div>

              <div className="flex flex-col justify-start ml-4 space-y-4 cdc-legend pt-6 w-96">
                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-primary-red"></span>
                  <span>
                    Negatif Manuel Puan Müdahelesi{" "}
                    <b>
                      {negativePoints?.manuel?.total
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>{" "}
                    (KS{" "}
                    {negativePoints?.manuel?.ks
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    , KK{" "}
                    {negativePoints?.manuel?.kk
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    )
                  </span>
                </span>

                <span title="24 (24%)" className="cdc-legend-item">
                  <span className="rounded-full cdc-legend-item-color bg-tertiary-purple"></span>
                  <span>
                    Expired Puanlar{" "}
                    <b>
                      {negativePoints?.expired
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                    </b>
                  </span>
                </span>

                <p className="text-secondary-secondary">
                  KS; Kaleseramik | KK; Kalekim
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="inline-block min-w-full">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-secondary-secondary-bg">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-20"
                  >
                    TARİH
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left uppercase font-md text-body-l text-primary-black"
                  >
                    AÇIKLAMA
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 uppercase font-md text-body-l text-primary-black w-20"
                  >
                    PUAN
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((record, index) => {
                  return (
                    <tr
                      className="bg-white border-b border-secondary-lines"
                      key={index}
                    >
                      <td className="px-6 py-2 text-center text-body text-secondary-primary">
                        {new Date(record.createdAt).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-2 text-body text-secondary-primary">
                        {record.description.includes("Manuel Puan Müdahalesi")
                          ? "Manuel Puan"
                          : record.description}
                      </td>
                      <td className="px-6 py-2 text-center text-body text-primary-red">
                        {(record.kk_used + record.ks_used !== 0
                          ? record.kk_used + record.ks_used
                          : record.used
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        totalItems={negativeRecords?.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={(page) => setCurrentPage(page)}
      />
    </div>
  );
}
