import React from 'react'
import ReactPlayer from "react-player";

export default function TemporaryImages({temporaryImages, removeImage}) {
  return temporaryImages?.length > 0 && (
    <div className="mb-2 mt-3 row-auto align-middle">
      {temporaryImages.map((images, mainIndex) => {
        return images?.map((img, index) => {
          if (
            img?.extension === "mkv" || 
            img?.extension === "mp4" || 
            img?.extension === "m4v" || 
            img?.extension === "avi" || 
            img?.extension === "mpg"
          ) {
            return (
              <span
                className="w-12 h-12 inline-block mr-1 mb-1 col-auto align-middle relative"
                key={`${mainIndex}-${index}`}
              >
                <ReactPlayer
                  url={img?.url}
                  width={48}
                  height={48}
                />
                <button className="absolute -top-2 left-0" onClick={() => removeImage(mainIndex, index, img?.name)}>
                <svg
                  role="img"
                  className="bg-secondary-primary w-5 h-5 text-center text-primary-red p-1 rounded-full "
                >
                  <use xlinkHref="/assets/img/necromancers.svg#cross" />
                </svg>
                </button>
              </span>
            );
          } else {
            return (
              <span
                key={`${mainIndex}-${index}`}
                className="inline-block w-12 h-12 relative mr-1 mb-1"
              >
                <img
                  src={img?.url}
                  className="w-12 h-12 inline-block"
                  alt={`img-${index}`}
                />
                <button className="absolute -top-2 left-0" onClick={() => removeImage(mainIndex, index, img?.name)}>
                <svg
                  role="img"
                  className="bg-secondary-primary w-5 h-5 text-center text-primary-red p-1 rounded-full "
                >
                  <use xlinkHref="/assets/img/necromancers.svg#cross" />
                </svg>
                </button>
                
              </span>
            );
          }
        });
      })}
    </div>
  )
}
