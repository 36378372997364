import React, { useEffect, useState, useRef } from "react";
import {
  searchFriends,
  sendMessage,
  addFriend,
  searchUser,
} from "../../../../../services/contentService";
import { useOnClickOutside } from "../../../../../hooks/useOnClickOutside";
import MessageDetails from "./MessageDetails";
import { useDispatch } from "react-redux";
import { SET_MESSAGE_USER } from "../../../../../store/message/messageSlice";
import classNames from "classnames";

export default function CreateMessage({
  setActiveContent,
  user,
  userFromMessageSlice,
  isMessagesOpen,
}) {
  const [name, setName] = useState("");
  const [friends, setFriends] = useState(null);
  const [showFriendsList, setShowFriendsList] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [messageContent, setMessageContent] = useState("");
  const [showMessageDetails, setShowMessageDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const friendsList = useRef(null);

  const sendMessageToFriend = async () => {
    if (!user) throw new Error("Unauthorized");
    if (!selectedFriend || messageContent === "") return;
    setLoading(true);
    try {
      await sendMessage(selectedFriend?.id, messageContent);
      setShowMessageDetails(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addNewFriend = async () => {
    setLoading(true);
    try {
      await addFriend(selectedFriend?.id);
      setSelectedFriend({ ...selectedFriend, friendRequestSended: true });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (isMessagesOpen && userFromMessageSlice) {
        setSelectedFriend(userFromMessageSlice);
      }
    })();
    //eslint-disable-next-line
  }, [userFromMessageSlice, isMessagesOpen]);

  useEffect(() => {
    (async () => {
      try {
        const searchResults = await searchUser(userFromMessageSlice?.name);
        const exactUser = searchResults?.find(
          (i) => i.id === userFromMessageSlice?.id
        );

        dispatch(
          SET_MESSAGE_USER({
            ...userFromMessageSlice,
            isFriend: exactUser?.is_friend,
            friendRequestSended: exactUser?.is_request_sent,
          })
        );
      } catch (error) {
        console.log(error);
      }
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const friends = await searchFriends(name);
      setFriends(friends);
    })();
  }, [showFriendsList, name]);

  useOnClickOutside(friendsList, () => setShowFriendsList(false));

  return !showMessageDetails ? (
    <div className="w-full" role="none">
      <div className="flex items-center justify-between p-6 bg-background-blue">
        <h3 className="text-sm font-sm">Yeni Mesaj</h3>
        <button
          className="absolute p-2 right-6 top-6 bg-primary-black"
          onClick={() => {
            setActiveContent("conversations");
            dispatch(SET_MESSAGE_USER(null));
          }}
        >
          <svg role="img" className="w-4 h-4 text-white link-icon">
            <use xlinkHref="/assets/img/necromancers.svg#cross" />
          </svg>
        </button>
      </div>

      <div
        role="menu"
        className="fixed top-0 left-0 right-0 z-10 w-full h-screen max-w-full transform bg-white shadow-lg lg:left-auto lg:top-auto lg:h-auto lg:absolute lg:max-w-sm"
      >
        <div className="w-full" role="none">
          <div className="flex flex-col overflow-y-auto overscroll-contain h-max lg:max-h-[23rem]">
            <div className="flex flex-col p-4">
              <div className="z-20">
                <div className="form-input-wrapper">
                  <input
                    type="text"
                    id="first-name"
                    autoComplete="off"
                    name="first-name"
                    placeholder=" "
                    className="form-input bg-transparent focus:border-transparent!important"
                    onChange={(e) => setName(e.target.value)}
                    onFocusCapture={() => setShowFriendsList(true)}
                    onFocus={() => {
                      setSelectedFriend(null);
                      setName("");
                      setShowFriendsList(true);
                    }}
                    value={selectedFriend ? selectedFriend?.name : name}
                  />
                  <label
                    htmlFor="first-name"
                    className="bg-transparent form-label"
                  >
                    Alıcı
                  </label>
                  {showFriendsList && (
                    <div className="absolute z-50 w-full overflow-y-auto rounded shadow overscroll-contain top-100 lef-0 max-h-60 bg-secondary-secondary-bg">
                      <div
                        className="flex flex-col w-full px-4 border bg-secondary-secondary-bg border-secondary-lines"
                        ref={friendsList}
                      >
                        {friends?.map((friend) => (
                          <div
                            className="message-user cursor-pointer w-full border-gray-100 border-b"
                            aria-selected="false"
                            key={friend?.id}
                            onClick={() => {
                              setSelectedFriend(friend);
                              setShowFriendsList(false);
                            }}
                          >
                            <div className="relative flex items-center w-full py-4 pl-2 border-l-2 border-transparent ">
                              <div className="flex flex-col items-center w-12">
                                <div className="relative flex items-center justify-center w-12 h-12 rounded-full bg-secondary-titles">
                                  {friend?.profile_image ? (
                                    <img
                                      className="rounded-full w-full h-full"
                                      alt={friend?.name}
                                      src={friend?.profile_image}
                                    />
                                  ) : (
                                    <>
                                      <span className="sr-only">User</span>
                                      <svg
                                        role="img"
                                        className="w-8 h-8 text-white"
                                      >
                                        <use xlinkHref="/assets/img/necromancers.svg#user" />
                                      </svg>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="flex items-center w-full">
                                <div className="mx-2 -mt-1">
                                  <span>{friend?.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <div className="mb-8 form-input-wrapper">
                  <textarea
                    type="text"
                    id="other"
                    autoComplete="cc-given-other"
                    name="other"
                    placeholder=" "
                    className="h-40 bg-transparent resize-none form-input"
                    onChange={(e) => setMessageContent(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        sendMessageToFriend();
                      }
                    }}
                    value={messageContent}
                  ></textarea>
                  <label htmlFor="other" className="bg-transparent form-label">
                    Mesajınız
                  </label>
                </div>
              </div>

              <div className="flex flex-row">
                <button
                  onClick={() => {
                    sendMessageToFriend(selectedFriend?.id, messageContent);
                    dispatch(SET_MESSAGE_USER(null));
                  }}
                  className="w-full btn bg-primary-red white lg:w-28 cursor-pointer mr-3"
                  disabled={loading}
                >
                  GÖNDER
                </button>

                {userFromMessageSlice?.isFriend === false && (
                  <button
                    onClick={() => {
                      addNewFriend();
                    }}
                    className={classNames(
                      (userFromMessageSlice?.isFriend === true ||
                        userFromMessageSlice?.friendRequestSended === true) &&
                        "opacity-75",
                      "w-full btn bg-primary-red white lg:w-28 cursor-pointer"
                    )}
                    disabled={
                      userFromMessageSlice?.isFriend === true ||
                      userFromMessageSlice?.friendRequestSended === true
                    }
                  >
                    {userFromMessageSlice?.isFriend === false &&
                      userFromMessageSlice?.friendRequestSended === true &&
                      "İstek Gönderildi"}
                    {userFromMessageSlice?.isFriend === false &&
                      userFromMessageSlice?.friendRequestSended === false &&
                      "Arkadaş Ekle"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MessageDetails
      setActiveContent={(content) => setActiveContent(content)}
      friendForMessageDetails={selectedFriend}
      user={user}
    />
  );
}
