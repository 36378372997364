import React, { useState } from "react";

export default function FaqItem({ faqItem }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="p-6 border border-secondary-inactive">
      <dt className="text-lg">
        <button
          type="button"
          className="flex items-start justify-between w-full"
          aria-controls="faq-0"
          onClick={() => setOpen(!open)}
          aria-expanded="true"
          data-testid="openBtn"
        >
          <span className="text-left text-body-l ">{faqItem.question}</span>
          <span className="flex ml-6">
            {open ? (
              <svg role="img" className={"w-6 h-6"} fill="none">
                <use xlinkHref="/assets/img/necromancers.svg#arrow-top" />
              </svg>
            ) : (
              <svg role="img" className={"w-6 h-6"} fill="none">
                <use xlinkHref="/assets/img/necromancers.svg#arrow-bottom" />
              </svg>
            )}
          </span>
        </button>
      </dt>
      {open && (
        <dd data-testid="faqItemAnswer" className="pr-12 mt-2" id="faq-0">
          <p>{faqItem.answer}</p>
        </dd>
      )}
    </div>
  );
}
