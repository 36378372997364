import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getExamAnswersByUser,
  getExamQuestions,
  getMyExamAnswers,
  getMyExamHistory,
  startExam,
} from "../../../services/contentService";
import CompletedExamModal from "./CompletedExamModal";
import ExamModal from "./ExamModal";

export default function Exam(props) {
  const {
    exam,
    setShowSuccessModal,
    setLoading,
    exams,
    setExams,
    setShowErrorModal,
    setErrorMessage,
    setShowInfoModal,
  } = props;

  const [questionCount, setQuestionCount] = useState(0);
  const [showExamModal, setShowExamModal] = useState(false);
  const [showCompletedExamModal, setShowCompletedExamModal] = useState(false);
  const [previousAnswers, setPreviousAnswers] = useState([]);
  const [examResults, setExamResults] = useState([]);
  const [userExamHistory, setUserExamHistory] = useState([]);
  const [questions, setQuestions] = useState([]);
  const user = useSelector((state) => state?.app?.user);

  const startExamOnClick = async () => {
    await startExam(user?.objectId, exam?.objectId);
    setShowExamModal(true);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const promises = [];
        if (!exam?.complete) {
          promises.push(await getMyExamAnswers(exam?.objectId));
          promises.push(await getMyExamHistory(exam?.objectId));
          promises.push(await getExamQuestions(exam?.objectId));
        } else {
          promises.push(await getExamAnswersByUser(exam?.objectId));
        }
        const result = await Promise.all(promises);
        if (!exam?.complete) {
          setPreviousAnswers(result[0]);
          setUserExamHistory(result[1]?.toJSON());
          setQuestions(result[2]);
        } else {
          setExamResults(promises[0]);
        }
      } catch (error) {
        setErrorMessage(error?.message);
        setShowErrorModal(true);
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!exam?.complete) {
      setQuestionCount(questions?.length);
    } else {
      setQuestionCount(examResults?.length);
    }
  }, [exam?.complete, questions, examResults]);

  const examButtonsMarkup = () => {
    if (exam?.complete === true) {
      return (
        <button
          onClick={() => setShowCompletedExamModal(true)}
          className="w-full px-6 py-4 mt-8 uppercase bg-primary-red form-submit-btn lg:w-auto max-w-none lg:mt-0 revert"
        >
          İNCELE
        </button>
      );
    } else {
      if (exam?.on_time === true) {
        return (
          <button
            onClick={() => setShowExamModal(true)}
            className="w-full px-6 py-4 mt-8 uppercase bg-primary-red form-submit-btn lg:w-auto max-w-none lg:mt-0"
          >
            Devam Et
          </button>
        );
      } else if (exam?.on_time === false) {
        return (
          <button
            disabled
            className="w-full px-6 py-4 mt-8 uppercase bg-primary-red form-submit-btn lg:w-auto max-w-none lg:mt-0 opacity-75"
          >
            SÜRESİ DOLDU
          </button>
        );
      } else {
        return (
          <button
            onClick={() => startExamOnClick()}
            className="w-full px-6 py-4 mt-8 uppercase bg-primary-red form-submit-btn lg:w-auto max-w-none lg:mt-0"
          >
            Başla
          </button>
        );
      }
    }
  };

  return (
    <div
      className={
        questionCount === 0
          ? "sr-only"
          : "gap-6 pb-6 mb-6 border-b lg:border border-secondary-lines lg:p-6 lg:flex"
      }
    >
      {showExamModal && (
        <ExamModal
          setShowExamModal={(show) => setShowExamModal(show)}
          exam={exam}
          questionCount={questionCount}
          setShowSuccessModal={(show) => setShowSuccessModal(show)}
          setLoading={(loading) => setLoading(loading)}
          exams={exams}
          setExams={(exams) => setExams(exams)}
          setShowErrorModal={(show) => setShowErrorModal(show)}
          setErrorMessage={(msg) => setErrorMessage(msg)}
          previousAnswers={previousAnswers}
          setPreviousAnswers={(answers) => setPreviousAnswers(answers)}
          userExamHistory={userExamHistory}
          setShowInfoModal={(state) => setShowInfoModal(state)}
          questions={questions}
        />
      )}
      {showCompletedExamModal && (
        <CompletedExamModal
          exam={exam}
          examResults={examResults}
          setShowCompletedExamModal={(show) => setShowCompletedExamModal(show)}
          questionCount={questionCount}
        />
      )}

      <div className="flex-col max-w-lg lg:flex">
        <p className="mb-4 text-body-l lg:mb-0 font-xs">{exam?.title}</p>
        <span className="mt-2">{exam?.description}</span>
        <div className="flex-row mt-4 lg:flex lg:gap-5">
          <span className="mr-6 lg:mr-0">{questionCount} Soru</span>
          <span className="relative mr-6 list-dot lg:mr-0">
            {exam?.difficulty}
          </span>
          <span className="relative mr-6 list-dot lg:mr-0">
            Süre {exam?.time}dk
          </span>
          <span className="relative mr-6 list-none list-dot lg:mr-0">
            Son Katılım Tarihi:{" "}
            {new Date(exam?.end_date?.iso).toLocaleDateString("tr")}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-end flex-1">
        {examButtonsMarkup()}
      </div>
    </div>
  );
}
