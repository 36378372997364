import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const OffSiteSource = () => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        <div className="flex flex-col items-center justify-center w-full h-full my-12">
          <div dangerouslySetInnerHTML={{ __html: location.state.url }} />
        </div>
      </AuthLayout>
    </>
  );
};

export default OffSiteSource;
