import React, { useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import InputMask from "react-input-mask";
import { forgotPassword } from "../../../services/contentService";
import LoadingModal from "../../../components/shared/LoadingModal";
import Modal from "../../../components/shared/Modal";

function ForgotPassword({
  showForgotPasswordModal,
  setShowForgotPasswordModal,
  setIsConfirmed,
}) {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [apiError, setApiError] = useState(null);
  const ref = useRef();

  const handleForgotPasswordModal = async () => {
    setShowForgotPasswordModal(false);
    setLoading(true);
    try {
      await forgotPassword(phone.replace(/\D/g, "").substring(1));
      setShowSuccessModal(true);
    } catch (error) {
      setApiError(error.message);
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  useOnClickOutside(ref, () => {
    setShowForgotPasswordModal(false);
    setIsConfirmed(false);
  });

  return (
    <>
      {loading && <LoadingModal />}
      {showSuccessModal && (
        <Modal
          shouldShow={showSuccessModal}
          type="success"
          msg={`${phone} telefon numarasına yeni şifreniz gönderildi.`}
          updateModalState={(state) => setShowSuccessModal(state)}
        />
      )}
      <Modal
        shouldShow={showErrorModal}
        type="error"
        msg={apiError ? apiError : "Şifre gönderilirken bir hata oluştu."}
        updateModalState={(state) => setShowErrorModal(state)}
      />
      {showForgotPasswordModal && (
        <div
          className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain"
          aria-labelledby="modal-title"
          aria-modal="true"
          data-testid="modal"
        >
          <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center lg:block lg:p-0">
            <div
              className="fixed inset-0 min-h-screen overflow-y-auto transition-opacity bg-white lg:bg-primary-black lg:bg-opacity-90"
              aria-hidden="true"
            ></div>
            <span
              className="hidden lg:inline-block lg:align-middle lg:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              ref={ref}
              className="inline-block align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-md sm:w-full sm:pb-16 sm:pt-12 sm:px-12 outline-none"
            >
              <div className="absolute top-0 right-0 pt-4 pr-4 lg:block">
                <button
                  data-testid="inline-modal"
                  onClick={handleForgotPasswordModal}
                  type="button"
                  className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <svg role="img" className="w-6 h-6 text-primary-black">
                    <use xlinkHref="/assets/img/necromancers.svg#cross" />
                  </svg>
                </button>
              </div>

              <div className="modal-body">
                <div>
                  <div className="justify-center flex-auto mb-8 text-center">
                    <p className="icon-wrapper bg-primary-black">
                      <svg role="img" className="w-5 h-5 text-white">
                        <use xlinkHref="/assets/img/necromancers.svg#lock" />
                      </svg>
                    </p>
                    <h2 className="mt-4 modal-title text-md">
                      Şifremi Unuttum
                    </h2>
                    <p className="mt-3 modal-content-text">
                      Sisteme kayıtlı telefon numaranızı giriniz.
                    </p>
                    <div className="flex-col justify-center flex-1 mt-8">
                      <div className="flex form-input-wrapper flex-start">
                        <InputMask
                          type="text"
                          id="phone"
                          autoComplete="cc-given-title"
                          className="bg-transparent form-input phone"
                          placeholder=" "
                          mask="0 (999) 999 99 99"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          maskplaceholder="_"
                        />
                        <label
                          htmlFor="phone"
                          className="w-full text-left bg-transparent phone form-label"
                        >
                          Cep Telefon Numaranız
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      onClick={handleForgotPasswordModal}
                      className="uppercase form-submit-btn"
                    >
                      Gönder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
