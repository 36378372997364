import React, { useEffect, useState } from "react";
import {
  getMyPendingFriends,
  responseToFriendRequest,
} from "../../../services/contentService";
import LoadingModal from "../../../components/shared/LoadingModal";
import { Link } from "react-router-dom";

const FriendRequests = () => {
  const [myPendingFriendsList, setMyPendingFriendsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getMyPendingFriends().then((res) => {
      setMyPendingFriendsList(res);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptFriendReq = (friend_id) => {
    responseToFriendRequest(friend_id, "accept").then((res) => {
      getMyPendingFriends().then((res) => {
        setMyPendingFriendsList(res);
      });
    });
  };

  const handleRejectFriendReq = (friend_id) => {
    responseToFriendRequest(friend_id, "reject").then((res) => {
      getMyPendingFriends().then((res) => {
        setMyPendingFriendsList(res);
      });
    });
  };

  return (
    <>
      {isLoading && <LoadingModal />}
      <div className="flex">
        <div className="flex flex-col w-full p-4 lg:p-0">
          {myPendingFriendsList.length > 0 &&
            myPendingFriendsList.map((item) => {
              return (
                <div
                  key={item.id}
                  className="flex w-full pb-4 mt-4 border-b border-secondary-lines"
                >
                  <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
                    <div className="flex">
                      <Link to={`/user/${item?.id}`}>
                        <div className="flex items-center justify-center w-[88px] h-[88px] overflow-hidden rounded-full mr-6 bg-secondary-titles">
                          <>
                            {item?.profile_image ? (
                              <img
                                src={item?.profile_image}
                                alt={item?.name}
                                className="w-full h-full "
                              />
                            ) : (
                              <>
                                <span className="sr-only">User</span>
                                <svg
                                  role="img"
                                  className="w-[66px] h-[66px] text-white"
                                >
                                  <use xlinkHref="/assets/img/necromancers.svg#user" />
                                </svg>
                              </>
                            )}
                          </>
                        </div>
                      </Link>
                      <div className="flex flex-col justify-center">
                        <Link to={`/user/${item?.id}`}>
                          <p className="mb-1 text-body-l font-md">
                            {item.name}
                          </p>
                        </Link>
                        {/* <p>ABC Kimya</p> */}
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-4 mt-6 lg:flex-row md:w-auto lg:mt-0">
                      <button
                        onClick={() => handleAcceptFriendReq(item.id)}
                        className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-white bg-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                      >
                        ONAYLA
                      </button>
                      <button
                        onClick={() => handleRejectFriendReq(item.id)}
                        className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                      >
                        SİL
                      </button>
                      {/* <button
                        className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                      >
                        ENGELLE
                      </button> */}
                    </div>
                  </div>
                </div>
              );
            })}
          {myPendingFriendsList.length === 0 && (
            <div className="text-xs text-center">
              Bekleyen Arkadaşlık İsteği Yok
            </div>
          )}
          {/* <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4 mt-6 lg:flex-row md:w-auto lg:mt-0">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-white bg-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                ONAYLA
              </a>
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                SİL
              </a>
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                ENGELLE
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4 mt-6 lg:flex-row md:w-auto lg:mt-0">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-white bg-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                ONAYLA
              </a>
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                SİL
              </a>
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                ENGELLE
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex flex-col w-full gap-4 mt-6 lg:flex-row md:w-auto lg:mt-0">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-white bg-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                ONAYLA
              </a>
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                SİL
              </a>
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                ENGELLE
              </a>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default FriendRequests;
