import React, { useCallback, useState } from "react";
import Question from "./Question";
import classNames from "classnames";
import { saveSurveyAnswer, completeSurvey } from "../../../services/contentService";
import Parse from "parse";

export default function SurveyModal(props) {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const { survey, setShowModal, setSurveys, surveys, setShowSuccessModal, setLoading, questions, setApiError, setShowErrorModal, surveyResults, setSurveyResults } = props;

  const setSurveyAsCompleted = async () => {
    const surveysCopy = [...surveys];
    const surveyIndex = surveysCopy.findIndex((i) => i.id === survey?.objectId);
    const ourSurvey = surveysCopy[surveyIndex]?.toJSON();
    ourSurvey.complete = true;
    const object = new Parse.Object("Survey", ourSurvey);
    surveysCopy[surveyIndex] = object;
    setSurveys(surveysCopy);
  };

  const isQuestionAnswered = useCallback(
    (question) => {
      const questionId = question?.objectId;
      const surveyId = question?.survey?.objectId;
      return answers.some((i) => i.questionId === questionId && i.surveyId === surveyId);
    },
    [answers]
  );

  const saveSurveyAnswers = async () => {
    setLoading(true);
    if (answers.length > 0) {
      const newAnswers = [];
      answers.map(async (answer) => {
        if (answer.choiceIds) {
          let choiceIds = "";
          answer.choiceIds.map((choiceId, index) => {
            if (index !== answer.choiceIds.length - 1) {
              return (choiceIds += `${choiceId},`);
            } else {
              return (choiceIds += choiceId);
            }
          });
          answer.choiceIds = choiceIds;
        }
      });
      try {
        await Promise.all([
          answers.map(async (answer) => {
            const newAnswer = await saveSurveyAnswer(answer);
            return newAnswers.push(newAnswer?.toJSON());
          }),
          await completeSurvey(survey?.objectId),
        ]);
        setShowModal(false);
        setShowSuccessModal(true);
        setSurveyResults([...surveyResults, ...newAnswers]);
        setSurveyAsCompleted();
      } catch (error) {
        setApiError(error?.message);
        setShowErrorModal(true);
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain" aria-labelledby="modal-title" aria-modal="true">
      <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center lg:block lg:p-0">
        <div className="fixed inset-0 min-h-screen overflow-y-auto transition-opacity bg-white lg:bg-primary-black lg:bg-opacity-90" aria-hidden="true"></div>
        <span className="hidden lg:inline-block lg:align-middle lg:h-screen" aria-hidden="true"></span>
        <div className="inline-block h-auto w-full max-w-full align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-auto lg:overflow-hidden shadow-xl transform transition-all lg:my-8 lg:align-middle lg:max-w-2xl lg:w-full lg:pb-16 lg:pt-12 lg:px-12 outline-none">
          <div className="absolute top-0 right-0 pt-4 pr-4 lg:block">
            <button type="button" onClick={() => setShowModal(false)} className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2">
              <span className="sr-only">Close</span>
              <span className="sr-only">Profile</span>
              <svg role="img" className="w-6 h-6 text-primary-black">
                <use xlinkHref="/assets/img/necromancers.svg#cross" />
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="flex flex-col items-center w-full">
              <div className="w-full max-w-full lg:max-w-2xl ">
                {questions?.map((question, index) => {
                  if (questionIndex === index) {
                    return (
                      <Question
                        key={question?.objectId}
                        question={question}
                        answers={answers}
                        setAnswers={(answers) => setAnswers(answers)}
                        isQuestionAnswered={isQuestionAnswered}
                        survey={survey}
                      />
                    );
                  } else {
                    return <span key={index} className="hidden"></span>;
                  }
                })}
              </div>

              <div className="flex items-center justify-center gap-4 mt-8">
                <button
                  type="button"
                  className={classNames(questionIndex === 0 && "opacity-50", "flex-1 px-6 form-submit-btn lg:flex-none lg:w-20 revert")}
                  onClick={() => setQuestionIndex(questionIndex - 1)}
                  disabled={questionIndex === 0}
                >
                  GERİ
                </button>
                {questionIndex === questions.length - 1 ? (
                  <button
                    type="button"
                    className={classNames(!isQuestionAnswered(questions[questionIndex]) && "opacity-75", "flex-1 px-6 uppercase form-submit-btn lg:flex-none lg:w-32")}
                    disabled={!isQuestionAnswered(questions[questionIndex])}
                    onClick={() => saveSurveyAnswers()}
                  >
                    Onayla
                  </button>
                ) : (
                  <button
                    type="button"
                    className={classNames(!isQuestionAnswered(questions[questionIndex]) && "opacity-75", "flex-1 px-6 uppercase form-submit-btn lg:flex-none lg:w-32")}
                    disabled={!isQuestionAnswered(questions[questionIndex])}
                    onClick={() => questionIndex !== questions.length - 1 && setQuestionIndex(questionIndex + 1)}
                  >
                    Devam Et
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
