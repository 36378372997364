import React, { useEffect, useState, useCallback } from "react";
import Question from "./Question";

export default function ExamModal(props) {
  const {
    setShowExamModal,
    exam,
    questionCount,
    exams,
    setExams,
    setShowSuccessModal,
    setLoading,
    setShowErrorModal,
    setErrorMessage,
    setShowInfoModal,
    previousAnswers,
    setPreviousAnswers,
    userExamHistory,
    questions,
  } = props;

  const [timer, setTimer] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(previousAnswers?.length);
  const [answers, setAnswers] = useState([]);
  const [examResult, setExamResult] = useState(null);

  const isQuestionAnswered = useCallback(
    (questionId) => {
      const examId = exam?.objectId;
      const questionExists = answers.filter(
        (i) => i.questionId === questionId && i.examId === examId
      );
      if (questionExists.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    [answers, exam?.objectId]
  );

  const isAnsweredPreviously = useCallback(
    (questionId) => {
      const questionExists = previousAnswers.filter(
        (i) => i.get("question").id === questionId
      );
      if (questionExists.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    [previousAnswers]
  );

  const setExamAsCompleted = async () => {
    const examsCopy = exams;
    const exactExam = examsCopy?.find((i) => i?.objectId === exam?.objectId);
    const index = examsCopy?.findIndex((i) => i?.objectId === exam?.objectId);
    examsCopy[index] = {
      ...exactExam,
      complete: true,
    };
    setExams([...examsCopy]);
  };

  const setExamAsCancelled = () => {
    const examsCopy = exams;
    const exactExam = examsCopy?.find((i) => i?.objectId === exam?.objectId);
    const index = examsCopy?.findIndex((i) => i?.objectId === exam?.objectId);
    examsCopy[index] = {
      ...exactExam,
      on_time: false,
    };
    setExams([...examsCopy]);
  };

  const handleClose = () => {
    if (examResult) {
      setShowExamModal(false);
      setExamAsCompleted();
    } else {
      const examsCopy = exams;
      const exactExam = examsCopy?.find((i) => i?.objectId === exam?.objectId);
      const index = examsCopy?.findIndex((i) => i?.objectId === exam?.objectId);
      examsCopy[index] = {
        ...exactExam,
        on_time: true,
      };
      setExams([...examsCopy]);
      setShowExamModal(false);
    }
  };

  useEffect(() => {
    endDate && setInterval(calculateTimeLeft, 1000);
    //eslint-disable-next-line
  }, [endDate]);

  useEffect(() => {
    let endDate;
    if (userExamHistory?.start_date?.iso) {
      const userStartDate = new Date(userExamHistory?.start_date?.iso);
      endDate = userStartDate.setMinutes(
        userStartDate.getMinutes() + exam?.time
      );
      setEndDate(endDate);
    } else {
      endDate = new Date();
      endDate.setMinutes(endDate.getMinutes() + exam?.time);
      setEndDate(endDate);
    }
  }, [exam?.time, userExamHistory?.start_date?.iso]);

  const calculateTimeLeft = () => {
    const now = new Date();
    const timeLeft = endDate - now;

    if (timeLeft !== 0) {
      setTimer({
        ...timer,
        days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((timeLeft % (1000 * 60)) / 1000),
      });
    }
  };

  return (
    <div
      className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain lg:align-middle"
      aria-labelledby="modal-title"
      aria-modal="true"
    >
      <div className="flex items-start justify-center min-h-full pb-20 text-center sm:p-0">
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          ​
        </span>
        <div className="min-h-screen inline-block align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle max-w-full sm:w-full sm:pb-16 sm:pt-12 sm:px-12 outline-none">
          <div className="absolute pt-4 pr-4 sm:block top-5 right-5">
            <button
              type="button"
              className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              onClick={() => handleClose()}
            >
              <span className="sr-only">Close</span>
              <span className="sr-only">Profile</span>
              <svg role="img" className="w-6 h-6 text-primary-black">
                <use xlinkHref="/assets/img/necromancers.svg#cross" />
              </svg>
            </button>
          </div>
          <div className="modal-body">
            {!examResult ? (
              <div className="flex flex-col items-center">
                <p className="text-body text-secondary-titles">
                  Kalan Süre:{" "}
                  {timer?.hours
                    ? timer?.hours < 10
                      ? "0" + timer?.hours
                      : timer?.hours
                    : "00"}
                  :
                  {timer?.minutes
                    ? timer?.minutes < 10
                      ? "0" + timer?.minutes
                      : timer?.minutes
                    : "00"}
                  :
                  {timer?.seconds
                    ? timer?.seconds < 10
                      ? "0" + timer?.seconds
                      : timer?.seconds
                    : "00"}
                </p>
                {questions?.map((question, index) => {
                  if (questionIndex === index) {
                    return (
                      <Question
                        key={question?.id}
                        questions={questions}
                        question={question}
                        questionIndex={questionIndex}
                        setQuestionIndex={(index) => setQuestionIndex(index)}
                        questionCount={questionCount}
                        answers={answers}
                        setAnswers={(answers) => setAnswers(answers)}
                        isQuestionAnswered={isQuestionAnswered}
                        exam={exam}
                        exams={exams}
                        setShowExamModal={(show) => setShowExamModal(show)}
                        setExams={(exams) => setExams(exams)}
                        setShowSuccessModal={(show) =>
                          setShowSuccessModal(show)
                        }
                        setLoading={(loading) => setLoading(loading)}
                        setShowErrorModal={(show) => setShowErrorModal(show)}
                        setErrorMessage={(msg) => setErrorMessage(msg)}
                        previousAnswers={previousAnswers}
                        setPreviousAnswers={(answers) =>
                          setPreviousAnswers(answers)
                        }
                        setExamAsCompleted={setExamAsCompleted}
                        timer={timer}
                        setExamAsCancelled={setExamAsCancelled}
                        setShowInfoModal={(show) => setShowInfoModal(show)}
                        setExamResult={(result) => setExamResult(result)}
                        isAnsweredPreviously={isAnsweredPreviously}
                      />
                    );
                  } else {
                    return <span key={index} className="hidden"></span>;
                  }
                })}
              </div>
            ) : (
              <div className="flex flex-col items-center w-full pt-20 lg:pt-0">
                <p className="flex items-center justify-center w-[120px] h-[120px] icon-wrapper bg-validation-confirmation">
                  <span className="sr-only">Check</span>
                  <svg
                    role="img"
                    className="w-10 text-white"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <use xlinkHref="/assets/img/necromancers.svg#check" />
                  </svg>
                </p>
                <div className="mt-8 text-center">
                  <h3 className="text-md">Sınav Tamamlandı</h3>
                </div>
                <div className="mt-8">
                  <p className="text-center">
                    {examResult?.examTitle} sınavının sonuçlarını aşağıda görebilirsiniz.
                  </p>
                </div>
                <div className="flex gap-4 mt-8 lg:gap-11">
                  <div className="flex flex-col items-center justify-center gap-2 bg-secondary-secondary-bg w-[103px] h-[103px] lg:w-[108px] lg:h-[108px]">
                    <p className="font-bold text-md">
                      {examResult?.trueAnswers}
                    </p>
                    <p className="text-base">Doğru</p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2 bg-secondary-secondary-bg w-[103px] h-[103px] lg:w-[108px] lg:h-[108px]">
                    <p className="font-bold text-md">
                      {examResult?.wrongAnswers}
                    </p>
                    <p className="text-base">Yanlış</p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-2 bg-secondary-secondary-bg w-[103px] h-[103px] lg:w-[108px] lg:h-[108px]">
                    <p className="font-bold text-md">{examResult?.reward}</p>
                    <p className="text-base">Kale Puan</p>
                  </div>
                </div>
                <div className="flex justify-center w-full mt-8 lg:w-auto">
                  <button
                    type="button"
                    className="form-submit-btn uppercase max-w-full lg:max-w-[12rem]"
                    onClick={() => handleClose()}
                  >
                    KAPAT
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
