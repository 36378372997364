import React, { useState, useEffect } from "react";
import classNames from "classnames";
import AuthLayout from "../../../layouts/AuthLayout";
import UserKaleWall from "./components/UserKaleWall";
import UserGallery from "./components/UserGallery";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyFriends,
  getPosts,
  // getMyPendingFriends,
  addFriend,
  removeFriend,
  getMyPendingFriends,
  responseToFriendRequest,
  getUserGalleries,
} from "../../../services/contentService";

import LoadingModal from "../../../components/shared/LoadingModal";
import { SET_MESSAGES } from "../../../store/header/headerSlice";
import { SET_MESSAGE_USER } from "../../../store/message/messageSlice";
import { useParams } from "react-router-dom";
import { getSingleUser, getBayiFirms } from "../../../services/authService";
import { Helmet } from "react-helmet";

function Profile() {
  const ownUser = useSelector((state) => state.app.user);
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firms, setFirms] = useState([]);
  const [isMyFriend, setIsMyFriend] = useState(false);
  const [isProfilePrivate, setIsProfilePrivate] = useState(false);
  const [isFriendReqSent, setIsFriendReqSent] = useState(false);
  const [isFriendReqExist, setIsFriendReqExist] = useState(false);
  const [albums, setAlbums] = useState(null);

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleGetUserGalleries = async () => {
    setLoading(true);
    const galleries = await getUserGalleries(user?.objectId);
    setAlbums(galleries);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getMyFriends();
      const isMyFriend = result.some((friend) => friend.id === id);

      setIsMyFriend(isMyFriend);
      setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await getMyPendingFriends();
      setIsFriendReqExist(
        result.some((friend) => friend.id === user?.objectId)
      );
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const firms = await getBayiFirms();
      setFirms(firms);

      setLoading(false);
    })();
    //eslint-disable-next-line
  }, [user?.firm]);

  const getFirmName = (firmId) => {
    const currentFirm = firms?.find((firm) => firm.Id === firmId);
    return currentFirm?.Name;
  };
  // getFirmName(user?.firm);

  const handleAddFriend = async () => {
    setLoading(true);
    try {
      const result = await addFriend(id);
      if (result === "başarılı") {
        // setIsMyFriend(true);
        setIsFriendReqSent(true);
      }
      if (result === "Bu isteği daha once gönderdin") {
        setIsFriendReqSent(true);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    //fetch user from parse server
    setLoading(true);
    getSingleUser(id)
      .then((user) => {
        setUser(user);
        setIsProfilePrivate(user?.hide_profile ? true : false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    handleGetPosts();
    handleGetUserGalleries();
    //eslint-disable-next-line
  }, [user]);

  const handleGetPosts = async () => {
    setLoading(true);
    if (user?.objectId) {
      const posts = await getPosts(user?.objectId);
      if (posts) {
        setPosts(posts);
      }
    }

    setLoading(false);
  };
  const handleRemoveFriend = async (id) => {
    try {
      setLoading(true);
      await removeFriend(id);
      setIsMyFriend(false);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const handleAcceptFriendReq = async (friend_id) => {
    try {
      setLoading(true);
      await responseToFriendRequest(user?.objectId, "accept");
      setIsMyFriend(true);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRejectFriendReq = async (friend_id) => {
    try {
      setLoading(true);
      await responseToFriendRequest(user?.objectId, "reject");
      setIsMyFriend(false);
      setIsFriendReqExist(false);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QFLY5D5WWK"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-QFLY5D5WWK");
          `}
        </script>
      </Helmet>
      <AuthLayout>
        {loading && <LoadingModal closeButton={false} />}
        <div id="content" className="lg:p-0">
          <div className="py-12 bg-background-teal md:px-4 lg:py-16 lg:px-0">
            <main className="px-4 mx-auto max-w-7xl lg:px-1">
              <div className="flex flex-col items-center justify-between grid-cols-12 px-4 lg:grid lg:flex-row lg:px-0">
                <div className="flex flex-col col-span-8 lg:flex-row lg:pl-28">
                  <div className="relative flex items-center justify-center w-40 h-40 rounded-full bg-secondary-titles">
                    {user?.profile_image || user?.profileImage?.url ? (
                      <img
                        src={
                          user?.profile_image?.url || user?.profileImage?.url
                        }
                        className="w-full h-full object-cover object-center rounded-full"
                        alt={user?.name || user?.Name}
                      />
                    ) : (
                      <>
                        <span className="sr-only">User</span>
                        <svg role="img" className="text-white w-20 h-20">
                          <use xlinkHref="/assets/img/necromancers.svg#user" />
                        </svg>
                      </>
                    )}
                    {user?.objectId === ownUser?.objectId && (
                      <button className="absolute flex items-center justify-center w-12 h-12 overflow-hidden border-2 rounded-full bg-secondary-primary border-primary-white -bottom-6">
                        <input
                          type="file"
                          className="absolute h-full opacity-0 cursor-pointer"
                        />
                        <svg role="img" className="w-6 h-6 text-white">
                          <use xlinkHref="/assets/img/necromancers.svg#photo-add" />
                        </svg>
                      </button>
                    )}
                  </div>

                  <div className="flex flex-col items-center pt-12 lg:items-start lg:pt-3 lg:ml-6">
                    <p className="mb-2 text-sm font-bold">
                      {user?.Name || user?.name}
                    </p>
                    {((isProfilePrivate && isMyFriend) ||
                      !isProfilePrivate ||
                      isMyFriend ||
                      ownUser?.objectId === user?.objectId) && (
                      <>
                        <p className="mb-2 text-base">
                          {getFirmName(user?.firm)}
                        </p>
                        <p className="mb-2 text-base">
                          {user?.TypeKS__c && !user?.TypeKK__c && "KS"}
                          {user?.TypeKK__c && !user?.TypeKS__c && "KK"}
                          {user?.TypeKS__c && user?.TypeKK__c && "KS - KK"}
                        </p>
                        <a
                          href="mailto:musa@abckimya.com"
                          className="flex items-center mb-2 text-base"
                        >
                          <svg
                            role="img"
                            className="w-5 h-5 mr-1.5 text-secondary-primary"
                          >
                            <use xlinkHref="/assets/img/necromancers.svg#mail" />
                          </svg>
                          {user?.Email || user?.email || user?.Email__c}
                        </a>
                        <a
                          href={`tel:${user?.Mobile__c || user?.phone}`}
                          className="flex items-center text-base"
                        >
                          <svg
                            role="img"
                            className="w-5 h-5 mr-1.5 text-secondary-primary"
                          >
                            <use xlinkHref="/assets/img/necromancers.svg#phone" />
                          </svg>
                          {user?.Mobile__c || user?.phone}
                        </a>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex  w-full col-span-4 pt-8 text-center">
                  {/* {ownUser?.objectId !== user?.objectId && (
                  <button
                    onClick={() => {
                      dispatch(SET_MESSAGES(true));
                      dispatch(
                        SET_MESSAGE_USER({
                          id: user?.id || user?.objectId,
                          name: user?.Name || user?.name,
                          profile_image: user?.profile_image?.url,
                        })
                      );
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    className="w-full max-w-full mx-auto uppercase lg:w-48 bg-primary-red form-submit-btn"
                  >
                    MESAJ GÖNDER
                  </button>
                )} */}

                  {!isMyFriend &&
                    ownUser?.objectId !== user?.objectId &&
                    !isFriendReqSent &&
                    !isFriendReqExist && (
                      <button
                        onClick={handleAddFriend}
                        className="w-full max-w-full mx-auto uppercase lg:w-48 bg-primary-red form-submit-btn"
                      >
                        Arkadaş Ekle
                      </button>
                    )}
                  {!isMyFriend &&
                    ownUser?.objectId !== user?.objectId &&
                    !isFriendReqSent &&
                    isFriendReqExist && (
                      <div className="flex">
                        <button
                          onClick={() => handleAcceptFriendReq(user?.objectId)}
                          className="mr-5 inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                        >
                          Arkadaşlık isteğini onayla
                        </button>
                        <button
                          onClick={() => handleRejectFriendReq(user?.objectId)}
                          className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                        >
                          Arkadaşlık isteğini reddet
                        </button>
                      </div>
                    )}
                  {!isMyFriend &&
                    ownUser?.objectId !== user?.objectId &&
                    isFriendReqSent && (
                      <button className="inline-flex items-center w-full px-6 py-4 lg:mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto">
                        İstek Gönderildi
                      </button>
                    )}
                  {isMyFriend && (
                    <>
                      <div className="flex w-full md:w-auto">
                        <button
                          onClick={() => handleRemoveFriend(user?.objectId)}
                          className=" items-center w-full px-6 py-4 mr-5  btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                        >
                          Arkadaşlıktan Çıkar
                        </button>
                      </div>
                      <div className="flex w-full md:w-auto">
                        <button
                          onClick={() => {
                            dispatch(SET_MESSAGES(true));
                            dispatch(
                              SET_MESSAGE_USER({
                                id: user?.id || user?.objectId,
                                name: user?.Name || user?.name,
                                profile_image: user?.profile_image?.url,
                              })
                            );
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          className=" items-center w-full px-6 py-4 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                        >
                          MESAJ GÖNDER
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </main>
          </div>
          {(isProfilePrivate && isMyFriend) ||
          !isProfilePrivate ||
          isMyFriend ||
          ownUser?.objectId === user?.objectId ? (
            <>
              <div className="px-4 mx-auto my-12 lg:px-1 max-w-7xl lg:my-16">
                {/* tab btn start */}
                <div className="w-full mb-12 text-center">
                  <div className="relative z-0 flex justify-center lg:inline-flex bg-secondary-secondary-bg mx-auto p-0.5">
                    <button
                      type="button"
                      onClick={() => setActiveTab(1)}
                      className={classNames(
                        activeTab === 1
                          ? "inline-block !bg-primary-red !text-white active"
                          : "inline-block !text-primary-black !bg-transparent",
                        "p-3 tab-btn hover:text-white lg:w-48"
                      )}
                    >
                      Kale Duvarı
                    </button>
                    <button
                      type="button"
                      onClick={() => setActiveTab(2)}
                      className={classNames(
                        activeTab === 2
                          ? "inline-block !bg-primary-red !text-white active"
                          : "inline-block !text-primary-black !bg-transparent",
                        "p-3 break-normal tab-btn hover:text-white lg:w-48"
                      )}
                    >
                      Albüm
                    </button>
                  </div>
                </div>
                {/* tab btn end */}
                {activeTab === 1 ? (
                  <UserKaleWall
                    posts={posts}
                    handleGetPosts={handleGetPosts}
                    setPosts={(posts) => setPosts(posts)}
                  />
                ) : (
                  <UserGallery
                    albums={albums}
                    setAlbums={(albums) => setAlbums(albums)}
                    user={user}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="px-4 mx-auto my-12 lg:px-1 max-w-7xl lg:my-16">
                <div className="w-full mb-12 text-center">
                  <h1>Gizli Hesap</h1>
                </div>
              </div>
            </>
          )}
        </div>
      </AuthLayout>
    </>
  );
}

export default Profile;
