import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "./header/headerSlice";
import footerSlice from "./footer/footerSlice";
import appSlice from "./app/appSlice";
import messageSlice from "./message/messageSlice";

const store = configureStore({
  reducer: {
    header: headerSlice,
    footer: footerSlice,
    app: appSlice,
    message: messageSlice
  },
});

export default store;
