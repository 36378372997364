import React, { useState } from "react";

function CookieBar() {
  const [showModal, setShowModal] = useState(false);
  const [showCookie, setShowCookie] = useState(true);
  return (
    <>
      {showCookie && (
        <div className="fixed bottom-0 left-0 z-50 w-full p-12 bg-white shadow-md md:bottom-10 md:left-10 md:max-w-lg">
          <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
            <button
              onClick={() => setShowCookie(false)}
              type="button"
              className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-6 h-6 text-primary-black"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          <p>
            Sitemizde sunulan özelliklerin ve sitenin işleyişi için bazı
            çerezlerin kullanılması teknik olarak zorunludur. Diğer bazı
            çerezler de sitemizi geliştirmek ve bizim tarafımızdan veya yetkili
            hizmet sağlayıcılarımız tarafından size ilgi alanınıza göre
            reklamların sunulması amacıyla kullanılmaktadır. Detaylı bilgi için{" "}
            <button onClick={() => setShowModal(true)} className="font-xs">
              Çerez Politikası
            </button>{" "}
            metnini inceleyebilirsiniz.
          </p>
        </div>
      )}

      {/* full modal */}
      {showModal && (
        <div
          className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain"
          aria-labelledby="modal-title"
          aria-modal="true"
        >
          <div className="flex items-start justify-center min-h-full pb-20 text-center sm:p-0">
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="min-h-screen inline-block align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle {{ modalSize }} sm:w-full sm:pb-16 sm:pt-12 sm:px-12 outline-none">
              <div className="absolute pt-4 pr-4 sm:block top-5 right-5">
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="w-6 h-6 text-primary-black"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.7504 4.75042L13.9445 11.5563L20.7504 18.3623L18.8059 20.3068L12 13.5008L5.19407 20.3068L3.24958 18.3623L10.0555 11.5563L3.24958 4.75042L5.19407 2.80593L12 9.61186L18.8059 2.80593L20.7504 4.75042Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                <div className="flex flex-col items-center">
                  <div className="space-y-4 lg:space-y-12">
                    <h1 className="text-md lg:text-xxl" id="modal-title">
                      Çerez Politikası
                    </h1>
                    <div className="prose-xxl">
                      <p>
                        Venenatis, velit elementum tincidunt tincidunt volutpat
                        lobortis. Semper vulputate bibendum cras purus elit
                        euismod. Ut enim iaculis tincidunt nibh vestibulum
                        dictum. Tellus elementum nisl porttitor sit dictumst leo
                        platea in. Diam non diam, dictumst condimentum leo,
                        lacinia. Lobortis in gravida laoreet nisi habitasse eu.
                        Ullamcorper nunc, venenatis, imperdiet amet. A et, amet,
                        suscipit tortor egestas elit. Feugiat mauris pulvinar
                        vitae consequat volutpat. Varius sed potenti amet
                        malesuada dui neque, ac ut. Odio malesuada sed lorem
                        lectus praesent. Facilisi quam tellus fermentum sagittis
                        cursus eget sed risus.
                      </p>
                      <p>
                        Donec dui nulla magna ipsum lacinia. Augue ullamcorper
                        lectus lacus mauris, sodales morbi. Posuere velit neque
                        turpis dignissim quam. Mauris a sed consequat aliquet.
                        Volutpat urna consectetur varius quam nullam.
                        Scelerisque quisque fringilla velit proin at tortor
                        risus, integer. Sit odio arcu nibh sed quis eget
                        pulvinar convallis. Dolor tempor eget risus magna at.
                        Cursus tincidunt nascetur et non sapien nibh turpis
                        egestas. Tincidunt faucibus viverra viverra amet
                        suspendisse. Imperdiet fusce netus id eget aliquam at
                        amet. A id mauris vitae a at. Mauris dolor, quis dictum
                        libero aliquam. Nisl pulvinar libero faucibus sociis a
                        sapien. Nec, et molestie tellus tincidunt.
                      </p>
                      <p>
                        Morbi diam feugiat bibendum eu augue vulputate pulvinar.
                        At quis nam venenatis nulla aliquet id leo sociis.
                        Adipiscing in proin feugiat quis sit consequat vel odio
                        habitant. Tortor placerat a, libero aliquet. Mus risus
                        in lacus in euismod hac faucibus blandit in. Enim vitae
                        lectus feugiat dolor velit, enim volutpat, euismod
                        fames. Donec iaculis amet magna vel. Aliquam sit nisl,
                        nulla nulla feugiat risus integer.
                      </p>
                      <p>
                        Et sapien consectetur bibendum malesuada sodales est.
                        Urna pellentesque quisque egestas porttitor fermentum
                        metus maecenas. Egestas vel pretium, eu mauris fames.
                        Purus mi egestas ut ullamcorper posuere. Interdum
                        suscipit nibh massa vivamus ullamcorper at turpis enim.
                        Urna et aliquam et nunc ante volutpat commodo a. Egestas
                        netus consequat id neque velit faucibus aliquet quis.
                        Cras justo, a, consectetur eu nisl, pellentesque. Eu
                        pulvinar eleifend suscipit vel, sed pellentesque vitae.
                        Nisl fermentum scelerisque morbi purus, sit lacus. In
                        scelerisque a, arcu nulla eleifend nunc.
                      </p>
                      <p>
                        Augue ante magna ut suspendisse. Ac enim, quisque quis
                        amet aliquet feugiat sed egestas. Integer diam sed
                        molestie non turpis amet amet. In nisl consectetur id
                        egestas non. Orci vehicula in justo, sit interdum
                        posuere in. Habitasse sit vel aenean purus dignissim
                        eget id. Leo ornare mauris euismod interdum nec, dis
                        sodales porttitor. Accumsan leo, felis vestibulum amet,
                        fermentum. Pellentesque nulla nec velit aliquam lobortis
                        risus. Tellus volutpat tincidunt amet, amet bibendum
                        fusce habitant volutpat.
                      </p>
                      <p>
                        Cursus viverra consequat mauris orci sodales sed
                        facilisis libero, dui. Amet bibendum arcu sit dictum
                        mattis nunc. Orci quam congue mattis habitasse ante
                        arcu, volutpat est ultrices.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CookieBar;
