import Parse from "parse";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import NonLoginHomePage from "./pages/NonLoginHomePage/NonLoginHomePage";
import AboutUsPage from "./pages/AboutUs/AboutUsPage";
import ContactPage from "./pages/Contact/ContactPage";
import NotFoundPage from "./pages/404/NotFoundPage";

import ChangePassword from "./pages/ChangePassword/ChangePassword";

import RegisterPage from "./pages/Register/RegisterPage";
import News from "./pages/News/NewsList/News";
import NewsDetails from "./pages/News/NewsDetails/NewsDetails";
import UpdateProfile from "./pages/Profile/UpdateProfile/UpdateProfile";
import Library from "./pages/Library/Library";
import EducationDocs from "./pages/EducationDocs/EducationDocs";
//cookie bar
import CookieBar from "./components/shared/CookieBar";
import Kvkk from "./pages/kvkk/Kvkk";
import Faq from "./pages/Faq/Faq";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import CookiePolicy from "./pages/CookiePolicy/CookiePolicy";
import AuthProvider from "./AuthProvider";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import RequireAuth from "./RequireAuth";
import NonRequireAuth from "./NonRequireAuth";
import KaleWall from "./pages/KaleWall/KaleWall";
import Friends from "./pages/Friends/Friends";
import OtherDocs from "./pages/OtherDocs/OtherDocs";
import Gallery from "./pages/Gallery/Gallery";
import GalleryDetails from "./pages/GalleryDetails/GalleryDetails";
import UserGalleryDetails from "./pages/UserGalleryDetails/UserGalleryDetails";
import ConsumptionCalc from "./pages/ConsumptionCalc/ConsumptionCalc";
import GalleryEdit from "./pages/GalleryEdit/GalleryEdit";
import Surveys from "./pages/Surveys/Surveys";
import Profile from "./pages/Profile/Profile/Profile";
import LiveStream from "./pages/LiveStream/LiveStream";
import VerifyFriendReq from "./pages/VerifyFriendReq/VerifyFriendReq";
import InformationCube from "./pages/InformationCube/InformationCube";
import Activity from "./pages/Activity/Activity";
import ActivityDetail from "./pages/Activity/components/ActivityDetail";
import Points from "./pages/Points/Points";
import TagManager from "react-gtm-module";
import OffSiteSource from "./pages/OffsiteSource/OffSiteSource";

const tagManagerArgs = {
  gtmId: "GTM-MFPNZJQ",
};

function App() {
  Parse.initialize(
    process.env.REACT_APP_PARSE_APPLICATION_ID,
    process.env.REACT_APP_PARSE_JAVASCRIPT_KEY
  );
  Parse.serverURL = process.env.REACT_APP_PARSE_HOST_URL;
  TagManager.initialize(tagManagerArgs);

  return (
    <>
      <CookieBar />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route element={<NonRequireAuth />}>
              <Route exact path="/" element={<NonLoginHomePage />} />
              <Route path="/register" element={<RegisterPage />} />
            </Route>
            <Route element={<RequireAuth />}>
              <Route path="/home" element={<HomePage />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/news" element={<News />} />
              <Route path="/news/:id" element={<NewsDetails />} />
              <Route
                path="/profile/update-profile"
                element={<UpdateProfile />}
              />
              <Route path="/user/:id" element={<Profile />} />
              <Route path="/profile/surveys" element={<Surveys />} />
              <Route path="/about" element={<AboutUsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/kvkk" element={<Kvkk />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/kale-wall" element={<KaleWall />} />
              <Route path="/library" element={<Library />} />
              <Route path="/education" element={<EducationDocs />} />
              <Route path="/other-docs" element={<OtherDocs />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/gallery/:id" element={<GalleryDetails />} />
              <Route
                path="/user-gallery/:id"
                element={<UserGalleryDetails />}
              />
              <Route path="/user-gallery/edit/:id" element={<GalleryEdit />} />
              <Route path="/friends" element={<Friends />} />
              <Route path="/consumption-calc" element={<ConsumptionCalc />} />
              <Route path="/live-stream" element={<LiveStream />} />
              <Route path="/activity" element={<Activity />} />
              <Route path="/activity/:id" element={<ActivityDetail />} />
              <Route path="/points" element={<Points />} />
              <Route path="/offsite-source" element={<OffSiteSource />} />
            </Route>
            <Route
              path="/mail_dogrulama/:token"
              element={<EmailVerification />}
            />
            <Route
              path="/verify-friend-request/:id"
              element={<VerifyFriendReq />}
            />
            <Route path="/information-cube" element={<InformationCube />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
