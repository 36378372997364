import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  getAllAnswersByUser,
  getSurveyQuestions,
} from "../../../services/contentService";
import CompletedSurveyModal from "./CompletedSurveyModal";
import SurveyModal from "./SurveyModal";

export default function Survey(props) {
  const [showModal, setShowModal] = useState(false);
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [surveyResults, setSurveyResults] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const {
    survey,
    setSurveys,
    surveys,
    setShowSuccessModal,
    setLoading,
    setApiError,
    setShowErrorModal,
    user,
  } = props;

  useEffect(() => {
    if (survey?.complete && surveyResults.length > 0) {
      setIsVisible(true);
    } else if (!survey.complete && questions.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [survey?.complete, surveyResults.length, questions.length]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const questionsForPush = [];
      const answersForPush = [];
      try {
        if (!survey?.complete) {
          const questions = await getSurveyQuestions(survey?.objectId);
          questions?.forEach((question) => {
            questionsForPush.push(question?.toJSON());
          });
          questionsForPush?.sort((a, b) => compareOrders(a, b));
          setQuestions([...questionsForPush]);
        } else {
          const results = await getAllAnswersByUser(
            survey?.objectId,
            user?.objectId
          );
          results?.forEach((result) => {
            answersForPush.push(result?.toJSON());
          });
          answersForPush?.sort((a, b) =>
            compareOrders(a?.question, b?.question)
          );
          setSurveyResults([...answersForPush]);
        }
      } catch (error) {
        setApiError(error?.message);
        setShowErrorModal(true);
      } finally {
        setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);

  const compareOrders = (a, b) => {
    return a?.order - b?.order;
  };

  return (
    isVisible && (
      <div className="p-6 mb-6 border border-secondary-lines lg:p-6">
        {showModal && (
          <SurveyModal
            survey={survey}
            setShowModal={(show) => setShowModal(show)}
            surveys={surveys}
            setSurveys={(surveys) => setSurveys(surveys)}
            setShowSuccessModal={(show) => setShowSuccessModal(show)}
            setLoading={(loading) => setLoading(loading)}
            questions={questions}
            setApiError={(error) => setApiError(error)}
            setShowErrorModal={(show) => setShowErrorModal(show)}
            surveyResults={surveyResults}
            setSurveyResults={(results) => setSurveyResults(results)}
          />
        )}

        {showCompletedModal && (
          <CompletedSurveyModal
            survey={survey}
            setShowModal={(show) => setShowCompletedModal(show)}
            surveyAnswers={surveyResults}
          />
        )}
        <div className="flex flex-col justify-between lg:flex-row lg:items-center">
          <h5 className="mb-4 text-body-l lg:mb-0">{survey?.title}</h5>
          <button
            onClick={() =>
              survey?.complete
                ? setShowCompletedModal(true)
                : setShowModal(true)
            }
            className={classNames(
              survey?.complete ? "revert lg:w-24" : "lg:w-40",
              "w-full px-6 py-4 uppercase bg-primary-red form-submit-btn max-w-none"
            )}
          >
            {survey?.complete ? "İNCELE " : "ŞİMDİ CEVAPLA"}
          </button>
        </div>
      </div>
    )
  );
}
