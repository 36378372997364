import React, { useEffect, useState } from "react";
import { getMyFriends, removeFriend } from "../../../services/contentService";
import LoadingModal from "../../../components/shared/LoadingModal";
import { useDispatch } from "react-redux";
import { SET_MESSAGES } from "../../../store/header/headerSlice";
import { SET_MESSAGE_USER } from "../../../store/message/messageSlice";
import { Link } from "react-router-dom";

const MyFriends = () => {
  const [myFriendsList, setMyFriendsList] = useState([]);
  const [filteredFriendsList, setFilteredFriendsList] = useState([]);
  const [friendsWithGroups, setFriendsWithGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getMyFriends();
      setMyFriendsList(result);
      setFilteredFriendsList(result);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    const result = filteredFriendsList
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .reduce((total, friend) => {
        let group = friend.name[0].toUpperCase();
        if (!total?.[group]) {
          total[group] = { group, users: [friend] };
        } else {
          total[group].users.push(friend);
        }
        //TODO: sorting
        return total;
      }, {});

    const groupedResult = Object.keys(result).map((k) => result[k]);
    const sortedByGroup = groupedResult.sort((a, b) =>
      a.group.toLowerCase() > b.group.toLowerCase() ? 1 : -1
    );
    setFriendsWithGroup(sortedByGroup);
  }, [filteredFriendsList]);

  useEffect(() => {
    if (searchValue) {
      const filteredList = myFriendsList.filter((friend) => {
        return friend.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      setFilteredFriendsList(filteredList);
    } else {
      setFilteredFriendsList(myFriendsList);
    }
    //eslint-disable-next-line
  }, [searchValue]);

  const handleRemoveFriend = async (id) => {
    try {
      setIsLoading(true);
      await removeFriend(id);
      const result = await getMyFriends();
      setMyFriendsList(result);
      setFilteredFriendsList(result);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingModal />}
      <div className="relative form-input-wrapper search-container max-w-[580px] mx-auto w-full">
        <input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          type="text"
          id="searchInput"
          autoComplete="search"
          name="search"
          placeholder=" "
          className="bg-transparent form-input"
        />
        <label htmlFor="search" className="bg-transparent form-label">
          Kişi Ara
        </label>
        <button
          type="button"
          className="absolute top-0 z-10 p-2 right-2 whitespace-nowrap search-icon"
          id="menu-button"
        >
          <svg role="img" className="w-6 link-icon text-primary-black">
            <use xlinkHref="/assets/img/necromancers.svg#search" />
          </svg>
        </button>
        <button
          type="button"
          className="absolute top-0 z-10 hidden p-2 right-2 whitespace-nowrap delete-icon"
          id="menu-button"
        >
          <svg role="img" className="w-6 link-icon text-primary-black ">
            <use xlinkHref="/assets/img/necromancers.svg#delete" />
          </svg>
        </button>
      </div>

      <div className="flex flex-col w-full p-4 pr-12 lg:p-0">
        {friendsWithGroups.length > 0 &&
          friendsWithGroups?.map((item, index) => {
            return (
              <div key={index}>
                <h2 className="text-xxl">{item.group}</h2>
                {item.users.map((user) => (
                  <div
                    key={user.id}
                    className="flex w-full pb-4 mt-4 border-b border-secondary-lines"
                  >
                    <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
                      <div className="flex">
                        <Link to={`/user/${user?.id}`}>
                          <div className="flex items-center justify-center w-[88px] h-[88px] overflow-hidden rounded-full mr-6 bg-secondary-titles">
                            {user.profile_image && (
                              <img
                                src={`${user.profile_image}`}
                                alt={user.name}
                                className="w-full h-full object-cover object-center"
                              />
                            )}
                            {!user.profile_image && (
                              <svg
                                role="img"
                                className="w-[66px] h-[66px] overflow-hidden rounded-full text-white"
                              >
                                <use xlinkHref="/assets/img/necromancers.svg#user" />
                              </svg>
                            )}
                          </div>
                        </Link>
                        <div className="flex flex-col justify-center">
                          <Link to={`/user/${user?.id}`}>
                            <p className="mb-1 text-body-l font-md">
                              {user.name}
                            </p>
                          </Link>
                          {/* <p>ABC Kimya</p> */}
                        </div>
                      </div>
                      <div className="flex w-full md:w-auto">
                        <button
                          onClick={() => {
                            dispatch(SET_MESSAGES(true));
                            dispatch(
                              SET_MESSAGE_USER({
                                id: user?.id,
                                name: user?.name,
                                profile_image: user?.profile_image,
                              })
                            );
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          }}
                          className="inline-flex items-center w-full px-6 py-4 mt-6 mr-5 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                        >
                          mesaj GÖNDER
                        </button>
                        <button
                          onClick={() => handleRemoveFriend(user.id)}
                          className="inline-flex items-center w-full px-6 py-4 mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
                        >
                          Arkadaşlarımdan Çıkar
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}

        {/* <h2 className="text-xxl" id="A">
          A
        </h2>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex w-full md:w-auto">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                mesaj GÖNDER
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex w-full md:w-auto">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                mesaj GÖNDER
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex w-full md:w-auto">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                mesaj GÖNDER
              </a>
            </div>
          </div>
        </div>
        <h2 className="mt-12 text-xxl" id="B">
          B
        </h2>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex w-full md:w-auto">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                mesaj GÖNDER
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex w-full md:w-auto">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                mesaj GÖNDER
              </a>
            </div>
          </div>
        </div>
        <div className="flex w-full pb-4 mt-4 border-b border-secondary-lines">
          <div className="flex flex-col items-start justify-between w-full md:items-center md:flex-row">
            <div className="flex">
              <div className="w-[88px] h-[88px] overflow-hidden rounded-full mr-6">
                <img src="/assets/img/dummy/profile-pic.png" alt="" />
              </div>
              <div className="flex flex-col justify-center">
                <p className="mb-1 text-body-l font-md">Ahmet Aslan</p>
                <p>ABC Kimya</p>
              </div>
            </div>
            <div className="flex w-full md:w-auto">
              <a
                href="#"
                className="inline-flex items-center w-full px-6 py-4 mt-6 btn border-primary-red revert text-primary-red hover:text-primary-white md:mt-0 md:w-auto"
              >
                mesaj GÖNDER
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default MyFriends;
