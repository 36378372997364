import React, { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { ResendOTPSMSBayi } from "../../../../services/authService";
function SmsVerification({
  shouldShow,
  updateModalState,
  handleAfterClose,
  duration,
  setDuration,
  handleAfterSubmit,
  code,
  setCode,
  jwt,
  setJwt,
  successMsg,
  setSuccessMsg,
  errMsg,
  setErrMsg,
  isLoading,
}) {
  const [firstInput, setFirstInput] = useState("");
  const [secondInput, setSecondInput] = useState("");
  const [thirdInput, setThirdInput] = useState("");
  const [fourthInput, setFourthInput] = useState("");
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const [strokeNum, setStrokeNum] = useState(283);
  const ref = useRef();
  useOnClickOutside(ref, () => updateModalState(false));
  const FULL_DASH_ARRAY = 283;
  const handleAfterResend = () => {
    ResendOTPSMSBayi(jwt)
      .then((result) => {
        setErrMsg("");
        setJwt(result.jwt);
        setDuration(
          Math.round((Date.parse(`${result.expiresAt}`) - Date.now()) / 1000)
        );
        setSuccessMsg(`${result}`);
      })
      .catch((e) => {
        setErrMsg(e.message);
        setFirstInput("");
        setSecondInput("");
        setThirdInput("");
        setFourthInput("");
      });
  };
  useEffect(() => {
    let interval;
    if (duration) {
      interval = setInterval(() => {
        setDuration(duration - 1);
        setSeconds(duration % 60);
        setMinutes(Math.floor(duration / 60));
        setCircleDasharray();
      }, 1000);
    }

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, [duration, setDuration, updateModalState, handleAfterResend]);
  useEffect(() => {
    if (duration === 0) {
      setDuration(0);
      setSeconds(0);
    }
    //eslint-disable-next-line
  }, [duration]);

  useEffect(() => {
    setCode(`${firstInput}${secondInput}${thirdInput}${fourthInput}`);
    //eslint-disable-next-line
  }, [code, firstInput, fourthInput, secondInput, thirdInput]);
  const handleSubmit = (e) => {
    e.preventDefault();

    handleAfterSubmit();
  };
  function calculateTimeFraction() {
    const rawTimeFraction = duration / 120;
    return rawTimeFraction - (1 / 120) * (1 - rawTimeFraction);
  }
  function setCircleDasharray() {
    const circleDasharray = `${(
      calculateTimeFraction() * FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    setStrokeNum(circleDasharray);
  }

  if (shouldShow) {
    return (
      <div
        className="fixed inset-0 z-[100] overflow-y-auto overscroll-contain"
        aria-labelledby="modal-title"
        aria-modal="true"
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 transition-opacity bg-primary-black bg-opacity-90"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="max-w-lg inline-block align-bottom bg-white px-4 pt-5 pb-16 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle {{ modalSize }} sm:w-full sm:pb-16 sm:pt-12 sm:px-12 outline-none">
            <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                onClick={() => {
                  updateModalState(false);
                  setFirstInput("");
                  setSecondInput("");
                  setThirdInput("");
                  setFourthInput("");
                  setErrMsg("");
                  if (handleAfterClose) handleAfterClose();
                }}
                type="button"
                className="bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <svg role="img" className="w-6 h-6 text-primary-black">
                  <use xlinkHref="/assets/img/necromancers.svg#cross" />
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="justify-center flex-auto pb-6 text-center">
                <div className="flex justify-center">
                  <div className="base-timer">
                    <svg
                      className="base-timer__svg"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g className="base-timer__circle">
                        <circle
                          className="base-timer__path-elapsed"
                          cx="50"
                          cy="50"
                          r="45"
                          shape-rendering="optimizeQuality"
                        ></circle>
                        <path
                          id="base-timer-path-remaining"
                          strokeDasharray={strokeNum}
                          className="base-timer__path-remaining"
                          d="
                  M 50, 50
                  m -45, 0
                  a 45,45 0 1,0 90,0
                  a 45,45 0 1,0 -90,0
                "
                        ></path>
                      </g>
                    </svg>
                    <span id="base-timer-label" className="base-timer__label">
                      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </div>
                </div>
                <h2 className="py-4 modal-title">Cep Telefonu Doğrula</h2>
                <p className="modal-content-text">
                  Size gelen tek kullanımlık dört haneli kodu giriniz.
                </p>
                <div className="flex justify-center mt-8 space-x-6 form-input-wrapper ">
                  <input
                    value={firstInput}
                    onChange={(e) => setFirstInput(e.target.value)}
                    type="text"
                    name="otp-1"
                    className="form-input otp"
                    maxlength="1"
                  />
                  <input
                    value={secondInput}
                    onChange={(e) => setSecondInput(e.target.value)}
                    type="text"
                    name="otp-2"
                    className="form-input otp"
                    maxlength="1"
                  />
                  <input
                    value={thirdInput}
                    onChange={(e) => setThirdInput(e.target.value)}
                    type="text"
                    name="otp-3"
                    className="form-input otp"
                    maxlength="1"
                  />
                  <input
                    value={fourthInput}
                    onChange={(e) => setFourthInput(e.target.value)}
                    type="text"
                    name="otp-4"
                    className="form-input otp"
                    maxlength="1"
                  />
                </div>
              </div>

              <div className="flex justify-center w-full mt-6">
                {!isLoading && (
                  <button
                    onClick={handleSubmit}
                    className="w-full max-w-full uppercase form-submit-btn"
                  >
                    Doğrula
                  </button>
                )}
                {isLoading && (
                  <button
                    className="w-full max-w-full uppercase form-submit-btn opacity-50"
                    disabled
                  >
                    Doğrulanıyor...
                  </button>
                )}
              </div>

              <div className="w-full space-y-6">
                <h6 className="mt-16 text-xs text-center">
                  Doğrulama kodu gelmedi mi?
                </h6>
                <p
                  role="alert"
                  className="mt-2 text-validation-error text-center"
                  id="email-error"
                >
                  {errMsg}
                </p>
                <div className="flex justify-center">
                  <div
                    onClick={handleAfterResend}
                    className="w-full max-w-full uppercase form-submit-btn revert hover:cursor-pointer"
                  >
                    Tekrar Gönder
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default SmsVerification;
