import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  about: false,
  social: false,
  contact: false,
  profile: false,
  other: false,
};

export const footerSlice = createSlice({
  name: "footerSlice",
  initialState,
  reducers: {
    TOGGLE_SOCIAL: (state) => {
      state.social = !state.social;
    },
    TOGGLE_ABOUT: (state) => {
      state.about = !state.about;
    },
    TOGGLE_CONTACT: (state) => {
      state.contact = !state.contact;
    },
    TOGGLE_PROFILE: (state) => {
      state.profile = !state.profile;
    },
    TOGGLE_OTHER: (state) => {
      state.other = !state.other;
    },
  },
});

export const {
  TOGGLE_SOCIAL,
  TOGGLE_ABOUT,
  TOGGLE_CONTACT,
  TOGGLE_PROFILE,
  TOGGLE_OTHER,
} = footerSlice.actions;
export default footerSlice.reducer;
